import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "input" };
const _hoisted_2 = ["id", "value", "disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_button = _resolveComponent("v-button");
    const _component_v_calendar = _resolveComponent("v-calendar");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.containerClass),
        ref: "containerRef"
    }, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("input", _mergeProps({
                ref: "inputRef",
                id: _ctx.id,
                type: "text",
                autoComplete: "off",
                value: _ctx.date,
                onInput: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.onInput && _ctx.onInput(...args))),
                onFocus: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
                onBlur: _cache[2] || (_cache[2] =
                    //@ts-ignore
                    (...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
                onKeydown: _cache[3] || (_cache[3] =
                    //@ts-ignore
                    (...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
            }, _ctx.$attrs, { disabled: _ctx.disabled }), null, 16, _hoisted_2),
            _createVNode(_component_v_button, {
                ref: "dropdownRef",
                layout: "icon",
                icon: "caret-down",
                class: _normalizeClass(_ctx.dropdownClass),
                onClick: _ctx.onDropdownClick,
                tab: false
            }, null, 8, ["class", "onClick"])
        ]),
        _createVNode(_Transition, {
            name: "c-connected-overlay",
            onEnter: _ctx.onOverlayEnter,
            onLeave: _ctx.onOverlayLeave
        }, {
            default: _withCtx(() => [
                (_ctx.overlayVisible)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        ref: "overlayRef",
                        class: "input-suggestion-overlay",
                        style: _normalizeStyle({ 'max-height': _ctx.scrollHeight })
                    }, [
                        _createVNode(_component_v_calendar, {
                            date: _ctx.date,
                            onChanged: _ctx.onChanged,
                            minDate: _ctx.props.minDate,
                            maxDate: _ctx.props.maxDate
                        }, null, 8, ["date", "onChanged", "minDate", "maxDate"])
                    ], 4))
                    : _createCommentVNode("", true)
            ]),
            _: 1
        }, 8, ["onEnter", "onLeave"])
    ], 2));
}
