<template>
	<div id="view-header" class="content-header">
		<div class="actions-left">
			<div class="select-or-back">
				<v-button icon="back" :label="i18n`Back`" @click.once="goBack" />
			</div>
		</div>
		<!-- <div class="actions-right">
			<v-button  type="button" layout="secondary" :text="i18n`Add to Favorites`" @click="addToFavoritesDialog = !addToFavoritesDialog" />
			<v-button type="button" layout="primary" :text="i18n`Favorites`" @click="favoritesSideBar = !favoritesSideBar" />
		</div> -->
	</div>

	<!--
	<v-sidebar header="Add to Favorites" v-model:visible="addToFavoritesDialog" :maximizable="false" position="right">
		<input type="text" class="form-control" autocomplete="off" :id="`f_saveReportAs`" v-model="favoriteName"/>
		<template #footer>
			<v-button text="Add" :disabled="favoriteName.trim() === ''"  layout="primary" @click="addToFavorites" autofocus/>
		</template>
	</v-sidebar>

	<v-sidebar header="Favorites" v-model:visible="favoritesSideBar" position="right">
		<ol class="list-group">
			<li v-for="(favorite, i) in favorites" :key="i" class="list-group-item list-group-item-action " >
				<div class="row">
					<div class="col-sm-11 fav-element" @click="showFavorite(i)">
						{{ getFavoriteNameWithoutCube(favorite) }}
					</div>
					<div class="col-sm-1 text-right">
						<v-icon icon="trash" @click="deleteFavorite(favorite.name, i)"></v-icon>
					</div>
				</div>
			</li>
		</ol>
	</v-sidebar>
	-->
	<div id="view" class="form-content">
		<!-- QUERY -->
		<div class="form-section form-header">
			<span data-toggle="collapse" :aria-expanded="group[0].collapsed ? 'false' : 'true'"
				@click="group[0].collapsed = !group[0].collapsed" :class="group[0].collapsed ? 'collapsed' : ''">
				<span class="collapse-point"><v-icon :icon="group[0].collapsed ? 'angle-right' : 'angle-down'"/></span>
				<span class="collapse-title" v-html="group[0].title"/>
			</span>
			<div class="collapse-content">
				<collapse-transition>
					<div v-if="!group[0].collapsed && fields !== null">
						<div class="form-fields">
							<div class="row">
								<input-field v-for="(field, i) in fields.fields" :key="i"
									:field="field" :entry="entry" class="col-sm-3"
									view="form" labelPosition="top"
									@itemSelect="onItemSelect" @itemUnselect="onItemUnselect"/>
							</div>
							<div class="row">
								<input-field
									:field="fields.timeDimensionField" :disabled="fields.timeDimensionField.items.length==0" :entry="entry" class="col-sm-3"
									view="form" labelPosition="top"
									@itemSelect="onItemSelect"/>
								<input-field v-if="!isEmpty(timeDimensions)" v-for="(field, i) in fields.timeFields" :key="i"
									:field="field" :entry="entry" class="col-sm-2"
									view="form" labelPosition="top"
									@itemSelect="onItemSelect"/>
								<input-field v-if="customDateRange" v-for="(field, i) in fields.customDateRangeFields" :key="i"
									:field="field" :entry="entry" class="col-sm-2"
									view="form" labelPosition="top"
									@changed="onItemSelect"/>
							</div>
							<div class="row">
								<div v-for="(filter, i) in filters" :key="i"  class="col-sm-9">
									<div class="row">
										<input-field :field="fields.filterField" :entry="entry.filters[i]" class="col-sm-4"
											view="form" labelPosition="top" :label="i<1 ? fields.filterField.label :' '"
											@changed="onFilterValueChanged(i, $event)"/>  
										<input-field :field="getFilterField(i)" :entry="entry.filters[i]" class="col-sm-3"
											view="form" labelPosition="top" :label="i<1 ? fields.filterOptionsFieldText.label :' '"
											@changed="onFilterValueChanged(i, $event)" :disabled="!entry.filters[i].filter"/>  
										<input-field :field="fields.filterValueField" :entry="entry.filters[i]" class="col-sm-4"
											view="form" labelPosition="top" :label="i<1 ? fields.filterValueField.label :' '"
											@changed="onFilterValueChanged(i, $event)"/>
										<div class="filter-button col-sm-1">
											<v-button type="text" icon="trash" layout="circle" @click="deleteFilter(i)"/>
										</div> 
									</div>
								</div>
								<div class="filter-button col-sm-3">
									<v-button :disabled="!canAddFilter" type="text" text="Filter" icon="plus" layout="secondary" @click="addFilter"/>
								</div>
							</div>
							<div class="form-group inline mt-2">
								<v-button  type="button" layout="primary" :text="i18n`Apply`" @click="runQuery()" :disabled="(isEmpty(measures) && isEmpty(dimensions)) || !fieldsChanged"/>
								<div class="dropdown dropdown-select" :class="orderDialogVisible ? 'show' : ''">
									<v-button type="button" layout="secondary" :text="i18n`Order`" :disabled="!(measures || dimensions || timeDimensions) || !orderedCurrentMembers.length>0" @click="orderDialogVisible =! orderDialogVisible"/>
									<div v-show="orderDialogVisible" @click="orderDialogVisible = false" class="dropdown-backdrop"></div>
									<div v-show="orderDialogVisible" class="dropdown-menu" :class="orderDialogVisible ? 'show' : ''">
										<div class="row orderMembersList" v-for="(val, i) in orderedCurrentMembers" :key="i">
											<div class="sort-icons-wrapper">
												<v-icon v-if="i > 0" class="clickable" icon="caret-up" @click="reorderMember(i, 'up')"/>
												<v-icon v-if="i < orderedCurrentMembers.length-1"  @click="reorderMember(i, 'down')" class="clickable" icon="caret-down"/>
											</div>
											<div class="membersName">
												{{val.name}}
											</div>
											<div class="orderOptions">
												<div class="orderOption" :class="{selected: val.order==='asc'}" @click="setMemberOrder(i,'asc')">ASC</div>
												<div class="orderOption" :class="{selected: val.order==='desc'}" @click="setMemberOrder(i,'desc')">DESC</div>
												<div class="orderOption" :class="{selected: val.order==='none'}" @click="setMemberOrder(i,'none')">NONE</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<hr>
							<div class="row">
								<input-field :field="fields.chartTypeField" :entry="entry"
									view="form" labelPosition="top"
									@itemSelect="onItemSelect" class="col-sm-3"/>
							</div>
								<div class="form-group inline mt-2">
									<v-button :disabled="!resultSet" layout="primary" @click="exportCSV" :text="i18n`Export`"/>
								</div>
						</div>
					</div>
				</collapse-transition>
			</div>
		</div>
		<!-- RESULT -->
		<div class="form-section">
			<span data-toggle="collapse" :aria-expanded="group[1].collapsed ? 'false' : 'true'"
				@click="group[1].collapsed = !group[1].collapsed" :class="group[1].collapsed ? 'collapsed' : ''">
				<span class="collapse-point"><v-icon :icon="group[1].collapsed ? 'angle-right' : 'angle-down'"/></span>
				<span class="collapse-title" v-html="group[1].title"/>
			</span>
			<div class="collapse-content">
				<collapse-transition>
					<div v-show="!group[1].collapsed">
						<div v-if="loading">
							<v-block class="container-loading" :blocked="loader.loading()" spinner="true"/>
						</div>
						<div v-else-if="resultSet">
							<v-table v-if="chartType === 'table'" :cfg="getTableCfg(resultSet)"/>
							<v-chart v-if="chartType !== 'table'" :type="getChartType" :data="getChartData(resultSet)" :options="getChartOptions"/>
						</div>
					</div>
				</collapse-transition>
			</div>
		</div>
	</div>
</template>

<script>
import cubejs from "@cubejs-client/core";
import {defineComponent, ref, computed, onMounted} from 'vue';
import {useMessages, loader} from '../app';
import {API, cfw} from '../utils';
import {APP} from "../app";
import {useRouter, useRoute} from "vue-router";

export default defineComponent({
	name: 'cube-renderer',
	// components: {
	// 	'query-renderer': QueryRenderer
	// },
	props: {
		initialFavorite: String
	},

	setup(props, {emit}) {
		const selectedCube = ref();
		const msg = useMessages();
		const route = useRoute();
		const router = useRouter();
		const loading = ref(false);

		const resultSet = ref(null);

		const items = ref([]);
		const cubes = ref([]);

		const fieldsChanged = ref(false);

		const orderedCurrentMembers = ref([]);
		const measures = ref({});
		const dimensions = ref({});
		const filters = ref([]);

		const timeDimensions = ref({});
		const granularity = ref();
		const dateRange = ref();
		const customDateRangeVal = ref([]);

		const chartType = ref("table");

		const orderDialogVisible = ref(false);

		const fields = ref(null);

		const entry = ref({
			filters: [],
			chart_type: "Table"
		});

		// const addToFavoritesDialog = ref(false);
		// const favoritesSideBar = ref(false);
		// const favorites = ref([]);
		// const favoriteName = ref('');

		const cubejsApi = cubejs(
			`Bearer ${API.value('token')}`,
			{apiUrl: `${APP.api_url}/reporting/cube`}
		);

		const group = ref([
			{title: 'Query', collapsed: false},
			{title: 'Result', collapsed: false}
		]);

		const query = ref({
			measures: [],
			timeDimensions: [],
			order: {},
			dimensions: [],
			limit: 5000
		});

		const customDateRange = computed(() => {
			return dateRange.value === 'custom';
		});

		const getChartType = computed(() => {
			if (chartType.value==='area') return 'line';
			return chartType.value;
		});

		const getChartData = (resultSet) => {
			const chartData = series(resultSet);
			const labels = chartData[0].data.map((e) => e[0]);
			const colors = [...resultSet.loadResponse.colorScheme];
			const dataSets = chartData.map((e,i) => {
				const data = e.data.map(el => el[1])
				let color = null;

				if(chartType.value==='pie')
					color = [...colors].sort(() => 0.5 - Math.random()).slice(0, chartData[0].data.length);
				else
					color = colors[Math.floor(Math.random() * colors.length)];

				return {
					label: chartData[i].name,
					data: data,
					borderColor: color,
					backgroundColor: color,
					fill: chartType.value === 'area' ? true : false
				};
			});

			const data = {
				labels: labels,
				datasets: dataSets
			};

			return data;
		};

		const getChartOptions = computed(() => {
			const chartOptions = {
				responsive: true,
				plugins: {
					legend: {
						position: 'top'
					}
				}
			};
			return chartOptions;
		});

		// const addToFavoritesField = {
		// 	"src": "favorite_name",
		// 	"label": "Name",
		// 	"type": "text",
		// 	"native": {
		// 		"type": "text"
		// 	}
		// };


		const series = (resultSet) => {
			if (!resultSet) {
				return [];
			}

			let seriesNames = resultSet.seriesNames();
			let pivot = resultSet.chartPivot();

			//Check for time dimensions
			if(resultSet.loadResponse.pivotQuery.timeDimensions[0] && resultSet.loadResponse.pivotQuery.timeDimensions[0].dimension && resultSet.loadResponse.pivotQuery.timeDimensions[0].granularity)
			{
				pivot = pivot.map(e => {
					const newObj = {...e};
					newObj['x'] = cfw.formatDate(new Date(e['x']), getFormatDateType("."+resultSet.loadResponse.pivotQuery.timeDimensions[0].granularity));
					return newObj;
				});

				if(Object.keys(resultSet.loadResponse.formatedValues).length !== 0){
					const fields = Object.keys(resultSet.loadResponse.formatedValues);
					const idx = {};
					for(const [i, dim] of resultSet.loadResponse.pivotQuery.dimensions.entries())
						if(fields.includes(dim))
							idx[i] = dim;

					seriesNames = seriesNames.map(e => {
						const newObj = {...e};
						const val = newObj.key.split(',');
						let finalVal = [...val];

						for(const entry of Object.entries(idx)){
							const obj = resultSet.loadResponse.formatedValues[entry[1]].find(e => e._id === val[entry[0]]);
							if(obj)
								finalVal[entry[0]] = obj.key + " - " + obj.name;

						}
						finalVal = finalVal.join(', ');
						newObj.title = finalVal;
						return newObj;
					});
				}
			}
			else if(Object.keys(resultSet.loadResponse.formatedValues).length !== 0){
				const fields = Object.keys(resultSet.loadResponse.formatedValues);
				const idx = {};
				for(const [i, dim] of resultSet.loadResponse.pivotQuery.dimensions.entries())
					if(fields.includes(dim))
						idx[i] = dim;

				pivot = pivot.map(e => {
					const newObj = {...e};
					const val = newObj.x.split(',');
					let finalVal = [...val];

					for(const entry of Object.entries(idx)){
						const obj = resultSet.loadResponse.formatedValues[entry[1]].find(e => e._id === val[entry[0]]);
						if(obj)
							finalVal[entry[0]] = obj.key + " - " + obj.name;
					}

					finalVal = finalVal.join(', ');
					newObj.x = finalVal;
					return newObj;
				});
			}

			const series = [];
			seriesNames.forEach((e) => {
				const data = pivot.map((p) => [p.x, p[e.key]]);
				series.push({
					name: e.title,
					data
				});
			});

			return series;
		};

		// From the selected cube, extract the available Measures, Dimensions and Time Dimensions
		const getMembers = () => {
			const availableMeasures = selectedCube.value.cube.measures;
			const availableDimensions = selectedCube.value.cube.dimensions.filter(e => e.type !== "time");
			const availableTimeDimensions = selectedCube.value.cube.dimensions.filter(e => e.type === "time");

			let field = fields.value.fields.find(e => e.src === 'measures');
			let items = availableMeasures.map(e => ({...e, _id: e.name, text: e.shortTitle}));
			field.items = items;
			fields.value.filterField.items.push(...items);

			field = fields.value.fields.find(e => e.src === 'dimensions');
			items = availableDimensions.map(e => ({...e, _id: e.name, text: e.shortTitle}));
			field.items = items;
			fields.value.filterField.items.push(...items);

			items = availableTimeDimensions.map(e => ({...e, _id: e.name, text: e.shortTitle}));
			fields.value.timeDimensionField.items = items;
			fields.value.filterField.items.push(...items);
		};

		// Get the current selected members
		const getCurrentMembers = computed(() => {
			const res = [];
			if (!isEmpty(Object.keys(measures.value))) res.push(...Object.values(measures.value));
			if (!isEmpty(Object.keys(dimensions.value))) res.push(...Object.values(dimensions.value));
			if (!isEmpty(Object.keys(timeDimensions.value))) res.push(...Object.values(timeDimensions.value));
			return res;
		});

		// split('.').join(""); removes the dots and joins, like CubeOrder.count equals CubeOrdercount, since component Table
		// doesn't seem to work with keys with dots
		const getColumns = (resultSet) => {
			const timeDim = Object.entries(resultSet.loadResponse.results[0].annotation.timeDimensions)
				.map(e => {return {"src": e[0].split('.').join(""), "label": e[1].shortTitle};})[0];
			const dim = Object.entries(resultSet.loadResponse.results[0].annotation.dimensions)
				.map(e => {return {"src": e[0].split('.').join(""), "label": e[1].shortTitle, "right": e[1].type === 'number'};});
			const meas = Object.entries(resultSet.loadResponse.results[0].annotation.measures)
				.map(e => {return {"src": e[0].split('.').join(""), "label": e[1].shortTitle, "right": e[1].type === 'number'};});
			const res = [];

			if (dim.length > 0) res.push(...dim);
			if (timeDim) res.push(timeDim);
			if (meas.length > 0) res.push(...meas);
			return res;
		};

		const getRows = (resultSet) => {
			const res = resultSet.loadResponse.results[0].data.map((e) => {
				const obj = {};
				const numericValues =[];
				for (const [key, value] of Object.entries(resultSet.loadResponses[0].annotation)) {
					for (const [subKey, subValue] of Object.entries(value)){
						if(subValue.type === 'number') numericValues.push(subKey);
					}
				}
				Object.entries(e).forEach((el) => {
				 obj[el[0].split('.').join("")] = getValueFormated(resultSet, numericValues, el[0], el[1]);
				});

				return obj;
			});

			return res;
		};

		function getFormatDateType(field){
			if (/\.hour$/.test(field)) {
				return 'DT';
			} else if (/\.month$/.test(field)) {
				return 'YM';
			} else if (/\.year$/.test(field)) {
				return 'Y';
			}
		}

		function getValueFormated(resultSet, numericValues, field, value){
			const timDims = Object.keys(resultSet.loadResponse.results[0].annotation.timeDimensions);
			const formatedVal = Object.keys(resultSet.loadResponse.formatedValues).includes(field) ?
				resultSet.loadResponse.formatedValues[field].find(e => e._id === value) : null;

			if (formatedVal) {
				return `${formatedVal.key} - ${formatedVal.name}`;
			} else if (timDims.includes(field)) {
				return cfw.formatDate(new Date(value), getFormatDateType(field));
			} else if (numericValues.includes(field)) {
				return cfw.formatNumber(parseFloat(value), /\.count$/.test(field) ? 'INT' : null);
			} else {
				return value;
			}
		}

		function getRowsLinks(tableData){
			tableData = tableData.map(e => {
				const newObj = {...e};
				for (const [key,value] of Object.entries(newObj)) {
					if (value && typeof value === 'object') newObj[key] = value._id;
				}
				return newObj;
			});

			const formatedDimensions = Object.keys(dimensions.value).map(e => e.replace(".", ""));
			let links = [];
			tableData.forEach(e => {
				let searchQuery = "";
				Object.entries(e).forEach(el => {
					let param = el[0];
					if (formatedDimensions.includes(param))
						param = param.replace(selectedCube.value.cube.name, "");
					else
						return false;

					param = selectedCube.value.report.modelFields[param] || param;
					const val = el[1];
					searchQuery === "" ? searchQuery += `${param}=${val}`: searchQuery+=`,${param}=${val}`;

				});
				if(searchQuery !== "") searchQuery = "/" + selectedCube.value.report.modelRoute + "?search=" + encodeURIComponent(searchQuery);
				links.push(searchQuery);
			});
			return links;
		}

		const getTableCfg = (resultSet) => {
			const tableFields = getColumns(resultSet);
			const tableData = getRows(resultSet);
			const rowsLinks = getRowsLinks(tableData);
			// const pq = resultSet.loadResponse.pivotQuery;
			// const cols = pq.dimensions.length + pq.timeDimensions.length + pq.measures.length;
			return {
				name: 'table',
				data: tableData,
				options: {
					paging: false
				},
				fields: tableFields,
				rowsLinks: rowsLinks
				// cols: cols
			};
		};

		function onItemSelect(event){
			fieldsChanged.value = true;
			switch(event.field.src){
				case "chart_type":
					chartType.value = event.field.items.find(e => e._id === event.value._id).component;
					break;
				case "measures":
					measures.value = {...measures.value, [event.value.name]: event.value};
					measures.value[event.value.name].order = "none";
					orderedCurrentMembers.value.push(event.value);
					break;
				case "dimensions":
					dimensions.value = {...dimensions.value, [event.value.name]: event.value};
					dimensions.value[event.value.name].order = "none";
					orderedCurrentMembers.value.push(event.value);
					break;
				case "time_dimensions":
					orderedCurrentMembers.value = orderedCurrentMembers.value.filter((e) => e.name !== timeDimensions.value.name);
					timeDimensions.value = event.value;
					timeDimensions.value.order = "none";
					orderedCurrentMembers.value.push(event.value);
					break;
				case "granularity":
					granularity.value = event.entry.granularity.text.toLowerCase();
					break;
				case "date_range":
					dateRange.value = event.entry.date_range.text.toLowerCase();
					break;
				case "range_from":
					customDateRangeVal.value[0] = new Date(event.value).toISOString().split('T')[0];
					break;
				case "range_to":
					customDateRangeVal.value[1] = new Date(event.value).toISOString().split('T')[0];
					break;
			}
		}

		function onItemUnselect(event){
			fieldsChanged.value = true;
			switch(event.field.src){
				case "measures":
					delete measures.value[event.value];
					orderedCurrentMembers.value = orderedCurrentMembers.value.filter((e) => event.value !== e.name);
					break;
				case "dimensions":
					delete dimensions.value[event.value];
					orderedCurrentMembers.value = orderedCurrentMembers.value.filter((e) => event.value !== e.name);
					break;
			}
		}

		const buildQuery = () => {
			let timeDim = {};
			if (timeDimensions.value) timeDim.dimension = timeDimensions.value.name;
			if (dateRange.value && dateRange.value !== 'custom') timeDim.dateRange = dateRange.value;
			if (dateRange.value && dateRange.value === 'custom') {
				if (customDateRangeVal.value.length>0){
					if (customDateRangeVal.value.length === 1) {
						msg.warn("There's a missing date in the date range. We will consider the missing date the same as the specified one.");
					} else {
						if ((new Date(customDateRangeVal.value[0])) > (new Date(customDateRangeVal.value[1]))) {
							msg.fail("'From' date range can't be after 'To'. We will not consider this parameter.");
						} else {
							timeDim.dateRange = [...customDateRangeVal.value];
						}
					}
				} else {
					msg.fail("There is no date range specified. We will not consider this parameter.");
				}
			}
			if (granularity.value) timeDim.granularity = granularity.value;

			let val = timeDim.dimension ? [timeDim] : [];

			return {
				measures: Object.keys(measures.value),
				timeDimensions: val,
				order: orderedCurrentMembers.value.filter((e) => e.order !== 'none').map( (el) => [el.name,el.order]),
				dimensions: Object.keys(dimensions.value),
				filters: filters.value.filter((e) => isFilterFieldFilled(e)).map(el => {return {...el};}),
				limit: 5000
			};
		};

		const runQuery = async (givenQuery = false) => {
			resultSet.value = null;
			loading.value = true;
			loader.show();
			fieldsChanged.value = false;
			const queryToRun = givenQuery !== false ? givenQuery : {...buildQuery()};
			if (queryToRun.dimensions.length === 0 && queryToRun.measures.length === 0) {
				msg.fail("Please, choose a dimension or measure.");
			} else {
				query.value = queryToRun;
				resultSet.value = await cubejsApi.load(queryToRun);
				loading.value = false;
				loader.hide();
			}
		};

		function isEmpty(obj) {
			return Object.keys(obj).length === 0;
		}

		const isFilterFieldFilled = (e) => {
			return (Object.values(e).length === 3 && Object.values(e).some(x => x === null || x === '' || (Array.isArray(x) && x[0] === '')) === false);
		};

		function reorderMember(i, to) {
			const newIndex = (to === "up") ? i - 1 : i + 1;
			orderedCurrentMembers.value.splice(newIndex, 0, orderedCurrentMembers.value.splice(i, 1)[0]);
			fieldsChanged.value = true;
		}

		function setMemberOrder(i, order) {
			if (orderedCurrentMembers.value[i].order !== order) {
				orderedCurrentMembers.value[i].order = order;
				fieldsChanged.value = true;
			}
		}

		const addFilter = () => {
			filters.value.push({});
			entry.value.filters.push({});
		};

		const canAddFilter = computed(() => {
			if (filters.value.length === 0) return true;
			return filters.value.filter((e) => isFilterFieldFilled(e)).length === filters.value.length;
		});

		const deleteFilter = (i) => {
			filters.value.splice(i, 1);
			entry.value.filters.splice(i, 1);
			fieldsChanged.value = true;
		};

		function isFilterRepeated(event, i){
			if(filters.value.filter((e, j) => i !== j
																					&& (e.member && filters.value[i].member && e.member === filters.value[i].member)
																					&&  (e.operator && filters.value[i].operator && e.operator === filters.value[i].operator)).length > 0 ){
				filters.value.splice(i, 1);
				entry.value.filters.splice(i, 1);
				msg.warn("Duplicated filter. Use the one already created.");
			}
		}

		function onFilterValueChanged(i, event){
			if(filters.value[i])
			{
				switch(event.field.src){
					case "filter":
						filters.value[i].member = event.value.name;
						isFilterRepeated(event, i);
						break;
					case "filter_options":
						filters.value[i].operator = fields.value.filterOptionNameToOperator[event.field.items.find((e) => e._id === event.value._id).text];
						isFilterRepeated(event, i);
						break;
					case "filter_value":
						filters.value[i].values = [...event.value.display.split(",").map(e => e.trim())];
						break;
				}
			}
			canAddFilter.value ? fieldsChanged.value = true : fieldsChanged.value = false;
		}

		const getFilterField = computed(() => {
			return function(i) {
				if(entry.value.filters[i].filter){
					if(entry.value.filters[i].filter.type === "number")
						return fields.value.filterOptionsFieldNumber;
					else if(entry.value.filters[i].filter.type === "time")
						return fields.value.filterOptionsFieldDate;
					else if(entry.value.filters[i].filter.type === "string")
						return fields.value.filterOptionsFieldText;
					else
						return fields.value.filterOptionsFieldText;
				}
				else
					return fields.value.filterOptionsFieldText;
			};
		});

		// async function addToFavorites(){
		// 				const test = await API.get('/api/reporting/cube');
		// 				// fetch('http://178.157.91.74:5000/cubejs-api/v1/meta')
		// 				//                    .then(response => response.json())

		// 				const data = {
		// 						action: 'add',
		// 						cube: selectedCube.value.cube.name,
		// 						name: selectedCube.value.cube.name + "-" + favoriteName.value,
		// 						query: {...buildQuery()},
		// 						entry: {...entry.value},
		// 						chartType: chartType.value,
		// 						orderedMembers: orderedCurrentMembers.value,
		// 						dimensions: dimensions.value,
		// 						measures: measures.value
		// 				}
		// 				try {
		// 		await API.post('/profile/user/config', {savedReports: data});
		// 						addToFavoritesDialog.value = false;
		// 						msg.done(favoriteName.value + " added to favorites.") ;
		// 						favoriteName.value = ""
		// 						getSavedReports()
		// 	} catch(err) {
		// 		msg.fail(err.message);
		// 	}
		// }

		// function showFavoriteOnInit(){
		// 	showFavorite(favorites.value.findIndex(e => e.name === props.initialFavorite))
		// }

		// function showFavorite(i){
		// 		entry.value = favorites.value[i].entry
		// 		chartType.value = favorites.value[i].chartType
		// 		orderedCurrentMembers.value = [...favorites.value[i].orderedMembers]
		// 		filters.value = [...favorites.value[i].query.filters]
		// 		dimensions.value = {...favorites.value[i].dimensions}
		// 		measures.value = {...favorites.value[i].measures}

		// 		if(favorites.value[i].entry.time_dimensions)
		// 				timeDimensions.value = favorites.value[i].entry.time_dimensions
		// 		else
		// 				timeDimensions.value = {}

		// 		if(favorites.value[i].entry.date_range)
		// 				dateRange.value = favorites.value[i].entry.date_range.text.toLowerCase()
		// 		else
		// 				dateRange.value = null

		// 		if(favorites.value[i].entry.granularity)
		// 				granularity.value = favorites.value[i].entry.granularity.text.toLowerCase()
		// 		else
		// 				granularity.value = null

		// 		if(dateRange.value === 'custom')
		// 		{
		// 				customDateRange.value = true
		// 				customDateRangeVal.value = [
		// 						favorites.value[i].entry.range_from ? new Date(favorites.value[i].entry.range_from).toISOString().split('T')[0] : "",
		// 						favorites.value[i].entry.range_to ? new Date(favorites.value[i].entry.range_to).toISOString().split('T')[0] : "",
		// 				]
		// 		}

		// 		favoritesSideBar.value=false
		// 		runQuery(favorites.value[i].query)
		// }

		// function getSavedReports(runInitialFavorite = false){
		// 		API.get(`/api/profile/user/${APP.user._id}`)
		// 				.then((user) => {
		// 						favorites.value = user.savedReports.filter(e => e.cube === selectedCube.value.cube.name)
		// 						if(runInitialFavorite)
		// 								showFavoriteOnInit()
		// 				})
		// }

		// async function deleteFavorite(name, i){
		// 		const data = {
		// 				action: 'remove',
		// 				name: name
		// 		};
		// 		try {
		// 				await API.post('/profile/user/config', {savedReports: data});
		// 				msg.done(`Report '${data.name}' was removed.`, false);
		// 				getSavedReports()
		// 		} catch(err) {
		// 				msg.fail(err.message);
		// 		}
		// }

		// function getFavoriteNameWithoutCube(favorite){
		// 		return favorite.name.replace(favorite.cube+"-", "")
		// }

		const goBack = () => {
			router.push({name: 'reports'});
		};

		const getReports = () => {
			const result = API.get("/api/reporting/user");
			return result;
			//.then(result => items.value = result);
		};

		const getCubes = () => {
			const data = API.get('/api/reporting/cube');
			return data;
			//.then(data => cubes.value = data.cubes)
		};

		const exportCSV = () => {
			const dataRows = getRows(resultSet.value);
			const headerRows = getColumns(resultSet.value);

			const bodyRows = dataRows.map(e => {
				const newObj = {...e};
				for(const [key,value] of Object.entries(e)){
					if(!headerRows.map(e => e.src).includes(key)) //delete keys who aren't headers in the table
						delete newObj[key]
					else if(typeof value === 'object') //Convert objects into key - name
						newObj[key] = value ? value.key + " - " + value.name : "";

				}
				 return newObj;
			});

			const rows = [headerRows.map(e => e.label), ...bodyRows.map(e => Object.values(e))];

			let csvString = rows.map(e => e.join(";")).join("\n");
			let universalBOM = "\uFEFF";
			var link = document.createElement("a");
			link.setAttribute("href", 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM+csvString));
			const fileName = selectedCube.value.cube.name + "_report_" +Date.now() + ".csv";
			link.setAttribute("download", fileName);
			document.body.appendChild(link); // Required for FF

			link.click();
		};

		onMounted(() => {
			const report = route.params.report;
			getReports().then((data) => {
				items.value = data;
				getCubes().then((result) => {
					cubes.value = result.cubes;
					API.fetch({api: '/config/reporting', cache: 'reporting'}).then(result => {
						fields.value = result;
						selectedCube.value = {
							cube: cubes.value.find(e => e.name.toLowerCase() === report.toLowerCase()),
							report: items.value.find(e => e.cubeName.toLowerCase() === report.toLowerCase())
						};

						if (!selectedCube.value.cube) router.push({name: 'reports'});

						getMembers();
					});
				});
			});
		});

		return {
			cubejsApi,
			getMembers,
			getColumns,
			getChartType,
			getChartData,
			getChartOptions,
			timeDimensions,
			getTableCfg,
			chartType,
			getRows,
			fields,
			entry,
			query,
			measures,
			dimensions,
			orderDialogVisible,
			getCurrentMembers,
			fieldsChanged,
			buildQuery,
			runQuery,
			onItemSelect,
			onItemUnselect,
			isEmpty,
			orderedCurrentMembers,
			reorderMember,
			setMemberOrder,
			// addToFavoritesDialog,
			// addToFavoritesField,
			// addToFavorites,
			// favoritesSideBar,
			filters,
			addFilter,
			canAddFilter,
			deleteFilter,
			onFilterValueChanged,
			// favorites,
			// favoriteName,
			// showFavorite,
			// deleteFavorite,
			customDateRange,
			goBack,
			// getFavoriteNameWithoutCube,
			loading,
			loader,
			resultSet,
			getFilterField,
			group,
			exportCSV
		};
	}
});
</script>

<style lang="scss" scoped>
.orderMembersList {
	margin: $padding-sm;
	padding:5px;
	align-items:center;
}

.clickable {
	 &:hover {
		cursor: pointer;
		background-color: $action-link-hover-bg;
	 }
}

.sort-icons-wrapper {
	display: flex;
	flex-direction: column;
	padding:0;
}

.orderOptions {
	padding-left:5px;
	display:flex;
	flex-direction: row;
	justify-content: flex-end;
}

.membersName {
		font-weight: 600;
}

.orderOption {
	margin-left:$padding;
	padding: $padding-xs;
	border-radius:20%;
	background-color: $gray-200;

	&:hover{
		cursor: pointer;
		background-color: $gray-300;
	}
}

.filter-button {
	display:flex;
	align-items: center;
	padding-bottom: $padding;
	padding-top: $padding-lg;
}

.selected {
	font-weight: 600;
}

.fav-element {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis !important;
	padding-left: $padding-xxl
}

.container-loading {
	// margin: 0 auto;
	// height: 100%;
	height: 50vh;
}
</style>
