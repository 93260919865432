import {ToastEventBus} from './events';
import {ToastSymbol} from './useToast';

export default {
	install: (app) => {
		const ToastService = {
			add: (message) => {
				ToastEventBus.emit('add', message);
			},
			removeGroup: (group) => {
				ToastEventBus.emit('remove-group', group);
			},
			removeAllGroups: () => {
				ToastEventBus.emit('remove-all-groups');
			}
		};
		app.provide(ToastSymbol, ToastService);
		app.config.globalProperties.$toast = ToastService;
	}
};
