<template>
	<div class="calendar-date-selector">
		<v-button icon="angle-left" label="Previous" @click="selectPrevious"/>
		<span class="today" @click="selectCurrent">Today</span>
		<v-button icon="angle-right" label="Next" @click="selectNext"/>
	</div>
</template>

<script lang="js">
import {defineComponent} from 'vue';
import dayjs from 'dayjs';

export default defineComponent({
	emits: ['date-selected'],
	props: {
		// currentDate: {
		// 	type: String,
		// 	required: true
		// },
		selectedDate: {
			type: Object, // Date,
			required: true
		}
	},
	setup(props, {emit}) {
		const selectPrevious = () => {
			const newSelectedDate = props.selectedDate.subtract(1, 'month'); // dayjs(props.selectedDate)
			emit('date-selected', newSelectedDate);
		};

		const selectCurrent = () => {
			const newSelectedDate = dayjs(); // props.currentDate
			emit('date-selected', newSelectedDate);
		};

		const selectNext = () => {
			const newSelectedDate = dayjs(props.selectedDate).add(1, 'month');
			emit('date-selected', newSelectedDate);
		};

		return {
			selectPrevious,
			selectCurrent,
			selectNext
		};
	}
});
</script>

<style lang="scss">
.calendar-date-selector {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.today {
		margin: 0 $padding;
	}

	.btn {
		margin-right: 0;
	}
}

.calendar-date-selector > * {
	cursor: pointer;
	user-select: none;
}
</style>
