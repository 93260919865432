import { reactive, toRefs } from 'vue';
import { APP, setUser } from '../app';
import { API } from '../utils';
import global from '../main';
const state = reactive({
    isOpen: false
});
const useSidebar = () => {
    return {
        ...toRefs(state)
    };
};
const isActive = (item) => {
    if (!APP.navigationCurrent)
        return false;
    return APP.navigationCurrent === item.name;
};
const useNavigation = (route, router) => {
    const signout = async (forceSignin = false) => {
        if (APP.user)
            await API.get(`/profile/user/signout/${APP.user._id}`);
        setUser(false);
        if (!global.PUBLIC_ROUTES.includes(route.name) || forceSignin) {
            redirect();
        }
    };
    const redirect = () => {
        router.push({ name: 'signin', query: { redirect: '/' } });
    };
    const home = async () => {
        let host = window.location.host;
        host = 'galpenergia.com';
        if (/galpenergia\.com/.test(host)) {
            const result = await API.get(`/profile/user/home/${APP.user._id}`);
            if (result.redirect) {
                setUser(false, true);
                window.location.replace(result.redirect);
            }
            else {
                await signout(true);
            }
        }
        else {
            await signout(true);
        }
    };
    return {
        signout,
        home
    };
};
export { useSidebar, isActive, useNavigation };
