<template>
	<v-card v-if="props.card === true" class="card-full">
		<template #title>
			<div class="card-titlebar"><label>{{i18n`My favorites`}}</label></div>
		</template>
		<template #content>
			<div v-for="(d, i) in userData" :key="i" class="mt-2 mb-2">
				<div v-if="d.items.length>0" class="text-weight-bold">{{d.label}}</div>
				<ul class="list-group">
					<li
						  class="list-group-item list-group-item-action"
						  v-for="item in d.items"
						  :key="item._id"
						  @click="router.push(`${item.url}`)"
					>
						{{item.name}}
					</li>
				</ul>
			</div>
		</template>
	</v-card>
	<div v-else>
		<ul v-if="userData.length > 0" class="list-group">
			<div v-for="(d, i) in userData" :key="i">
				<div v-if="d.items.length > 0" class="text-muted">{{d.label}}</div>
				<li v-for="item in d.items" :key="item._id" class="list-group-item favorite-element list-item-action">
					<a @click="router.push(`${d.route}/${item._id}`)">{{item.name}}</a>
					<v-button
						icon="trash"
						:label="i18n`Delete`"
						@click="deleteFavorite(d.model, item)"
						class="delete"
					/>
				</li>
			</div>
		</ul>
		<p v-else>No favorites to show</p>
	</div>
</template>

<script>
import {defineComponent, ref, onMounted} from "vue";
import {APP, useMessages, i18n} from "../app";
import {API} from "../utils";
import {useRouter} from "vue-router";

export default defineComponent({
	name: "user-favorites",
	props: {
		card: {
			type: Boolean,
			default: false
		},
		show: {
			type: Number,
			default: 5
		}
	},
	setup(props) {
		const router = useRouter();

		const msg = useMessages();

		const userData = ref([]);

		const getData = () => {
			if (!APP.user) return;
			API.get(`/api/profile/favorites/${APP.user._id}`).then((favorites) => {
				userData.value = favorites;
			});
		};

		onMounted(() => {
			getData();
		});

		const deleteFavorite = (model, item) => {
			msg.confirm({
				title: i18n`Delete`,
				message: i18n`Are you sure you want to delete ${item.name} from this list?`,
				icon: 'exclamation-circle',
				accept: async () => {
					await API.post('/profile/user/config', {
						favorites: {model: model, id: item._id}
					});
					getData();
					msg.done(i18n`Item deleted`);
				}
			});
		};

		return {
			userData,
			props,
			deleteFavorite,
			router
		};
	}
});
</script>

<style lang="scss" scoped>
.favorite-element {
	border: 0 none !important;
	padding: 0;
}
</style>
