import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "note-item list-item-action" };
const _hoisted_2 = {
    key: 0,
    class: "user"
};
const _hoisted_3 = {
    key: 1,
    class: "title"
};
const _hoisted_4 = {
    key: 2,
    class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_button = _resolveComponent("v-button");
    const _component_v_file = _resolveComponent("v-file");
    return (_openBlock(), _createElementBlock("li", _hoisted_1, [
        (_ctx.data.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(_ctx.cfw.formatDate(_ctx.data.ts, 'yyyy-MM-dd HH:mm')) + " " + _toDisplayString((_ctx.data.rejected && "Rejected by")) + " " + _toDisplayString(_ctx.data.user.name) + " (" + _toDisplayString(_ctx.visibility) + ") ", 1),
                (_ctx.editable)
                    ? (_openBlock(), _createBlock(_component_v_button, {
                        key: 0,
                        icon: "trash",
                        layout: "circle",
                        label: "Delete",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.deleteNote())),
                        class: "delete"
                    }))
                    : _createCommentVNode("", true)
            ]))
            : _createCommentVNode("", true),
        (_ctx.data.line)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.data.line.label), 1))
            : _createCommentVNode("", true),
        (_ctx.data.text)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.data.text), 1))
            : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.files, (file) => {
            return (_openBlock(), _createBlock(_component_v_file, {
                key: file._id,
                file: file,
                download: true
            }, null, 8, ["file"]));
        }), 128))
    ]));
}
