<template>
	<span class="cell" v-if="view === 'list' && type === 'icon_status'">
		<v-icon-status :status="inputValue.input"/>
	</span>
	<span class="cell" v-else-if="view === 'list' && type === 'media'">
		<div class="img-container">
			<v-file :file="currentPhoto" type="image" class="img-responsive"/>
		</div>
	</span>
	<span class="cell" v-else-if="view === 'list' && type === 'switch'">
		<input-switch v-model="inputValue.input" @changed="onChanged"/>
	</span>
	<span class="cell" v-else-if="view === 'list' && type === 'progress'">
		<v-progress :value="inputValue.input" :showValue="true"/>
	</span>
	<span class="cell" v-else-if="view === 'list' && !['icon_status', 'media', 'switch', 'progress'].includes(type) && inputValue.link">
		<router-link :to="inputValue.link" v-html="inputDisplay"/>
	</span>
	<span class="cell" v-else-if="view === 'list' && !['icon_status', 'media', 'switch', 'progress'].includes(type) && inputValue.externalLink">
		<a :href="inputValue.externalLink" v-html="inputDisplay" target="_blank"/>
	</span>
	<!-- TODO - overlay com mais info -->

	<span class="cell" v-else-if="view === 'list' && !['icon_status', 'media', 'switch', 'progress'].includes(type) && !inputValue.link && field.display === 'tag'">
		<span class="tag tag-status" :class="inputStatusColor" :title="inputDisplay">{{inputDisplay}}</span>
	</span>

	<span class="cell" v-else-if="view === 'list' && !['icon_status', 'media', 'switch', 'progress'].includes(type) && !inputValue.link && field.display !== 'tag'" v-html="inputDisplay"/>

	<div v-else-if="type === 'table'" style="height:300px;">
		<v-grid
			class="tbl"
			theme="compact"
			resize="false"
			:readonly="isReadonly"
			:source="rows"
			:columns="columns"
			:range="true"/>
	</div>

	<div v-else :id="`fc_${inputName}`" :class="containerCss">

		<v-label v-if="labelPosition !== 'none' && inputLabel && !['checkbox', 'switch'].includes(type)"
			:for="`f_${inputName}`"
			:class="labelCss"
			:label="inputLabel"
			:help="inputHelp"
			:readonly="isReadonly"
			:required="isRequired"/>

		<div :class="inputCss">

			<!-- TODO - overlay com mais info -->
			<v-overlay v-if="isReadonly && /^ref_/.test(type) && ['ref_location', 'ref_supplier', 'ref_user'].includes(type)" class="moreInfo">
				<template v-slot:handler>
					<div class="field-readonly field-overlay">
						{{inputDisplay}}
					</div>
				</template>
				<div v-html="inputMoreInfo"></div>
			</v-overlay>

			<div v-else-if="isReadonly && !['icon_status', 'media', 'checkbox', 'switch', 'progress'].includes(type)" :id="`f_${inputName}`" class="field-readonly" v-html="inputDisplay"/>

			<v-icon-status v-else-if="type === 'icon_status'" :status="inputValue.input"/>

			<!-- TODO -->
			<v-progress v-else-if="type === 'progress'" :value="inputValue.input" :showValue="true"
				:margin="props.entry && props.entry.item && props.entry.item.tolerance ? 10 : 0"
				:label="props.entry && props.entry.item && props.entry.item.tolerance ? i18n`10% tolerance` : ''"/>

			<input-checkbox v-else-if="type === 'checkbox'" :id="`f_${inputName}`" v-model="inputValue.input" @changed="onChanged"
				:label="inputLabel" v-bind="inputAttrs" :readonly="isReadonly" :help="inputHelp"/>

			<input-switch v-else-if="type === 'switch'" :id="`f_${inputName}`" v-model="inputValue.input" @changed="onChanged"
				v-bind="inputAttrs" :readonly="isReadonly"/>

			<input-suggestion v-else-if="type === 'switch-select'" :id="`f_${inputName}`" v-model:value="inputValue.input" 
				@changed="onChanged" @input="search" @blur="onBlur" @itemSelect="onItemSelect" @itemUnselect="onItemUnselect" 
				:field="field" :entry="entry" :multiple="false" :results="results" :delay="100" v-bind="inputAttrs"/>

			<input-rating v-else-if="type === 'rating'" :id="`f_${inputName}`" v-model="inputValue.input" :max="max" @changed="onChanged"
				v-bind="inputAttrs"/>

			<input-address v-else-if="type === 'address'" :id="`f_${inputName}`" v-model="inputValue.input" @changed="onChanged"
				:entry="entry" :field="field" v-bind="inputAttrs"/>

			<input-measure v-else-if="type === 'measure'" :id="`f_${inputName}`" v-model="inputValue.input" @changed="onChanged"
				:entry="entry" :field="field" v-bind="inputAttrs"/>

			<input-verification-code v-else-if="type === 'verification-code'" :id="`f_${inputName}`" v-model="inputValue.input" @changed="onChanged"
				:entry="entry" :field="field" v-bind="inputAttrs"/>

			<input-date v-else-if="type === 'date'" :id="`f_${inputName}`" v-model:value="inputValue.display" @changed="onChanged"
				v-bind="inputAttrs" :minDate="props.field.min" :maxDate="props.field.max"/>

			<input-tree-select v-else-if="/^ref_/.test(type) && props.field.tree" :id="`f_${inputName}`" :field="props.field" :entry="entry"
				:multiple="isMultiple" v-model:value="inputValue.display"
				@itemSelect="onNodeSelect" @itemUnselect="onNodeUnselect" @changed="onChanged"/>
			
			<input-radio v-else-if="type === 'radio'" id="`f_${inputName}`" v-model="inputValue.input" :options="props.options" :value="props.value" @changed="onChanged"
				:label="inputLabel" v-bind="inputAttrs" :readonly="isReadonly" />

			<input-range v-else-if="type === 'date-range'" type='date' :id="`f_${inputName}`" v-model:value="inputValue.input" @changed="onChanged"
				v-bind="inputAttrs" :min="props.field.min" :max="props.field.max"/>

			<input-range v-else-if="type === 'numeric-range'" type='numeric' :id="`f_${inputName}`" v-model:value="inputValue.input" @changed="onChanged"
				v-bind="inputAttrs" :min="props.field.min" :max="props.field.max"/>

			<input-suggestion v-else-if="(/^ref_/.test(type) || type === 'select') && !isMultiple" :id="`f_${inputName}`" v-model:value="inputValue.display"
				@changed="onChanged" @input="search" @blur="onBlur" @itemSelect="onItemSelect"
				:field="field" :entry="entry" :multiple="false" :select="isSelectDropdown"
				:results="results" :delay="100" v-bind="inputAttrs"/>

			<input-suggestion v-else-if="/^ref_/.test(type) && isMultiple" :id="`f_${inputName}`" v-model:value="inputValue.input"
				@changed="onChanged" @input="search" @blur="onBlur" @itemSelect="onItemSelect" @itemUnselect="onItemUnselect"
				:field="field" :entry="entry" :multiple="true" :select="isSelectDropdown"
				:results="results" :delay="100" v-bind="inputAttrs"/>

			<div v-else-if="type === 'password'" class="input-icon">
				<button type="button" :class="revealClass" :aria-label="i18n`Reveal`" tabindex="-1"
					@click="togglePasswordVisible(inputAttrs.disabled)">
					<v-icon :icon="passwordVisible ? 'eye' : 'eye-slash'"/>
				</button>
				<input :type="passwordVisible ? 'text' : 'password'" class="form-control" autocomplete="off"
					:id="`f_${inputName}`" v-model="inputValue.input" @input="onInput" @focus="onFocus" @blur="onBlur"
					v-bind="inputAttrs"/>
			</div>

			<div v-else-if="langs.length > 0">
				<textarea v-if="type === 'textarea'" class="form-control resizable" autocomplete="off"
					:id="`f_${inputName}`" v-model="inputValue.input" @input="onInput"
					@focus="onFocus" @blur="onBlur" v-bind="inputAttrs"></textarea>
				<input v-else type="text" class="form-control" autocomplete="off"
					:id="`f_${inputName}`" v-model="inputValue.display" @input="onInput"
					@focus="onFocus" @blur="onBlur" v-bind="inputAttrs">
				<li v-for="(lang) in langs" :key="lang" class="input-list">
					<textarea v-if="type === 'textarea' && translation[inputName]"
						class="form-control resizable" autocomplete="off"
						:id="`f_${inputName}_${lang}`" v-model="translation[inputName][lang]"
						@input="onInput(true)" @focus="onFocus" @blur="onBlur"
						v-bind="inputAttrs" :placeholder="`${inputLabel} - ${lang.toUpperCase()}`"></textarea>
					<input v-else-if="translation[inputName]"
						type="text" class="form-control" autocomplete="off"
						:id="`f_${inputName}_${lang}`" v-model="translation[inputName][lang]"
						@input="onInput(true)" @focus="onFocus" @blur="onBlur"
						v-bind="inputAttrs" :placeholder="`${inputLabel} - ${lang.toUpperCase()}`">
				</li>
			</div>

			<div v-else-if="type === 'media' && !mediaType" class="mb-3">
				<v-file :file="currentPhoto" type="image" class="img"/>
				<div v-if="!readonly" class="btn-group">
					<v-button layout="primary" @click="uploadDialogOpen" :text="i18n`Upload`" icon="upload"/>
					<v-button layout="secondary" @click="deleteDialogOpen" :text="i18n`Delete`" icon="trash" :disabled="!currentPhoto"/>
				</div>
				<v-dialog :header="i18n`Upload Photo`" v-model:visible="uploadDialogVisible" :maximizable="uploadDialogMaximizable" :position="uploadDialogPosition">
					<div style="text-align: center">
						<p v-if="!selectedFile">{{i18n`No file selected`}}</p>
						<div v-else>
							<v-cropper
								class="cropper"
								:src="selectedPhotoURL"
								:stencil-props="{
									aspectRatio: 1
								}"
								@change="onCropperChange"
							/>
							<p>{{selectedFile.name}}</p>
						</div>
						<v-button :text="i18n`Choose file`" layout="primary" @click="chooseFile"/>
						<input type="file" id="selectFile" accept="image/*" @change="onChangeFile"/>
					</div>
					<template #footer>
						<v-button :text="i18n`Cancel`" layout="secondary" @click="uploadDialogCloseAndReset"/>
						<v-button :text="i18n`Save`" layout="primary" @click="uploadPhoto" autofocus :disabled="!selectedFile"/>
					</template>
				</v-dialog>
				<v-dialog :header="i18n`Delete`" v-model:visible="deleteDialogVisible" :maximizable="deleteDialogMaximizable" :position="deleteDialogPosition">
					<div class="dialog-center-message">
						<h4>{{i18n`Are you sure you want to delete this photo?`}}?</h4>
					</div>
					<template #footer>
						<v-button :text="i18n`Cancel`" layout="secondary" @click="deleteDialogClose"/>
						<v-button :text="i18n`Delete`" layout="primary" @click="deletePhoto" autofocus/>
					</template>
				</v-dialog>
			</div>

			<div v-else-if="type === 'media' && mediaType" class="mb-3 pt-1">
				<v-file v-if="currentFile" :file="currentFile" type="file" download="true"/>
				<div v-if="!readonly" class="btn-group">
					<v-button layout="primary" @click="uploadDialogOpen" :text="i18n`Upload`" icon="upload"/>
					<v-button layout="secondary" @click="deleteDialogOpen" :text="i18n`Delete`" icon="trash" :disabled="!currentFile"/>
				</div>
				<v-dialog header="Upload File" v-model:visible="uploadDialogVisible" :maximizable="uploadDialogMaximizable" :position="uploadDialogPosition">
					<div style="text-align: center">
						<p v-if="!selectedFile">No file selected</p>
						<div v-else>
							<p>{{selectedFile.name}}</p>
						</div>
						<v-button text="Choose file" layout="primary" @click="chooseFile"/>
						<input type="file" id="selectFile" :accept="mediaType" @change="onChangeFile"/>
					</div>
					<template #footer>
						<v-button text="Cancel" layout="secondary" @click="uploadDialogCloseAndReset"/>
						<v-button text="Save" layout="primary" @click="uploadFile" autofocus :disabled="!selectedFile"/>
					</template>
				</v-dialog>
				<v-dialog header="" v-model:visible="deleteDialogVisible" :maximizable="deleteDialogMaximizable" :position="deleteDialogPosition">
					<div class="dialog-center-message">
						<h4>{{i18n`Are you sure you want to delete this file?`}}?</h4>
					</div>
					<template #footer>
						<v-button :text="i18n`Cancel`" layout="secondary" @click="deleteDialogClose"/>
						<v-button :text="i18n`Delete`" layout="primary" @click="deleteFile" autofocus/>
					</template>
				</v-dialog>
			</div>

			<textarea v-else-if="type === 'textarea'" class="form-control resizable" autocomplete="off"
				:id="`f_${inputName}`" v-model="inputValue.input" @input="onInput" @focus="onFocus" @blur="onBlur"
				v-bind="inputAttrs"></textarea>

			<quill-editor v-else-if="type === 'richtextarea'" :id="`f_${inputName}`" :value="inputValue.input" @changed="onChanged"/>

			<!-- default = text -->
			<input v-else type="text" class="form-control" autocomplete="off"
				:id="`f_${inputName}`" v-model="inputValue.display" @input="onInput" @focus="onFocus" @blur="onBlur"
				v-bind="inputAttrs"/>

			<v-button v-if="type === 'address' && (
				(entry.address && entry.address.location && entry.address.location.length === 2) ||
				(entry.deliveryAddress && entry.deliveryAddress.location && entry.deliveryAddress.location.length === 2))"
				icon="map-marker-alt" @click="redirectToMap" class="icon-link" layout="link" :text="i18n`View on map`"/>

			<div v-if="info" class="info">{{info}}</div>
			<div v-if="warning" class="warning">{{warning}}</div>
			<div v-if="errors.length > 0 || feedback" class="feedback">{{errors.length > 0 ? errors.join(',') : feedback}}</div>
		</div>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed} from 'vue';
import {
	props,
	inputType,
	contentType,
	maxSize,
	getAttrs,
	fieldLangs,
	fieldTranslation,
	useInput,
	useTextarea,
	usePassword,
	fieldValueRef,
	fieldMoreInfo
} from '../../use/input';
import api from '../../use/api';
import {minLength, validators} from '../../utils/validators';
import {APP} from '../../app';
import {cfw, API, cleanHTML} from '../../utils';
import {useRouter} from "vue-router";
import VGrid from '@revolist/vue3-datagrid';

import 'vue-advanced-cropper/dist/style.css';

/* TODO

Boolean
Date
Decimal
Entity reference
File
Float
Image
Line item reference
Long text
Long text and summary
Physical dimensions
Physical weight
Postal address
Price
Product reference
Term reference
Text

"defaultValue": null,
"allowedValues": 1,
"maxlength": 30,
{"type": "Number", "label": "", "defaultValue": 0, "min:": 0, "max": 10, "step": 1, "allowedValues": 4}

has any of...
has all of...
has none of...
is within
is before
is after
is on or before
is on or after
*/

export default defineComponent({
	name: 'InputField',
	emits: ['update:value', 'update:modelValue', 'input', 'changed', 'item-select', 'item-unselect'],
	props: props,
	components: {
		'v-grid': VGrid
	},
	setup(props, {emit}) {
		const router = useRouter();
		//const type = inputType(props);
		const mediaType = contentType(props);
		const maxFileSize = maxSize(props) ? maxSize(props) : 20000000; // default 20mb
		const langs = fieldLangs(props);
		const translation = fieldTranslation(props);
		const {resize} = useTextarea();
		const {passwordVisible, togglePasswordVisible} = usePassword(validators); // testStrength
		const feedback = ref(null);
		const results = ref([]);
		const uploadDialogPosition = ref(null);
		const uploadDialogMaximizable = ref(false);
		const uploadDialogVisible = ref(false);

		const deleteDialogPosition = ref(null);
		const deleteDialogMaximizable = ref(false);
		const deleteDialogVisible = ref(false);

		const selectedFile = ref(null);

		// const cropper = ref(null);
		const cropperCanvas = ref(null);

		const inputValidators = computed(() => {
			let validators = null;
			// if (type.value === 'password') validators = [minLength(6)];
			return validators;
		});

		const isRequired = computed(() => {
			return props.field.required;
		});

		const isReadonly = computed(() => {
			let readonly = props.readonly || false;
			if (!readonly && props.field) readonly = props.field.readonly || false;
			return readonly;
		});

		const isMultiple = computed(() => {
			return props.field && props.field.multiple || false;
		});

		const isSelectDropdown = computed(() => {
			return props.field.filterSelect || false;
		});

		const isFilter = computed(() => {
			return props.filter;
		});

		const info = computed(() => {
			return props.field && props.field.info || false;
		});

		const warning = computed(() => {
			return props.field && props.field.warning || false;
		});

		const inputAttrs = computed(() => {
			return getAttrs(type.value, props);
		});

		const inputMoreInfo = computed(() => {
			const info = fieldMoreInfo(props);
			let out = [];
			Object.entries(info).forEach(([k, v]) => {
				if (v) {
					if (cfw.isArray(v)) {
						out = [...out, ...v];
					} else {
						out.push(v);
					}
				}
			});
			return out.join('<br>');
		});

		const revealClass = computed(() => {
			return ['input-icon-btn', 'reveal', {'disabled': inputAttrs.value.disabled}];
		});

		const {
			inputValue,
			errors,
			containerCss,
			labelCss,
			inputCss,
			labelPosition,
			focused
		} = useInput(props, inputValidators.value, value => {
			emit('update:value', value);
		});

		/* watch(() => props.value, (newval, oldval) => {
			if (newval !== oldval) _search();
		}); */

		const inputName = computed(() => {
			let name = props.name;
			if (!name && props.field) name = props.field.id || props.field.src;
			return name;
		});

		const inputLabel = computed(() => {
			let label = props.label;
			if (!label && props.field) label = props.field.label;
			if (labelPosition.value === 'none') label = '';
			return label;
		});

		const inputDisplay = computed(() => {
			let value = inputValue.value.display;
			// TODO
			if (props.field && props.field.type === 'ref_tax') value = inputValue.value.list;
			if (props.entry && props.field && /^ref_evaluation/.test(props.field.type) && props.field.type !== "ref_evaluationPeriod"){
				const item = props.field.items.find(i => cfw.isObject(props.entry[props.field.src])	
					? i._id === props.entry[props.field.src]._id
					: i._id === props.entry[props.field.src]);
				
				if (!item) {
					let splitValue = "";
					if (props.entry[props.field.src] && cfw.isObject(props.entry[props.field.src])) {
						splitValue = props.entry[props.field.src]._id.split("_");
					} else if (props.entry[props.field.src]) {
						splitValue = props.entry[props.field.src].split("_");
					}
					value = splitValue ? splitValue[splitValue.length - 1] : "";
				}
			}
			if (props.field && ['key', 'text', 'textarea', 'address'].includes(props.field.type)) {
				value = cleanHTML(value, 1);
			}
			return value;
		});

		const inputStatusColor = computed(() => {
			let color = null;
			if (props.cfg && props.cfg.wcfg) {
				const state = props.cfg.wcfg.states.find(e => e.name === inputValue.value.input);
				if (state) color = state.color;
			}
			return color || 'light';
		});

		const inputHelp = computed(() => {
			const h = {
				text: props.helpText,
				link: props.helpLink
			};
			if (props.field) {
				if (props.field.help && !h.text) h.text = props.field.help;
				if (props.field.link && !h.link) h.link = props.field.link;
			}
			if (cfw.isObject(h.text)) h.text = h.text[APP.user.lang];
			if (cfw.isObject(h.link)) h.link = h.link[APP.user.lang];
			return h;
		});

		const type = computed(() => {
			return inputType(props);
		});
		
		const setValue = (value, typed = true) => {
			const typedValue = typed ? cfw.applyType(value, props.field.dataType) : value;
			cfw.setProp(props.entry, props.field.src, typedValue);
		};

		const lastValue = ref(inputValue.value['input']);
		const currentValue = ref(inputValue.value['input']);

		const onChanged = (e) => {
			setValue(e.value, false);
			emit('update:value', e.value);
			if ((props.field && /ref_/.test(type.value) && !props.field.multiple) || props.field.type === 'date') {
				emit('changed', {
					field: props.field,
					entry: props.entry,
					value: e.value
				});
			} else if (props.field.type === 'switch-select') {
				emit('changed', {
					field: props.field,
					entry: props.entry,
					value: e.value
				});
			}
		};

		const onNodeSelect = (e) => {
			const obj = cfw.clone(e.value);
			const selected = cfw.clone(e.selected);
			const nodeObj = {
				key: obj.key,
				text: obj.label,
				label: obj.label
			};
			setValue(selected, false);
			emit('item-select',  {
				field: props.field,
				entry: props.entry,
				value: nodeObj
			});
		};

		const onNodeUnselect = (e) => {
			const obj = cfw.clone(e.value);
			delete obj.text;
			emit('item-unselect',  {
				field: props.field,
				entry: props.entry,
				value: e.value
			});
		};

		const onItemSelect = (e) => {
			// TODO
			const obj = cfw.clone(e.value);
			delete obj.text;
			// setValue(obj);
			emit('item-select',  {
				field: props.field,
				entry: props.entry,
				value: obj
			});
			// emit('update:value', obj);
			// emit('changed', {
			// 	field: props.field,
			// 	entry: props.entry,
			// 	value: obj
			// });
		};

		const onItemUnselect = (e) => {
			const obj = cfw.clone(e.value);
			delete obj.text;
			emit('item-unselect',  {
				field: props.field,
				entry: props.entry,
				value: e.value
			});
			// emit('update:value', obj);
			// emit('changed', {
			// 	field: props.field,
			// 	entry: props.entry,
			// 	value: obj
			// });
		};

		const onFocus = () => {
			focused.value = true;
		};

		const onBlur = () => {
			focused.value = false;
			// only emit if changed
			if (currentValue.value !== lastValue.value) {
				if (props.field.dataType === 'number' && /,/.test(currentValue.value)) {
					currentValue.value = currentValue.value.replace(/,/, '\.');
				}
				// TODO - martelada quantidade 3 casas
				if (props.field.dataType === 'number' && props.field.type === 'quantity') {
					const p = currentValue.value.split('.');
					if (p[1] && String(p[1]).length > 3) currentValue.value = cfw.roundFix(currentValue.value, 3);
				}
				setValue(currentValue.value);
				emit('changed', {
					field: props.field,
					entry: props.entry,
					value: inputValue.value,
					lastValue: lastValue.value
				});
				lastValue.value = currentValue.value;
			} else if (/ref_/.test(type.value) && !props.field.multiple) {
				let val = cfw.getProp(props.entry, props.field.src);
				if (!cfw.isObject(val)) {
					val = null;
					cfw.setProp(props.entry, props.field.src, val);
				}
				emit('changed', {
					field: props.field,
					entry: props.entry,
					value: val
				});
			} else if (props.field.type === 'quantity') {
				// TODO ... por qualquer coisa como emit allways no field
				emit('changed', {
					field: props.field,
					entry: props.entry,
					value: inputValue.value,
					lastValue: lastValue.value
				});
			}
		};

		const onInput = (e, translation = false) => {
			let val = e.target.value;
			if (type.value === 'suggestion') {
				emit('input', e);
			// } else if (type.value === 'password' && props.field && props.field.feedback) {
			// const level = testStrength(e.target.value);
			// const l = {1: 'Weak password', 2: 'Medium password', 3: 'Strong password'};
			// feedback.value = level > 0 ? l[level] : '';
			} else {
				if (type.value === 'checkbox') val = e.target.checked;
				if (type.value === 'percentage' && props.field) {
					const {min, max} = props.field;
					if ((min || min === 0) && val < min) val = min;
					if (max && val > max) val = max;
				}
				if (!translation) {
					currentValue.value = val;
					setValue(val, false);
				}
				if (type.value === 'textarea') resize(e.target);
			}
			emit('input', e); // Apagar?
		};

		const uploadDialogOpen = (position = 'center', maximizable = false) => {
			uploadDialogPosition.value = position;
			uploadDialogMaximizable.value = maximizable;
			uploadDialogVisible.value = true;
		};

		const uploadDialogClose = () => {
			uploadDialogVisible.value = false;
		};

		const uploadDialogCloseAndReset = () => {
			uploadDialogVisible.value = false;
			selectedFile.value = null;
		};

		const deleteDialogOpen = (position = 'center', maximizable = false) => {
			deleteDialogPosition.value = position;
			deleteDialogMaximizable.value = maximizable;
			deleteDialogVisible.value = true;
		};

		const deleteDialogClose = () => {
			deleteDialogVisible.value = false;
		};

		const chooseFile = () => {
			document.getElementById("selectFile").click();
		};

		const onChangeFile = (e) => {
			selectedFile.value = e.target.files[0];
		};

		const onCropperChange = ({canvas}) => {
			cropperCanvas.value = canvas;
		};

		const selectedPhotoURL = computed(() => {
			return URL.createObjectURL(selectedFile.value);
		});

		const uploadPhoto = async () => {
			const postdata = new FormData();
			const dataURL = cropperCanvas.value.toDataURL("image/png");
			const data = dataURLToBlob(dataURL);
			postdata.append("files[0]", data);
			const url = "/files";
			try {
				const result = await API.post(url, postdata, true);
				cfw.setProp(props.entry, props.field.src, result[0]);
				emit('changed', {
					entry: props.entry
				});
			} catch (err) {
				Logger.error(err);
			}

			uploadDialogCloseAndReset();
		};

		const uploadFile = async () => {
			if (selectedFile.value.size > maxFileSize) {
				if (maxFileSize < 1000000) {
					alert(`File is over ${maxFileSize / 1000} KB size limit.`);
				} else {
					alert(`File is over ${maxFileSize / 1000000} MB size limit.`);
				}
				return;
			}

			const postdata = new FormData();
			postdata.append("files[0]", selectedFile.value);
			const url = "/files";
			try {
				const result = await API.post(url, postdata, true);
				cfw.setProp(props.entry, props.field.src, {
					_id: result[0],
					name: selectedFile.value.name
				});
				emit('changed', {
					entry: props.entry
				});
			} catch (err) {
				Logger.error(err);
			}

			uploadDialogCloseAndReset();
		};

		const dataURLToBlob = (dataURL) => {
			const splitDataURL = dataURL.split(',');
			const byteString = splitDataURL[0].indexOf('base64') >= 0 ? atob(splitDataURL[1]) : decodeURI(splitDataURL[1]);
			const mimeString = splitDataURL[0].split(':')[1].split(';')[0];

			const ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i);
			}

			return new Blob([ia], {type: mimeString});
		};

		const currentPhoto = computed(() => {
			const v = cfw.getProp(props.entry, props.field.src);
			const file = v ? {_id: v} : null;
			if (file && cfw.isString(file._id) && /^assets/.test(file._id)) {
				file.assets = true;
			}
			return file;
		});

		const currentFile = computed(() => {
			const v = cfw.getProp(props.entry, props.field.src);
			return v || null;
		});

		const deletePhoto = () => {
			cfw.setProp(props.entry, props.field.src, null);
			emit('changed', {
				entry: props.entry
			});
			deleteDialogClose();
		};

		const deleteFile = () => {
			cfw.setProp(props.entry, props.field.src, null);
			emit('changed', {
				entry: props.entry
			});
			deleteDialogClose();
		};

		const searchLocal = (e, items) => {
			if (!e.query.trim().length) {
				return items;
			} else {
				const re = new RegExp(e.query, 'i');
				return items.filter(e => re.test(e.text));
			}
		};

		const redirectToMap = () => {
			// JSON.stringify(props.entry)
			router.push({name: 'map', params: {markerData: 1}}); // , query: {test: 1}
		};

		const search = async (e) => {
			const items = props.field.items ? cfw.clone(props.field.items) : false;
			if (items) {
				results.value = searchLocal(e, items);
				return;
			}
			if (type.value === "switch-select") {
				results.value = [
					{_id: true, text: "Yes", name: "Yes", key: true},
					{_id: false, text: "No", name: "No", key: false}
				];
				return;
			}
			const params = {q: e.query, p: 1, l: 10, c: 0}; // sort_by: '',
			const cfg = cfw.clone(props.field.ref);
			let url = api.getSearchUrl(cfg.list.url, props.entry, cfg.options, params);
			const data = await API.get(url);
			results.value = [...data.items.map(e => {
				let item = e;
				// TODO - ter um display para o selected e um display para a lista
				if (cfg.map) {
					item = {
						_id: e.item._id,
						key: e.item.key,
						name: e.description,
						uom: e.uom,
						price: e.price,
						tax: e.tax
					};
				}
				const vref = fieldValueRef(item, props.field);
				const obj = vref._raw;
				obj['text'] = vref.text;
				return obj;
			})];
		};

		const _fields = [
			{"src": "name", "label": "Name"},
			{"src": "email", "label": "Email"},
			{
				"src": "type",
				"type": "select",
				"label": "Type",
				"native": {
					"type": "select"
				},
				"search": false,
				"items": [
					{"_id": "1", "text": "Type 1"},
					{"_id": "2", "text": "Type 2"},
					{"_id": "3", "text": "Type 3"}
				]
			},
			{"src": "value", "type": "number", "label": "Value", "class": "cell-value"}
		];

		const _data = [
			{"name": "Name 1", "type": "1", "value": 1},
			{"name": "Name 2", "type": "2", "value": 2},
			{"name": "Name 3", "type": "3", "value": 3},
			{"name": "Name 4", "type": "1", "value": 4},
			{"name": "Name 5", "type": "2", "value": 5},
			{"name": "Name 6", "type": "3", "value": 6}
		];

		const rows = _data.map(r => {
			return {
				name: r.name,
				email: '',
				type: r.type,
				value: r.value
			};
		});

		const columns = _fields.map(f => {
			return {
				prop: f.src,
				name: f.label
			};
		});

		return {
			props,
			type,
			langs,
			translation,
			inputValue,
			errors,
			feedback,
			containerCss,
			labelCss,
			inputCss,
			labelPosition,
			onChanged,
			onInput,
			onFocus,
			onBlur,
			search,
			results,
			passwordVisible,
			togglePasswordVisible,
			isMultiple,
			isRequired,
			isReadonly,
			inputName,
			inputLabel,
			inputDisplay,
			inputStatusColor,
			inputHelp,
			inputAttrs,
			revealClass,
			uploadDialogPosition,
			uploadDialogMaximizable,
			uploadDialogVisible,
			uploadDialogOpen,
			uploadDialogClose,
			uploadDialogCloseAndReset,
			deleteDialogPosition,
			deleteDialogMaximizable,
			deleteDialogVisible,
			deleteDialogOpen,
			deleteDialogClose,
			chooseFile,
			selectedFile,
			onChangeFile,
			selectedPhotoURL,
			onCropperChange,
			uploadPhoto,
			uploadFile,
			deletePhoto,
			deleteFile,
			currentPhoto,
			currentFile,
			mediaType,
			onItemSelect,
			onItemUnselect,
			inputMoreInfo,
			redirectToMap,
			onNodeSelect,
			onNodeUnselect,
			cleanHTML,
			info,
			warning,
			rows,
			columns,
			isSelectDropdown
		};
	}
});
</script>

<style lang="scss">
	.input-list {
		list-style: none;
		margin-top: $input-padding-y;
	}

	.form-group {
		.info {
			font-size: 0.8rem;
			color: $gray-600;
			padding-top: 0.25rem;
			padding-bottom: 0.5rem;
		}

		.warning {
			font-size: 0.8rem;
			color: #e6412d;
			padding-top: 0.25rem;
			padding-bottom: 0.5rem;
		}
	}

	.cropperBackground {
		background: none;
	}

	.img {
		width: 50%;
		border: 1px solid $border-color;
		border-radius: $border-radius;
	}

	.moreInfo {
		min-width: 200px;
		background: $gray-100 !important;
		padding: $padding-lg;
		border-radius: $border-radius;
		border: $border-base;
		box-shadow: $dropdown-box-shadow;
		color: $secondary;
		font-size: 0.875rem;
		font-weight: 400;
	}

	.btn-group {
		margin-top: $input-padding-y;
	}

	.dialog-center-message {
		text-align: center;
		margin-bottom: 2.5rem;
	}

	.tag-status {
		// width: 100%;
		// text-overflow: ellipsis;
		// white-space: nowrap;
		// overflow: hidden;
	}
	.icon-link {
		// margin-left: $padding-sm;
		padding: 0 !important;
		// cursor: pointer;
	}

	revo-grid {
  	height: 100%;
	}

	.tbl .header-rgRow,
	.tbl .group-rgRow {
		text-transform: none !important;
		// font-size: 0.8rem !important;
		// color: red !important;
	}

	.tbl .rgHeaderCell {
		font-weight: normal;
		color: $gray-600;
	}

	.tbl .rgCell {
		// font-weight: normal;
		color: $gray-700 !important; // 700 on edit?
		font-size: 0.95rem !important;
	}

	// .tbl .rgRow {
	// 	box-shadow: 0 -1px 0 0 rgba(73,80,87,.125) inset !important;
	// }

	revogr-edit input[type=text] {
		border: 1px solid transparent !important;
		color: $gray-700 !important;
		&:-moz-focusring {
			color: transparent !important;
			text-shadow: 0 0 0 $input-color !important;
		}
		&.focus {
			outline: 0 !important;
			box-shadow: none !important;
		}
	}

	revogr-focus.focused-cell {
		box-shadow: -1px 0 0 $primary inset, 1px 0 0 $primary inset, 0 -1px 0 $primary inset, 0 1px 0 $primary inset;
	}

	.tbl .selection-border-range {
  	box-shadow: -1px 0 0 $primary inset, 1px 0 0 $primary inset, 0 -1px 0 $primary inset, 0 1px 0 $primary inset;
	}

	.tbl .autofill-handle {
		background: $primary;
	}

	.tbl .rgHeaderCell.focused-cell {
  	background: none !important;
	}

	.tbl .rgRow.focused-rgRow {
		background: none !important;
  	// background-color: $gray-100 !important;
	}

	/* .tbl .row,
	.header-row {
		// display: flex;
	}
	.tbl .row .data-cell,
	.data-header-cell {
		// position: relative;
		width: auto !important;
	}
	revogr-focus,
	.selection-border-range,
	.edit-input-wrapper {
		// width: 100% !important;
	} */
</style>
