<template>
	<div :class="containerClass">
		<textarea v-model="text" :placeholder="i18n`Write your message here...`" class="resizable" autocomplete="off" rows="3"
			@input="onInput" @focus="onFocus" @blur="onBlur"/>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed} from 'vue';
import {useTextarea} from '../../use/input';

export default defineComponent({
	name: 'input-textarea',
	emits: ['changed'],
	setup(props, {emit}) {
		const focused = ref(false);
		const text = ref('');
		const {resize} = useTextarea();

		const containerClass = computed(() => {
			return ['container input-comment', {
				'focus': focused.value
			}];
		});

		const onInput = (e) => {
			resize(e.target);
		};

		const onFocus = () => {
			focused.value = true;
		};

		const onBlur = () => {
			focused.value = false;
			_changed();
		};

		const _changed = () => {
			emit('changed', text.value);
		};

		return {
			containerClass,
			onInput,
			onFocus,
			onBlur,
			text
		};
	}
});
</script>

<style lang="scss">
.container {
	margin: 0 0 $padding-xl 0;
}

</style>
