<template>
	<div class="c-chart">
		<canvas ref="canvasRef" @click="onCanvasClick"></canvas>
	</div>
</template>

<script lang="js">
// :width="width" :height="height"
import {defineComponent, ref, watch, onMounted, onBeforeUnmount} from 'vue';
import {cfw} from '../utils';

import {
	Chart,
	ArcElement,
	LineElement,
	BarElement,
	PointElement,
	BarController,
	BubbleController,
	DoughnutController,
	LineController,
	PieController,
	PolarAreaController,
	RadarController,
	ScatterController,
	CategoryScale,
	LinearScale,
	LogarithmicScale,
	RadialLinearScale,
	TimeScale,
	TimeSeriesScale,
	Decimation,
	Filler,
	Legend,
	Title,
	Tooltip
} from 'chart.js';

// import zoomPlugin from 'chartjs-plugin-zoom';
// import annotationPlugin from 'chartjs-plugin-annotation';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
// import ChartStreaming from 'chartjs-plugin-streaming';

// import 'chartjs-chart-geo';

import {
	color
	// getRelativePosition
} from 'chart.js/helpers';

Chart.register(
	ArcElement,
	LineElement,
	BarElement,
	PointElement,
	BarController,
	BubbleController,
	DoughnutController,
	LineController,
	PieController,
	PolarAreaController,
	RadarController,
	ScatterController,
	CategoryScale,
	LinearScale,
	LogarithmicScale,
	RadialLinearScale,
	TimeScale,
	TimeSeriesScale,
	Decimation,
	Filler,
	Legend,
	Title,
	Tooltip
	// zoomPlugin,
	// annotationPlugin,
	// ChartDataLabels
	// ChartStreaming
);

export default defineComponent({
	name: 'v-chart',
	emits: ['select'],
	props: {
		type: {
			type: String,
			required: true
		},
		data: {
			type: Object,
			required: true
		},
		options: {
			type: Object,
			default: () => ({})
		},
		plugins: {
			type: Array,
			default: () => []
		},
		width: {
			type: Number,
			default: 300
		},
		height: {
			type: Number,
			default: 150
		},
		custom: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const canvasRef = ref();
		let chart = null;

		onMounted(() => {
			initChart();
		});

		onBeforeUnmount(() => {
			if (chart) {
				chart.destroy();
				chart = null;
			}
		});

		watch(() => props.data, (newval, oldval) => {
			if (newval !== oldval) reinit();
		});

		watch(() => props.type, (newval, oldval) => {
			if (newval !== oldval) reinit();
		});

		watch(() => props.options, (newval, oldval) => {
			if (newval !== oldval) reinit();
		});

		const initChart = () => {
			const data = props.data;

			data.datasets.forEach(ds => {
				if (props.type === 'line' && !props.custom) {
					ds.backgroundColor = color(ds.borderColor).alpha(0.2).rgbString();
					ds.fill = true;
					// ds.stepped = true;
					ds.borderWidth = 3;
					// ds.borderDash = [5, 5];

					// ds.pointStyle = 'circle';
					ds.pointRadius = 0;
					ds.pointHoverRadius = 0;
					ds.pointHitRadius = 5;
					// ds.pointBackgroundColor = color(ds.borderColor).alpha(0.8).rgbString();

					// ds.cubicInterpolationMode = 'monotone';
					ds.tension = 0.1;
				} else if (props.type === 'bar' && !props.custom) {
					// ds.borderColor = ds.backgroundColor;
					ds.borderWidth = 3;
					ds.backgroundColor = color(ds.backgroundColor).alpha(0.5).rgbString();
					// ds.borderRadius = 50;
					// ds.borderSkipped = false;
				}
			});

			const options = cfw.clone(props.options);

			chart = new Chart(canvasRef.value, {
				type: props.type,
				data: data,
				options: options,
				plugins: props.plugins
			});
		};

		const getCanvas = () => {
			return canvasRef.value;
		};

		const getBase64Image = () => {
			return chart.toBase64Image('image/png', 1);
		};

		const update = () => {
			if (chart) chart.update();
		};

		const generateLegend = () => {
			if (chart) return chart.generateLegend();
		};

		const reinit = () => {
			if (chart) {
				chart.destroy();
				initChart();
			}
		};

		const onCanvasClick = (e) => {
			if (chart) {
				// const points = myChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);
				const element = chart.getElementsAtEventForMode(e, 'nearest', {intersect: true}, false);
				const dataset = chart.getElementsAtEventForMode(e, 'dataset', {intersect: true}, false);
				if (element && element[0] && dataset) {
					emit('select', {originalEvent: e, element: element[0], dataset: dataset});
				}
			}
		};

		return {
			canvasRef,
			onCanvasClick,
			getCanvas,
			getBase64Image,
			update,
			generateLegend
		};
	}
});
// https://www.chartjs.org/docs/3.0.2/configuration/responsive.html
// https://codepen.io/chartjs/pen/YVWZbz
</script>

<style lang="scss">
.c-chart {
	// position: relative;
	// margin: auto;
	padding: $padding-xl;
	canvas {
		// border: 1px dotted red;
	}
}
</style>
