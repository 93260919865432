<template>
	<transition-group v-if="group"
		@before-enter="beforeEnter"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave"
		@after-leave="afterLeave">
		<slot></slot>
	</transition-group>
	<transition v-else
		@before-enter="beforeEnter"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave"
		@after-leave="afterLeave">
		<slot></slot>
	</transition>
</template>

<!--
	move-class="collapse-move"
	v-bind="$attrs"
	v-on="$listeners"
-->

<script lang="js">
import {defineComponent, computed} from 'vue';

const props = {
	duration: {
		type: Number,
		default: 300
	},
	delay: {
		type: Number,
		default: 0
	},
	// whether the component should be a transition-group component
	group: Boolean,
	// transition tag, in case the component is a transition-group
	tag: {
		type: String,
		default: 'div'
	}
};

export default defineComponent({
	name: 'collapse-transition',
	props: props,
	setup(props) { // , {emit}
		// const componentType = computed(() => props.group ? 'transition-group' : 'transition');
		const group = computed(() => props.group);
		const tag = computed(() => props.tag);

		// ease-in-out
		const _style = (duration = 300) => {
			const seconds = duration / 1000;
			const style = `${seconds}s height ease, ${seconds}s padding-top ease, ${seconds}s padding-bottom ease`;
			// const style = `height ${seconds}s ease`;
			return style;
		};

		// const _setStyles = (el) => {
		// 	// this.setTransformOrigin(el)
		// 	Object.keys(this.styles).forEach(key => {
		// 		// const styleValue = this.styles[key]
		// 		// if (styleValue) {
		// 		// 	el.style[key] = styleValue
		// 		// }
		// 	});
		// };

		// setTransformOrigin(el) {
		// 	if (this.origin) {
		// 		el.style.transformOrigin = this.origin
		// 	}
		// 	return this
		// }

		const beforeEnter = (el) => {
			el.style.transition = _style(props.duration);
			if (!el.dataset) el.dataset = {};
			el.dataset.oldPaddingTop = el.style.paddingTop;
			el.dataset.oldPaddingBottom = el.style.paddingBottom;
			el.style.height = '0';
			el.style.paddingTop = 0;
			el.style.paddingBottom = 0;
			// this.setStyles(el)
		};

		const enter = (el) => {
			el.dataset.oldOverflow = el.style.overflow;
			if (el.scrollHeight !== 0) {
				el.style.height = el.scrollHeight + 'px';
				el.style.paddingTop = el.dataset.oldPaddingTop;
				el.style.paddingBottom = el.dataset.oldPaddingBottom;
			} else {
				el.style.height = '';
				el.style.paddingTop = el.dataset.oldPaddingTop;
				el.style.paddingBottom = el.dataset.oldPaddingBottom;
			}
			el.style.overflow = 'hidden';
		};

		const afterEnter = (el) => {
			// for safari: remove class then reset height is necessary
			el.style.transition = '';
			el.style.height = '';
			el.style.overflow = el.dataset.oldOverflow;
		};

		const beforeLeave = (el) => {
			if (!el.dataset) el.dataset = {};
			el.dataset.oldPaddingTop = el.style.paddingTop;
			el.dataset.oldPaddingBottom = el.style.paddingBottom;
			el.dataset.oldOverflow = el.style.overflow;
			el.style.height = el.scrollHeight + 'px';
			el.style.overflow = 'hidden';
			// this.setStyles(el);
		};

		const leave = (el) => {
			if (el.scrollHeight !== 0) {
				// for safari: add class after set height, or it will jump to zero height suddenly, weired
				el.style.transition = _style(props.duration);
				el.style.height = 0;
				el.style.paddingTop = 0;
				el.style.paddingBottom = 0;
			}
			// necessary for transition-group
			// this.setAbsolutePosition(el);
		};

		const afterLeave = (el) => {
			el.style.transition = '';
			el.style.height = '';
			el.style.overflow = el.dataset.oldOverflow;
			el.style.paddingTop = el.dataset.oldPaddingTop;
			el.style.paddingBottom = el.dataset.oldPaddingBottom;
		};

		return {
			group,
			tag,
			beforeEnter,
			enter,
			afterEnter,
			beforeLeave,
			leave,
			afterLeave
		};
	}
});
</script>

<!--
	class="collapse" :class="item.expanded ? 'show': ''" <transition name="accordion"

<style lang="scss" scoped>
.accordion {

	&-enter-active {
		transition: all 0.35s ease;
		overflow: hidden;
		// transition-duration: 0.3s;
		// transition-timing-function: ease-in;
	}

	&-leave-active {
		transition: all 0.35s ease;
		overflow: hidden;
	}

	&-enter-from,
	&-leave-to {
		height: 0;
	}
	&-enter-to,
	&-leave-from {
		height: 100px;
		max-height: 200px;
	}
}
/* &-enter {
	transform: translateY(-25px);
	opacity: 0;
}
&-leave-to {
	transform: translateY(25px);
	opacity: 0;
} */
</style>

-->
