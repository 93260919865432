import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "app-page" };
const _hoisted_2 = { class: "app-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_header = _resolveComponent("app-header");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_header),
        _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
        ])
    ]));
}
