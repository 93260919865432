import { reactive, ref, computed, readonly } from 'vue';
import global from '../main';
import { APP, loader, useMessages, isRole, isSupplier, isInspector, isAdmin, isRoot, hasRole, i18n } from '../app';
import { cfw, API, DomHandler, constants, asyncForEach, throttle } from '../utils';
const _state = reactive({
    view: null,
    cfg: null
});
const layout = ref('list');
const drawerContent = ref('settings');
const drawerOptions = ref({});
const drawerOpen = ref(false);
const drawerBack = ref(false);
const drawerIndex = ref(0);
const drawerTopOpen = ref(false);
// TODO
const drawer = computed(() => {
    const drawers = {
        'mass-update': 'data-update',
        'import': 'data-import',
        'export': 'data-export',
        'workflow': 'data-state',
        'submit-approval': 'data-state',
        'return-buyer': 'data-state',
        'confirm': 'data-state',
        'negotiate': 'data-state',
        // 'submit': 'data-state',
        // 'complete': 'data-state',
        'accept-return': 'data-state',
        'approve': 'data-state',
        'reject': 'data-state',
        'close': 'data-state',
        'reject-plan': 'data-state',
        'reject-close-complaint': 'data-state',
        'reject-return': 'data-state',
        'submit-info': 'data-state',
        'return-to-inspection': 'data-state',
        'suspend-contract': 'data-state',
        'suspend-pep': 'data-state',
        'suspend-other': 'data-state',
        'reject-request': 'data-state',
        // para todas as aprovações (DataForm approval)
        'approval-approve': 'data-state',
        'approval-reject': 'data-state',
        'approval-request': 'data-state'
    };
    return drawers[drawerContent.value];
});
const drawerCommentRequired = computed(() => {
    const drawers = {
        'reject-plan': true,
        'reject-close-complaint': true,
        'reject-return': true,
        'submit-info': true,
        'negotiate': true,
        'reject': true,
        'return-to-inspection': true,
        'suspend-contract': true,
        'suspend-pep': true,
        'suspend-other': true,
        'approval-reject': true,
        'reject-request': true
    };
    return drawers[drawerContent.value] === true;
});
const drawerHelpText = computed(() => {
    const helpTexts = {
    //'close-return': 'Doing this will close the return.',
    };
    return helpTexts[drawerContent.value];
});
const STATE = readonly(_state);
const _getType = (route) => {
    let type = route.params.type;
    if (!type && route.meta.model)
        type = route.meta.model;
    return type;
};
const getQuery = (val) => {
    return val ? Object.entries(val).map(e => `${e[0]}${e[1]['operator']}${e[1]['value']}`).join(',') : null;
};
const getModel = async (route) => {
    let view = 'list';
    if (route.params.id)
        view = 'form';
    const type = _getType(route);
    if (!type) {
        _state.view = 'list';
        _state.cfg = { list: { options: {}, fields: [] }, form: { fields: [] } };
        return;
    }
    const cfg = await getModelSchema(type, { route: route });
    _state.view = view;
    _state.cfg = cfg;
};
const setModel = (cfg, view) => {
    _state.view = view;
    _state.cfg = cfg;
};
const getModelSchema = (type, options = null) => {
    const key = `model-${type}`;
    const url = `/config/model/${APP.lang}/${type}?_v=${APP.version}`;
    const localData = API.value(key);
    if (!options)
        options = {};
    let reload = options['reload'] || false;
    const route = options['route'] || null;
    if (localData && !reload)
        return Promise.resolve(localData);
    return new Promise(resolve => {
        API.fetch({ api: url }).then(data => {
            const cfg = cfw.clone(data);
            // TODO
            if (route) {
                cfg['query'] = route.meta.query;
                let id = cfg['name'];
                if (cfg['query'])
                    id += `|${JSON.stringify(cfg['query'])}`;
                cfg['id'] = id;
            }
            if (Object.keys(cfg).length === 0)
                return resolve(cfg);
            API.value(key, cfg);
            resolve(cfg);
        });
    });
};
const isPurchasingModel = (data, options = { rfx: true }) => {
    const types = options.rfx ? [...constants.PURCHASING_MODELS, 'rfx'] : constants.PURCHASING_MODELS;
    let p = false;
    if (data && data.doc && data.doc.type && types.includes(data.doc.type)) {
        p = true;
        if (!options.rfx && data.doc.type === 'requisition' && data.doc.subtype === 'R007') {
            p = false;
        }
    }
    return p;
};
const actionsAvailable = (cfg, view, options = {}) => {
    if (!cfg || !cfg[view])
        return [];
    let cfg_actions = cfw.clone(cfg[view].options.actions ? cfg[view].options.actions : (cfg.list.options.actions || []));
    if (cfg_actions.length === 0 && cfg.actions)
        cfg_actions = cfg.actions.all || [];
    const ALL_ACTIONS = { ...global.ACTIONS, ...(cfg.actions ? (cfg.actions.custom || {}) : {}) };
    const more = cfg.actions ? (cfg.actions.more || []) : [];
    const entry = options['entry'] || {};
    const wf = options['wf'] || null;
    const current = options['current'] || null;
    const trash = options['trash'] || false;
    let readonly = false;
    const sourcing_types = constants.SOURCING_TYPES;
    let wf_state = null, permissions = [...(cfg.permissions || [])];
    if (wf && entry._state) {
        wf_state = wf.states.find(s => s.name === entry._state.current);
        if (wf_state && wf_state.lock || entry._state.lock) { // && !isAdmin()
            readonly = true;
            permissions = ['A', 'R'];
        }
        else if (entry._state.readonly === true && !isRoot()) {
            readonly = true;
            permissions = ['A', 'R'];
        }
        else if (cfg.workflow === 'approval' && entry.completed === true) {
            readonly = true;
            permissions = ['A', 'R'];
        }
    }
    const userRole = APP.user.role.key;
    let restrict = isPurchasingModel(entry);
    if (!restrict && ['contract-evaluation', 'order-evaluation'].includes(cfg.name))
        restrict = true;
    if (restrict && ['contract-evaluation', 'order-evaluation'].includes(cfg.name) && ['buyer'].includes(userRole)) {
        restrict = false;
    }
    if (entry && restrict && ['auditor', 'buyer', 'director', 'manager-purchasing', 'manager-supplier'].includes(userRole)) {
        readonly = true;
        permissions = ['A', 'R'];
    }
    const actions = {
        left: [],
        right: []
    };
    const fixed = {
        left: ['favorites'],
        right: []
    };
    const exclude = ['favorites'];
    if (!APP.user)
        return actions;
    if (trash) {
        actions.left = [...actions.left, 'restore', 'purge'];
    }
    else {
        Object.entries(fixed).forEach(([p, a]) => {
            a.forEach(ac => {
                if (cfg[view].options[ac])
                    actions[p].push(ac);
            });
            if (p === 'left')
                actions[p] = [...actions[p], ...cfg_actions.filter(e => !exclude.includes(e))];
        });
        if (view === 'form') {
            if ((['complaint', 'complaint-info'].includes(cfg.workflow) && entry._state?.current !== 'created')
                || ['return'].includes(cfg.workflow)
                || (cfg.workflow === 'rfx' && ![
                    'created',
                    'proposal',
                    'first-inspection',
                    'proposed',
                    'first-approval',
                    'regularization',
                    'suspended-pep',
                    'suspended-contract',
                    'suspended-other'
                ].includes(entry._state?.current))
                || (cfg.workflow === 'rfx-requisition' && !['rejected'].includes(entry._state?.current))) {
                actions.left = [...actions.left, 'save', 'cancel', 'edit'];
            }
            else {
                actions.left = [...actions.left, 'save', 'cancel', 'delete', 'edit'];
            }
        }
        else {
            if (['return'].includes(cfg.workflow)) {
                actions.left = [...actions.left, cfg.submodels ? 'create-multiple' : 'create'];
            }
            else {
                actions.left = [...actions.left, cfg.submodels ? 'create-multiple' : 'create', 'delete'];
            }
        }
        if (cfg.id === 'purchase-from-rfx') {
            actions.left = actions.left.filter(action => action !== 'delete');
        }
        // TODO
        if (isSupplier()) {
            if (!['user'].includes(cfg.name)) {
                if (cfg.name?.includes('complaint')
                    || (['rfx'].includes(cfg.name) && ['proposal', 'first-inspection', 'regularization', 'execution'].includes(entry._state?.current))
                    || (['rfx-requisition'].includes(cfg.name) && ['final-inspection'].includes(entry._state?.current))) {
                    actions.left = actions.left.filter(e => !['create-multiple', 'create', 'delete', 'mass-update', 'duplicate'].includes(e));
                }
                else {
                    actions.left = actions.left.filter(e => !['edit', 'create-multiple', 'create', 'delete', 'mass-update', 'duplicate'].includes(e));
                }
                if (!isInspector(entry) && (['rfx', 'rfx-requisition'].includes(cfg.name) && ['first-inspection', 'final-inspection'].includes(entry._state?.current))) {
                    actions.left = actions.left.filter(e => !['edit', 'create-multiple', 'create', 'delete', 'mass-update', 'duplicate'].includes(e));
                }
            }
        }
        else {
            // TODO
            if (isPurchasingModel(entry, { rfx: false })) {
                let canEdit = true, canDelete = true;
                if (!['P007', 'P008', 'P009', 'P010', 'P011', 'P012', 'P013', 'R002', 'C004'].includes(entry.doc.subtype)) {
                    actions.left = actions.left.filter(e => !['duplicate'].includes(e));
                }
                else if (['P011', 'P013'].includes(entry.doc.subtype)) {
                    let duplicateDisabled = !hasRole('exc,cexc');
                    if (!duplicateDisabled && !isAdmin() && entry.lines && entry.lines.length > 0) {
                        let ids = APP.user.filter ? (APP.user.filter.item || []) : [];
                        let existing = entry.lines.map(l => String(l.item._id._id));
                        existing = [...new Set(existing)];
                        const match = existing.filter(e => ids.includes(e));
                        duplicateDisabled = match.length < existing.length;
                    }
                    if (duplicateDisabled)
                        actions.left = actions.left.filter(e => !['duplicate'].includes(e));
                }
                if (!['created', 'updated', 'rejected', 'erp-fail'].includes(entry._state.current) || /^\d{10}$/.test(entry.key)) {
                    canDelete = false;
                }
                if (sourcing_types.includes(entry.doc.subtype)) {
                    canDelete = false;
                }
                if (entry.doc.type === 'requisition' && entry.doc.subtype === 'R007' && ['rejected'].includes(entry._state.current)) {
                    canDelete = true;
                }
                if (entry.doc.type === 'order' && !sourcing_types.includes(entry.doc.subtype) && !['erp', 'approval'].includes(entry._state.current)) {
                    const consumed = entry.lines.filter(line => line._cq);
                    canDelete = consumed.length === 0;
                }
                if (entry.doc.type === 'requisition' && (/^\d{10}$/.test(entry.key) || entry.doc.subtype !== 'R002' && entry._imported)) {
                    canEdit = false;
                    if (!canEdit && entry.doc.subtype === 'R002' && ['rfq-rejected', 'updated'].includes(entry._state.current))
                        canEdit = true;
                    // if (entry.doc.subtype !== 'R002' && entry._state.current !== 'rfq-rejected') actions.left = actions.left.filter(e => !['edit'].includes(e));
                }
                if (['completed', 'closed', 'archived', 'erp-done', 'approved', 'rfq'].includes(entry._state.current)) {
                    canEdit = false;
                }
                if (!canEdit)
                    actions.left = actions.left.filter(e => !['edit'].includes(e));
                if (!canDelete)
                    actions.left = actions.left.filter(e => !['delete'].includes(e));
            }
        }
    }
    Object.entries(ALL_ACTIONS).forEach(kv => {
        const key = kv[0], action = cfw.clone(kv[1]);
        action.name = key;
        action.more = more.includes(action.name);
        // TODO
        action.mode = 'all';
        if (view === 'form') {
            if (['favorites'].includes(action.name)) {
                action.mode = 'all';
            }
            else if (['cancel', 'save'].includes(action.name)) {
                action.mode = 'edit';
            }
            else {
                action.mode = 'view';
            }
        }
        const m = action.hasOwnProperty(view) ? action[view] : true;
        ['left', 'right'].forEach(p => {
            let i = actions[p].findIndex(e => e === key);
            if (i >= 0) {
                if (!m) {
                    delete actions[p][i];
                }
                else {
                    actions[p][i] = action;
                }
            }
        });
    });
    const schema = { fields: ALL_ACTIONS, validate: false, sort: true };
    ['left', 'right'].forEach(p => {
        actions[p] = Object.values(cfw.validObj(cfw.asObject(Object.values(actions[p]), 'name'), schema));
        actions[p] = actions[p].filter(e => {
            if (!e.permission)
                return true;
            return permissions.includes(e.permission);
        });
    });
    // TODO
    if (entry && entry._state && ['consulting-approval', 'manager-approval'].includes(entry._state.current) && readonly && current && current.assignees && current.assignees.length > 0) {
        if (current.assignees.includes(APP.user._id))
            readonly = false;
    }
    if (wf_state && !readonly) {
        let awf = [];
        wf.transitions.forEach(t => {
            const from = cfw.isArray(t.from) ? t.from : [t.from];
            let hasPermission = isRole('admin') || !t.roles || t.roles.includes(APP.user.role.key);
            // TODO
            if (!hasPermission && t.roles.includes('assigned')) {
                let assignees = [];
                if (current && cfw.isArray(current.assignees) && current.assignees.length > 0) {
                    assignees = current.assignees.map(e => String(e));
                }
                else if (entry.assignees && cfw.isArray(entry.assignees) && entry.assignees.length > 0) {
                    assignees = entry.assignees;
                    assignees = assignees.map(e => String(e._id));
                }
                hasPermission = assignees.includes(APP.user._id);
            }
            if (from.includes(entry._state.current) && hasPermission) {
                awf.push(t);
            }
        });
        awf.forEach(a => {
            // TODO
            if (isPurchasingModel(entry, { rfx: false }) && entry.doc.subtype) {
                if (isSupplier()) {
                    const supplierId = String(APP.user._profile[0]);
                    if (!['confirm', 'negotiate'].includes(a.name)) {
                        a.disabled = true;
                    }
                    else {
                        a.disabled = String(entry.supplier._id) !== supplierId;
                    }
                    if (a.name === 'confirm' && entry._state.current === 'negotiation') {
                        a.disabled = true;
                    }
                }
                else {
                    if (a.name === 'return-buyer' && !sourcing_types.includes(entry.doc.subtype)) {
                        a.disabled = true;
                    }
                    else if (['confirm', 'negotiate'].includes(a.name)) {
                        a.disabled = true;
                    }
                    if (['confirm'].includes(a.name) && entry._state.current === 'negotiation') {
                        a.disabled = false;
                    }
                }
                if (a.name === 'submit-approval' && /^\d{10}$/.test(entry.key) && entry.doc.type === 'requisition' && entry.doc.subtype !== 'R002') {
                    a.disabled = true;
                }
            }
            if (a.name === 'reopen' && ['closed', 'archived'].includes(entry._state.current) && entry._state.created) {
                const days = Math.round((new Date().getTime() - entry._state.created.ts) / (1000 * 3600 * 24)); // _state.ts
                if (days > 365)
                    a.disabled = true;
            }
            if (!a.disabled) {
                const btn = { name: a.name, btn: 'secondary', label: a.label, mode: 'view', more: false };
                actions.left.push(btn);
            }
        });
    }
    return actions;
};
const useList = (conditions, options, items) => {
    const selected = computed(() => items.value.filter(e => e['_selected']));
    // let disabled = false;
    const count = computed(() => {
        if (!options.value)
            return '';
        if (options.value.count === 0)
            return '';
        let first = conditions.p === 1 ? 1 : (conditions.p - 1) * conditions.l + 1;
        const last = conditions.p === options.value.pages ? options.value.count : conditions.p * conditions.l;
        return `${first}-${last} of ${options.value.count}`;
    });
    const firstPage = computed(() => {
        return conditions.p === 1;
    });
    const lastPage = computed(() => {
        if (!options.value)
            return false;
        return conditions.p === options.value.pages;
    });
    const pagePreviousNow = () => {
        if (conditions.p === 1)
            return;
        // if (disabled) return;
        conditions.p--;
        if (options.value && options.value.count)
            conditions.c = options.value.count;
        // disabled = true;
        // setTimeout(() => {
        // 	disabled = false;
        // }, 500);
    };
    const pageNextNow = () => {
        if (conditions.p >= options.value.pages)
            return;
        // if (disabled) return;
        conditions.p++;
        if (options.value && options.value.count)
            conditions.c = options.value.count;
        // disabled = true;
        // setTimeout(() => {
        // 	disabled = false;
        // }, 500);
    };
    const pagePrevious = throttle(pagePreviousNow, 500);
    const pageNext = throttle(pageNextNow, 500);
    const toggle = (item) => {
        item._selected = !item._selected;
    };
    const toggleAll = () => {
        const s = selected.value.length < items.value.length;
        items.value.map(e => e['_selected'] = s);
    };
    const css = (field, header = false) => {
        let css = [];
        if (header) {
            // TODO ... por aqui os sorts da coluna
        }
        if (field.class) {
            css.push(field.class);
        }
        else if (/(key|checkbox|date|datetime)/gi.test(field.type)) {
            css.push(`cell-${field.type}`);
        }
        else if (/(number|value|price|quantity)/gi.test(field.type)) {
            css.push('cell-value');
        }
        else if (/(?:uom)/gi.test(field.type)) {
            css.push('cell-code');
        }
        else if (/^ref_/gi.test(field.type)) {
            css.push('cell-ref');
        }
        // if (edit) {
        // 	const readonly = field.readonly || false;
        // 	if (!readonly && !header) css.push('cell-edit');
        // }
        return css.join(' ');
    };
    return {
        selected,
        count,
        firstPage,
        lastPage,
        pagePrevious,
        pageNext,
        toggle,
        toggleAll,
        css
    };
};
const changeFields = (fields, entry) => {
    if (!isPurchasingModel(entry, { rfx: false }))
        return;
    // TODO... esta validação já é feita em server, ter um metodo para devolver o cfg model (required, etc) consoante o que ja está preenchido
    const type = entry.doc ? entry.doc.type : null;
    const rf = {
        "risk.contract": { "required": true },
        "risk.E": { "required": true },
        "risk.HS": { "required": true },
        "risk.gdpr": { "required": true },
        "risk.cyber": { "required": true },
        "businessPlan": { "required": true },
        "evaluationId": { "required": true }
    };
    if (type === 'requisition') {
        rf["cyberFile"] = { "required": true };
    }
    let field = null;
    const required = entry.lines.filter(e => e.type === 'service').length > 0;
    if (required) {
        Object.keys(rf).forEach(f => {
            field = fields.value.find(e => e.src === f);
            if (field)
                field.required = true;
        });
        let gdpr = entry.risk ? (entry.risk.gdpr || null) : null;
        if (gdpr && gdpr._id)
            gdpr = gdpr._id;
        if (!gdpr || gdpr === '1') {
            field = fields.value.find(e => e.src === 'evaluationId');
            field.required = false;
        }
        if (type === 'requisition') {
            let cyber = entry.risk ? (entry.risk.cyber || null) : null;
            if (cyber && cyber._id)
                cyber = cyber._id;
            if (!cyber || cyber === '3') {
                field = fields.value.find(e => e.src === 'cyberFile');
                if (field)
                    field.required = false;
            }
        }
    }
};
const getOverrides = async (entry, what = 'header') => {
    const cfg = await API.fetch({ api: '/config/purchasing', cache: 'purchasing' });
    let overrides = entry.doc && entry.doc.subtype ? cfg.overrides.find(e => e.subtype.includes(entry.doc.subtype)) : null;
    // TODO - no overrides for old exception contracts
    if (entry.doc && entry.doc.type === 'contract' && entry.doc.subtype === 'C004' && entry._imported) {
        if (what === 'groups') {
            return overrides[what] || {};
        }
        else {
            return {};
        }
    }
    let suppliers = null;
    if (!overrides) {
        overrides = {};
        if (what === 'header' && ['contract-evaluation', 'order-evaluation'].includes(entry.type) && entry.parent) {
            overrides.dueDate = {
                readonly: true
            };
        }
    }
    else {
        overrides = overrides[what] || {};
        if (what === 'header' && entry.doc.type === 'order' && entry.partners) {
            suppliers = [entry.supplier._id, ...entry.partners].join('|');
            overrides.supplier = {
                "ref.options.search": `_id=${suppliers}`,
                readonly: false
            };
        }
    }
    if (what === 'header' && entry.doc && entry.doc.type === 'order') {
        let readonly = entry.doc.subtype !== 'P009';
        if (!readonly) {
            const orderValue = cfw.roundFix(entry.listValue, constants.ROUND_VALUE);
            const orderValueEUR = entry.exchangeRate ? cfw.roundFix(orderValue / entry.exchangeRate, constants.ROUND_VALUE) : null;
            readonly = orderValueEUR >= 500;
            if (entry._stats && !readonly)
                readonly = entry._stats.consumed > 0;
        }
        overrides.deliveryLock = {
            readonly: readonly
        };
        if (entry.reference && entry.reference.contract) {
            [
                'supplier', 'purchaseGroup', 'payterms', 'currency', 'currency',
                'bankGuarantee.required', 'bankGuarantee.value', 'bankGuarantee.date',
                'risk.contract', 'risk.E', 'risk.HS', 'risk.gdpr', 'risk.cyber', 'businessPlan', 'evaluationId'
            ].forEach(f => {
                overrides[f] = overrides[f] || {};
                if (f === 'supplier' && suppliers) {
                    overrides[f].readonly = false;
                }
                else {
                    overrides[f].readonly = true;
                }
            });
        }
    }
    if (what === 'header' && entry.doc && entry.doc.type === 'contract' && entry.doc.subtype === 'C004' && entry._state && entry._state.erp) {
        ['supplier', 'currency', 'purchaseProject'].forEach(field => {
            overrides[field] = overrides[field] || {};
            overrides[field].readonly = true;
        });
    }
    // TODO
    if (what === 'groups' && entry.reference && entry.reference.contract && ['P011', 'P013'].includes(entry.doc.subtype)) {
        overrides.header = [
            ["key", "reference.contract.key", "reference.rfx.key", "date", "name", "doc.subtype", "requester", "supplier", "reference.proposal"],
            ["location", "plant", "purchaseOrg", "purchaseGroup", "payterms", "currency", "grossValue", "discountValue", "costValue", "netValue", "taxValue", "value"]
        ];
    }
    return overrides;
};
const getFields = (model, fields, view, overrides = {}) => {
    fields.value = [];
    if (!model[view])
        return;
    const optional = model[view].optionalFields || [];
    cfw.clone(model[view].fields).forEach(field => {
        field.visible = optional.includes(field.src) ? false : true;
        field.list = field.label.trim();
        const or = overrides[field.src];
        if (or) {
            Object.keys(or).forEach(p => {
                cfw.setProp(field, p, or[p]);
            });
        }
        fields.value.push(field);
    });
};
const getGroups = (model, groups, fields, view, overrides = {}) => {
    groups.value = [];
    if (!model[view] || !model[view].groups)
        return;
    let i = 0;
    Object.entries(cfw.clone(model[view].groups)).forEach(([key, group]) => {
        group['key'] = key;
        group['collapsed'] = group.hasOwnProperty('collapsed') ? group['collapsed'] : (i > 0);
        if (!group.hasOwnProperty('type'))
            group['type'] = 'header';
        if (['header', 'fields'].includes(group['type'])) {
            const colFields = [];
            if (group['fields'] === 'all') {
                colFields.push({ fields: fields.value });
            }
            else {
                const or = overrides[key];
                if (or)
                    group['fields'] = or;
                const cols = Array.from(Array(group['cols']).keys());
                cols.forEach(c => {
                    const f = group['fields'][c];
                    const notSorted = fields.value.filter(field => f.includes(field.src));
                    colFields.push({ fields: Object.values(cfw.validObj(cfw.asObject(notSorted, 'src'), { fields: f, validate: false, sort: true })) });
                });
            }
            group['cols'] = colFields;
        }
        else if (group['type'] === 'ref') {
            const ref = model[view].form_ref[key];
            const obj = cfw.clone(ref);
            obj.name = `${model.name}-ref-${key}`;
            group['collapsed'] = ref.hasOwnProperty('collapsed') ? ref['collapsed'] : true;
            group['label'] = ref['label'];
            group['cfg'] = obj;
        }
        else if (group['type'] === 'filter') {
            group['name'] = `${model.name}-filter-${key}`;
            group['collapsed'] = ref.hasOwnProperty('collapsed') ? ref['collapsed'] : true;
            group['label'] = ref['label'];
        }
        groups.value.push(group);
        i++;
    });
};
const getStyle = (model, fields, replace = false) => {
    const id = `model-${model.name}`;
    const found = DomHandler.findById(id);
    if (found && !replace)
        return;
    let list = [];
    let cols = [];
    list.push('@media (min-width: 768px) {');
    let select = false;
    if (model.options && model.options.select)
        select = true;
    if (!select && model.list && model.list.options.select)
        select = true;
    if (select)
        cols.push('48px');
    if (model.options && model.options.line)
        cols.push(model.options.lineWidth || '40px');
    let customCss = [];
    let detail = false;
    if (model.options && model.options.detail) {
        detail = true;
        customCss = model.options.css || [];
    }
    if (!detail && model.list && model.list.options.detail) {
        detail = true;
        customCss = model.list.options.css || [];
    }
    if (model.list && model.list.options.message) {
        customCss = model.list.options.css || [];
    }
    if (detail)
        cols.push('40px');
    if (customCss.length === 0 && model.options && model.options.css)
        customCss = model.options.css || [];
    fields.value.forEach(f => {
        if (f.visible)
            cols.push(f.width || '1fr');
    });
    let css = `
		.model-${model.name}:not(.card) > .table-row,
		.model-${model.name}:not(.card) > .table-header {
			grid-template-columns: ${cols.join(' ')};
		} ${customCss.map(e => `.model-${model.name}:not(.card) ${e}`).join(' ')}`;
    list.push(css);
    list.push('}');
    const style = cfw.cleanText(list.join('\n'));
    DomHandler.appendStyle(style, id);
};
const drawerGo = (ix) => {
    drawerBack.value = ix < drawerIndex.value;
    drawerIndex.value = ix;
};
const drawerShow = (content = 'settings', ix = 0, options) => {
    drawerContent.value = content;
    drawerBack.value = ix < drawerIndex.value;
    drawerIndex.value = ix;
    drawerOpen.value = true;
    drawerOptions.value = options; // used for import/export drawer
};
const getLog = async (entry, log, wcfg, currentState) => {
    const w = STATE.cfg.workflow ? wcfg : {
        states: [
            { index: 0, label: 'Created', name: 'created' },
            { index: 1, label: 'Updated', name: 'updated', optional: true },
            { index: 2, label: 'Deleted', name: 'deleted', optional: true }
        ]
    };
    log.value.cfg = w;
    if (!entry.value)
        return;
    const id = entry.value._id;
    const state = entry.value._state;
    if (!state)
        return;
    if (currentState)
        currentState.value = state.current;
    if (!STATE.cfg.form)
        return;
    if (!STATE.cfg.form.options.worklog)
        return;
    const c = w.states.find(e => e.name === state.current);
    if (c) {
        log.value.current = c.label;
    }
    else {
        log.value.current = state.current.capitalize();
    }
    // show repeated consecutive states (false = collapsed)
    const repeat = wcfg ? (wcfg.repeat || false) : false;
    const result = await API.get(`/notifications/${STATE.cfg.name}/${id}`);
    log.value.timeline = result; // cfw.clone(result).reverse();
    let wf = [];
    const r = cfw.clone(log.value.timeline).reverse().filter(e => e.state !== 'message');
    let i = 0, lastIndex = -1;
    // r.forEach(h => {
    await asyncForEach(r, async (h) => {
        if (!h.state || !cfw.isString(h.state))
            return;
        const ws = w.states.find(e => e.name === h.state);
        const node = {
            state: h.state,
            active: i === (r.length - 1),
            complete: true,
            index: (ws ? ws.index : null),
            label: (ws ? ws.label : h.state.capitalize()),
            ts: h.ts,
            time: cfw.formatDate(h.ts, 'yyyy-MM-dd HH:mm'),
            user: h.user,
            text: h.text || '',
            files: h.files || [],
            hidden: (ws ? ws.hidden || false : false),
            read: (h.read ? cfw.formatDate(h.read, 'yyyy-MM-dd HH:mm') : null)
        };
        if (ws)
            lastIndex = ws.index;
        if (isPurchasingModel(entry.value)) {
            if (['approval', 'erp'].includes(node.state)) {
                if (h.tracking && h.tracking.id)
                    node['tracking'] = h.tracking.id;
            }
            if (h.approval && h.approval._id) {
                node['approval'] = h.approval._id;
                const approval = await API.get(`/automation/approval/${h.approval._id}`);
                if (approval) {
                    const assignedUser = approval.assignees.length > 0 ? approval.assignees[0] : null;
                    if (assignedUser)
                        node['assignedUser'] = assignedUser;
                }
            }
        }
        const lastNode = wf.length > 0 ? wf[wf.length - 1] : null;
        if (lastNode && lastNode.state === node.state && lastNode.user._id === node.user._id && !repeat) {
            let count = lastNode.count || 1;
            lastNode.ts = node.ts;
            lastNode.time = node.time;
            lastNode.user = node.user;
            lastNode.text = `${lastNode.text} ${node.text || ''}`.trim();
            lastNode.files = [...lastNode.files, ...(node.files || [])];
            count++;
            lastNode.count = count;
        }
        else {
            if (ws)
                wf.push(node);
        }
        i++;
    });
    if (lastIndex > -1) {
        w.states.filter(e => e.index > lastIndex && !e.back && !e.optional && !e.hidden).forEach(h => {
            wf.push({
                state: h.state,
                active: false,
                complete: false,
                index: h.index,
                label: h.label
            });
        });
    }
    log.value.workflow = wf;
};
const useForm = (fields, groups) => {
    const msg = useMessages();
    const init = (fullscreen = false) => {
        loader.show(fullscreen);
        if (fields) {
            fields.value.forEach(field => {
                field.errors = [];
            });
        }
    };
    const fail = (err, toast = false) => {
        const errors = [];
        const errList = err.data ? (err.data.errors || []) : (err.errors || []);
        if (errList) {
            errList.forEach(error => {
                if (error.field && !error.line) {
                    const field = fields ? fields.value.find(f => f.src === error.field) : null;
                    if (field) {
                        field.errors = [error.message];
                        if (groups) {
                            groups.value.forEach(g => {
                                if (g.fields && cfw.isArray(g.fields) && g.fields.flat().includes(field.src))
                                    g.collapsed = false;
                            });
                        }
                        if (toast)
                            errors.push({ message: error.message, pos: 0, subpos: 0, key: '' });
                    }
                    else {
                        if (toast)
                            errors.push({ message: error.message, pos: 0, subpos: 0, key: '' });
                    }
                }
                else {
                    const errData = { message: error.message };
                    if (error.line) {
                        errData['pos'] = error.line.pos;
                        errData['subpos'] = error.line.subpos;
                        errData['key'] = error.line.key;
                    }
                    errors.push(errData);
                }
            });
        }
        else {
            errors.push({ message: err.message, pos: 0, subpos: 0, key: '' });
        }
        if (errors.length > 0) {
            let display = [];
            const sorted = cfw.sortBy(errors, 'pos', 'subpos', 'message');
            sorted.forEach(e => {
                const found = display.find(f => f.message === e.message);
                if (found) {
                    found.keys.push(e.key);
                }
                else {
                    display.push({ message: e.message, keys: [e.key] });
                }
            });
            display = display.map(e => {
                let m = e.message;
                const l = e.keys.filter(k => k);
                if (l.length > 0)
                    m += ` (${l.join(',')})`;
                return m;
            });
            const alert = err.key ? msg.warn : msg.fail;
            let m = display.join('<br>');
            const status = err._status ? ' ' + i18n `${err._status.capitalize()}` : '';
            if (err.key)
                m = `${err.key}${status}<br>${m}`;
            alert(m);
        }
        else {
            loader.hide();
        }
    };
    return {
        init,
        fail
    };
};
const isAvailableForUser = (list, mode = 'view') => {
    // admin manager
    // requester
    // buyer
    // manager-purchasing manager-supplier
    // auditor director
    // supplier supplier-user
    // viewer
    if (!APP.user)
        return false;
    let available = true;
    let all = ['admin', 'manager'];
    if (mode === 'view')
        all = ['admin', 'manager', 'buyer', 'auditor', 'director', 'manager-purchasing', 'manager-supplier'];
    if (!list || all.includes(APP.user.role.key))
        return available;
    return isAvailable(list, APP.user.role.key);
};
const isAvailable = (list, compare, availableIfEmpty = true) => {
    let available = true;
    if (!list || !compare)
        return availableIfEmpty;
    const allowList = list.filter(e => !/^!/.test(e));
    const denyList = list.filter(e => /^!/.test(e)).map(e => e.replace(/^!/, ''));
    if (denyList.length > 0) {
        if (denyList.includes(compare))
            available = false;
    }
    else if (allowList.length > 0) {
        if (!allowList.includes(compare))
            available = false;
    }
    return available;
};
const isEmptyData = (group, entry, isEmptyList) => {
    const { type, _state, eval_items_material, eval_items_service } = entry.value;
    if (type !== "contract-evaluation" && type !== "order-evaluation")
        return false;
    if (entry.value._state.current === "expired" && group.key !== 'header')
        return true;
    if (group.key === "possibleEvaluators" && (!entry.value.hasOwnProperty('possibleEvaluators') || entry.value.possibleEvaluators.length === 0))
        return true;
    if (group.key === "totals" &&
        Array.isArray(eval_items_service) &&
        eval_items_service.length === 0) {
        if (group.cols.length > 1)
            group.cols.shift();
    }
    if (group.key === "ssa") {
        if (_state.current === "closed" && !entry.value.hasOwnProperty('ssaTotal'))
            return true;
        if (type === "contract-evaluation" && entry.value.requesterEvaluators && entry.value.requesterEvaluators.length > 0)
            return true;
        if (entry.value.notSSA) {
            return true;
        }
    }
    if ((group.key === "eval_items_service" || group.key === "ssa") &&
        Array.isArray(eval_items_service) &&
        eval_items_service.length === 0)
        return true;
    if (group.key === "eval_items_material" &&
        Array.isArray(eval_items_material) &&
        eval_items_material.length === 0)
        return true;
    if (group.key === "eval_history" && isEmptyList.value)
        return true;
    return false;
};
const toHide = (field, entry) => {
    const { type, eval_items_material, eval_items_service, parent, notSSA, reason } = entry.value;
    if (type === "contract-evaluation" || type === "order-evaluation") {
        if (Array.isArray(eval_items_material) && eval_items_material.length === 0 && field.id.startsWith("totalMaterial"))
            return true;
        if (Array.isArray(eval_items_service) && eval_items_service.length === 0 && (field.id.startsWith("totalService") || field.id === "ssaTotal"))
            return true;
        if (field.id === "requesterEvaluators" && (parent || type === "order-evaluation"))
            return true;
        if (field.id === "ssaTotal" && notSSA)
            return true;
        if (field.id === "reason" && !reason)
            return true;
    }
    return false;
};
const toDisable = (field, entry) => {
    const { type, key, parent } = entry.value;
    if (type === "contract-evaluation" || type === "order-evaluation") {
        if (field.id === "period" && !!parent && !key.includes('.0'))
            return true;
    }
    return false;
};
const getAvailableGroups = (groups, entry, currentState, isEmptyList) => {
    groups.forEach(group => {
        // RVP
        // group.states = null;
        const userAvailable = isAvailableForUser(group.roles);
        const stateAvailable = isAvailable(group.states, currentState.value);
        const isEmpty = isEmptyData(group, entry, isEmptyList);
        group.visible = userAvailable && stateAvailable && !isEmpty;
    });
    return groups.filter(group => group.visible);
};
const getAvailableFields = (fields, entry, currentState) => {
    const f = cfw.clone(fields);
    let conditionalFields = null;
    f.forEach(field => {
        if (field.conditionalValue && entry.value[field.id]) {
            conditionalFields = {
                conditionalIfFields: field.conditionalIfFields,
                conditionalElseFields: field.conditionalElseFields,
                visible: entry.value[field.id]._id
                    ? field.conditionalValue !== entry.value[field.id]._id
                    : field.conditionalValue !== entry.value[field.id]
            };
        }
        if (conditionalFields) {
            const { conditionalIfFields, conditionalElseFields, visible } = conditionalFields;
            if (conditionalIfFields && conditionalIfFields.includes(field.id))
                field.startInvisible = visible;
            if (conditionalElseFields && conditionalElseFields.includes(field.id))
                field.startInvisible = !visible;
        }
        field.visible = field.startInvisible ? false : isAvailableForUser(field.viewRoles) && isAvailable(field.viewStates, currentState.value) && !toHide(field, entry);
        field.disabled = !isAvailableForUser(field.editRoles, 'edit') || !isAvailable(field.editStates, currentState.value) || toDisable(field, entry);
        field.required = field.required || isAvailable(field.requiredStates, currentState.value, false);
        // TODO check overrides
        const fieldVal = cfw.getProp(entry.value, field.src);
        if (field.default && cfw.isEmpty(fieldVal)) {
            cfw.setProp(entry.value, field.src, field.default);
        }
        if (field.hiddenIfNull && cfw.isEmpty(fieldVal)) {
            field.visible = false;
        }
    });
    return f.filter(field => field.visible);
};
const getEntryFields = (type, entry, fields, line) => {
    const f = cfw.clone(fields);
    if (type !== 'order')
        return f;
    let taxVisible = true;
    let taxShow = 'normal';
    let taxWarning = null;
    // Inversão Sujeito Passivo
    if (entry.lsi) {
        if (isSupplier()) {
            taxVisible = false;
            taxShow = 'netValue';
        }
        else {
            taxWarning = '(Valor não presente na fatura)';
        }
    }
    let tax = [];
    if (line && line.tax) {
        tax = [line.tax.key];
    }
    else {
        tax = [...new Set(entry.lines.map(l => l.tax ? l.tax.key : null).filter(e => e))];
    }
    // Isento / Não sujeito
    // Não dedutivel
    // Dedutível Intra e Extracomunitários
    const taxCfg = APP.cfg.purchasing.tax;
    if (tax.length > 0 && isSupplier()) {
        if (tax.some(t => taxCfg['no-tax'].includes(t))) {
            taxVisible = false;
            taxShow = 'netValue';
        }
        else if (tax.some(t => taxCfg['non-deductible'].includes(t))) {
            taxVisible = false;
            taxShow = 'value';
        }
        else if (tax.some(t => taxCfg['deductible-intra'].includes(t))) {
            taxVisible = false;
            taxShow = 'netValue';
        }
    }
    if (tax.length > 0 && !isSupplier() && tax.some(t => taxCfg['deductible-intra'].includes(t))) {
        taxWarning = '(Valor não presente na fatura)';
    }
    if (taxShow !== 'normal' || taxWarning) {
        f.forEach(field => {
            if (taxShow !== 'normal') {
                if (['netValue', 'value'].includes(field.src))
                    field.src = taxShow;
                if (['tax', 'taxValue'].includes(field.src) && !taxVisible)
                    field.src = '';
            }
            if (field.src === 'taxValue' && taxWarning)
                field.warning = taxWarning;
        });
    }
    return f;
};
const editCheck = async (type, entry, action = 'check') => {
    const c = action === 'edit' ? 'edit-start' : 'edit-check';
    const url = `/purchasing/${type}/${entry._id}/${c}`;
    const result = await API.get(url);
    return result;
};
const getConfig = (key, defaultValue = null) => {
    if (!cfw.isObject(STATE.cfg))
        return defaultValue;
    if (Object.keys(STATE.cfg).length === 0)
        return defaultValue;
    const value = cfw.getProp(STATE.cfg, key);
    return cfw.isUndefined(value) ? defaultValue : value;
};
export { STATE, getModel, setModel, getModelSchema, getQuery, actionsAvailable, layout, drawer, drawerContent, drawerOptions, drawerHelpText, drawerOpen, drawerBack, drawerIndex, drawerShow, drawerGo, drawerTopOpen, drawerCommentRequired, useList, getFields, getStyle, getGroups, getLog, useForm, getOverrides, changeFields, getAvailableGroups, getAvailableFields, getEntryFields, isPurchasingModel, editCheck, getConfig };
