import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "d-flex justify-content-center" };
const _hoisted_2 = ["id", "onUpdate:modelValue"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.code, (n, i) => {
            return _withDirectives((_openBlock(), _createElementBlock("input", {
                key: i,
                type: "number",
                pattern: "\\d*",
                id: 'input_' + i,
                maxlength: "1",
                "onUpdate:modelValue": ($event) => ((_ctx.code[i]) = $event),
                onInput: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
                onKeypress: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.isNumber && _ctx.isNumber(...args))),
                onKeydown: _cache[2] || (_cache[2] = _withKeys(
                //@ts-ignore
                (...args) => (_ctx.handleDelete && _ctx.handleDelete(...args)), ["delete"])),
                onPaste: _cache[3] || (_cache[3] =
                    //@ts-ignore
                    (...args) => (_ctx.onPaste && _ctx.onPaste(...args))),
                class: "form-control keycode"
            }, null, 40, _hoisted_2)), [
                [_vModelText, _ctx.code[i]]
            ]);
        }), 128))
    ]));
}
