/*
 * string
 */
String.prototype.repeat = function(num) {
	return new Array(num + 1).join(this);
};

String.prototype.pad = function(num) {
	return this.length >= num ? String(this) : String('0'.repeat(num) + this).slice(-num);
};

String.prototype.ellipsis = function(num) {
	if (this.length > num) return this.substring(0, num) + '...';
	return this;
};

String.prototype.convertDateFromISO = function() {
	var s = this;
	s = s.split(/\D/);
	return new Date(Date.UTC(s[0], --s[1]||'', s[2]||'', s[3]||'', s[4]||'', s[5]||'', s[6]||''));
};

String.prototype.hashCode = function() {
	var hash = 0;
	if (this.length === 0) return hash;
	for (var i = 0; i < this.length; i++) {
		var char = this.charCodeAt(i);
		hash = ((hash<<5)-hash)+char;
		hash = hash & hash; // convert to 32bit integer
	}
	return hash;
};

String.prototype.startsWith = String.prototype.startsWith || function(searchString, position) {
	position = position || 0;
	return this.lastIndexOf(searchString, position) === position;
};

String.prototype.endsWith = String.prototype.endsWith || function(searchString, position) {
	var subjectString = this.toString();
	if (position === undefined || position > subjectString.length) {
		position = subjectString.length;
	}
	position -= searchString.length;
	var lastIndex = subjectString.indexOf(searchString, position);
	return lastIndex !== -1 && lastIndex === position;
};

String.prototype.includes = String.prototype.includes || function() {
	'use strict';
	return String.prototype.indexOf.apply(this, arguments) !== -1;
};

String.prototype.contains = function(str) {
	return new RegExp(str, 'i').test(this);
};

String.prototype.replaceAll = function(search, replacement) {
	return this.replace(new RegExp(search, 'g'), replacement);
};

String.prototype.format = String.prototype.format || function() {
	var str = this.toString();
	if (arguments.length) {
		var t = typeof arguments[0];
		var key;
		var args = (t === 'string' || t === 'number') ? Array.prototype.slice.call(arguments) : arguments[0];
		for (key in args) {
			str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key] || '');
		}
	}
	return str;
};

String.prototype.capitalize = String.prototype.capitalize || function(l) {
	if (l === 'lower') return this.charAt(0).toLowerCase() + this.substring(1);
	return this.charAt(0).toUpperCase() + this.substring(1);
};

// https://stackoverflow.com/questions/18749591/encode-html-entities-in-javascript

String.prototype.toHtmlEntities = function() {
	return this.replace(/./gm, function(s) {
		// return "&#" + s.charCodeAt(0) + ";";
		return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
	});
};

String.fromHtmlEntities = function(string) {
	return (string+"").replace(/&#\d+;/gm,function(s) {
		return String.fromCharCode(s.match(/\d+/gm)[0]);
	});
};

/*
 * date
 */
// years y months M weeks w days d hours h minutes m seconds s milliseconds ms
Date.prototype.add = function(n, d) {
	var value = new Date(this.valueOf());
	if (d === undefined) {
		d = n.substr(n.length - 1);
		n = parseInt(n.substr(0, n.length - 1), 10);
	}
	switch (d) {
		case 'y': return value.addYears(n);
		case 'M': return value.addMonths(n);
		case 'w': return value.addWeeks(n);
		case 'd': return value.addDays(n);
		case 'H': return value.addHours(n);
		case 'm': return value.addMinutes(n);
		case 's': return value.addSeconds(n);
	}
};

Date.prototype.addSeconds = function(num) {
	var value = this.valueOf();
	value += 1000 * num;
	return new Date(value);
};

Date.prototype.addMinutes = function(num) {
	var value = this.valueOf();
	value += 60000 * num;
	return new Date(value);
};

Date.prototype.addHours = function(num) {
	var value = this.valueOf();
	value += 3600000 * num;
	return new Date(value);
};

Date.prototype.addDays = function(num) {
	var value = this.valueOf();
	value += 86400000 * num;
	return new Date(value);
};

const lastday = (y, m) => {
	return new Date(y, m + 1, 0).getDate();
};

Date.prototype.addMonths = function(num) {
	var value = new Date(this.valueOf());
	var mo = this.getMonth();
	var yr = this.getFullYear();
	mo = (mo + num) % 12;
	if (0 > mo) {
		yr += (this.getMonth() + num - mo - 12) / 12;
		mo += 12;
	} else {
		yr += ((this.getMonth() + num - mo) / 12);
	}
	if (mo === 1) {
		const ld = lastday(yr, mo);
		if (value.getDate() > ld) {
			value.setDate(ld);
		}
	}
	value.setMonth(mo);
	value.setYear(yr);
	return value;
};

Date.prototype.addYears = function(num) {
	var value = new Date(this.valueOf());
	var yr = this.getFullYear();
	yr += num;
	value.setYear(yr);
	return value;
};

Date.prototype.addWeeks = function(num) {
	var value = this.valueOf();
	value += 86400000 * num * 7;
	return new Date(value);
};

Date.prototype.noTime = function() {
	this.setHours(0, 0, 0, 0);
	var value = this.valueOf();
	return new Date(value);
};

Date.prototype.diffDays = function(to) {
	return Math.round((to.getTime() - this.getTime()) / (24 * 60 * 60 * 1000));
};

Date.prototype.isLastDayOfMonth = function() {
	return new Date(this.getTime() + 86400000).getDate() === 1;
};

/*
 * array
 */
if (!Array.prototype.includes) {
	Array.prototype.includes = function(val) {
		return (this.indexOf(val) !== -1);
	};
}

Array.prototype.uniq = function() {
	var a = this;
	return a.filter(function(el, pos) {
		return a.indexOf(el) === pos;
	});
};

Array.prototype.min = function() {
	return Math.min.apply(null, this);
};

Array.prototype.max = function() {
	return Math.max.apply(null, this);
};

/* Array.prototype.sortBy = function() {
	function _sortByAttr(attr) {
		var sortOrder = 1;
		if (attr[0] === '-') {
			sortOrder = -1;
			attr = attr.substr(1);
		}
		return function(a, b) {
			var result = (a[attr] < b[attr]) ? -1 : (a[attr] > b[attr]) ? 1 : 0;
			return result * sortOrder;
		};
	}
	function _getSortFunc() {
		if (arguments.length === 0) {
			throw 'Zero length arguments not allowed for Array.sortBy()';
		}
		var args = arguments;
		return function(a, b) {
			for (var result = 0, i = 0; result === 0 && i < args.length; i++) {
				result = _sortByAttr(args[i])(a, b);
			}
			return result;
		};
	}
	return this.sort(_getSortFunc.apply(null, arguments));
}; */
