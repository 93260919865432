import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_toast_message = _resolveComponent("v-toast-message");
    return (_openBlock(), _createElementBlock("div", {
        ref: "container",
        class: _normalizeClass(_ctx.containerClass)
    }, [
        _createVNode(_TransitionGroup, {
            name: "c-toast-message",
            tag: "div"
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (msg) => {
                    return (_openBlock(), _createBlock(_component_v_toast_message, {
                        key: msg.id,
                        message: msg,
                        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.remove($event)))
                    }, null, 8, ["message"]));
                }), 128))
            ]),
            _: 1
        })
    ], 2));
}
