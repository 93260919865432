let CURRENT_LOCALE = null;

const DEFAULT_LOCALE = {
	date: {
		format: 'dd-MM-yyyy',
		monthsFull: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		daysFull: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		daysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
		timeFormat: 'HH:mm',
		dtsFormat: 'HH:mm:ss', // .SSS
		shortDateFormat: 'dd-MM-yyyy',
		longDateFormat: 'EEE, dd MMMM yyyy',
		weekStart: 0
	},
	number: {
		format: '#,##0.0#',
		groupingSeparator: ',',
		decimalSeparator: '.'
	}
};

const setLocale = (locale) => {
	CURRENT_LOCALE = locale;
};

const getLocale = () => {
	const locale = CURRENT_LOCALE || DEFAULT_LOCALE;
	return locale;
};

const _date = (value, format) => {

	const locale = CURRENT_LOCALE || DEFAULT_LOCALE;

	let i = 0,
		j = 0,
		l = 0,
		c = '',
		token = '',
		x,
		y;

	// UNIX timestamp or JS time
	if (/^\d+$/.test(value)) {
		const tmp = new Date(parseInt(/^\d{10}$/.test(value) ? value * 1000 : value));
		const valid = tmp.getTime();
		if (valid > 0) {
			value = tmp;
			if (!format) return value;
		} else {
			return '';
		}
	} else if (isEmpty(value)) {
		return '';
	}

	if (isEmpty(format)) format = locale.date.format;

	if (isString(value)) {

		const _getNumber = (str, p, minlength, maxlength) => {
			for (let x = maxlength; x >= minlength; x--) {
				const token = str.substring(p, p + x);
				if (token.length >= minlength && /^\d+$/.test(token)) {
					return token;
				}
			}
			return null;
		};

		let _strict = false,
			pos = 0,
			now = new Date(0, 0, 0, 0, 0, 0, 0),
			year = now.getYear(),
			month = now.getMonth() + 1,
			date = 1,
			hh = now.getHours(),
			mm = now.getMinutes(),
			ss = now.getSeconds(),
			SSS = now.getMilliseconds(),
			ampm = '',
			monthName,
			dayName;

		while (i < format.length) {
			token = '';
			c = format.charAt(i);
			while ((format.charAt(i) === c) && (i < format.length)) {
				token += format.charAt(i++);
			}
			if (token.indexOf('MMMM') > - 1 && token.length > 4) {
				token = 'MMMM';
			}
			if (token.indexOf('EEEE') > - 1 && token.length > 4) {
				token = 'EEEE';
			}
			if (token === 'yyyy' || token === 'yy' || token === 'y') {
				if (token === 'yyyy') {
					x = 4;
					y = 4;
				}
				if (token === 'yy') {
					x = 2;
					y = 2;
				}
				if (token === 'y') {
					x = 2;
					y = 4;
				}
				year = _getNumber(value, pos, x, y);
				if (year === null) {
					return 0;
				}
				pos += year.length;
				if (year.length === 2) {
					year = parseInt(year, 10);
					if (year > 70) {
						year = 1900 + year;
					} else {
						year = 2000 + year;
					}
				}
			} else if (token === 'MMMM') {
				month = 0;
				for (j = 0, l = locale.date.monthsFull.length; j < l; j++) {
					monthName = locale.date.monthsFull[j];
					if (value.substring(pos, pos + monthName.length).toLowerCase() === monthName.toLowerCase()) {
						month = j + 1;
						pos += monthName.length;
						break;
					}
				}
				if ((month < 1) || (month > 12)) {
					return 0;
				}
			} else if (token === 'MMM') {
				month = 0;
				for (j = 0, l = locale.date.monthsShort.length; j < l; j++) {
					monthName = locale.date.monthsShort[j];
					if (value.substring(pos, pos + monthName.length).toLowerCase() === monthName.toLowerCase()) {
						month = j + 1;
						pos += monthName.length;
						break;
					}
				}
				if ((month < 1) || (month > 12)) {
					return 0;
				}
			} else if (token === 'EEEE') {
				for (j = 0, l = locale.date.daysFull.length; j < l; j++) {
					dayName = locale.date.daysFull[j];
					if (value.substring(pos, pos + dayName.length).toLowerCase() === dayName.toLowerCase()) {
						pos += dayName.length;
						break;
					}
				}
			} else if (token === 'EEE') {
				for (j = 0, l = locale.date.daysShort.length; j < l; j++) {
					dayName = locale.date.daysShort[j];
					if (value.substring(pos, pos + dayName.length).toLowerCase() === dayName.toLowerCase()) {
						pos += dayName.length;
						break;
					}
				}
			} else if (token === 'MM' || token === 'M') {
				month = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (month === null || (month < 1) || (month > 12)) {
					return 0;
				}
				pos += month.length;
			} else if (token === 'dd' || token === 'd') {
				date = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (date === null || (date < 1) || (date > 31)) {
					return 0;
				}
				pos += date.length;
			} else if (token === 'hh' || token === 'h') {
				hh = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (hh === null || (hh < 1) || (hh > 12)) {
					return 0;
				}
				pos += hh.length;
			} else if (token === 'HH' || token === 'H') {
				hh = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (hh === null || (hh < 0) || (hh > 23)) {
					return 0;
				}
				pos += hh.length;
			} else if (token === 'KK' || token === 'K') {
				hh = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (hh === null || (hh < 0) || (hh > 11)) {
					return 0;
				}
				pos += hh.length;
			} else if (token === 'kk' || token === 'k') {
				hh = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (hh === null || (hh < 1) || (hh > 24)) {
					return 0;
				}
				pos += hh.length;
				hh--;
			} else if (token === 'mm' || token === 'm') {
				mm = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (mm === null || (mm < 0) || ( mm > 59)) {
					return 0;
				}
				pos += mm.length;
			} else if (token === 'ss' || token === 's') {
				ss = _getNumber(value, pos, _strict ? token.length : 1, 2);
				if (ss === null || (ss < 0) || (ss > 59)) {
					return 0;
				}
				pos += ss.length;
			} else if (token === 'SSS' || token === 'SS' || token === 'S') {
				SSS = _getNumber(value, pos, _strict ? token.length : 1, 3);
				if (SSS === null || (SSS < 0) || (SSS > 999)) {
					return 0;
				}
				pos += SSS.length;
			} else if (token === 'a') {
				const ap = value.substring(pos, pos + 2).toLowerCase();
				if (ap === 'am') {
					ampm = 'AM';
				} else if (ap === 'pm') {
					ampm = 'PM';
				} else {
					return 0;
				}
				pos += 2;
			} else {
				if (token !== value.substring(pos, pos + token.length)) {
					return 0;
				} else {
					pos += token.length;
				}
			}
		}
		if (pos !== value.length) {
			return 0;
		}
		if (month === 2) {
			if (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)) {
				if (date > 29) {
					return 0;
				}
			} else {
				if (date > 28) {
					return 0;
				}
			}
		}
		if ((month === 4) || (month === 6) || (month === 9) || (month === 11)) {
			if (date > 30) {
				return 0;
			}
		}
		if (hh < 12 && ampm === 'PM') {
			hh = hh - 0 + 12;
		} else if (hh > 11 && ampm === 'AM') {
			hh -= 12;
		}

		return (new Date(year, month - 1, date, hh, mm, ss, SSS));
	}

	const _formatNumber = (n, s) => {
		if (isEmpty(s) || s === 2) {
			return (n >= 0 && n < 10 ? '0' : '') + n;
		} else {
			if (n >= 0 && n < 10) {
				return '00' + n;
			}
			if (n >= 10 && n <100) {
				return '0' + n;
			}
			return n;
		}
	};

	y = value.getYear();
	if (y < 1000) {
		y = String(y + 1900);
	}

	const M = value.getMonth() + 1,
		d = value.getDate(),
		E = value.getDay(),
		H = value.getHours(),
		m = value.getMinutes(),
		s = value.getSeconds(),
		S = value.getMilliseconds();

	value = {
		y: y,
		yyyy: y,
		yy: String(y).substring(2, 4),
		M: M,
		MM: _formatNumber(M),
		MMM: locale.date.monthsShort[M - 1],
		MMMM: locale.date.monthsFull[M - 1],
		d: d,
		dd: _formatNumber(d),
		EEE: locale.date.daysShort[E],
		EEEE: locale.date.daysFull[E],
		H: H,
		HH: _formatNumber(H)
	};

	if (H === 0) {
		value.h = 12;
	} else if (H > 12) {
		value.h = H - 12;
	} else {
		value.h = H;
	}

	value.hh = _formatNumber(value.h);
	value.k = H !== 0 ? H : 24;
	value.kk = _formatNumber(value.k);

	if (H > 11) {
		value.K = H - 12;
	} else {
		value.K = H;
	}

	value.KK = _formatNumber(value.K);

	if (H > 11) {
		value.a = 'PM';
	} else {
		value.a = 'AM';
	}

	value.m = m;
	value.mm = _formatNumber(m);
	value.s = s;
	value.ss = _formatNumber(s);
	value.S = S;
	value.SS = _formatNumber(S);
	value.SSS = _formatNumber(S, 3);

	let result = '', rs = false;

	i = 0;
	c = '';
	token = '';

	while (i < format.length) {
		token = '';
		c = format.charAt(i);
		if (c === '\'') {
			i++;
			if (format.charAt(i) === c) {
				result = result + c;
				i++;
			} else {
				rs = !rs;
			}
		} else {
			while (format.charAt(i) === c) {
				token += format.charAt(i++);
			}
			if (token.indexOf('MMMM') !== -1 && token.length > 4) {
				token = 'MMMM';
			}
			if (token.indexOf('EEEE') !== -1 && token.length > 4) {
				token = 'EEEE';
			}
			if (!isEmpty(value[token]) && !rs) {
				result = result + value[token];
			} else {
				result = result + token;
			}
		}
	}

	return result;
};

const _number = (value, format) => {

	const locale = CURRENT_LOCALE || DEFAULT_LOCALE;

	let roundFactor,
		result,
		i;

	if (isString(value)) {
		if (locale.number.decimalSeparator !== '.') {
			value = value.replace(/\./g, '').replace(locale.number.decimalSeparator, '.');
		}
		const number = (value.indexOf('%') > -1 ? 0.01 : 1) *
			(((value.split('-').length + Math.min(value.split('(').length-1, value.split(')').length-1)) % 2) ? 1: -1) *
			Number(value.replace(/[^0-9\.]+/g, ''));

		return number;
	}

	if (typeof format == 'undefined' || format.length < 1) format = locale.number.format;

	const groupingSeparator = ',',
		groupingIndex = format.lastIndexOf(groupingSeparator),
		decimalSeparator = '.',
		decimalIndex = format.indexOf(decimalSeparator);

	const negative = value < 0,
		minFraction = format.substr(decimalIndex + 1).replace(/#/g, '').length,
		maxFraction = format.substr(decimalIndex + 1).length;

	let integer = '',
		fraction = '',
		powFraction = 10;

	value = Math.abs(value);

	if (decimalIndex !== -1) {
		fraction = locale.number.decimalSeparator;
		if (maxFraction > 0) {
			roundFactor = 1000;
			powFraction = Math.pow(powFraction, maxFraction);
			const tempRound = Math.round(parseInt(value * powFraction * roundFactor - Math.round(value) * powFraction * roundFactor, 10) / roundFactor);
			let tempFraction = String(tempRound < 0
				? Math.round(parseInt(value * powFraction * roundFactor - parseInt(value, 10) * powFraction * roundFactor, 10) / roundFactor)
				: tempRound);
			const parts = value.toString().split('.');
			if (typeof parts[1] != 'undefined') {
				for (i = 0; i < maxFraction; i++) {
					if (parts[1].substr(i, 1) === '0' && i < maxFraction - 1 && tempFraction.length !== maxFraction) {
						tempFraction = '0' + tempFraction;
					} else {
						break;
					}
				}
			}
			for (i = 0; i < (maxFraction - fraction.length); i++) {
				tempFraction += '0';
			}
			let symbol, formattedFraction = '';
			for (i = 0; i < tempFraction.length; i++) {
				symbol = tempFraction.substr(i, 1);
				if (i >= minFraction && symbol === '0' && /^0*$/.test(tempFraction.substr(i+1))) {
					break;
				}
				formattedFraction += symbol;
			}
			fraction += formattedFraction;
		}
		if (fraction === locale.number.decimalSeparator) {
			fraction = '';
		}
	}

	if (decimalIndex !== 0) {
		if (fraction !== '') {
			integer = String(parseInt(Math.round(value * powFraction) / powFraction, 10));
		} else {
			integer = String(Math.round(value));
		}
		const grouping = locale.number.groupingSeparator;
		let groupingSize = 0;
		if (groupingIndex !== -1) {
			if (decimalIndex !== -1) {
				groupingSize = decimalIndex - groupingIndex;
			} else {
				groupingSize = format.length - groupingIndex;
			}
			groupingSize--;
		}
		if (groupingSize > 0) {
			let count = 0, formattedInteger = '';
			i = integer.length;
			while (i--) {
				if (count !== 0 && count % groupingSize === 0) {
					formattedInteger = grouping + formattedInteger;
				}
				formattedInteger = integer.substr(i, 1) + formattedInteger;
				count++;
			}
			integer = formattedInteger;
		}
		const maxRegExp = /#|,/g;
		const maxInteger = decimalIndex !== -1
			? format.substr(0, decimalIndex).replace(maxRegExp, '').length
			: format.replace(maxRegExp, '').length;
		let tempInteger = integer.length;
		for (i = tempInteger; i < maxInteger; i++) {
			integer = '0' + integer;
		}
	}

	result = integer + fraction;
	return (negative ? '-' : '') + result;
};

const getDate = (value, format) => {
	return _date(value, format);
};

const getNumber = (value, format) => {
	return _number(value, format);
};

const isString = value => typeof value === 'string' || value instanceof String;
const isNumber = value => typeof value === 'number' && isFinite(value);
const isArray = value => value && typeof value === 'object' && value.constructor === Array;
const isFunction = value => typeof value === 'function';
const isObject = value => value && typeof value === 'object' && value.constructor === Object;
const isNull = value => value === null;
const isUndefined = value => typeof value === 'undefined';
const isEmpty = value => isNull(value) || isUndefined(value);
const isBoolean = value => typeof value === 'boolean';
const isRegExp = value => value && typeof value === 'object' && value.constructor === RegExp;
const isError = value => value instanceof Error && typeof value.message !== 'undefined';
const isDate = value => value instanceof Date;
const isSymbol = value => typeof value === 'symbol';

const isEquivalent = (x, y) => {
	if (x === null || x === undefined || y === null || y === undefined) {
		return x === y;
	}
	if (x.constructor !== y.constructor) {
		return false;
	}
	if (x instanceof Function) {
		return x === y;
	}
	if (x instanceof RegExp) {
		return x === y;
	}
	if (x === y || x.valueOf() === y.valueOf()) {
		return true;
	}
	if (Array.isArray(x) && x.length !== y.length) {
		return false;
	}
	if (x instanceof Date) {
		return false;
	}
	if (!(x instanceof Object)) {
		return false;
	}
	if (!(y instanceof Object)) {
		return false;
	}
	let p = Object.keys(x);
	return (
		Object.keys(y).every((i) => {
			return p.indexOf(i) !== -1;
		}) && p.every((i) => {
			return isEquivalent(x[i], y[i]);
		})
	);
};

const _printable = (str) => {
	// remove all non printable chars
	// [\x00-\x1F\x80-\xFF] /[\x00-\x1F\x7F]/u
	return str.replace(/[^[:print:]]/g, '');
};

const cleanText = (str, len) => {
	let text = _printable(str);
	text = text.replace(/\W_/g, '');
	text = text.replace(/[\n\r\s\t]+/g, ' ');
	text = text.trim();
	if (!len) return text;
	if (text.length > len) text = text.substr(0, len) + '...';
	return text;
};

const escapeXml = (value, quote) => {
	if (quote === undefined) quote = false;
	return quote ? value.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&apos;') : value.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;');
};

const escapeString = (value) => {
	if (typeof value != 'string') return value;
	return value
		.replace(/[\0\n\r\b\\\'\"]/g, (s) => {
			switch (s) {
				// @formatter:off
				case '\0': return '\\0';
				case '\n': return '\\n';
				case '\r': return '\\r';
				case '\b': return '\\b';
				default:   return '\\' + s;
				// @formatter:off
			}
		})
		// uglify compliant
		.replace(/\t/g, '\\t')
		.replace(/\x1a/g, '\\Z');
};

const escapeRegExp = (str) => {
	return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
};

const jsonEscapeUTF = (s) => {
	return s.replace(/[^\x20-\x7F]/g, x => "\\u" + ("000"+x.codePointAt(0).toString(16)).slice(-4));
};

const pick = (...props) => o => {
	if (props.length === 1 && isArray(props)) props = props[0];
	return props.reduce((a, e) => ({...a, [e]: o[e]}), {});
};

const getProp = (obj, props) => {
	if (!obj) return null;
	if (isString(props)) props = props.split('.');
	const prop = props.shift();
	if (!obj[prop] || !props.length) return obj[prop];
	return getProp(obj[prop], props);
};

const setProp = (obj, props, value) => {
	if (!obj) return;
	if (isString(props)) props = props.split('.');
	const prop = props.shift();
	// TODO RVP... isto estava antes com obj.hasOwnProperty ...
	if (!obj[prop]) obj[prop] = {};
	// if (!obj.hasOwnProperty(prop)) obj[prop] = {};
	if (!props.length) {
		obj[prop] = value;
		return;
	}
	setProp(obj[prop], props, value);
};

const deleteProp = (obj, props) => {
	if (!obj) return;
	if (isString(props)) props = props.split('.');
	const prop = props.shift();
	// if (!obj[prop])
	if (!obj.hasOwnProperty(prop)) return;
	if (!props.length) {
		delete obj[prop];
		return;
	}
	deleteProp(obj[prop], props);
};

const deleteProps = (obj, props) => {
	for (let i = 0; i < props.length; i++) {
		const key = props[i];
		if (obj.hasOwnProperty(key)) delete obj[key];
	}
};

const deletePropsExcept = (obj, props) => {
	const keys = Object.keys(obj);
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		if (obj.hasOwnProperty(key) && !props.includes(key)) delete obj[key];
	}
};

// const removeProp = (propKey, {[propKey]: propValue, ...rest}) => rest;
const removeProps = (object, ...keys) => Object.entries(object).reduce((prev, [key, value]) => ({...prev, ...(!keys.includes(key) && {[key]: value})}), {});
/* Usage
object = removeProps(object, 'a', 'b')
Or
object = removeProps(object, ...['a', 'b']) */

const renameKeys = (obj, newKeys) => {
	const keyValues = Object.keys(obj).map(key => {
		const newKey = newKeys[key] || key;
		return {[newKey]: obj[key]};
	});
	return Object.assign({}, ...keyValues);
};

const keyValues = (data, keyName, content = 'obj') => {
	if (!keyName) keyName = '_id';
	const kv = {};
	data.forEach(item => {
		kv[item[keyName]] = content === 'obj' ? item : getProp(item, content);
	});
	return kv;
};

const copy = (obj) => {

	// Copy properties from the original object to the clone
	const copyProps = (clone) => {
		for (let key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				clone[key] = copy(obj[key]);
			}
		}
	};

	// Create an immutable copy of an object
	const cloneObj = () => {
		let clone = {};
		copyProps(clone);
		return clone;
	};

	// Create an immutable copy of an array
	const cloneArr = () => {
		return obj.map(item => {
			return copy(item);
		});
	};

	// Create an immutable copy of a Map
	const cloneMap = () => {
		let clone = new Map();
		for (let [key, val] of obj) {
			clone.set(key, copy(val));
		}
		return clone;
	};

	// Create an immutable clone of a Set
	const cloneSet = () => {
		let clone = new Set();
		for (let item of set) {
			clone.add(copy(item));
		}
		return clone;
	};

	// Create an immutable copy of a function
	const cloneFunction = () => {
		let clone = obj.bind(this);
		copyProps(clone);
		return clone;
	};

	// Get object type
	let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

	// Return a clone based on the object type
	if (type === 'object') return cloneObj();
	if (type === 'array') return cloneArr();
	if (type === 'map') return cloneMap();
	if (type === 'set') return cloneSet();
	if (type === 'function') return cloneFunction();
	return obj;
};

const clone = (obj) => {
	const out = isArray(obj) ? [] : {};
	for (const key in obj) {
		const v = obj[key];
		if (!isUndefined(v)) out[key] = (isObject(v)) ? clone(v) : v;
	}
	return out;
};

const deepCopy = (obj) => {
	if (isObject(obj)) {
		return Object.keys(obj).map(k => ({[k]: deepCopy(obj[k])})).reduce((a, c) => Object.assign(a, c), {});
	} else if (isArray(obj)) {
		return obj.map(deepCopy);
	}
	return obj;
};

const deepClone = (obj) => {
	if (obj === null) return null;
	let clone = Object.assign({}, obj);
	Object.keys(clone).forEach((key) => {
		if (isDate(obj[key])) {
			const original = obj[key];
			clone[key] = new Date(original);
			return;
		}
		clone[key] = isObject(obj[key]) ? deepClone(obj[key]) : obj[key];
	});
	return isArray(obj) && obj.length
		? (clone.length = obj.length) && Array.from(clone)
		: isArray(obj)
			? Array.from(obj)
			: clone;
};

const merge = (current, update) => {
	Object.keys(update).forEach(key => {
		// if update[key] exist, and it's not a string or array,
		// we go in one level deeper
		if (current.hasOwnProperty(key) && isObject(current[key])) {
			merge(current[key], update[key]);
			// if update[key] doesn't exist in current, or it's a string
			// or array, then assign/overwrite current[key] to update[key]
		} else if (current.hasOwnProperty(key) && isArray(current[key]) && isArray(update[key])) {
			// concatenate arrays
			current[key] = current[key].concat(update[key]).uniq(); // Array.from(new Set(current[key].concat(update[key])));
		} else {
			current[key] = update[key];
		}
	});
	return current;
};

const trueValues = ['true', '1', 'yes', 'on', 's', 'y', 'x'];
const falseValues = ['false', '0', 'no', 'off', 'n'];
const emptyValues = ['null', 'undefined'];

const typeFunctions = {
	'string': (str) => {
		if (isEmpty(str) || String(str).trim() === '') return null; // tirar a parte do string se quiser receber ''
		if (emptyValues.includes(String(str).trim().toLowerCase())) return null;
		return String(str);
	},
	'number': (str) => {
		if (isEmpty(str) || String(str).trim() === '') return null;
		if (emptyValues.includes(String(str).trim().toLowerCase())) return null;
		if (falseValues.includes(String(str).trim().toLowerCase())) return 0;
		if (trueValues.includes(String(str).trim().toLowerCase())) return 1;
		let number = Number(str);
		if (String(str).contains('|')) return str;
		if (isNaN(number)) return null;
		return number;
	},
	'date': (str) => {
		if (isEmpty(str) || String(str).trim() === '') return null;
		if (emptyValues.includes(String(str).trim().toLowerCase())) return null;
		let number = Number(str);
		if (isNaN(number)) {
			const date = _date(String(str));
			if (isDate(date)) {
				number = date.getTime();
			} else {
				number = null;
			}
		}
		if (String(str).contains('|')) number = str;
		return number;
	},
	'boolean': (str) => {
		if (!str) return false;
		return trueValues.includes(String(str).trim().toLowerCase());
	},
	'array': (str) => {
		return isArray(str) ? str : JSON.parse(str);
	},
	'object': (str) => {
		return isObject(str) ? str : JSON.parse(str);
	}
};

const applyType = (value, type) => {
	const typeFunc = typeFunctions[type];
	if (!typeFunc) return value;
	return typeFunc(value);
};

const round = (value, exp) => {
	if (typeof exp === 'undefined' || +exp === 0) return Math.round(value);
	value = +value;
	exp  = +exp;
	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN;
	// shift
	value = value.toString().split('e');
	value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
	// shift back
	value = value.toString().split('e');
	return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
};

const roundFix = (number, precision) => {
	const multi = Math.pow(10, precision);
	return Math.round((parseNumber(number) * multi).toFixed(precision + 1)) / multi;
};

const countDecimals = (value) => {
	return value % 1 ? value.toString().split('.')[1].length : 0;
};

const cleanEmpty = obj => Object.entries(obj)
	.map(([k, v]) => [k, v && isObject(v) ? cleanEmpty(v) : v])
	.reduce((a, [k, v]) => (v == null ? a : {...a, [k]: v}), {});

const fromEntries = (xs) => xs.reduce((acc, [key, value]) => ({...acc, [key]: value}), {});

const filterEmpty = (obj) => {
	return fromEntries(
		Object.entries(obj).filter((item) => {
			const value = item[1];
			return typeof value !== 'undefined' && value !== null && value !== '' && value.length !== 0;
		})
	);
};

const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));

const includesEvery = (arr, values) => {
	return values.every(value => {
		return arr.includes(value);
	});
};

const isAvailable = (list, compare, availableIfEmpty = true) => {
	let available = true;
	if (!list || !compare) return availableIfEmpty;
	const allowList = list.filter(e => !/^!/.test(e));
	const denyList = list.filter(e => /^!/.test(e)).map(e => e.replace(/^!/, ''));
	if (denyList.length > 0) {
		if (denyList.includes(compare)) available = false;
	} else if (allowList.length > 0) {
		if (!allowList.includes(compare)) available = false;
	}
	return available;
};

const slugify = (text) => {
	return text.toString().toLowerCase().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-').trim();
};

const parseIcon = (icon) => {
	let tmp = icon.split(' ');
	if (tmp.length === 1) return {name: tmp[0], type: 'fas'};
	return {name: tmp[1].replace(/^fa-/, ''), type: tmp[0]};
};

const query = (params, ts = false) => {
	const esc = encodeURIComponent;
	if (ts && !params.ts) params.ts = new Date().getTime();
	const p = Object.keys(params).map(k => isEmpty(params[k]) ? '' : `${esc(k)}=${esc(params[k])}`).filter(e => e);
	return p.join('&');
};

const decode = (text) => {
	try {
		return decodeURIComponent('' + text);
	} catch (err) {
		console.error(`Error decoding "${text}".`);
	}
	return '' + text;
};

const parseQuery = (search) => {
	const query = {};
	if (search === '' || search === '?') return query;
	const hasLeadingIM = search[0] === '?';
	const searchParams = (hasLeadingIM ? search.slice(1) : search).split('&');
	for (let i = 0; i < searchParams.length; ++i) {
		let [key, rawValue] = searchParams[i].split('=');
		key = decode(key);
		let value = rawValue == null ? null : decode(rawValue);
		if (key in query) {
			let currentValue = query[key];
			if (!isArray(currentValue)) {
				currentValue = query[key] = [currentValue];
			}
			currentValue.push(value);
		} else {
			query[key] = value;
		}
	}
	return query;
};

const isIncluded = (path, paths) => {
	if (!isArray(paths)) paths = [paths];
	for (let i = 0; i < paths.length; i++) {
		let p = paths[i];
		if (p === path) return true;
		if (p !== '') {
			const re = new RegExp('^' + p);
			if (re.test(path)) return true;
		}
	}
	return false;
};

const parseBoolean = (value) => {
	if (isBoolean(value)) return value;
	if (trueValues.includes(String(value).trim().toLowerCase())) return true;
	// if (/^true$/i.test(value)) return true;
	return false;
};

const parseNumber = (value) => {
	if (isNumber(value)) return value;
	return !isNaN(value) ? parseFloat(value) : 0;
};

const validCode = (str) => {
	if (str.indexOf('-') === -1) return str;
	const c = str.split('-');
	return c[0].trim();
};

const validObj = (obj, schema) => {

	if (!obj) return obj;

	const keys = isObject(schema.fields) ? Object.keys(schema.fields) : schema.fields;
	const validate = schema.hasOwnProperty('validate') ? schema.validate : true;
	if (keys.length > 0 && validate) {
		keys.forEach(key => {
			if (isObject(schema.fields)) {
				const field = schema.fields[key];
				if (!obj.hasOwnProperty(key) || isEmpty(obj[key])) obj[key] = field.default || null;
				if (isString(obj[key])) obj[key] = obj[key].trim();
				if (obj[key] === '') obj[key] = field.default || null;
				if (field.type === 'yesno' && field.required) obj[key] = parseBoolean(obj[key]);
				if (field.type === 'number' && field.required) obj[key] = parseNumber(obj[key]);
				// && obj[key] !== null && obj[key] !== undefined) ord[key] = obj[key];
				if (field.code && obj[key]) obj[key] = validCode(obj[key]);
			} else {
				if (!obj.hasOwnProperty(key) || isEmpty(obj[key])) obj[key] = null;
			}
		});
	}
	if (!schema.sort) return obj;

	const addNull = schema.addNull || false;

	const ord = {};
	keys.forEach(key => {
		if (obj.hasOwnProperty(key)) {
			if (!isUndefined(obj[key])) {
				if (isNull(obj[key])) {
					if (addNull) ord[key] = obj[key];
				} else {
					ord[key] = obj[key];
				}
			}
		} else {
			if (addNull) ord[key] = null;
		}
	});
	const all = Object.keys(obj);
	all.forEach(key => {
		if (!ord.hasOwnProperty(key) && !isUndefined(obj[key])) ord[key] = obj[key]; // isEmpty
	});
	return ord;
};

const asObject = (array, key) => {
	return array.reduce((acc, e) => {
		if (e.hasOwnProperty(key)) acc[e[key]] = e;
		return acc;
	}, {});
};

const asArray = x => [].concat(x);

// https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value

const sortBy = (array, ...attrs) => {
	// generate an array of predicate-objects contains
	// property getter, and descending indicator
	let predicates = attrs.map(pred => {
		let descending = pred.charAt(0) === '-' ? -1 : 1;
		pred = pred.replace(/^-/, '');
		return {
			getter: o => o[pred],
			descend: descending
		};
	});
	// schwartzian transform idiom implementation. aka: "decorate-sort-undecorate"
	return array.map(item => {
		return {
			src: item,
			compareValues: predicates.map(predicate => predicate.getter(item))
		};
	}).sort((o1, o2) => {
		let i = -1, result = 0;
		while (++i < predicates.length) {
			if (o1.compareValues[i] < o2.compareValues[i]) result = -1;
			if (o1.compareValues[i] > o2.compareValues[i]) result = 1;
			if (result *= predicates[i].descend) break;
		}
		return result;
	}).map(item => item.src);
};

const paginate = (array, index, size) => {
	// transform values
	index = Math.abs(parseInt(index));
	size = parseInt(size);
	size = size < 1 ? 1 : size;
	// let pages = Math.ceil((array.length / size));
	// if (index < 1) index = 1;
	// if (index > pages) index = pages;

	// filter
	// index = index > 0 ? index - 1 : index;
	// return [...(array.filter((value, n) => {
	// 	return (n >= (index * size)) && (n < ((index + 1) * size));
	// }))];

	// slice
	return array.slice((index - 1) * size, index * size);
};

const deepSet = (o, keys, value) => {
	let key = keys[0];

	// Only one key, then it's not a deepSet, just assign the value.
	if (keys.length === 1) {
		if (key === '') {
			o.push(value); // '' is used to push values into the array (assume o is an array)
		} else {
			o[key] = value; // other keys can be used as object keys or array indexes
		}
	// With more keys is a deepSet. Apply recursively.
	} else {
		const nextKey = keys[1];

		// "" is used to push values into the array,
		// with nextKey, set the value into the same object, in object[nextKey].
		// Covers the case of ["", "foo"] and ["", "var"] to push the object {foo, var}, and the case of nested arrays.
		if (key === '') {
			const lastIdx = o.length - 1; // asume o is array
			const lastVal = o[lastIdx];
			if (isObject(lastVal) && (isUndefined(lastVal[nextKey]) || keys.length > 2)) { // if nextKey is not present in the last object element, or there are more keys to deep set
				key = lastIdx; // then set the new value in the same object element
			} else {
				key = lastIdx + 1; // otherwise, point to set the next index in the array
			}
		}

		// '' is used to push values into the array "array[]"
		if (nextKey === '') {
			if (isUndefined(o[key]) || !isArray(o[key])) {
				o[key] = []; // define (or override) as array to push values
			}
		} else {
			// if (opts.useIntKeysAsArrayIndex && isValidArrayIndex(nextKey)) { // if 1, 2, 3 ... then use an array, where nextKey is the index
			// 		if (isUndefined(o[key]) || !$.isArray(o[key])) {
			// 				o[key] = []; // define (or override) as array, to insert values using int keys as array indexes
			// 		}
			// } else { // for anything else, use an object, where nextKey is going to be the attribute name
			if (isUndefined(o[key]) || !isObject(o[key])) {
				o[key] = {}; // define (or override) as object, to set nested properties
			}
		}

		// recursively set the inner object
		const tail = keys.slice(1);
		deepSet(o[key], tail, value);
	}
};

const formatDate = (value, format, add) => {

	const locale = CURRENT_LOCALE || DEFAULT_LOCALE;

	if (format === 'Z') {
		const dt = new Date(value);

		// TODO...
		if (add) {
			dt.setSeconds(dt.getSeconds() + add);
			// dt.setHours(dt.getHours() + tz);
		}

		// getUTC ?
		let str = dt.getFullYear() +
			'-' + String(dt.getMonth() + 1).pad(2) +
			'-' + String(dt.getDate()).pad(2) +
			' ' + String(dt.getHours()).pad(2) +
			':' + String(dt.getMinutes()).pad(2) +
			':' + String(dt.getSeconds()).pad(2) +
			'.' + (dt.getMilliseconds() / 1000).toFixed(3).slice(2, 5);

		// 2020-01-20T12:42:35.000+00:00

		// const loc = dt.toLocaleString('en-US', {timeZone: 'Europe/Lisbon'});

		// const z = parseInt(dt.getTimezoneOffset() / 60);
		// str += (z < 0 ? ' -' : ' +') + String(Math.abs(z)).pad(4);
		str += ' +0000';

		// Date value must be expressed in the form
		// yyyy-MM-dd
		// or
		// yyyy-MM-dd HH:mm:ss.SSS Z

		// eg. 2012-03-01 or 2012-03-01 12:36:42.535 +0100
		// 2019-11-11T09:34:21.279Z

		return str;
	}

	if (isEmpty(value)) {
		value = new Date();
	} else if (isString(value)) {
		value = new Date(value);
	} else if (isNumber(value)) {
		value = new Date(/^\d{10}$/.test(value) ? value * 1000 : value);
	} else if (isArray(value)) {
		value = new Date(value[0]);
	}
	if (!value.getMonth) return '';
	if (!format) {
		format = locale.date.format;
	} else if (format === 'DT') {
		format = locale.date.format + ' ' + locale.date.timeFormat;
	} else if (format === 'DTS') {
		format = locale.date.format + ' ' + locale.date.dtsFormat;
	} else if (format === 'Y') {
		format = 'y';
	} else if (format === 'YM') {
		format = 'MMM y';
	}
	return _date(value, format);
};

const formatNumber = (value, format) => {
	if (value === null || value === undefined || isNaN(value)) return '';
	if (!format) {
		format = '#,##0.00#';
	} else if (format === 'INT') {
		format = '#,##0';
	}
	return _number(value, format);
};

const formatPercent = (value) => {
	return Math.round(value * 100) + '%';
};

const formatElapsed = (ms) => {
	const seconds = (ms / 1000).toFixed(1);
	const minutes = (ms / (1000 * 60)).toFixed(0);
	const hours = (ms / (1000 * 60 * 60)).toFixed(0);
	const days = (ms / (1000 * 60 * 60 * 24)).toFixed(0);
	if (ms < 1000) {
		return ms + 'ms';
	} else if (seconds < 60) {
		return seconds + 's';
	} else if (minutes < 60) {
		return minutes + 'm';
	} else if (hours < 24) {
		return hours + 'h';
	} else {
		return days + 'd';
	}
};

const formatBytes = (bytes, u, p, small = false) => {
	if (!u) u = 1024;
	if (!p) p = 0; // 2

	let units = [' bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'];
	if (small) units = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

	if (bytes === null || bytes === undefined || isNaN(bytes) || bytes === 0) return `0${units[0]}`;

	const exponent = Math.min(Math.floor(Math.log(bytes) / Math.log(u)), units.length - 1);
	// const bytesStr = Number((bytes / Math.pow(u, exponent)).toPrecision(p));
	const bytesStr = (bytes / Math.pow(u, exponent)).toFixed(p); // .toPrecision(p)
	const unit = units[exponent];
	return `${bytesStr}${unit}`;
};

const formatBoolean = (value, format) => {
	const tf = format.split(',');
	return (value === true || value === 'true') ? tf[0] : tf[1];
};

const formatCode = (value, pad) => {
	if (!value) return '';
	let v = String(value).trim();
	const reg = new RegExp('^\\d+$');
	if (reg.test(v)) {
		v = String(parseInt(v));
		if (pad !== undefined) return v.pad(pad);
		return v;
	}
	return v;
};

const formatXml = (xml) => {
	let formatted = '';
	const reg = /(>)(<)(\/*)/g;
	xml = xml.replace(reg, '$1\r\n$2$3');
	let pad = 0;
	xml.split('\r\n').forEach(node => { // index
		let indent = 0;
		if (node.match(/.+<\/\w[^>]*>$/)) {
			indent = 0;
		} else if (node.match(/^<\/\w/)) {
			if (pad !== 0) pad -= 1;
		} else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
			indent = 1;
		} else {
			indent = 0;
		}
		let padding = '';
		for (let i = 0; i < pad; i++) {
			padding += '  ';
		}
		formatted += padding + node + '\r\n';
		pad += indent;
	});
	// return formatted.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;').replace(/ /g, '&nbsp;');
	return formatted;
};

const formatExportObj = (header, e) => {
	const obj = {};
	header.forEach(c => {
		obj[c.key] = getProp(e, c.src);
		if (c.type === 'date' && obj[c.key]) obj[c.key] = formatDate(obj[c.key], 'yyyy-MM-dd');
		if (c.type === 'datetime' && obj[c.key]) obj[c.key] = formatDate(obj[c.key], 'yyyy-MM-dd HH:mm');
		if (c.type === 'boolean') {
			if (c.src[0] === '!') {
				obj[c.key] = cfw.getProp(obj, c.src.substring(1)) === false ? 'Y' : 'N';
			} else {
				// obj[c.key] = cfw.formatBoolean(obj[c.key], 'X,');
				obj[c.key] = cfw.applyType(obj[c.key], 'boolean') ? 'Y' : 'N';
			}
		}
		if (c.type === 'array' && obj[c.key] && isArray(obj[c.key])) {
			obj[c.key] = obj[c.key].map(v => {
				if (c.map && v.hasOwnProperty(c.map)) return v[c.map];
				if (v.hasOwnProperty('key')) return v.key;
				if (v.hasOwnProperty('_id')) return v._id;
				return v;
			}).join(', ');
		}
	});
	return obj;
};

const arrayBufferToBase64 = (buffer) => {
	let binary = '';
	const bytes = [].slice.call(new Uint8Array(buffer));
	bytes.forEach((b) => binary += String.fromCharCode(b));
	return btoa(binary);
};

const base64encode = (str) => {
	let encode = encodeURIComponent(str).replace(/%([a-f0-9]{2})/gi, (m, $1) => String.fromCharCode(parseInt($1, 16)));
	return btoa(encode);
};

const base64decode = (str) => {
	let decode = atob(str).replace(/[\x80-\uffff]/g, (m) => `%${m.charCodeAt(0).toString(16).padStart(2, '0')}`);
	return decodeURIComponent(decode);
};

const utf8length = (str) => {
	// Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
	const m = encodeURIComponent(str).match(/%[89ABab]/g);
	return str.length + (m ? m.length : 0);
};

const workingDay = (date = null, n = 'last') => {
	// const date = new Date();
	let offset = 0;
	let result = null;

	if (isEmpty(date)) date = new Date();
	const year = date.getFullYear();
	const month = date.getMonth();

	// dayOfWeek
	// const result = new Date (year, month + 1 , 0);
	// // find out what day of week that is and adjust
	// result.setDate(result.getDate() - (result.getDay() + 7 - dayOfWeek) % 7);
	// return result;

	if (n === 'last') {
		do {
			result = new Date(year, month + 1, offset);
			offset--;
		} while (result.getDay() === 0 || result.getDay() === 6);
	} else {
		let d = 0;
		do {
			result = new Date(year, month, offset);
			if (result.getDay() !== 0 && result.getDay() !== 6) d++;
			offset++;
		} while (d < n);
	}

	return result;
};

// TODO
const mapDisplayList = (items, e, clean = true) => {
	const mapped = items.map(item => {
		const obj = {};
		e.fields.forEach(f => {
			setProp(obj, f, getProp(item, f));
		});
		if (e.hasOwnProperty('display')) {
			const fields = e.display.match(/\{.*?\}/g).map(e => e.substr(1, e.length - 2));
			const v = {};
			fields.forEach(f => v[f] = getProp(item, f));
			obj.name = e.display.format(v);
		}
		deletePropsExcept(obj, ['_id', 'name']);
		if (e.url) {
			obj.url = e.url;
		} else {
			// TODO - arranjas uma maneira de saber a route pelo subtype
			if (item.type && item.type === 'complaint') {
				obj.url = `${e.route}/${item._id}`;
			} else {
				obj.url = `${e.route}/${item._id}`;
			}
		}
		return obj;
	});
	if (clean) cfw.deleteProps(e, ['fields', 'display', 'query', 'route', 'url']);
	return mapped;
};

const cfw = {
	setLocale,
	getLocale,
	applyType,
	round,
	roundFix,
	countDecimals,

	isString,
	isNumber,
	isArray,
	isFunction,
	isObject,
	isNull,
	isUndefined,
	isEmpty,
	isBoolean,
	isRegExp,
	isError,
	isDate,
	isSymbol,

	isEquivalent,

	pick,
	getProp,
	setProp,
	deleteProp,
	deleteProps,
	deletePropsExcept,
	removeProps,
	renameKeys,
	keyValues,
	copy,
	clone,
	deepCopy,
	deepClone,
	merge,
	cleanEmpty,
	fromEntries,
	filterEmpty,
	uniqueArray,
	includesEvery,
	isAvailable,

	slugify,
	parseIcon,
	query,
	parseQuery,
	isIncluded,
	cleanText,
	escapeXml,
	escapeString,
	escapeRegExp,
	jsonEscapeUTF,
	parseBoolean,
	parseNumber,
	validObj,
	asObject,
	asArray,
	sortBy,
	paginate,
	deepSet,
	getDate,
	getNumber,
	formatDate,
	formatNumber,
	formatPercent,
	formatElapsed,
	formatBytes,
	formatBoolean,
	formatCode,
	formatXml,
	formatExportObj,
	arrayBufferToBase64,
	base64encode,
	base64decode,
	utf8length,
	workingDay,

	mapDisplayList
};

export default cfw;
