import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, mergeProps as _mergeProps, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = ["aria-labelledby", "aria-modal"];
const _hoisted_2 = {
    key: 0,
    class: "panel-header"
};
const _hoisted_3 = ["id"];
const _hoisted_4 = { class: "panel-header-actions" };
const _hoisted_5 = {
    key: 1,
    class: "panel-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_button = _resolveComponent("v-button");
    return (_ctx.containerVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref: _ctx.maskRef,
            class: _normalizeClass(_ctx.maskClass),
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.onMaskClick && _ctx.onMaskClick(...args)))
        }, [
            _createVNode(_Transition, {
                name: "c-dialog",
                onBeforeEnter: _ctx.onBeforeEnter,
                onEnter: _ctx.onEnter,
                onBeforeLeave: _ctx.onBeforeLeave,
                onLeave: _ctx.onLeave,
                onAfterLeave: _ctx.onAfterLeave,
                appear: ""
            }, {
                default: _withCtx(() => [
                    (_ctx.visible)
                        ? (_openBlock(), _createElementBlock("div", _mergeProps({
                            key: 0,
                            ref: _ctx.containerRef,
                            class: _ctx.dialogClass
                        }, _ctx.$attrs, {
                            role: "dialog",
                            "aria-labelledby": _ctx.ariaLabelledById,
                            "aria-modal": _ctx.modal
                        }), [
                            (_ctx.header || _ctx.$slots.header || _ctx.closable || _ctx.maximizable)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                    _renderSlot(_ctx.$slots, "header", {}, () => [
                                        (_ctx.header)
                                            ? (_openBlock(), _createElementBlock("h5", {
                                                key: 0,
                                                id: _ctx.ariaLabelledById,
                                                class: "panel-title"
                                            }, _toDisplayString(_ctx.header), 9, _hoisted_3))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_4, [
                                        (_ctx.maximizable)
                                            ? (_openBlock(), _createBlock(_component_v_button, {
                                                key: 0,
                                                onClick: _ctx.maximize,
                                                icon: _ctx.maximized ? 'compress' : 'expand'
                                            }, null, 8, ["onClick", "icon"]))
                                            : _createCommentVNode("", true),
                                        (_ctx.closable)
                                            ? (_openBlock(), _createBlock(_component_v_button, {
                                                key: 1,
                                                onClick: _ctx.close,
                                                "aria-label": _ctx.ariaCloseLabel,
                                                layout: "close"
                                            }, null, 8, ["onClick", "aria-label"]))
                                            : _createCommentVNode("", true)
                                    ])
                                ]))
                                : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: "panel-content",
                                style: _normalizeStyle(_ctx.contentStyle)
                            }, [
                                _renderSlot(_ctx.$slots, "default")
                            ], 4),
                            (_ctx.footer || _ctx.$slots.footer)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                    _renderSlot(_ctx.$slots, "footer", {}, () => [
                                        _createTextVNode(_toDisplayString(_ctx.footer), 1)
                                    ])
                                ]))
                                : _createCommentVNode("", true)
                        ], 16, _hoisted_1))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            }, 8, ["onBeforeEnter", "onEnter", "onBeforeLeave", "onLeave", "onAfterLeave"])
        ], 2))
        : _createCommentVNode("", true);
}
