import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_QuillEditor = _resolveComponent("QuillEditor", true);
    return (_openBlock(), _createBlock(_component_QuillEditor, {
        style: { "height": "200px" },
        toolbar: "essential",
        contentType: "html",
        options: _ctx.options,
        content: _ctx.content,
        "onUpdate:content": [
            _cache[0] || (_cache[0] = ($event) => ((_ctx.content) = $event)),
            _ctx.onChanged
        ]
    }, null, 8, ["options", "content", "onUpdate:content"]));
}
