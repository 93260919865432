const m = {
	trace: 'debug',
	debug: 'debug',
	info:  'info',
	warn:  'warn',
	error: 'error',
	fatal: 'error',
	none:  'none'
};

// https://www.w3schools.com/colors/colors_names.asp
// ANSI colors
let Colors = {
	'Black':   0,
	'Red':     1,
	'Green':   2,
	'Yellow':  3,
	'Blue':    4,
	'Magenta': 5,
	'Cyan':    6,
	'Grey':    7,
	'White':   9,
	'Default': 9
};

// CSS colors
Colors = {
	'Black':   'Black',
	'Red':     'Red',
	'Green':   'DarkGreen', // LimeGreen
	'Yellow':  'OrangeRed',
	'Blue':    'DarkBlue',
	'Magenta': 'DarkRed',
	'Cyan':    'DodgerBlue',
	'Grey':    'Grey',
	'White':   'White',
	'Default': 'Black'
};

const mColors = {
	trace: Colors.Grey,
	debug: Colors.Cyan,
	info:  Colors.Blue,
	warn:  Colors.Yellow, // 'SaddleBrown',
	error: Colors.Red,
	fatal: Colors.Magenta,
	none:  Colors.Default
};

// https://github.com/haadcode/logplease
let defaultLogLevel = 'debug';

class Logger {
	constructor() {
		let logLevel = (typeof process !== "undefined" && process.env !== undefined && process.env.LOG !== undefined) ? process.env.LOG : null;
		logLevel = (typeof window !== "undefined" && window.LOG) ? window.LOG : logLevel;
		this.logLevel = logLevel || defaultLogLevel;
		// this.trace = this.trace.bind(this);
		// this.debug = this.debug.bind(this);
		// this.info = this.info.bind(this);
		// this.warn = this.warn.bind(this);
		// this.error = this.error.bind(this);
		// this.fatal = this.fatal.bind(this);
	}

	trace(...args) {
		if (this._log('trace')) this._write('trace', ...args);
	}

	debug(...args) {
		if (this._log('debug')) this._write('debug', ...args);
	}

	info(...args) {
		if (this._log('info')) this._write('info', ...args);
	}

	warn(...args) {
		if (this._log('warn')) this._write('warn', ...args);
	}

	error(...args) {
		if (this._log('error')) this._write('error', ...args);
	}

	fatal(...args) {
		if (this._log('fatal')) this._write('fatal', ...args);
	}

	_write(level, ...args) {
		const method = m[level];
		const {format, color} = this._format(level, ...args);
		console[method](format, color); // ...args
	}

	_format(level, ...args) {
		const color = `color: ${mColors[level]}`;
		return {format: `%c${args.join(' ')}`, color: color};
	}

	_log(level) {
		if (this.logLevel === 'none') return;
		const levels = Object.keys(m); // .map((f) => LogLevels[f]);
		const index = levels.indexOf(level);
		const levelMin = levels.indexOf(this.logLevel);
		return index >= levelMin;
	}
}

const instance = new Logger();
window.Logger = instance;
export default instance;
