import {ConfirmEventBus} from './events';
import {ConfirmSymbol} from './useConfirm';

export default {
	install: (app) => {
		const ConfirmService = {
			require: (options) => {
				ConfirmEventBus.emit('confirm', options);
			},
			close: () => {
				ConfirmEventBus.emit('close');
			}
		};
		app.provide(ConfirmSymbol, ConfirmService);
		app.config.globalProperties.$confirm = ConfirmService;
	}
};
