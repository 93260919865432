import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, TransitionGroup as _TransitionGroup } from "vue";
const _hoisted_1 = { class: "sidebar-header" };
const _hoisted_2 = ["src"];
const _hoisted_3 = {
    key: 0,
    class: "app-actions"
};
const _hoisted_4 = {
    class: "avatar mr-2",
    tabindex: "-1",
    "aria-haspopup": "true",
    role: "button"
};
const _hoisted_5 = {
    key: 0,
    class: "sidebar-nav"
};
const _hoisted_6 = ["aria-expanded", "onClick"];
const _hoisted_7 = { class: "sidebar-nav-text" };
const _hoisted_8 = ["onClick"];
const _hoisted_9 = { key: 0 };
const _hoisted_10 = ["onClick"];
const _hoisted_11 = { key: 0 };
const _hoisted_12 = {
    key: 1,
    class: "sidebar-nav"
};
const _hoisted_13 = { class: "sidebar-nav-item" };
const _hoisted_14 = ["onClick"];
const _hoisted_15 = { class: "sidebar-footer" };
const _hoisted_16 = {
    key: 0,
    class: "app-actions"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_router_link = _resolveComponent("router-link");
    const _component_user_avatar = _resolveComponent("user-avatar");
    const _component_v_icon = _resolveComponent("v-icon");
    const _component_collapse_transition = _resolveComponent("collapse-transition");
    const _component_v_button = _resolveComponent("v-button");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", {
            class: _normalizeClass(["app-sidebar", _ctx.isOpen ? 'show' : ''])
        }, [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_router_link, {
                    class: "app-logo",
                    tabindex: "-1",
                    "aria-label": "",
                    to: "/"
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("img", {
                            src: `${_ctx.APP.path}themes/${_ctx.APP.theme}/logo.png`,
                            alt: ""
                        }, null, 8, _hoisted_2)
                    ]),
                    _: 1
                }),
                (_ctx.APP.user)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("div", {
                            class: "app-user",
                            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.next('account')))
                        }, [
                            _createElementVNode("span", _hoisted_4, [
                                _createVNode(_component_user_avatar)
                            ]),
                            _createElementVNode("span", {
                                class: "text-muted",
                                role: "button",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.next()))
                            }, _toDisplayString(_ctx.APP.user.name), 1)
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ]),
            _createVNode(_TransitionGroup, {
                tag: "div",
                class: "sidebar-body",
                name: _ctx.back ? 'slideback' : 'slide'
            }, {
                default: _withCtx(() => [
                    (_ctx.currentIndex === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("ul", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item, p) => {
                                    return (_openBlock(), _createElementBlock("li", { key: p }, [
                                        (item.children)
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                class: "sidebar-nav-item",
                                                "aria-expanded": item.expanded,
                                                onClick: _withModifiers(($event) => (_ctx.expandNavigation(item)), ["prevent"])
                                            }, [
                                                _createVNode(_component_v_icon, {
                                                    icon: item.icon.name,
                                                    type: item.icon.type,
                                                    class: "sidebar-nav-icon"
                                                }, null, 8, ["icon", "type"]),
                                                _createElementVNode("span", _hoisted_7, _toDisplayString(item.text), 1)
                                            ], 8, _hoisted_6))
                                            : (_openBlock(), _createBlock(_component_router_link, {
                                                key: 1,
                                                to: item.route,
                                                class: "sidebar-nav-item",
                                                "aria-expanded": item.expanded
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_v_icon, {
                                                        icon: item.icon.name,
                                                        type: item.icon.type,
                                                        class: "sidebar-nav-icon"
                                                    }, null, 8, ["icon", "type"]),
                                                    _createElementVNode("span", {
                                                        class: "sidebar-nav-text",
                                                        onClick: ($event) => (_ctx.next(item))
                                                    }, _toDisplayString(item.text), 9, _hoisted_8)
                                                ]),
                                                _: 2
                                            }, 1032, ["to", "aria-expanded"])),
                                        _createVNode(_component_collapse_transition, null, {
                                            default: _withCtx(() => [
                                                (item.children)
                                                    ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_9, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child, c) => {
                                                            return (_openBlock(), _createElementBlock("li", {
                                                                key: c,
                                                                class: _normalizeClass(_ctx.itemClass(child))
                                                            }, [
                                                                _createVNode(_component_router_link, {
                                                                    to: child.route,
                                                                    class: "sidebar-nav-item"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createElementVNode("span", {
                                                                            class: "sidebar-nav-text",
                                                                            onClick: ($event) => (_ctx.next(child))
                                                                        }, _toDisplayString(child.text), 9, _hoisted_10)
                                                                    ]),
                                                                    _: 2
                                                                }, 1032, ["to"])
                                                            ], 2));
                                                        }), 128)),
                                                        (item.after)
                                                            ? (_openBlock(), _createElementBlock("li", _hoisted_11, _toDisplayString(item.after), 1))
                                                            : _createCommentVNode("", true)
                                                    ], 512)), [
                                                        [_vShow, item.expanded]
                                                    ])
                                                    : _createCommentVNode("", true)
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]));
                                }), 128))
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.currentIndex === 1)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("ul", null, [
                                _createElementVNode("li", null, [
                                    _createElementVNode("span", _hoisted_13, [
                                        _createElementVNode("span", {
                                            class: "sidebar-nav-text",
                                            onClick: _cache[2] || (_cache[2] =
                                                //@ts-ignore
                                                (...args) => (_ctx.prev && _ctx.prev(...args)))
                                        }, _toDisplayString(_ctx.i18n `Back`), 1)
                                    ])
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentNext, (child, c) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                        key: c,
                                        class: _normalizeClass(_ctx.itemClass(child))
                                    }, [
                                        _createVNode(_component_router_link, {
                                            to: child.route,
                                            class: "sidebar-nav-item"
                                        }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("span", {
                                                    class: "sidebar-nav-text",
                                                    onClick: ($event) => (_ctx.next(child))
                                                }, _toDisplayString(child.text), 9, _hoisted_14)
                                            ]),
                                            _: 2
                                        }, 1032, ["to"])
                                    ], 2));
                                }), 128))
                            ])
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }, 8, ["name"]),
            _createElementVNode("div", _hoisted_15, [
                (_ctx.APP.user)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createVNode(_component_v_button, {
                            layout: "secondary",
                            icon: "sign-out-alt",
                            tab: false,
                            text: _ctx.i18n `Sign out`,
                            onClick: _ctx.home
                        }, null, 8, ["text", "onClick"])
                    ]))
                    : _createCommentVNode("", true)
            ])
        ], 2),
        _createElementVNode("div", {
            class: _normalizeClass(["sidebar-backdrop", _ctx.isOpen ? 'show' : '']),
            onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.isOpen = false))
        }, null, 2)
    ], 64));
}
