import { ref } from 'vue';
import { cfw, DomHandler, OverlayScrollHandler } from '../utils';
const useOverlay = (overlayRef, containerRef, inputRef, dropdownRef, options = {}) => {
    // const overlayRef = ref();
    // let overlay = overlayRef.value.$el;
    // function overlayRef(el) {
    // 	overlay = el;
    // }
    let resizeListener = null;
    let outsideClickListener = null;
    let scrollHandler = null;
    const overlayVisible = ref(false);
    const showOverlay = () => {
        overlayVisible.value = true;
    };
    const hideOverlay = () => {
        overlayVisible.value = false;
    };
    const toggleOverlay = () => {
        overlayVisible.value = !overlayVisible.value;
    };
    const onOverlayEnter = () => {
        const overlay = overlayRef.value;
        const target = containerRef.value; // props.multiple ? this.$refs.multiContainer : this.$refs.input;
        overlay.style.zIndex = String(DomHandler.generateZIndex());
        // width
        const containerWidth = options.hasOwnProperty('containerWidth') ? options['containerWidth'] : true;
        if (containerWidth)
            overlay.style.width = target.offsetWidth + 'px';
        // create
        // if (props.appendTo) {
        //	if (props.appendTo === 'body') {
        document.body.appendChild(overlay);
        // 	} else {
        // 		document.getElementById(props.appendTo).appendChild(overlay);
        // 	}
        // }
        // align
        // if (props.appendTo) {
        const pos = DomHandler.absolutePosition(overlay, target, true);
        overlay.style.top = pos.top + 'px';
        overlay.style.left = pos.left + 'px';
        // overlay.style.borderTopLeftRadius = 0;
        // overlay.style.borderTopRightRadius = 0;
        // overlay.style.borderColor = '#343a40';
        // overlay.style.borderTopColor = 'transparent';
        // target.style.borderBottomLeftRadius = 0;
        // target.style.borderBottomRightRadius = 0;
        // } else {
        // DomHandler.relativePosition(overlay, target);
        // }
        _listening();
    };
    const onOverlayLeave = () => {
        // const target = containerRef.value;
        // target.style.borderBottomWidth = '1px';
        // target.style.borderBottomLeftRadius = '0.15rem';
        // target.style.borderBottomRightRadius = '0.15rem';
        _listening(false);
        // overlay = null;
    };
    const _listening = (action = true) => {
        const target = containerRef.value;
        const overlay = overlayRef.value;
        if (action === true) {
            if (!resizeListener) {
                resizeListener = () => {
                    if (overlayVisible.value)
                        hideOverlay();
                };
                window.addEventListener('resize', resizeListener);
            }
            if (!outsideClickListener) {
                outsideClickListener = (e) => {
                    if (overlayVisible.value && overlay && _isOutsideClicked(e)) {
                        hideOverlay();
                    }
                };
                document.addEventListener('click', outsideClickListener);
            }
            if (!scrollHandler) {
                scrollHandler = new OverlayScrollHandler(target, () => {
                    if (overlayVisible.value)
                        hideOverlay();
                });
                scrollHandler.bindScrollListener();
            }
        }
        else {
            if (resizeListener) {
                window.removeEventListener('resize', resizeListener);
                resizeListener = null;
            }
            if (outsideClickListener) {
                document.removeEventListener('click', outsideClickListener);
                outsideClickListener = null;
            }
            if (scrollHandler) {
                scrollHandler.unbindScrollListener();
                scrollHandler.destroy();
                scrollHandler = null;
            }
        }
    };
    const _isOutsideClicked = (e) => {
        const containerClicked = containerRef.value && cfw.isFunction(containerRef.value.contains) && containerRef.value.contains(e.target);
        const overlayClicked = overlayRef.value && cfw.isFunction(overlayRef.value.contains) && overlayRef.value.contains(e.target);
        return !containerClicked && !overlayClicked && !_isInputClicked(e) && !_isDropdownClicked(e);
    };
    const _isInputClicked = (e) => {
        return e.target === inputRef.value;
    };
    /* function _isInputClicked(e) {
        if (props.multiple) {
            return e.target === inputRef.value; /// this.$refs.multiContainer || this.$refs.multiContainer.contains(event.target);
        } else {
            return e.target === inputRef.value;
        }
    } */
    const _isDropdownClicked = (e) => {
        return dropdownRef.value && cfw.isFunction(dropdownRef.value.contains) && dropdownRef.value.contains(e.target);
    };
    const removeOverlay = () => {
        /* if (overlay && props.appendTo) {
            if (props.appendTo === 'body') {
                document.body.removeChild(overlay);
            } else {
                document.getElementById(props.appendTo).removeChild(overlay);
            }
        } */
        const overlay = overlayRef.value;
        if (overlay)
            document.body.removeChild(overlay);
        _listening(false);
        // overlay = null;
    };
    return {
        // overlayRef,
        overlayVisible,
        showOverlay,
        hideOverlay,
        toggleOverlay,
        onOverlayEnter,
        onOverlayLeave,
        removeOverlay
    };
};
export { useOverlay };
