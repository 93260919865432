import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_icon = _resolveComponent("v-icon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.containerClass)
    }, [
        (_ctx.cancel)
            ? (_openBlock(), _createBlock(_component_v_icon, {
                key: 0,
                class: "rating-icon ban",
                icon: "ban",
                tabindex: _ctx.focusIndex,
                onClick: _ctx.onCancelClick,
                onKeydown: _withKeys(_withModifiers(_ctx.onCancelClick, ["prevent"]), ["enter"])
            }, null, 8, ["tabindex", "onClick", "onKeydown"]))
            : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max, (i) => {
            return (_openBlock(), _createBlock(_component_v_icon, {
                key: i,
                tabindex: _ctx.focusIndex,
                onClick: ($event) => (_ctx.onStarClick($event, i)),
                onKeydown: _withKeys(_withModifiers(($event) => (_ctx.onStarClick($event, i)), ["prevent"]), ["enter"]),
                icon: "star",
                type: i > _ctx.modelValue ? 'far' : 'fas',
                class: "rating-icon"
            }, null, 8, ["tabindex", "onClick", "onKeydown", "type"]));
        }), 128))
    ], 2));
}
