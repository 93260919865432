import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue";
const _hoisted_1 = ["aria-modal"];
const _hoisted_2 = {
    key: 0,
    class: "panel-header"
};
const _hoisted_3 = {
    key: 0,
    class: "panel-title"
};
const _hoisted_4 = { class: "panel-header-actions" };
const _hoisted_5 = { class: "panel-content" };
const _hoisted_6 = {
    key: 1,
    class: "panel-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_button = _resolveComponent("v-button");
    return (_openBlock(), _createBlock(_Transition, {
        name: "c-sidebar",
        onEnter: _ctx.onEnter,
        onBeforeLeave: _ctx.onBeforeLeave,
        onLeave: _ctx.onLeave,
        onAfterLeave: _ctx.onAfterLeave,
        appear: ""
    }, {
        default: _withCtx(() => [
            (_ctx.visible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.containerClass),
                    ref: "container",
                    role: "complementary",
                    "aria-modal": _ctx.modal
                }, [
                    (_ctx.header || _ctx.$slots.header || _ctx.closable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "header", {}, () => [
                                (_ctx.header)
                                    ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.header), 1))
                                    : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", _hoisted_4, [
                                (_ctx.closable)
                                    ? (_openBlock(), _createBlock(_component_v_button, {
                                        key: 0,
                                        onClick: _ctx.close,
                                        "aria-label": _ctx.ariaCloseLabel,
                                        layout: "close"
                                    }, null, 8, ["onClick", "aria-label"]))
                                    : _createCommentVNode("", true)
                            ])
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_5, [
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                    (_ctx.footer || _ctx.$slots.footer)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _renderSlot(_ctx.$slots, "footer", {}, () => [
                                _createTextVNode(_toDisplayString(_ctx.footer), 1)
                            ])
                        ]))
                        : _createCommentVNode("", true)
                ], 10, _hoisted_1))
                : _createCommentVNode("", true)
        ]),
        _: 3
    }, 8, ["onEnter", "onBeforeLeave", "onLeave", "onAfterLeave"]));
}
