const operators = ['=', '+', '-', '*', '/', '>', '<', '>=', '<=', '==', '!='];

const isOp = (v) => {
	for (let i = 0; i < operators.length; i++) {
		if (operators[i] === v) return true;
	}
	return false;
};

const isNum = (v) => {
	return !isNaN(parseFloat(v)) && isFinite(v);
};

const isAlpha = (v) => {
	const regExp = new RegExp(/^[a-z0-9]+$/i);
	return regExp.test(v);
};

export {
	isOp,
	isNum,
	isAlpha
};
