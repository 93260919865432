<template>
	<v-card class="card-full" v-if="availableCharts">
		<template #title>
			<div class="card-titlebar">
				<v-dropdown v-if="editable" :itemList="chartDropdownList" :defaultItemId="selectedChart" @selected="(item) => onChartSelected(item)"/>
				<label v-else>{{availableCharts[selectedChart].title}}</label>
				<v-dropdown v-if="editable" class="ml-auto" :itemList="periodDropdownList" :defaultItemId="selectedPeriod" @selected="(item) => onPeriodSelected(item)"/>
				<label v-else class="ml-auto mr-2">{{availableCharts[selectedChart].period.find(p => p._id === selectedPeriod).text}}</label>
				<v-button icon="download" @click="exportChart"/>
			</div>
		</template>
		<template #content>
			<v-block :blocked="loading" :spinner="true" :fullscreen="false" style="min-height:150px;">
				<v-chart v-if="selectedChart && selectedPeriod && chartData"
					ref="chartRef"
					:type="chart.type"
					:data="chartData"
					:options="chart.options"
					:plugins="plugins"
				/>
			</v-block>
		</template>
	</v-card>
</template>

<script lang="js">
import {defineComponent, ref, watch, computed, onMounted} from 'vue';
// import {topojson} from 'chartjs-chart-geo';
import {APP} from '../app'; // loader
import {API, cfw} from '../utils';

import ChartDataLabels from 'chartjs-plugin-datalabels';

export default defineComponent({
	props: {
		charts: Object,
		defaultChart: String,
		defaultPeriod: String,
		editable: Boolean
	},
	emits: ['selected'],
	setup(props, {emit}) {
		// const msg = useMessages();

		const availableCharts = ref(null);
		const availableChartsList = ref([]);
		const selectedChart = ref(null);
		const selectedPeriod = ref(null);

		const chart = ref(null);
		const chartData = ref(null);
		const chartRef = ref(null);
		const plugins = ref([]);

		const loading = ref(false);

		const chartDropdownList = computed(() => {
			let list = availableChartsList.value.map((key) => {
				return {id: key, value: availableCharts.value[key].title, enabled: availableCharts.value[key].enabled};
			});
			return list.filter(e => e.enabled);
		});

		const periodDropdownList = computed(() => {
			return availableCharts.value[selectedChart.value].period.map((period) => {
				return {id: period._id, value: period.text};
			});
		});

		onMounted(() => {
			getAvailableCharts();
		});

		watch([selectedChart, selectedPeriod], () => {
			getChartData();
		});

		const getAvailableCharts = () => {
			if (!APP.user) return;
			const result = props.charts;
			let keys = Object.keys(result);
			availableCharts.value = cfw.clone(result);
			availableChartsList.value = keys;
			selectedChart.value = props.defaultChart && keys.includes(props.defaultChart) ? props.defaultChart : keys[0];
			if (props.defaultPeriod && result[selectedChart.value].period.map(period => period._id).includes(props.defaultPeriod)) {
				selectedPeriod.value = props.defaultPeriod;
			} else {
				selectedPeriod.value = result[selectedChart.value].period[0]._id;
			}
		};

		/**
		 * https://codesandbox.io/s/chartjs-gauge-example-forked-smqglk
		 * https://www.youtube.com/watch?v=ARXBu4OOseg
		 * https://chartjs-plugin-datalabels.netlify.app/
		 * https://www.chartjs.org/docs/latest/axes/cartesian/
		 * https://chartjs-plugin-datalabels.netlify.app/guide/formatting.html#custom-labels
		 * https://www.chartjs.org/docs/latest/axes/labelling.html
		 */

		const getChart = () => {
			if (!availableCharts.value || !selectedChart.value || !chartData.value) return;

			const c = cfw.clone(availableCharts.value[selectedChart.value]);

			let min = 0, max = 0;
			chartData.value.datasets.forEach(ds => {
				let m = Math.max(...ds.data);
				if (m > max) max = m;
				m = Math.min(...ds.data);
				if (m < min) min = m;
			});
			let step = Math.floor(max / 10);

			// selectedChart.value === 'purchasing-count'
			plugins.value = [];
			if (c.type === 'bar') plugins.value.push(ChartDataLabels);

			if (c.type === 'bar') { // selectedChart.value === 'purchasing-count'

				if (c.options.indexAxis === 'y') {
					c.options.scales.x.suggestedMax = max + step;
					c.options.scales.x.display = false;
				} else {
					c.options.scales.y.suggestedMax = max + step;
				}

				// c.options.scales.y.weight = 2000;
				// c.options.scales.y.suggestedMin = min;
				// "min": 0,
				// "max": 2000

				c.options.plugins = c.options.plugins || {};
				c.options.plugins.datalabels = {
					"display": "auto",
					"anchor": "end",
					// "clamp": true,
					"align": "end",
					"clip": true,
					"color": "#6c757d",
					"font": {
						"size": 14
					// 	// "weight": "bold"
					},
					formatter: (value, context) => {
						// const v = context.chart.data.labels[context.dataIndex];
						return cfw.formatNumber(value, '#,##0');
					}
				};
			}

			chart.value = c;
		};

		const getChartData = () => {
			if (!availableCharts.value || !selectedChart.value) return;
			// default simple modern master purple ogb1 ogb2 retro warm complementary test
			// categorical.retro categorical.dutch categorical.river categorical.spring
			// sequential.blue2yellow sequential.gray2red
			const theme = 'categorical.retro';
			const url = `${availableCharts.value[selectedChart.value].url}/${selectedPeriod.value}?theme=${theme}`;
			loading.value = true;
			API.get(url).then(result => {
				if (availableCharts.value[selectedChart.value].type === 'choropleth') {
					getMapData(result);
				} else {
					chartData.value = cfw.clone(result);
				}
				getChart();
				loading.value = false;
			});
		};

		const getMapData = () => { // result
			chartData.value = {};
			// fetch(result.topoJsonUrl).then((r) => r.json()).then((us) => {
			// 	const nation = topojson.feature(us, us.objects.nation).features[0];
			// 	const states = topojson.feature(us, us.objects.states).features;
			// 	chartData.value = {
			// 		labels: states.map((d) => d.properties.name),
			// 		datasets: [{
			// 			label: 'States',
			// 			outline: nation,
			// 			data: states.map((d) => ({feature: d, value: result.datasets[0].data[d.id]}))
			// 		}]
			// 	};
			// });
		};

		const exportChart = () => {
			let a = document.createElement('a');
			a.href = chartRef.value.getBase64Image();
			a.download = 'image-export.png';
			a.click();
			a = null;
		};

		const onChartSelected = (item) => {
			selectedChart.value = item.id;
			emit('selected', {chart: selectedChart.value, period: selectedPeriod.value});
		};

		const onPeriodSelected = (item) => {
			selectedPeriod.value = item.id;
			emit('selected', {chart: selectedChart.value, period: selectedPeriod.value});
		};

		return {
			availableCharts,
			availableChartsList,
			selectedChart,
			selectedPeriod,
			chart,
			chartData,
			chartRef,
			exportChart,
			chartDropdownList,
			periodDropdownList,
			onChartSelected,
			onPeriodSelected,
			loading,
			plugins
		};
	}
});
</script>

<style lang="scss">
</style>
