class APIError extends Error {
	// base constructor only accepts string message as an argument
	// we extend it here to accept object, allowing us to pass other data
	constructor({message, data, status}) {
		super(message);
		this.name = 'APIError';
		this.title = message; // 'Error';
		this.data = data;
		this.status = status;
		if (data.errors) {
			let errors = [];
			data.errors.forEach(error => {
				errors.push(error.message);
			});
			if (data.key && data._status) {
				this.key = data.key;
				this._status = data._status;
			}
			this.message = `${errors.join(', ')}.`;
		}
	}
}

class ErrorService {
	constructor() {
		// this.initHandler();
	}

	static onError(error) {
		const response = error.response;
		if (response && response.status >= 400 && response.status < 405) {
			// You can handle this differently
			ErrorService.sentryLogEngine(error);
			return false;
		}
		// Send Error to Log Engine e.g LogRocket
		ErrorService.logRocketLogEngine(error);
	}

	static onWarn(error) {
		// Send Error to Log Engine e.g LogRocket
		this.logRocketLogEngine(error);
	}

	static onInfo(error) {
		// You can handle this differently
		this.sentryLogEngine(error);
	}

	static onDebug(error) {
		const response = error.response;
		if (response && response.status >= 400 && response.status < 405) {
			// You can handle this differently
			this.sentryLogEngine(error);
			return false;
		}
		// Send Error to Log Engine e.g LogRocket
		this.logRocketLogEngine(error);
	}

	static initHandler() {
		const scope = this;
		window.onerror = (message, url, lineNo, columnNo, error) => {
			console.log(error, "test");
			if (error) {
				scope.onError(error);
				console.log(message, url, lineNo, columnNo, error);
			}
		};
	}

	static logRocketLogEngine(error) {
		// Implement LogRocket Engine here
		console.log(error, "LogRocket");
	}

	static sentryLogEngine(error) {
		// Implement Sentry Engine here
		console.log(error, "Sentry");
	}
}

export {
	APIError,
	ErrorService
};
