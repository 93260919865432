<template>
	<div ref="containerRef">
		<div ref="inputRef" class="dropdown-handler" :class="layout">
			<span @click.stop="dropdownClick">{{selectedLabel}}</span>
			<v-button ref="dropdownRef" layout="icon" icon="caret-down" @click="dropdownClick" :tab="false" :disabled="disabled"/>
		</div>
		<transition name="c-connected-overlay" @enter="onOverlayEnter" @leave="onOverlayLeave">
			<div ref="overlayRef" v-if="overlayVisible" class="input-suggestion-overlay" :class="layout">
				<ul v-if="!multiple" class="list-group">
					<li v-for="item in items" :key="item.id" class="dropdown-item multiple" @click="itemClick(item)">
						{{item.value}}
					</li>
				</ul>
				<ul v-else class="list-group">
					<li v-for="item in items" :key="item.id" class="dropdown-item multiple" @click="itemSelect(item)">
						<input-checkbox class="checkbox-class dropdown-checkbox" v-model="item._selected"/>
						{{item.value}}
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed, onBeforeUnmount} from 'vue';
import {useOverlay} from '../use/overlay';
export default defineComponent({
	name: 'v-dropdown',
	emits: ['selected'],
	props: {
		itemList: Array,
		defaultItemId: String,
		multiple: Boolean,
		title: String,
		disabled: Boolean,
		layout: String
	},
	setup(props, {emit}) {

		const overlayRef = ref();
		const containerRef = ref();
		const inputRef = ref();
		const dropdownRef = ref();

		const items = ref(props.itemList);
		const selectedItem = ref(props.defaultItemId ? props.itemList.find(item => item.id === props.defaultItemId) : props.itemList[0]);

		const selectedLabel = computed(() => {
			if (!props.multiple) return selectedItem.value ? selectedItem.value.value : '';

			const selectedItems = items.value.filter(i => i._selected);
			if (selectedItems.length === 1)	return selectedItems[0].value;

			return props.title;
		});

		onBeforeUnmount(() => {
			removeOverlay();
		});

		const dropdownClick = () => {
			if (props.disabled) return;
			// if (!overlayVisible.value) {
			showOverlay();
			// } else {
			// 	hideOverlay();
			// }
		};

		const itemClick = (item) => {
			selectedItem.value = item;
			emit('selected', selectedItem.value);
			hideOverlay();
		};

		const itemSelect = (item) => {
			item._selected = !item._selected;
			const selectedItems = items.value.filter(i => i._selected);
			emit('selected', selectedItems);
		};

		const options = {containerWidth: false};
		const {
			overlayVisible,
			showOverlay,
			hideOverlay,
			onOverlayEnter,
			onOverlayLeave,
			removeOverlay
		} = useOverlay(overlayRef, containerRef, inputRef, dropdownRef, options);

		return {
			overlayRef,
			containerRef,
			inputRef,
			dropdownRef,
			overlayVisible,
			onOverlayEnter,
			onOverlayLeave,
			dropdownClick,
			itemClick,
			items,
			selectedItem,
			selectedLabel,
			itemSelect
		};
	}
});
</script>

<style lang="scss">
	.dropdown-handler.search {
		padding-left: calc(0.75rem + 1px);
		max-width:150px
	}
	.input-suggestion-overlay.search {
		max-height: calc((1.5em + 1rem) * 10);
	}
</style>
