import {isNum, isOp} from './utils';

class Token {
	constructor() {
		// this.inst = null;
		this.tokens = [];
	}

	// static getInst() {
	// 	if (!this.inst) this.inst = new Token();
	// 	return this.inst;
	// }

	tokenize(str) {
		str = str.trim();
		let s = '';

		for (let index = 0; index < str.length; index++) {
			s += str[index];
			const peek = str[index + 1];

			if (isNum(s.trim()) && !isNum(peek) && peek !== '.') {
				this.tokens.push({type: 'NUM', value: s.trim()});
				s = '';
			}

			if (s.trim() === '(' || s.trim() === ')') {
				if (s.trim() === '(') {
					this.tokens.push({type: 'LPAREN'});
				} else {
					this.tokens.push({type: 'RPAREN'});
				}
				s = '';
			}

			if (isOp(s.trim()) && !isOp(peek)) {
				this.tokens.push({type: 'OP', value: s.trim()});
				s = '';
			}

			if (s === ';' || s === '\n') {
				this.tokens.push({type: 'EOL'});
				s = '';
			}

			if (index === (str.length - 1)) {
				this.tokens.push({type: 'EOF'});
				s = '';
			}
		}

		let hasOp = false;
		for (let i = 0; i < this.tokens.length; i++) {
			const t = this.tokens[i];
			if (t.type === 'OP' && t.value === '-') {
				if (hasOp || i === 0) {
					const nt = this.tokens[i + 1];
					if (nt && nt.type === 'NUM') {
						nt.value = `-${nt.value}`;
						t.remove = true;
					}
				} else if (i > 0 && i < this.tokens.length - 1) {
					const previousT = this.tokens[i - 1];
					const nextT = this.tokens[i + 1];
					if (previousT && previousT.type !== 'NUM' && nextT && nextT.type === 'NUM') {
						nextT.value = `-${nextT.value}`;
						t.remove = true;
					}
				}
			} else if (t.type === 'OP' && t.value !== '-') {
				hasOp = true;
			} else {
				hasOp = false;
			}
		}
		this.tokens = this.tokens.filter(e => !e.remove);

		return this.tokens;
	}
}

export default Token;
