<template>
	<transition name="c-message" appear>
		<div :class="containerClass" v-if="visible" role="alert">
			<div class="c-message-wrapper">
				<span :class="iconClass"></span>
				<div class="c-message-text">
					<slot></slot>
				</div>
				<v-button layout="close" class="c-message-close" @click="visible = false" v-if="closable"/>
			</div>
		</div>
	</transition>
</template>

<script lang="js">
import {defineComponent, ref, computed, onMounted} from 'vue';

export default defineComponent({
	name: 'Message',
	props: {
		severity: {
			type: String,
			default: 'info'
		},
		closable: {
			type: Boolean,
			default: true
		},
		sticky: {
			type: Boolean,
			default: true
		},
		life: {
			type: Number,
			default: 3000
		}
	},
	setup(props) {
		const visible = ref(true);

		const containerClass = computed(() => {
			return 'c-message alert alert-' + props.severity;
		});

		const iconClass = computed(() => {
			return ['c-message-icon', {
				'info': props.severity === 'info',
				'check': props.severity === 'success',
				'exclamation': props.severity === 'warn',
				'times': props.severity === 'error'
			}];
		});

		onMounted(() => {
			if (!props.sticky) {
				setTimeout(() => {
					visible.value = false;
				}, props.life);
			}
		});

		return {
			containerClass,
			iconClass,
			visible
		};
	}
});
</script>

<style lang="scss">
.c-message-wrapper {
	display: flex;
	align-items: center;
}

.c-message-close {
	margin-left: auto;
}

.c-message-enter-from {
	opacity: 0;
}

.c-message-enter-active {
	transition: opacity .3s;
}

.c-message.c-message-leave-from {
	max-height: 1000px;
}

.c-message.c-message-leave-to {
	max-height: 0;
	opacity: 0;
	margin: 0 !important;
}

.c-message-leave-active {
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(0, 1, 0, 1), opacity 0.3s, margin 0.15s;
}

.c-message-leave-active .c-message-close {
	display: none;
}
</style>
