import { watch, ref } from 'vue';
export default function useSpeechRecognition(lang) {
    const SpeechRecognition = window.SpeechRecognition;
    const SpeechGrammarList = window.SpeechGrammarList;
    // const SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
    if (!SpeechRecognition)
        return false;
    const recognition = new SpeechRecognition();
    if (SpeechGrammarList) {
        const colors = ['aqua', 'azure', 'beige', 'bisque', 'black', 'blue', 'brown', 'chocolate', 'coral', 'crimson', 'cyan', 'fuchsia', 'ghostwhite', 'gold', 'goldenrod', 'gray', 'green', 'indigo', 'ivory', 'khaki', 'lavender', 'lime', 'linen', 'magenta', 'maroon', 'moccasin', 'navy', 'olive', 'orange', 'orchid', 'peru', 'pink', 'plum', 'purple', 'red', 'salmon', 'sienna', 'silver', 'snow', 'tan', 'teal', 'thistle', 'tomato', 'turquoise', 'violet', 'white', 'yellow'];
        const grammar = '#JSGF V1.0; grammar colors; public <color> = ' + colors.join(' | ') + ' ;';
        const speechRecognitionList = new SpeechGrammarList();
        speechRecognitionList.addFromString(grammar, 1);
        recognition.grammars = speechRecognitionList;
    }
    recognition.continuous = false; // true;
    recognition.lang = lang;
    recognition.interimResults = false; // true;
    recognition.maxAlternatives = 1;
    const isListening = ref(false);
    const note = ref('');
    const error = ref(null);
    const toggleListening = () => {
        isListening.value = !isListening.value;
    };
    const handleListen = () => {
        if (isListening.value) {
            recognition.start();
        }
        else {
            recognition.stop();
        }
    };
    watch(isListening, () => {
        handleListen();
    });
    recognition.onresult = e => {
        const transcript = Array.from(e.results)
            .map(result => result[0])
            .map(result => result.transcript)
            .join('');
        note.value = transcript;
    };
    recognition.onaudiostart = () => {
        console.log('Audio capturing started');
    };
    recognition.onspeechend = () => {
        recognition.stop();
    };
    recognition.onnomatch = e => {
        console.log('nomatch', e);
        // diagnostic.textContent = "I didn't recognise that color.";
    };
    recognition.onerror = e => {
        console.log('error', e);
        error.value = e.error;
    };
    return {
        toggleListening,
        note,
        error,
        isListening
    };
}
