import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeStyle as _normalizeStyle, renderList as _renderList } from "vue";
const _hoisted_1 = ["aria-expanded"];
const _hoisted_2 = {
    key: 0,
    class: "p-checkbox"
};
const _hoisted_3 = ["aria-checked"];
const _hoisted_4 = { class: "p-treenode-label" };
const _hoisted_5 = {
    key: 0,
    class: "p-treenode-children",
    role: "group"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_icon = _resolveComponent("v-icon");
    const _component_v_treenode = _resolveComponent("v-treenode");
    return (_openBlock(), _createElementBlock("li", {
        class: _normalizeClass(_ctx.containerClass)
    }, [
        _createElementVNode("div", {
            class: _normalizeClass(_ctx.contentClass),
            tabindex: "0",
            role: "treeitem",
            "aria-expanded": _ctx.expanded,
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.onClick && _ctx.onClick(...args))),
            onKeydown: _cache[2] || (_cache[2] =
                //@ts-ignore
                (...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args))),
            onTouchend: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => (_ctx.onTouchEnd && _ctx.onTouchEnd(...args))),
            style: _normalizeStyle(_ctx.node.style)
        }, [
            _createElementVNode("span", {
                class: "p-tree-toggler",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.toggle && _ctx.toggle(...args)))
            }, [
                _createVNode(_component_v_icon, { icon: _ctx.toggleIcon }, null, 8, ["icon"])
            ]),
            (_ctx.checkboxMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.checkboxClass),
                        role: "checkbox",
                        "aria-checked": _ctx.checked
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.checkboxIcon)
                        }, null, 2)
                    ], 10, _hoisted_3)
                ]))
                : _createCommentVNode("", true),
            _createElementVNode("span", {
                class: _normalizeClass(_ctx.icon)
            }, null, 2),
            _createElementVNode("span", _hoisted_4, [
                (_ctx.templates[_ctx.node.type] || _ctx.templates['default'])
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.templates[_ctx.node.type] || _ctx.templates['default']), {
                        key: 0,
                        node: _ctx.node
                    }, null, 8, ["node"]))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(_ctx.node.label), 1)
                    ], 64))
            ])
        ], 46, _hoisted_1),
        (_ctx.hasChildren && _ctx.expanded)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.node.children, (childNode) => {
                    return (_openBlock(), _createBlock(_component_v_treenode, {
                        key: childNode.key,
                        node: childNode,
                        templates: _ctx.templates,
                        expandedKeys: _ctx.expandedKeys,
                        onNodeToggle: _ctx.onChildNodeToggle,
                        onNodeClick: _ctx.onChildNodeClick,
                        selectionMode: _ctx.selectionMode,
                        selectionKeys: _ctx.selectionKeys,
                        onCheckboxChange: _ctx.propagateUp
                    }, null, 8, ["node", "templates", "expandedKeys", "onNodeToggle", "onNodeClick", "selectionMode", "selectionKeys", "onCheckboxChange"]));
                }), 128))
            ]))
            : _createCommentVNode("", true)
    ], 2));
}
