import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["src"];
const _hoisted_2 = ["src"];
const _hoisted_3 = { key: 2 };
const _hoisted_4 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_button = _resolveComponent("v-button");
    return (/image/.test(_ctx.contentType) && !_ctx.download)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _ctx.base64,
            class: "img-responsive"
        }, null, 8, _hoisted_1))
        : (/image/.test(_ctx.contentType) && _ctx.download)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "btn-download",
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.downloadFile && _ctx.downloadFile(...args)))
            }, [
                _createElementVNode("img", { src: _ctx.base64 }, null, 8, _hoisted_2),
                _createElementVNode("span", null, _toDisplayString(_ctx.file.filename), 1)
            ]))
            : (_ctx.type === 'file' && !_ctx.download)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.file.name)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.file.name), 1))
                        : _createCommentVNode("", true)
                ]))
                : (_openBlock(), _createBlock(_component_v_button, {
                    key: 3,
                    layout: "link",
                    icon: _ctx.icon,
                    onClick: _ctx.downloadFile,
                    text: _ctx.filename
                }, null, 8, ["icon", "onClick", "text"]));
}
