import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "app-page" };
const _hoisted_2 = { class: "app-nav" };
const _hoisted_3 = { class: "app-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_header = _resolveComponent("app-header");
    const _component_app_sidebar = _resolveComponent("app-sidebar");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_header),
            _createVNode(_component_app_sidebar)
        ]),
        _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
        ])
    ]));
}
