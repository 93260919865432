<template>
	<span :class="style" :title="tooltip">
		<v-icon :icon="iconProp('icon')"/>
	</span>
	<span v-if="statusMessage" v-html="statusMessage" class="icon-message"/>
</template>

<script lang="js">
import {defineComponent, computed} from 'vue';
import {cfw} from '../utils';
import {isSupplier} from '../app';

export default defineComponent({
	name: 'v-icon-status',
	props: {
		status: [Object, String],
		text: String
	},
	setup(props) {
		const getIconStatus = (status) => {
			const format = {
				'new': {text: 'text-gray-500', icon: 'plus-circle', tooltip: 'New'},
				'wait': {text: 'text-warning', icon: 'question-circle', tooltip: 'Wait'},
				'done': {text: 'text-success', icon: 'check-circle', tooltip: 'Done'},
				'fail': {text: 'text-danger', icon: 'exclamation-circle', tooltip: 'Fail'},
				'block': {text: 'text-warn5', icon: 'times-circle', tooltip: 'Block'},
				'receive': {text: 'text-success', icon: 'check-circle', tooltip: 'Received'},
				'receive-supplier': {text: 'text-success', icon: 'check-circle', tooltip: 'Dispatched'},
				'reverse': {text: 'text-secondary', icon: 'minus-circle', tooltip: 'Reversed'},
				'reverse-wait': {text: 'text-warning', icon: 'minus-circle', tooltip: 'Reverse wait'},
				'reverse-fail': {text: 'text-danger', icon: 'minus-circle', tooltip: 'Reverse fail'},
				'escalated': {text: 'text-danger', icon: 'arrow-circle-up', tooltip: 'Escalated to the GPC'},
				'action-new': {text: 'text-gray-500', icon: 'circle', tooltip: 'New'},
				'action-approved': {text: 'text-success', icon: 'check-circle', tooltip: 'Approved'},
				'action-rejected': {text: 'text-danger', icon: 'exclamation-circle', tooltip: 'Rejected'},
				'info': {text: 'text-info', icon: 'info-circle', tooltip: 'Info'},
				'deleted': {text: 'text-gray-300', icon: 'trash', tooltip: 'Deleted'},
				'lock': {text: 'text-secondary', icon: 'lock', tooltip: 'Locked'},
				'draft': {text: 'text-danger', icon: 'circle', tooltip: 'New line'},
				'ship': {text: 'text-warning', icon: 'truck', tooltip: 'Shipped by supplier'},
				'missing-info': {text: 'text-warning', icon: 'circle', tooltip: 'Required information missing'},
				'unread': {text: 'text-gray-500', icon: 'envelope', tooltip: 'Not opened by supplier'},
				'import': {text: 'text-gray-500', icon: 'file-import', tooltip: 'Imported from ERP'},
				'upload': {text: 'text-gray-500', icon: 'file-upload', tooltip: 'Uploaded'},
				'download': {text: 'text-gray-500', icon: 'file-download', tooltip: 'Downloaded'},
				'value-increase': {text: 'text-success', icon: 'arrow-circle-up', tooltip: 'Value Increased'},
				'accounting-info-filled': {text: 'text-success', icon: 'circle', tooltip: 'Accounting Information Filled'},
				'rfx-contract-expired': {text: 'text-danger', icon: 'circle', tooltip: 'Contract Expired/Consumed'},
				'none': {text: '', icon: '', tooltip: ''}
			};
			if (status === "receive" && isSupplier()) return format['receive-supplier'];
			return format[status] || format['none'];
		};

		const iconProp = (prop) => {
			let s = props.status;
			if (cfw.isObject(props.status)) s = s.status;
			const icon = getIconStatus(s);
			return icon[prop];
		};

		const tooltip = computed(() => {
			let t = props.text || iconProp('tooltip');
			if (cfw.isObject(props.status)) {
				t = props.status.message;
				if (props.status.error) {
					t += ` ${props.status.error}`;
				} else if (props.status.key) {
					t += ` ${props.status.key}`;
				}
			}
			return t;
		});

		const statusMessage = computed(() => {
			let t = '';
			if (cfw.isObject(props.status)) {
				t = props.status.step;
			}
			return t;
		});

		const style = computed(() => {
			let t = iconProp('text');
			if (cfw.isObject(props.status) && props.status.text) t = props.status.text;
			return t;
		});

		return {
			iconProp,
			tooltip,
			statusMessage,
			style
		};
	}
});
</script>

<style lang="scss">
.icon-message {
	color: $gray-600;
	font-size: 0.75rem;
	padding-left: $padding-sm;
}
</style>
