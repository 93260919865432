import Visitor from './visitor';

class Evaluator {
	constructor(asts) {
		this.asts = asts;
		this.visitor = new Visitor();
	}

	evaluate() {
		return this.visitor.visitExpressions(this.asts);
	}
}

export default Evaluator;
