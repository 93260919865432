import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "c-toast-message-content" };
const _hoisted_2 = { class: "c-toast-message-text" };
const _hoisted_3 = { class: "c-toast-summary" };
const _hoisted_4 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_icon = _resolveComponent("v-icon");
    const _component_v_button = _resolveComponent("v-button");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.containerClass),
        role: "alert",
        "aria-live": "assertive",
        "aria-atomic": "true"
    }, [
        _createElementVNode("div", _hoisted_1, [
            (_ctx.message.icon)
                ? (_openBlock(), _createBlock(_component_v_icon, {
                    key: 0,
                    class: "c-toast-message-icon",
                    icon: _ctx.message.icon
                }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.message.summary), 1),
                _createElementVNode("div", {
                    class: "c-toast-detail",
                    innerHTML: _ctx.message.detail
                }, null, 8, _hoisted_4)
            ]),
            (_ctx.message.closable !== false)
                ? (_openBlock(), _createBlock(_component_v_button, {
                    key: 1,
                    layout: "close",
                    onClick: _ctx.onCloseClick
                }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
        ])
    ], 2));
}
