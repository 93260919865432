<template>
	<div :class="containerClass" ref="containerRef">
		<div class="input">
			<!-- <v-button v-if="leftButton" layout="icon" :icon="leftButton" :class="prependClass" :tab="false"/> -->
			<input v-if="!multiple" ref="inputRef" type="text" autoComplete="off"
				v-bind="$attrs" :disabled="disabled" :readonly="searchDisabled" :class="inputClass"
				:value="inputValue"
				@input="onInput"
				@focus="onFocus"
				@blur="onBlur"
				@keydown="onKeyDown" :id="inputId"/>
			<div v-if="multiple" class="input-selected">
				<div v-for="(item, i) in value" :key="i" class="tag">
					<span>{{displaySelected(item)}}</span>
					<v-button v-if="!disabled" @click="removeItem($event, i)" layout="close" class="remove"/>
				</div>
				<input v-if="multiple" ref="inputRef" type="text" autoComplete="off"
					v-bind="$attrs" :disabled="disabled" :readonly="searchDisabled" :class="inputClass" :placeholder="inputPlaceholder"
					@input="onInput"
					@focus="onFocus"
					@blur="onBlur"
					@keydown="onKeyDown" :id="inputId"/>
			</div>
			<!-- <v-icon v-if="loading" icon="circle-notch" class="icon-light append spin"/> -->
			<!-- <v-button v-if="rightButton" layout="icon" :icon="rightButton" :class="appendClass" :tab="false"/> -->
			<v-button v-if="dropdownMode" ref="dropdownRef" layout="icon" icon="caret-down" :class="dropdownClass" @click="onDropdownClick" :tab="false"/>
		</div>
		<transition name="c-connected-overlay" @enter="onOverlayEnter" @leave="onOverlayLeave">
			<div ref="overlayRef" v-if="overlayVisible" class="input-suggestion-overlay list-group" :style="{'max-height': scrollHeight}">
				<div v-if="selectMode">
					<div class="list-group-item">
						<input-checkbox class="checkbox-class dropdown-checkbox" label="Select all" :checked="selectedAll" @input="selectAll"/>
					</div>
					<div v-for="(item, i) in filteredResults" :key="i" class="list-group-item" @mousedown.prevent>
						<input-checkbox class="checkbox-class dropdown-checkbox" :label="displayResult(item)" :checked="item.checked" @input="selectItemCheckbox(item)"/>
					</div>
				</div>
				<div v-else>
					<div v-for="(item, i) in filteredResults" :key="i" class="list-group-item list-group-item-action"
						@click="selectItem($event, item)" @mousedown.prevent>
						{{displayResult(item)}}
					</div>
					<div v-if="addEnabled" class="list-group-item list-group-add">
						<v-button :text="addLabel" layout="empty" class="dropdown-item" @click="addItem" icon="create" @mousedown.prevent/>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed, watch, onBeforeUnmount} from 'vue';
import {useRouter} from 'vue-router';
import {cfw, DomHandler, ObjectUtils, componentId} from '../../utils';
import {useOverlay} from '../../use/overlay';

/*
 :class="getResultsContainerClass"
:class="getResultsItemClass"
*/

export default defineComponent({
	name: 'input-suggestion',
	emits: ['update:value', 'input', 'item-select', 'item-unselect', 'dropdown-click', 'clear', 'changed'],
	inheritAttrs: false,
	props: {
		id: String,
		value: null,
		results: {
			type: Array,
			default: null
		},
		// max: {
		// 	type: Number,
		// 	default: 10
		// },
		multiple: {
			type: Boolean,
			default: false
		},
		max: {
			type: Number,
			default: null
		},
		minLength: {
			type: Number,
			default: 1
		},
		delay: {
			type: Number,
			default: 300
		},
		field: Object,
		entry: Object,
		leftButton: {
			type: String,
			default: null
		},
		rightButton: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dropdown: {
			type: String,
			default: 'blank'
		},
		scrollHeight: {
			type: String,
			default: '200px'
		},
		select: Boolean
	},
	setup(props, {attrs, emit}) {
		const router = useRouter();
		let timeout = null;

		const loading = ref(false);
		const focused = ref(false);

		const overlayRef = ref();
		const containerRef = ref();
		const inputRef = ref();
		const dropdownRef = ref();

		watch(() => props.results, () => {
			if (loading.value) {
				if (props.results && props.results.length) {
					showOverlay();
				} else {
					if (!addEnabled.value) {
						hideOverlay();
					} else {
						showOverlay();
					}
				}
				loading.value = false;
			}

			if (props.value) {
				selectedAll.value = true;
				props.results.forEach(item => {
					if (props.value.includes(item._id)) {
						item.checked = true;
					} else {
						selectedAll.value = false;
					}
				});
			}	
		});
	
		onBeforeUnmount(() => {
			removeOverlay();
		});

		const inputId = computed(() => {
			return props.id || componentId();
		});

		const inputPlaceholder = computed(() => {
			let placeholder = attrs.placeholder || '';
			if (props.value && props.value.length) placeholder = '';
			return placeholder;
		});

		const inputValue = computed(() => {
			return getInputValue();
		});

		const containerClass = computed(() => {
			// , 'input-icon'
			return ['input-suggestion', {
				'focus': focused.value,
				'open': overlayVisible.value,
				'disabled': props.disabled
			}];
		});

		const inputClass = computed(() => {
			return [{
				'single': !props.multiple,
				'multiple': props.multiple
			}];
		});

		const searchDisabled = computed(() => {
			let disabled = false;
			if (props.field) {
				const search = props.field.hasOwnProperty('search') ? props.field.search : true;
				disabled = !search;
			}
			return disabled;
		});

		const dropdownMode = computed(() => {
			let mode = props.dropdown;
			if (props.field) {
				const m = props.field.hasOwnProperty('dropdown') ? props.field.dropdown : true;
				mode = m;
			}
			if (mode === true) mode = 'blank';
			if (mode === false && searchDisabled.value) mode = 'blank';
			return mode;
		});

		const selectMode = computed(() => {
			return props.select || false;
		});

		const selectedAll = ref(false);

		const selectAll = () => {
			if (selectedAll.value) {
				props.results.forEach(item => {
					item.checked = false;
				});
				selectedAll.value = false;
				removeAllItems();
			} else {
				props.results.forEach(item => {
					item.checked = true;
				});
				selectedAll.value = true;
				selectAllItems();
			}
		}

		const selectItemCheckbox = (item) => {
			if (item.checked) {
				item.checked = false;
				removeItem(null, props.value.findIndex(i => i === item._id));
			} else {
				item.checked = true;
				selectItem(null, item);
			}
		}

		const getInputValue = () => {
			if (props.multiple) return '';
			// const f = 'text';
			if (props.field.type === "switch-select") {
				const items = [
					{_id: true, text: "Yes", name: "Yes", key: true},
					{_id: false, text: "No", name: "No", key: false}
				];
				const item = items.find(e => e._id === props.value);
				if (item) return item.name;
			}
			if (props.value) {
				const item = cfw.isArray(props.value) ? props.value[0] : props.value;
				// if (f) {
				// 	const data = ObjectUtils.resolveFieldData(item, f);
				// 	return data != null ? data : item;
				// } else {
				return item;
				// }
			} else {
				return '';
			}
		};

		const canSelect = () => {
			if (props.value && props.field.max > 1 && props.field.max < props.value.length + 1) return false;
			return true;
		};

		const addEnabled = computed(() => {
			return props.field.add ? true : false;
		});

		const addLabel = computed(() => {
			return props.field.add ? props.field.add.label : null;
		});

		const addItem = () => {
			if (!props.field.add) return;
			router.push(props.field.add.route);
		};

		const selectItem = (e, item, keepFocus = true) => {
			if (e) e.preventDefault();
			if (props.multiple) {
				inputRef.value.value = '';
				if (!isSelected(item) && canSelect()) {
					const add = cfw.clone(item);
					if (props.field) {
						const merge = props.field.merge || false;
						if (merge) add.merge = true;
					}
					let newValue = props.value ? [...props.value, add] : [add];
					emit('update:value', newValue);
					emit('changed', {value: newValue});
				}
			} else {
				emit('update:value', item);
				emit('changed', {value: item});
			}
			emit('item-select', {
				originalEvent: e,
				value: item
			});
			if (!selectMode) hideOverlay();
			if (keepFocus) focus();
		};

		const selectAllItems = () => {
			const newValue = [...props.results];
			emit('update:value', newValue);
			emit('changed', {value: newValue});
		};

		const removeItem = (e, i) => {
			let removedValue = props.value[i];
			let newValue = props.value.filter((val, ix) => (i !== ix));
			emit('update:value', newValue);
			emit('changed', {value: newValue});
			emit('item-unselect', {
				originalEvent: e,
				value: removedValue
			});
			focus();
		};

		const removeAllItems = () => {
			const newValue = [];
			emit('update:value', newValue);
			emit('changed', {value: newValue});
		};

		const isSelected = (val) => {
			let selected = false;
			if (props.value && props.value.length) {
				for (let i = 0; i < props.value.length; i++) {
					delete val.perc; //only for requesterEvaluators
					if (ObjectUtils.equals(props.value[i], val)) {
						selected = true;
						break;
					}
				}
			}
			return selected;
		};

		// triggered on input changes with a dynamic delay
		const onInput = (e) => {
			if (timeout) clearTimeout(timeout);

			let query = e.target.value;
			if (!props.multiple) {
				emit('update:value', query);
				emit('changed', {value: query});
			}

			if (query.length === 0) {
				emit('clear');
				hideOverlay();
			} else {
				if (query.length >= props.minLength) {
					timeout = setTimeout(() => {
						search(e, query, 'input');
					}, props.delay);
				} else {
					hideOverlay();
				}
			}
		};

		const onFocus = () => {
			if (!props.disabled) focused.value = true;
		};

		const onBlur = () => {
			focused.value = false;

			// if (!props.multiple) {
			// 	// if (props.results.length === 1) {
			// 	// 	selectItem(null, props.results[0], false);
			// 	// }
			// 	const val = cfw.getProp(props.entry, props.field.src);
			// 	if (!cfw.isObject(val)) cfw.setProp(props.entry, props.field.src, null);
			// }

			// if (!overlayVisible.value && !props.multiple) {
			// 	props.value = null; // inputRef.value.value = '';
			// }
		};

		const onKeyDown = (e) => {
			if (props.disabled) return;
			// const val = e.target.value.trim();

			if (overlayVisible.value) {
				const overlay = overlayRef.value;
				const highlightItem = DomHandler.findSingle(overlay, '.list-group-item.active');

				switch(e.which) {
					// down
					case 40:
						if (highlightItem) {
							let nextElement = highlightItem.nextElementSibling;
							if (nextElement) {
								DomHandler.addClass(nextElement, 'active');
								DomHandler.removeClass(highlightItem, 'active');
								DomHandler.scrollInView(overlay, nextElement);
							}
						} else {
							DomHandler.addClass(DomHandler.findSingle(overlay, '.list-group-item'), 'active');
						}
						e.preventDefault();
						break;

					// up
					case 38:
						if (highlightItem) {
							let previousElement = highlightItem.previousElementSibling;
							if (previousElement) {
								DomHandler.addClass(previousElement, 'active');
								DomHandler.removeClass(highlightItem, 'active');
								DomHandler.scrollInView(overlay, previousElement);
							}
						}
						e.preventDefault();
						break;

					// enter
					case 13:
						if (highlightItem) {
							selectItem(e, props.results[DomHandler.index(highlightItem)]);
							hideOverlay();
						} else if (props.results.length === 1) {
							selectItem(e, props.results[0], false);
						}
						// e.preventDefault();
						break;

					// escape
					case 27:
						hideOverlay();
						e.preventDefault();
						break;

					// tab
					case 9:
						if (highlightItem) {
							selectItem(e, props.results[DomHandler.index(highlightItem)]);
						} else if (props.results.length === 1) {
							selectItem(e, props.results[0], false);
						}
						hideOverlay();
						break;

					default:
						break;
				}
			} else {
				if (e.which === 40) {
					if (props.results && props.results.length) {
						showOverlay();
					} else {
						onDropdownClick({query: ''});
					}
				}
			}

			if (props.multiple) {
				switch(e.which) {
					// backspace
					case 8:
						if (props.value && props.value.length && !inputRef.value.value) { // val.length === 0
							removeItem(e, props.value.length - 1);
						}
						// if (props.modelValue && props.modelValue.length && !input.value) {
						// 	let removedValue = props.modelValue[props.modelValue.length - 1];
						// 	let newValue = props.modelValue.slice(0, -1);
						// 	emit('update:modelValue', newValue);
						// 	emit('item-unselect', {
						// 		originalEvent: event,
						// 		value: removedValue
						// 	});
						// }
						break;

					// enter comma
					case 13:
					case 188:
						// selectItem(e);
						// if (inputValue && inputValue.trim().length && !this.maxedOut) {
						// 		this.addItem(event, inputValue, true);
						// }
						e.preventDefault();
						break;

					default:
						break;
				}
			}
		};

		const onDropdownClick = (e) => {
			if (props.disabled) return;
			focus();
			if (overlayVisible.value) {
				hideOverlay();
				return;
			}
			const query = inputRef.value.value || '';
			if (dropdownMode.value === 'blank') {
				search(e, '', 'dropdown');
			} else if (dropdownMode.value === 'current') {
				search(e, query, 'dropdown');
			}
			emit('dropdown-click', {
				originalEvent: e,
				query: query
			});
		};

		const search = (e, query, source) => {
			// allow empty string but not undefined or null
			if (query === undefined || query === null) return;

			// do not search blank values on input change
			if (source === 'input' && query.trim().length === 0) return;

			loading.value = true;
			emit('input', {
				originalEvent: e,
				query: query
			});
		};

		const focus = () => {
			inputRef.value.focus();
		};

		const {
			overlayVisible,
			showOverlay,
			hideOverlay,
			onOverlayEnter,
			onOverlayLeave,
			removeOverlay
		} = useOverlay(overlayRef, containerRef, inputRef, dropdownRef);

		const filteredResults = computed(() => {
			let items = cfw.clone(props.results);
			if (props.field.sort && props.field.sortField) {
				items = items.map((r) => {
					const find = props.field.sort.find(s => String(r._id) === String(s._id));
					if (find) return {...find, ...r};
					return r;
				});
				items.sort((a,b) => b[props.field.sortField] - a[props.field.sortField]);
			}
			return items;
			// return props.results; // props.results.slice(0, props.max);
		});

		const displaySelected = (item) => {
			const f = 'text';
			if (cfw.isObject(item)) {
				return ObjectUtils.resolveFieldData(item, f);
			} else {
				if (props.field && props.field.items) {
					const found = props.field.items.find(e => e._id === item);
					return found ? ObjectUtils.resolveFieldData(found, f) : item;
				}
			}
		};

		const displayResult = (item) => {
			const f = 'text';
			return f ? ObjectUtils.resolveFieldData(item, f) : item;
		};

		const dropdownClass = computed(() => {
			return ['append', {'disabled': props.disabled}];
		});

		return {
			inputId,
			inputPlaceholder,
			inputValue,
			overlayRef,
			containerRef,
			inputRef,
			dropdownRef,
			containerClass,
			inputClass,
			loading,
			onInput,
			onFocus,
			onBlur,
			onKeyDown,
			onDropdownClick,
			selectItem,
			removeItem,
			filteredResults,
			displaySelected,
			displayResult,
			overlayVisible,
			onOverlayEnter,
			onOverlayLeave,
			dropdownClass,
			searchDisabled,
			dropdownMode,
			selectMode,
			selectedAll,
			selectAll,
			selectItemCheckbox,
			selectAllItems,
			removeAllItems,
			addEnabled,
			addLabel,
			addItem
		};
	}
});
</script>
