import './cfw/polyfills';
import cfw from './cfw';
import API from './api';
import Logger from './logger';
import {DomHandler, OverlayScrollHandler, componentId, cleanHTML} from './dom';
import {EventBus, TerminalService} from './events';
import {APIError} from './error';
import ObjectUtils from './object';
import constants from './constants';
import {calc, calcExpr, calcLine} from './calc';

const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array);
	}
};

const debounce = (fn, wait) => {
	let timer;
	return (...args) => {
		if (timer) {
			clearTimeout(timer); // clear any pre-existing timer
		}
		const context = this; // get the current context
		timer = setTimeout(() => {
			fn.apply(context, args); // call the function if time expires
		}, wait);
	};
};

const throttle = (fn, wait) => {
	let throttled = false;
	return (...args) => {
		if (!throttled) {
			fn.apply(this, args);
			throttled = true;
			setTimeout(()=>{
				throttled = false;
			}, wait);
		}
	};
};

export {
	cfw,
	API,
	Logger,
	DomHandler,
	OverlayScrollHandler,
	componentId,
	EventBus,
	TerminalService,
	ObjectUtils,
	APIError,
	constants,
	cleanHTML,
	calc,
	calcExpr,
	calcLine,
	asyncForEach,
	debounce,
	throttle
};
