class DomHandler {

	static findById(id) {
		return document.getElementById(id);
	}

	static appendStyle(content, id = null) {
		if (id) {
			const found = document.getElementById(id);
			if (found) document.head.removeChild(found);
		}
		const style = document.createElement('style');
		if (id) style.id = id;
		style.type = 'text/css';
		style.appendChild(document.createTextNode(content));
		document.head.appendChild(style);
	}

	static scriptExists(url) {
		return document.querySelectorAll(`script[src="${url}"]`).length > 0;
	}

	static loadScript(url, top = false, loaded) {
		const script = document.createElement('script');
		script.src = url;
		if (top) {
			script.setAttribute('async', 'false');
			document.head.insertBefore(script, document.head.firstElementChild);
		} else {
			document.body.appendChild(script);
		}
		if (loaded) script.addEventListener('load', loaded, false);
	}

	static initMaps(callback) {
		// If you're using vue cli, then directly checking 'google' obj will throw an error at the time of transpiling.
		if (!!window.google) {
			callback();
			return true;
		}
		// TODO
		const gmc = {
			"api": "https://maps.googleapis.com/maps/api/js",
			"api_geocode": "https://maps.googleapis.com/maps/api/geocode/json",
			"lang": "en",
			"key": "AIzaSyCwzKoBoyEmLyxxLeYG-cElfm12BjZl6Jo"
		};
		window.gmInitialized = callback;
		this.loadScript(`${gmc.api}?key=${gmc.key}&language=${gmc.lang}&libraries=places,geometry&callback=gmInitialized`);
	}

	static initFreshChat(url, callback) {
		if (!!window.fcWidget) {
			callback();
			return true;
		}
		this.loadScript(url, false, callback);
	}

	static fallbackCopyToClipboard(text) {
		const textArea = document.createElement("textarea");
		textArea.value = text;

		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			document.execCommand('copy');
		} catch (err) {
			console.error('Unable to copy', err);
		}
		document.body.removeChild(textArea);
	}

	static copyToClipboard(text) {
		if (!navigator.clipboard) return this.fallbackCopyToClipboard(text);
		return new Promise(resolve => {
			navigator.clipboard.writeText(text).then(function() {
				resolve(text);
			}, function(err) {
				console.error('Unable to copy', err);
				resolve();
			});
		});
	}

	static find(el, selector) {
		return el.querySelectorAll(selector);
	}

	static findSingle(el, selector) {
		return el.querySelector(selector);
	}

	static appendChild(el, target) {
		if (this.isElement(target)) {
			target.appendChild(el);
		} else if (target.el && target.elElement) {
			target.elElement.appendChild(el);
		} else {
			throw new Error('Cannot append ' + target + ' to ' + el);
		}
	}

	static getParents(el, parents = []) {
		return el['parentNode'] === null ? parents : this.getParents(el.parentNode, parents.concat([el.parentNode]));
	}

	static getScrollableParents(el) {
		let scrollableParents = [];
		if (el) {
			let parents = this.getParents(el);
			const overflowRegex = /(auto|scroll)/;
			const overflowCheck = (node) => {
				let styleDeclaration = window['getComputedStyle'](node, null);
				return overflowRegex.test(styleDeclaration.getPropertyValue('overflow'))
					|| overflowRegex.test(styleDeclaration.getPropertyValue('overflowX'))
					|| overflowRegex.test(styleDeclaration.getPropertyValue('overflowY'));
			};
			for (let parent of parents) {
				if (parent.nodeType === 1) {
					const check = overflowCheck(parent);
					if (check) scrollableParents.push(parent);
				}
			}
		}
		return scrollableParents;
	}

	static index(el) {
		const children = el.parentNode.childNodes;
		let num = 0;
		for (let i = 0; i < children.length; i++) {
			if (children[i] === el) return num;
			if (children[i].nodeType === 1) num++;
		}
		return -1;
	}

	static getWidth(el) {
		let width = el.offsetWidth;
		const style = getComputedStyle(el);
		width -= parseFloat(style.paddingLeft) + parseFloat(style.paddingRight) + parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
		return width;
	}

	static getHeight(el) {
		let height = el.offsetHeight;
		const style = getComputedStyle(el);
		height -= parseFloat(style.paddingTop) + parseFloat(style.paddingBottom) + parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
		return height;
	}

	static getOuterWidth(el, margin) {
		if (el) {
			let width = el.offsetWidth;
			if (margin) {
				const style = getComputedStyle(el);
				width += parseFloat(style.marginLeft) + parseFloat(style.marginRight);
			}
			return width;
		} else {
			return 0;
		}
	}

	static getOuterHeight(el, margin) {
		if (el) {
			let height = el.offsetHeight;
			if (margin) {
				const style = getComputedStyle(el);
				height += parseFloat(style.marginTop) + parseFloat(style.marginBottom);
			}
			return height;
		} else {
			return 0;
		}
	}

	static getViewport() {
		const win = window,
			d = document,
			e = d.documentElement,
			g = d.getElementsByTagName('body')[0],
			w = win.innerWidth || e.clientWidth || g.clientWidth,
			h = win.innerHeight || e.clientHeight || g.clientHeight;

		return {width: w, height: h};
	}

	static getOffset(el) {
		const rect = el.getBoundingClientRect();
		return {
			top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
			left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0)
		};
	}

	static getWindowScrollTop() {
		const doc = document.documentElement;
		return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	}

	static getWindowScrollLeft() {
		const doc = document.documentElement;
		return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
	}

	static addMultipleClasses(el, className) {
		if (!el) return;
		const styles = className.split(' ');
		if (el.classList) {
			for (let i = 0; i < styles.length; i++) {
				el.classList.add(styles[i]);
			}
		} else {
			for (let i = 0; i < styles.length; i++) {
				el.className += ' ' + styles[i];
			}
		}
	}

	static addClass(el, className) {
		if (!el) return;
		if (el.classList) {
			el.classList.add(className);
		} else {
			el.className += ' ' + className;
		}
	}

	static removeClass(el, className) {
		if (!el) return;
		if (el.classList) {
			el.classList.remove(className);
		} else {
			el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
		}
	}

	static hasClass(el, className) {
		if (!el) return;
		if (el) {
			if (el.classList) {
				return el.classList.contains(className);
			} else {
				return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
			}
		}
		return false;
	}

	static scrollInView(container, item) {
		const borderTopValue = getComputedStyle(container).getPropertyValue('borderTopWidth');
		const borderTop = borderTopValue ? parseFloat(borderTopValue) : 0;
		const paddingTopValue = getComputedStyle(container).getPropertyValue('paddingTop');
		const paddingTop = paddingTopValue ? parseFloat(paddingTopValue) : 0;
		const containerRect = container.getBoundingClientRect();
		const itemRect = item.getBoundingClientRect();
		const offset = (itemRect.top + document.body.scrollTop) - (containerRect.top + document.body.scrollTop) - borderTop - paddingTop;
		const scroll = container.scrollTop;
		const elementHeight = container.clientHeight;
		const itemHeight = this.getOuterHeight(item);

		if (offset < 0) {
			container.scrollTop = scroll + offset;
		} else if ((offset + itemHeight) > elementHeight) {
			container.scrollTop = scroll + offset - elementHeight + itemHeight;
		}
	}

	static isVisible(el) {
		return el.offsetParent != null;
	}

	static getFocusableElements(element) {
		let focusableElements = DomHandler.find(element, `button:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
			[href][clientHeight][clientWidth]:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
			input:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]), select:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
			textarea:not([tabindex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]), [tabIndex]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden]),
			[contenteditable]:not([tabIndex = "-1"]):not([disabled]):not([style*="display:none"]):not([hidden])`
		);
		let visibleFocusableElements = [];
		for (let focusableElement of focusableElements) {
			if (getComputedStyle(focusableElement).display !== "none" && getComputedStyle(focusableElement).visibility !== "hidden") {
				visibleFocusableElements.push(focusableElement);
			}
		}
		return visibleFocusableElements;
	}

	static generateZIndex() {
		this.zindex = this.zindex || 999;
		return ++this.zindex;
	}

	static getCurrentZIndex() {
		return this.zindex;
	}

	static getHiddenElementDimensions(element) {
		const dimensions = {};
		element.style.visibility = 'hidden';
		element.style.display = 'block';
		dimensions.width = element.offsetWidth;
		dimensions.height = element.offsetHeight;
		element.style.display = 'none';
		element.style.visibility = 'visible';
		return dimensions;
	}

	static isEllipsisActive(e) {
		const tolerance = 2; // In px. Depends on the font you are using
		return e.offsetWidth + tolerance < e.scrollWidth;
	}

	static absolutePosition(element, target, out = false) {
		const elementDimensions = element.offsetParent ? {
			width: element.offsetWidth,
			height: element.offsetHeight
		} : this.getHiddenElementDimensions(element);
		const elementOuterHeight = elementDimensions.height;
		const elementOuterWidth = elementDimensions.width;
		const targetOuterHeight = target.offsetHeight;
		const targetOuterWidth = target.offsetWidth;
		const targetOffset = target.getBoundingClientRect();
		const windowScrollTop = this.getWindowScrollTop();
		const windowScrollLeft = this.getWindowScrollLeft();
		const viewport = this.getViewport();
		let top, left;

		if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
			top = targetOffset.top + windowScrollTop - elementOuterHeight;
			element.style.transformOrigin = 'bottom';
			if (top < 0) {
				top = windowScrollTop;
			}
		} else {
			top = targetOuterHeight + targetOffset.top + windowScrollTop;
			element.style.transformOrigin = 'top';
		}

		if (targetOffset.left + elementOuterWidth > viewport.width) {
			left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth);
		} else {
			left = targetOffset.left + windowScrollLeft;
		}

		if (out) return {top: top, left: left};

		element.style.top = top + 'px';
		element.style.left = left + 'px';
	}

	static relativePosition(element, target, out = false) {
		const elementDimensions = element.offsetParent ? {
			width: element.offsetWidth,
			height: element.offsetHeight
		} : this.getHiddenElementDimensions(element);
		const targetHeight = target.offsetHeight;
		const targetOffset = target.getBoundingClientRect();
		const viewport = this.getViewport();
		let top, left;

		if ((targetOffset.top + targetHeight + elementDimensions.height) > viewport.height) {
			top = -1 * (elementDimensions.height);
			element.style.transformOrigin = 'bottom';
			if (targetOffset.top + top < 0) {
				top = -1 * targetOffset.top;
			}
		} else {
			top = targetHeight;
			element.style.transformOrigin = 'top';
		}

		if (elementDimensions.width > viewport.width) {
			// element wider then viewport and cannot fit on screen (align at left side of viewport)
			left = targetOffset.left * -1;
		} else if ((targetOffset.left + elementDimensions.width) > viewport.width) {
			// element wider then viewport but can be fit on screen (align at right side of viewport)
			left = (targetOffset.left + elementDimensions.width - viewport.width) * -1;
		} else {
			// element fits on screen (align with target)
			left = 0;
		}

		if (out) return {top: top, left: left};

		element.style.top = top + 'px';
		element.style.left = left + 'px';
	}

	static fadeIn(element, duration) {
		element.style.opacity = 0;

		let last = +new Date();
		let opacity = 0;

		const tick = () => {
			opacity = +element.style.opacity + (new Date().getTime() - last) / duration;
			element.style.opacity = opacity;
			last = +new Date();

			if (+opacity < 1) {
				(window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
			}
		};

		tick();
	}

	static fadeOut(element, ms) {
		let opacity = 1,
			interval = 50,
			duration = ms,
			gap = interval / duration;

		let fading = setInterval(() => {
			opacity -= gap;
			if (opacity <= 0) {
				opacity = 0;
				clearInterval(fading);
			}
			element.style.opacity = opacity;
		}, interval);
	}
}

let lastId = 0;

const componentId = (prefix = 'c-') => {
	lastId++;
	return `${prefix}${lastId}`;
};

class OverlayScrollHandler {

	constructor(element, listener = () => {}) {
		this.element = element;
		this.listener = listener;
	}

	bindScrollListener() {
		this.scrollableParents = DomHandler.getScrollableParents(this.element);
		for (let i = 0; i < this.scrollableParents.length; i++) {
			this.scrollableParents[i].addEventListener('scroll', this.listener);
		}
	}

	unbindScrollListener() {
		if (this.scrollableParents) {
			for (let i = 0; i < this.scrollableParents.length; i++) {
				this.scrollableParents[i].removeEventListener('scroll', this.listener);
			}
		}
	}

	destroy() {
		this.unbindScrollListener();
		this.element = null;
		this.listener = null;
		this.scrollableParents = null;
	}
}

let decoder = null;
const stripHTML = (html) => {
	decoder = decoder || document.createElement('div');
	decoder.innerHTML = html;
	return decoder.textContent;
};

// https://github.com/cure53/DOMPurify
// https://github.com/jitbit/HtmlSanitizer
// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Sanitizer_API
// const result = new Sanitizer().sanitizeToString(str);

const cleanHTML = (str, level = 0, nodes = false) => {

	const stringToHTML = () => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(str, 'text/html');
		return doc.body || document.createElement('body');
	};

	const removeScripts = (html) => {
		let scripts = html.querySelectorAll('script');
		for (let script of scripts) {
			script.remove();
		}
	};

	const isPossiblyDangerous = (name, value) => {
		const val = value.replace(/\s+/g, '').toLowerCase();
		if (['src', 'href', 'xlink:href'].includes(name)) {
			if (level === 1) return true;
			if (val.includes('javascript:') || val.includes('data:')) return true;
		}
		if (name.startsWith('on')) return true;
	};

	// Remove potentially dangerous attributes from an element
	const removeAttributes = (elem) => {
		// Loop through each attribute
		// If it's dangerous, remove it
		let atts = elem.attributes;
		for (let {name, value} of atts) {
			if (!isPossiblyDangerous(name, value)) continue;
			elem.removeAttribute(name);
		}
	};

	// Remove dangerous stuff from the HTML document's nodes
	const clean = (html) => {
		let nodes = html.children;
		for (let node of nodes) {
			removeAttributes(node);
			clean(node);
		}
	};

	// Convert the string to HTML
	let html = stringToHTML();

	// Sanitize it
	removeScripts(html);
	clean(html);

	// If the user wants HTML nodes back, return them
	// Otherwise, pass a sanitized string back
	return nodes ? html.childNodes : html.innerHTML;
};

export {
	DomHandler,
	OverlayScrollHandler,
	componentId,
	cleanHTML,
	stripHTML
};
