<template>
	<v-dialog v-model:visible="visible" :modal="true" :closable="false" :position="confirmation.position" :class="confirmation.class">
		<template #header>
			<h6 class="panel-title text-gray-600">
				<v-icon v-if="confirmation.icon" :icon="confirmation.icon" class="text-gray-600"/>
				{{confirmation.title}}
			</h6>
			<!-- <div class="text-weight-bold">
				<v-icon v-if="confirmation.icon" :icon="confirmation.icon" class="text-gray-600"/>
				{{confirmation.title}}
			</div> -->
		</template>
		<div class="mb-3" :class="confirmation.messageClass" v-html="confirmation.message"></div>
		<template #footer>
			<v-button v-if="confirmation.cancel" :text="confirmation.cancelLabel || i18n`Cancel`" layout="secondary" @click="cancel()" autofocus/>
			<v-button v-if="confirmation.cancel" :text="confirmation.rejectLabel || i18n`No`" layout="secondary" @click="reject()"/>
			<v-button v-if="!confirmation.cancel" :text="confirmation.rejectLabel || i18n`No`" layout="secondary" @click="reject()" autofocus/>
			<v-button :text="confirmation.acceptLabel || i18n`Yes`" layout="primary" @click="accept()"/>
		</template>
	</v-dialog>
</template>

<script lang="js">
import {defineComponent, ref, onMounted, onBeforeUnmount} from 'vue';
import {ConfirmEventBus} from './events';
// import {i18n} from '../../app';

export default defineComponent({
	name: 'Toast',
	// props: {
	// 	group: {
	// 		type: String,
	// 		default: null
	// 	}
	// },
	setup() { // props
		const visible = ref(false);
		const confirmation = ref({
			title: null,
			message: null
		});

		// const containerClass = computed(() => {
		// 	return 'c-toast c-toast-' + props.position;
		// });

		onMounted(() => {
			ConfirmEventBus.on('confirm', (options) => {
				if (!options) return;
				// if (options.group === this.group) {
				confirmation.value = options;
				confirmation.value.icon = confirmation.value.icon || null;
				confirmation.value.position = confirmation.value.position || 'center';
				confirmation.value.class = confirmation.value.class || 'c-confirm';
				confirmation.value.messageClass = confirmation.value.messageClass || '';
				visible.value = true;
				// }
			});
			ConfirmEventBus.on('close', () => {
				confirmation.value = {
					title: null,
					message: null
				};
				visible.value = false;
			});
		});

		onBeforeUnmount(() => {
			ConfirmEventBus.off('confirm');
			ConfirmEventBus.off('close');
		});

		const accept = () => {
			if (confirmation.value.accept) confirmation.value.accept();
			visible.value = false;
		};

		const reject = () => {
			if (confirmation.value.reject) confirmation.value.reject();
			visible.value = false;
		};

		const cancel = () => {
			if (confirmation.value.cancel) confirmation.value.cancel();
			visible.value = false;
		};

		//    blockScroll() {
		//        return this.confirmation ? this.confirmation.blockScroll : true;
		//    },
		//    iconClass() {
		//        return ['p-confirm-dialog-icon', this.confirmation ? this.confirmation.icon : null];
		//    },
		//    acceptLabel() {
		//        return this.confirmation ? (this.confirmation.acceptLabel || this.$primevue.config.locale.accept) : null;
		//    },
		//    rejectLabel() {
		//        return this.confirmation ? (this.confirmation.rejectLabel || this.$primevue.config.locale.reject) : null;
		//    },
		//    acceptIcon() {
		//        return this.confirmation ? this.confirmation.acceptIcon : null;
		//    },
		//    rejectIcon() {
		//        return this.confirmation ? this.confirmation.rejectIcon : null;
		//    },
		//    acceptClass() {
		//        return ['p-confirm-dialog-accept', this.confirmation ? this.confirmation.acceptClass : null];
		//    },
		//    rejectClass() {
		//        return ['p-confirm-dialog-reject', this.confirmation ? (this.confirmation.rejectClass || 'p-button-text') : null];
		//    }

		return {
			visible,
			confirmation,
			accept,
			reject,
			cancel
		};
	}
});
</script>

<style lang="scss">
</style>
