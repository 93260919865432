<template>
	<!-- div ref="container" class="c-block-container">
		<slot></slot>
	</div> -->
	<label>
		<span>{{label}}</span><span class="flag-required">{{required && !readonly ? "*" : ""}}</span>
		<!-- https://valgeirb.github.io/vue3-popper/ -->
		<v-popper v-if="help" hover offsetDistance="6">
			<v-icon v-if="(help.text || help.link)" icon="question-circle" class="input-help-icon"/>
			<template #content>
				<div class="input-help-info" v-html="help.text"></div>
				<div v-if="help.link" class="mt-1">
					<a :href="help.link" target="_blank">
						<v-icon icon="external-link-alt"></v-icon> {{i18n`More info`}}
					</a>
				</div>
			</template>
		</v-popper>
	</label>

</template>

<script lang="js">
import {defineComponent} from 'vue';
// import {DomHandler} from '../utils';
import Popper from 'vue3-popper';

export default defineComponent({
	name: 'v-label',
	components: {
		'v-popper': Popper
	},
	props: {
		field: Object,
		label: String,
		help: Object,
		readonly: Boolean,
		required: Boolean
	}
	// setup(props) {
	// 	const isRequired = computed(() => {
	// 		return props.field.required;
	// 	});
	// 	return {
	// 		isRequired
	// 	};
	// }
});
</script>

<style lang="scss" scoped>
	:deep(.popper) {
		background: $gray-100 !important;
		padding: $padding-lg;
		border-radius: $border-radius;
		border: $border-base;
		box-shadow: $dropdown-box-shadow;
		// color: $white !important;
		// font-size: 1rem;
		// p {
		// 	max-width: 30vw;
		// }
	}

	/* :deep(.popper #arrow::before) {
		background: #e92791;
	}

	:deep(.popper:hover),
	:deep(.popper:hover > #arrow::before) {
		background: #e92791;
	} */
</style>
