import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["title"];
const _hoisted_2 = ["title"];
const _hoisted_3 = { class: "panel-title" };
const _hoisted_4 = { class: "list-group" };
const _hoisted_5 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_icon = _resolveComponent("v-icon");
    const _component_v_button = _resolveComponent("v-button");
    const _component_v_dialog = _resolveComponent("v-dialog");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("li", {
            class: _normalizeClass(["calendar-day", {
                    'calendar-day--not-current': !_ctx.day.isCurrentMonth,
                    'calendar-day--today': _ctx.isToday,
                    'calendar-day--not-selectable': !_ctx.selectable
                }])
        }, [
            _createElementVNode("span", {
                onClick: _cache[0] || (_cache[0] =
                    //@ts-ignore
                    (...args) => (_ctx.selectDay && _ctx.selectDay(...args)))
            }, _toDisplayString(_ctx.label), 1),
            (_ctx.events)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.handleEventClick(0))),
                    class: "event-label",
                    title: _ctx.events[0].label
                }, _toDisplayString(_ctx.events[0].label), 9, _hoisted_1))
                : _createCommentVNode("", true),
            (_ctx.events && _ctx.events.length > 1)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "other-events",
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.dialogVisible = true)),
                    title: _ctx.i18n `See more`
                }, " + " + _toDisplayString(_ctx.events.length - 1) + " " + _toDisplayString(_ctx.i18n `event` + (_ctx.events.length === 2 ? '' : 's')), 9, _hoisted_2))
                : _createCommentVNode("", true)
        ], 2),
        (_ctx.events)
            ? (_openBlock(), _createBlock(_component_v_dialog, {
                key: 0,
                visible: _ctx.dialogVisible,
                "onUpdate:visible": _cache[4] || (_cache[4] = ($event) => ((_ctx.dialogVisible) = $event)),
                closable: false
            }, {
                header: _withCtx(() => [
                    _createElementVNode("h5", _hoisted_3, [
                        _createVNode(_component_v_icon, {
                            icon: "far fa-calendar-alt",
                            class: "text-gray-600"
                        }),
                        _createTextVNode(" " + _toDisplayString(_ctx.date), 1)
                    ])
                ]),
                footer: _withCtx(() => [
                    _createVNode(_component_v_button, {
                        text: "Close",
                        layout: "primary",
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.dialogVisible = false)),
                        autofocus: ""
                    })
                ]),
                default: _withCtx(() => [
                    _createElementVNode("ol", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (event, index) => {
                            return (_openBlock(), _createElementBlock("li", {
                                key: index,
                                class: "list-group-item list-group-item-action",
                                onClick: ($event) => (_ctx.handleEventClick(index))
                            }, [
                                _createElementVNode("span", null, _toDisplayString(event.label), 1)
                            ], 8, _hoisted_5));
                        }), 128))
                    ])
                ]),
                _: 1
            }, 8, ["visible"]))
            : _createCommentVNode("", true)
    ], 64));
}
