<template>
	<div ref="container" :class="containerClass">
		<slot></slot>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, onMounted, onBeforeUnmount, watch, computed} from 'vue';
import {DomHandler} from '../utils';

export default defineComponent({
	name: 'v-block',
	emits: ['block', 'unblock'],
	props: {
		blocked: {
			type: Boolean,
			default: false
		},
		spinner: {
			type: Boolean,
			default: false
		},
		fullscreen: {
			type: Boolean,
			default: false
		},
		baseZIndex: {
			type: Number,
			default: 0
		},
		autoZIndex: {
			type: Boolean,
			default: true
		}
	},
	setup(props, {emit}) {
		let mask = null;
		const container = ref();

		const containerClass = computed(() => {
			return ['c-block-container', {
				'c-block-active': props.blocked
			}];
		});

		onMounted(() => {
			if (props.blocked) block();
		});

		onBeforeUnmount(() => {
			mask = null;
		});

		watch(() => props.blocked, (newval) => {
			if (newval === true) {
				block();
			} else {
				unblock();
			}
		});

		const block = () => {
			if (mask) return;
			mask = document.createElement('div');

			if (props.spinner) {
				const spinner = document.createElement('div');
				spinner.setAttribute('class', 'spinner-bar');
				mask.append(spinner); // 'Please wait...'
			}
			/* <div class="spinner spinner-block spinner-lg" :class="APP.loading ? 'show' : ''" id="spinner">
				<div class="spinner-bar"></div>
				{{i18n`Please wait...`}}
			</div> */

 			// component-overlay component-overlay-enter

			if (props.fullscreen) {
				mask.setAttribute('class', 'c-block c-block-document');
				document.body.appendChild(mask);
				DomHandler.addClass(document.body, 'overflow-hidden');
				document.activeElement.blur();
			} else {
				mask.setAttribute('class', 'c-block');
				container.value.appendChild(mask);
				// TODO
				const v = document.getElementById('view');
				if (v) v.scrollTo(0, 0);
			}
			if (mask) {
				setTimeout(() => {
					DomHandler.addClass(mask, 'component-overlay');
				}, 1);
			}
			if (props.autoZIndex) {
				mask.style.zIndex = String(props.baseZIndex + DomHandler.generateZIndex());
			}
			emit('block');
		};

		const unblock = () => {
			DomHandler.addClass(mask, 'mask-leave');
			setTimeout(() => {
				removeMask();
			}, 200);
			// mask.addEventListener('transitionend', () => {
			// 	removeMask();
			// });
		};

		const removeMask = () => {
			if (!mask) return;
			if (props.fullScreen) {
				document.body.removeChild(mask);
				DomHandler.removeClass(document.body, 'overflow-hidden');
			} else {
				try {
					container.value.removeChild(mask);
				} catch (err) {
					document.body.removeChild(mask);
					DomHandler.removeClass(document.body, 'overflow-hidden');
				}
			}
			mask = null;
			emit('unblock');
		};

		return {
			container,
			containerClass
		};
	}
});
</script>

<style lang="scss">
.c-block-container {
	position: relative;
}

.c-block-active {
	// overflow: hidden !important;
}

.c-block {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	transition-property: background-color;
}

.c-block.component-overlay {
	position: absolute;
	background-color: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
	transition-duration: 0.3s;

	display: flex;
	font-size: $spinner-block-font-size;
	flex-flow: column;
	align-items: center;
	justify-content: center;

	.spinner-bar {
		display: inline-block;
		width: $spinner-size-lg;
		height: $spinner-size-lg;
		border-radius: 50%;
		border: 0.5rem solid $gray-400;
		border-bottom-color: $primary;
		animation: spinner $spinner-speed linear infinite;
		margin-bottom: $padding-lg;
	}

}

.c-block-document.component-overlay {
	position: fixed;
}

.c-block.mask-leave {
	background-color: transparent;
	z-index: auto !important;
	visibility: hidden;
}
</style>
