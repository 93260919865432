<template>
	<img v-if="APP.user.photo" :src="APP.user.photo" :aria-label="APP.user.name"/>
	<div v-else >
		{{getInitials}}
	</div>
</template>

<script>
import {defineComponent, computed} from "vue";
import {APP} from "../app";

export default defineComponent({
	name: "user-avatar",
	setup() {
		const getInitials = computed(() => {
			const indexLastName = APP.user.name.lastIndexOf(' ');
			return `${APP.user.name[0]}${indexLastName > -1 ? APP.user.name[indexLastName + 1] : ''}`;
		});

		return {
			APP,
			getInitials
		};
	}
});
</script>
