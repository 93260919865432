<template>
	<v-card class="card-full">
		<template #title>
			<div class="card-titlebar">
				<label>{{i18n`Recent activity`}}</label>
				<v-dropdown v-if="props.editable" :itemList="options" :defaultItemId="opt" @selected="(item) => onSelected(item)" class="ml-auto"/>
				<label v-else class="ml-auto">{{options[opt].value}}</label>
			</div>
		</template>
		<template #content>
			<div v-for="(d, i) in userData" :key="i" class="mt-4">
				<div v-if="d.items.length > 0" class="text-weight-bold">{{d.label}}</div>
				<ul class="list-group">
					<li
						class="list-group-item list-group-item-action"
						v-for="item in d.items"
						:key="item._id"
						@click="router.push(`${item.url}`)"
					>
						{{item.name}}
					</li>
				</ul>
			</div>
			<div v-if="props.editable">
				<span class="ml-auto text-muted">{{i18n`Show`}}:</span>
				<input style="margin-right: 0.5rem" class="number-input" type="number" min="0" v-model.number="show"/>
			</div>
		</template>
	</v-card>
</template>

<script>
import {defineComponent, ref, onMounted, watch} from "vue";
import {APP} from "../app";
import {API} from "../utils";
import {useRouter} from "vue-router";

export default defineComponent({
	name: "user-activity",
	props: {
		show: {
			type: Number,
			default: 5
		},
		activityOption: {
			type: Number,
			default: 0
		},
		editable: {
			type: Boolean,
			default: false
		}
	},
	emits: ['selected'],
	setup(props, {emit}) {
		const router = useRouter();
		const userData = ref([]);
		const options = ref([
			{id: 0, value: "Pending requisitions"},
			{id: 1, value: "Draft orders"},
			{id: 2, value: "Receiving orders"},
			{id: 3, value: "Orders waiting for manager approval"},
			{id: 4, value: "Orders waiting for approval"},
			{id: 5, value: "Orders waiting for my approval"},
			{id: 6, value: "Complaints"},
			{id: 7, value: "Pending contract evaluations"},
			{id: 8, value: "Pending order evaluations"},
			{id: 9, value: "Requisitions I'm working on"},
			{id: 10, value: "Orders I'm working on"},
			{id: 11, value: "Recent orders"}
		]);
		const opt = ref(props.activityOption);
		const show = ref(props.show);

		const getData = () => {
			if (!APP.user) return;
			API.get(`/api/profile/activity/${APP.user._id}?k=${opt.value}${show.value ? "&l=" + show.value : ""}`).then((favorites) => {
				userData.value = favorites;
			});
		};

		onMounted(() => {
			getData();
		});

		watch(() => opt.value, (newval, oldval) => {
			if (newval !== oldval) getData();
		});

		watch(() => show.value, () =>{
			if (!show.value) return;
			getData();
			emit('selected', {opt: opt.value, show: show.value});
		});

		const onSelected = (item) => {
			opt.value = item.id;
			emit('selected', {opt: opt.value, show: show.value});
		};
		
		return {
			userData,
			props,
			router,
			options,
			opt,
			onSelected,
			show
		};
	}
});
</script>

<style lang="scss" scoped>
.number-input {
   border: none;
   outline: none;
   border-bottom: $border-width solid $border-color;
   width: 3ch;
   text-align: center;
   margin-right: $padding-xxl;
   margin-left: $padding-sm;
}
</style>
