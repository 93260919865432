import { vModelText as _vModelText, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["placeholder"];
const _hoisted_2 = ["placeholder"];
const _hoisted_3 = ["placeholder"];
const _hoisted_4 = ["placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_input_field = _resolveComponent("input-field");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _withDirectives(_createElementVNode("input", _mergeProps({
            class: "form-control input-address",
            id: "street",
            type: "text",
            autoComplete: "off",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.address.street) = $event)),
            onBlur: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
        }, _ctx.$attrs, {
            placeholder: _ctx.i18n `Street`
        }), null, 16, _hoisted_1), [
            [_vModelText, _ctx.address.street]
        ]),
        _withDirectives(_createElementVNode("input", _mergeProps({
            class: "form-control input-address",
            id: "housenum",
            type: "text",
            autoComplete: "off",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.address.housenum) = $event)),
            onBlur: _cache[3] || (_cache[3] =
                //@ts-ignore
                (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
        }, _ctx.$attrs, {
            placeholder: _ctx.i18n `House nº`
        }), null, 16, _hoisted_2), [
            [_vModelText, _ctx.address.housenum]
        ]),
        _withDirectives(_createElementVNode("input", _mergeProps({
            class: "form-control input-address",
            id: "postcode",
            type: "text",
            autoComplete: "off",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.address.postcode) = $event)),
            onBlur: _cache[5] || (_cache[5] =
                //@ts-ignore
                (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
        }, _ctx.$attrs, {
            placeholder: _ctx.i18n `Postcode`
        }), null, 16, _hoisted_3), [
            [_vModelText, _ctx.address.postcode]
        ]),
        _withDirectives(_createElementVNode("input", _mergeProps({
            class: "form-control input-address",
            id: "city",
            type: "text",
            autoComplete: "off",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.address.city) = $event)),
            onBlur: _cache[7] || (_cache[7] =
                //@ts-ignore
                (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
        }, _ctx.$attrs, {
            placeholder: _ctx.i18n `City`
        }), null, 16, _hoisted_4), [
            [_vModelText, _ctx.address.city]
        ]),
        _createVNode(_component_input_field, _mergeProps({
            class: "input-address",
            id: _ctx.countryField.id,
            entry: _ctx.entry,
            field: _ctx.countryField,
            value: _ctx.address.country
        }, _ctx.$attrs, {
            placeholder: _ctx.i18n `Country`
        }), null, 16, ["id", "entry", "field", "value", "placeholder"])
    ], 64));
}
