import { vModelText as _vModelText, mergeProps as _mergeProps, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
const _hoisted_1 = { class: "d-flex" };
const _hoisted_2 = ["id", "placeholder"];
const _hoisted_3 = ["placeholder"];
const _hoisted_4 = ["placeholder"];
const _hoisted_5 = ["id", "placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_input_field = _resolveComponent("input-field");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.field.measure === 'dimensions')
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 0,
                id: _ctx.id,
                class: "form-control measure",
                type: "number",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.measure.length) = $event)),
                onBlur: _cache[1] || (_cache[1] =
                    //@ts-ignore
                    (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
            }, _ctx.$attrs, {
                placeholder: _ctx.i18n `Length`
            }), null, 16, _hoisted_2)), [
                [_vModelText, _ctx.measure.length]
            ])
            : _createCommentVNode("", true),
        (_ctx.field.measure === 'dimensions')
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 1,
                class: "form-control measure",
                type: "number",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.measure.width) = $event)),
                onBlur: _cache[3] || (_cache[3] =
                    //@ts-ignore
                    (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
            }, _ctx.$attrs, {
                placeholder: _ctx.i18n `Width`
            }), null, 16, _hoisted_3)), [
                [_vModelText, _ctx.measure.width]
            ])
            : _createCommentVNode("", true),
        (_ctx.field.measure === 'dimensions')
            ? _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 2,
                class: "form-control measure",
                type: "number",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.measure.height) = $event)),
                onBlur: _cache[5] || (_cache[5] =
                    //@ts-ignore
                    (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
            }, _ctx.$attrs, {
                placeholder: _ctx.i18n `Height`
            }), null, 16, _hoisted_4)), [
                [_vModelText, _ctx.measure.height]
            ])
            : _withDirectives((_openBlock(), _createElementBlock("input", _mergeProps({
                key: 3,
                id: _ctx.id,
                class: "form-control measure",
                type: "number",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.measure.value) = $event)),
                onBlur: _cache[7] || (_cache[7] =
                    //@ts-ignore
                    (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
            }, _ctx.$attrs, {
                placeholder: _ctx.i18n `Value`
            }), null, 16, _hoisted_5)), [
                [_vModelText, _ctx.measure.value]
            ]),
        _createVNode(_component_input_field, _mergeProps({
            class: "unit",
            entry: _ctx.entry,
            field: _ctx.fields[_ctx.field.measure],
            value: _ctx.measure.uom
        }, _ctx.$attrs, {
            placeholder: _ctx.i18n `Unit`
        }), null, 16, ["entry", "field", "value", "placeholder"])
    ]));
}
