import { ref, computed, watch } from 'vue';
import { cfw, calcExpr } from '../utils';
import { isSupplier } from '../app';
const props = {
    id: String,
    type: {
        type: String
        // required: true,
        // default: 'text'
    },
    name: {
        type: String
        // required: true
    },
    value: [String, Number, Boolean, Object, Array],
    label: String,
    labelPosition: String,
    placeholder: String,
    helpText: String,
    helpLink: String,
    field: Object,
    entry: Object,
    cfg: Object,
    fields: Array,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    // dataType: String,
    checked: {
        type: Boolean,
        default: false
    },
    // multiple: {
    // 	type: Boolean,
    // 	default: false
    // },
    min: Number,
    max: Number,
    step: Number,
    rows: Number,
    view: {
        type: String,
        default: 'form'
    }
};
const fieldValue = (field, entry, view = null) => {
    const native = field.native || { type: 'text' };
    let format = field.format || null;
    let value = cfw.getProp(entry, field.src);
    let displayValue = value || '', link = null;
    let listValue = '';
    if (['checkbox', 'switch'].includes(field.type)) {
        value = value ? true : false;
    }
    let inputValue = value;
    if (value) {
        let v;
        if (['date', 'datetime'].includes(field.type) || native.type === 'datetime') {
            if (!format)
                format = field.type === 'date' ? null : 'DT';
            displayValue = format ? cfw.formatDate(value, format) : cfw.formatDate(value);
        }
        else if (/(?:number|value|quantity)/gi.test(field.type)) { // \b()\b
            if (field.expr)
                value = calcExpr({ expr: field.expr, round: 2 }, entry, {}, true);
            displayValue = format === 'none' ? parseFloat(value) : cfw.formatNumber(parseFloat(value));
        }
        else if (field.type === 'switch-select') {
            const items = [
                { _id: true, text: "Yes", name: "Yes", key: true },
                { _id: false, text: "No", name: "No", key: false }
            ];
            let tmp = value._id;
            v = items.find(e => e._id === tmp);
            if (v) {
                inputValue = v._id;
                displayValue = v.text;
            }
        }
        else if (field.type === 'price') {
            displayValue = cfw.formatNumber(parseFloat(value), '#,##0.00###');
        }
        else if (field.type === 'percentage') {
            displayValue = parseFloat(value) >= 0 ? `${parseFloat(value)}%` : "";
        }
        else if (field.type === 'address') {
            displayValue = fieldValueObj(value, field, ['location', 'place_id', 'formatted']);
        }
        else if (field.type === 'measure') { // \b()\b
            let unit = cfw.isObject(value.uom) ? value.uom.key : value.uom;
            if (!unit)
                unit = '';
            let mv = [];
            if (field.measure === 'dimensions') {
                ['length', 'width', 'height'].forEach(p => {
                    if (value.hasOwnProperty(p) && cfw.parseNumber(value[p]) > 0)
                        mv.push(value[p]);
                });
                displayValue = `${mv.join(' x ')} ${unit}`.trim();
            }
            else {
                ['value', 'net', 'gross'].forEach(p => {
                    if (value.hasOwnProperty(p) && cfw.parseNumber(value[p]) > 0)
                        mv.push(value[p]);
                });
                displayValue = `${mv.join(' | ')} ${unit}`.trim();
            }
        }
        else if (['key_line', 'key_pos', 'key_subpos', 'key_pos_subpos'].includes(field.type)) {
            if (entry.doc && entry.doc.key && entry.doc.pos && entry.doc.subpos) {
                v = [entry.doc.key, entry.doc.pos, entry.doc.subpos];
            }
            else {
                v = value.split('.');
            }
            let ln = parseInt(v[1]);
            if (field.type === 'key_line') {
                displayValue = `${v[0]}.${ln}`;
                const sp = entry.doc && entry.doc.type === 'contract' ? true : false;
                if (v.length === 3 && sp)
                    displayValue += '.' + parseInt(v[2]);
            }
            else if (field.type === 'key_pos') {
                displayValue = ln;
            }
            else if (field.type === 'key_subpos') {
                displayValue = v.length === 3 ? parseInt(v[2]) : '';
            }
            else if (field.type === 'key_pos_subpos') {
                displayValue = v.length === 3 ? `${ln}.${parseInt(v[2])}` : ln;
            }
        }
        else if (/^ref_/.test(field.type) || native.type === 'select') {
            // TODO - ver se isto funciona ficando apenas no fieldValueRef
            const items = field.items ? cfw.clone(field.items) : false;
            if (items) { // && view === 'readonly'
                if (cfw.isArray(value)) {
                    const mult = {
                        inputValue: [],
                        displayValue: []
                    };
                    value.forEach(a => {
                        let tmp = a._id || a;
                        v = items.find(e => e._id === tmp);
                        if (v) {
                            mult.inputValue.push(v._id);
                            mult.displayValue.push(v.text);
                        }
                    });
                    inputValue = mult.inputValue;
                    displayValue = mult.displayValue.join(', ');
                }
                else {
                    let tmp = value._id || value;
                    v = items.find(e => e._id === tmp);
                    if (v) {
                        inputValue = v._id;
                        displayValue = v.text;
                    }
                }
            }
            else if (cfw.isArray(value)) {
                const mult = {
                    inputValue: [],
                    displayValue: []
                };
                value.forEach(a => {
                    v = fieldValueRef(a, field); // view
                    const obj = v._raw;
                    obj['text'] = v.text;
                    mult.inputValue.push(obj);
                    mult.displayValue.push(v.text);
                });
                inputValue = mult.inputValue;
                displayValue = mult.displayValue.join(', ');
            }
            else if (cfw.isObject(value)) {
                v = fieldValueRef(value, field); // view
                inputValue = v.value;
                displayValue = v.text;
            }
            if (field.ref)
                link = `${field.ref.route}/${inputValue}`;
            // TODO
            if (field.type === 'ref_tax' && v) {
                listValue = isSupplier() ? `${v._raw.value * 100}%` : `${v._raw.key} ${v._raw.value * 100}%`;
            }
        }
        else if (cfw.isObject(value)) {
            displayValue = fieldValueObj(value, field);
        }
        else if (cfw.isArray(value)) {
            let tmp = [];
            value.forEach(e => {
                if (!cfw.isEmpty(e) && (cfw.isString(e) || cfw.isNumber(e) || cfw.isBoolean(e)))
                    tmp.push(e);
            });
            displayValue = tmp.join(', ');
        }
    }
    if (field.type === 'textarea') {
        displayValue = displayValue.replace(/\r/g, '').replace(/\n/g, '<br>');
    }
    else if (['date', 'value'].includes(field.type)) {
        value = cfw.getProp(entry, field.src);
        if (value && cfw.isString(value) && value.indexOf('|') !== -1) {
            const vals = value.split('|');
            if (field.type === 'date') {
                displayValue = (vals[0].length > 0 ? cfw.formatDate(parseInt(vals[0])) : "")
                    + " - " + (vals[1].length > 0 ? cfw.formatDate(parseInt(vals[1])) : "");
            }
            else {
                displayValue = (vals[0].length > 0 ? vals[0] : "")
                    + "-" + (vals[1].length > 0 ? vals[1] : "");
            }
        }
    }
    if (view === 'list')
        return displayValue || '';
    const out = {
        display: displayValue || '',
        input: inputValue,
        list: listValue || displayValue || ''
    };
    if (field.route) {
        const i = field.route.lastIndexOf(':');
        let route = null, f = null;
        if (i >= 0) {
            route = field.route.substr(0, i);
            f = field.route.substr(i + 1, field.route.length);
            if (!link) {
                const rid = f ? cfw.getProp(entry, f) : inputValue;
                link = `${route}${rid}`;
            }
        }
        // const key = entry ? (entry._id || entry.key || entry.id) : '';
        // link = `${field.route}/${key}`;
        if (link) {
            if (/http/.test(link)) {
                out['externalLink'] = link;
            }
            else {
                out['link'] = link;
            }
        }
    }
    return out;
};
const fieldValueObj = (obj, field, ignore = []) => {
    const ex = [...['_id', 'id'], ...ignore];
    if (field.hasOwnProperty('expr')) {
        const fields = field.expr.match(/\{.*?\}/g).map(e => e.substr(1, e.length - 2));
        const v = {};
        fields.forEach(f => {
            v[f] = cfw.getProp(obj, f);
        });
        return field.expr.format(v);
    }
    else {
        let v = [];
        Object.keys(obj).forEach(k => {
            if (!ex.includes(k)) {
                let vk = obj[k];
                if (vk) {
                    if (vk.key)
                        vk = vk.key;
                    v.push(vk);
                }
            }
        });
        return v.join(' ');
    }
};
const fieldMoreInfo = (props) => {
    const field = props.field, entry = props.entry;
    let obj = cfw.getProp(entry, field.src);
    if (Array.isArray(obj) && obj.length === 1)
        obj = obj[0];
    const info = { 'key': null, 'name': null, 'vat': null, 'email': null, 'address': null };
    // 'web': null 'phone': null
    Object.keys(info).forEach(k => {
        let vk = obj[k];
        if (vk) {
            if (vk.key) {
                vk = vk.key;
            }
            else if (k === 'address') {
                vk = fieldValueObj(vk, field, ['location', 'place_id', 'formatted']);
            }
            else if (k === 'email') {
                vk = vk.split(';');
            }
            info[k] = vk;
        }
    });
    return info;
};
const fieldValueRef = (e, field) => {
    // const items = field.items ? cfw.clone(field.items) : false;
    const content = field.content || 'obj';
    const fnames = field.ref.search.fields.split(',');
    let value = content === 'obj' ? cfw.getProp(e, Object.keys(e)[0]) : cfw.getProp(e, content); // e[field.ref.rkey];
    // if (items && content === '_id') value = e;
    const display = field.ref.display || 'name';
    let text = null;
    // if (items) { // && view === 'readonly'
    // 	const tmp = value._id || value;
    // 	const found = items.find(e => e._id === tmp);
    // 	if (found) text = found.text;
    // } else {
    if (display.hasOwnProperty('expr')) {
        const fields = display.expr.match(/\{.*?\}/g).map(e => e.substr(1, e.length - 2));
        const obj = {};
        fields.forEach(f => {
            obj[f] = cfw.getProp(e, f);
        });
        text = display.expr.format(obj);
    }
    else {
        text = cfw.getProp(e, display);
    }
    //}
    const data = {
        value: value,
        text: text,
        name: text,
        _raw: {
            _id: value
        }
    };
    // if (!items) {
    fnames.forEach(f => {
        data._raw[f] = cfw.getProp(e, f);
    });
    // }
    return data;
};
/*
checked	Specifies that an input field should be pre-selected when the page loads (for type="checkbox" or type="radio")
disabled	Specifies that an input field should be disabled
max	Specifies the maximum value for an input field
maxlength	Specifies the maximum number of character for an input field
min	Specifies the minimum value for an input field
pattern	Specifies a regular expression to check the input value against
readonly	Specifies that an input field is read only (cannot be changed)
required	Specifies that an input field is required (must be filled out)
size	Specifies the width (in characters) of an input field
step	Specifies the legal number intervals for an input field
value	Specifies the default value for an input field

pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
*/
// color file
// checkbox radio
// text textarea
// email url tel
// date datetime-local time month week
// number range
const inputType = (props) => {
    // data-model="..." data-value-type="string"
    let t = props.type || 'text';
    if (props.field) {
        t = props.field.type || 'text';
        if (props.field.native && !['measure'].includes(props.field.type)) {
            if (props.field.native.type === 'datetime') {
                t = 'date';
            }
            else if (props.field.native.type === 'number') {
                t = 'number';
            }
            else if (props.field.native.type === 'select' && !/^ref_/.test(props.field.type)) {
                t = 'select';
            }
        }
    }
    return t;
};
const contentType = (props) => {
    if (inputType(props) === 'media') {
        return props.field.contentType;
    }
    else {
        return null;
    }
};
const maxSize = (props) => {
    if (inputType(props) === 'media') {
        return props.field.maxSize;
    }
    else {
        return null;
    }
};
const getAttrs = (type, props) => {
    let placeholder = props.placeholder;
    if (!placeholder && props.field)
        placeholder = props.field.placeholder;
    const attrs = {
        required: props.field && props.field.required || props.required,
        disabled: props.field && props.field.disabled || props.disabled,
        placeholder: placeholder || null
    };
    if (type === 'textarea') {
        let rows = props.rows;
        if (!rows && props.field)
            rows = props.field.rows || 3;
        attrs['rows'] = rows;
    }
    return attrs;
};
const fieldLangs = (props) => {
    const langs = computed(() => {
        if (props.field.langs) {
            return props.field.langs;
        }
        else {
            return [];
        }
    });
    return langs;
};
const fieldTranslation = (props) => {
    const translation = computed(() => {
        if (props.entry && props.entry._translation) {
            return props.entry._translation;
        }
        else {
            return {};
        }
    });
    return translation;
};
const inputStyle = (props, errors) => {
    const getLabelPosition = () => {
        let position = props.labelPosition;
        if (!position && props.field && props.field.labelPosition)
            position = props.field.labelPosition;
        if (!position)
            position = 'top';
        return position;
    };
    const containerCss = computed(() => {
        const p = getLabelPosition();
        const attrs = getAttrs(inputType(props), props);
        const css = (p === 'left') ? ['form-group', 'row'] : ['form-group'];
        return [...css, ...(errors.value.length > 0 ? ['has-error'] : []), { 'disabled': attrs.disabled }]; //.join(' ');
    });
    const labelCss = computed(() => {
        const p = getLabelPosition();
        const css = p === 'left' ? ['col-sm-3', 'col-form-label'] : [];
        return css;
    });
    const inputCss = computed(() => {
        const p = getLabelPosition();
        const _inputCss = p === 'left' ? ['col-sm-9'] : [];
        const css = [..._inputCss, ...(errors.value.length > 0 ? [] : [])];
        // , ...(props.field && props.field.dataType === 'number' ? ['text-right'] : [])
        return css.join(' ');
    });
    const labelPosition = computed(() => {
        return getLabelPosition();
    });
    return { containerCss, labelCss, inputCss, labelPosition };
};
const useInput = (props, validators, onValidate) => {
    const focused = ref(false);
    // const entry = ref(props.entry || {[props.name]: props.value});
    // const field = ref(props.field || {src: props.name});
    // const inputValue = computed(() => {
    // 	const c = fieldValue(field.value, entry.value);
    // 	c.display = focused.value ? c.input : c.display;
    // 	//  return "$ " + this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
    // 	return c;
    // });
    const entry = props.entry || { [props.name]: props.value };
    const field = props.field || { src: props.name, dataType: 'string' };
    const inputValue = computed(() => {
        // let view = props.readonly ? 'readonly' : null;
        // if (!view && props.field) view = props.field.readonly ? 'readonly' : null;
        // if (props.view === 'list') view = 'readonly';
        const c = fieldValue(props.field, props.entry); // view
        c.display = focused.value ? c.input : c.display;
        return c;
    });
    // const displayValue = computed(() => {
    // 	let c = {display: props.value || null, input: props.value || null, link: null};
    // 	if (props.field && props.entry) c = fieldValue(props.field, props.entry);
    // 	return c.display;
    // });
    const errors = ref([]);
    const { containerCss, labelCss, inputCss, labelPosition } = inputStyle(props, errors);
    watch(inputValue, value => {
        if (validators)
            errors.value = validators.map(validator => validator(value.input)).filter(e => e);
        onValidate(value.input);
    });
    watch(() => props.field.errors, (newval) => {
        errors.value = newval || [];
    });
    return {
        entry,
        field,
        inputValue,
        // displayValue,
        errors,
        containerCss,
        labelCss,
        inputCss,
        labelPosition,
        focused
    };
};
const useTextarea = () => {
    const cachedScrollHeight = ref(null);
    const resize = (el) => {
        if (!cachedScrollHeight.value) {
            cachedScrollHeight.value = el.scrollHeight;
            el.style.overflow = 'hidden';
        }
        if (cachedScrollHeight.value !== el.scrollHeight) {
            el.style.height = '';
            el.style.height = el.scrollHeight + 'px';
            if (parseFloat(el.style.height) >= parseFloat(el.style.maxHeight)) {
                el.style.overflowY = 'scroll';
                el.style.height = el.style.maxHeight;
            }
            else {
                el.style.overflow = 'hidden';
            }
            cachedScrollHeight.value = el.scrollHeight;
        }
    };
    return {
        cachedScrollHeight,
        resize
    };
};
const usePassword = (validators) => {
    const passwordVisible = ref(false);
    const testStrength = (str) => {
        let level = 0;
        if (validators.passwordStrong.test(str)) {
            level = 3;
        }
        else if (validators.passwordMedium.test(str)) {
            level = 2;
        }
        else if (str.length) {
            level = 1;
        }
        return level;
    };
    const togglePasswordVisible = (disabled = false) => {
        if (disabled)
            return;
        passwordVisible.value = !passwordVisible.value;
    };
    return {
        passwordVisible,
        togglePasswordVisible,
        testStrength
    };
};
export { props, inputType, contentType, maxSize, getAttrs, fieldLangs, fieldTranslation, inputStyle, useInput, useTextarea, usePassword, fieldValue, fieldValueRef, fieldMoreInfo };
