import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "calendar-month" };
const _hoisted_2 = { class: "calendar-month-header" };
const _hoisted_3 = { class: "selectors" };
const _hoisted_4 = { class: "days-grid" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CalendarDateIndicator = _resolveComponent("CalendarDateIndicator");
    const _component_v_dropdown = _resolveComponent("v-dropdown");
    const _component_CalendarDateSelector = _resolveComponent("CalendarDateSelector");
    const _component_CalendarWeekdays = _resolveComponent("CalendarWeekdays");
    const _component_CalendarMonthDayItem = _resolveComponent("CalendarMonthDayItem");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_CalendarDateIndicator, {
                "selected-date": _ctx.selectedDate,
                class: "calendar-month-header-selected-month"
            }, null, 8, ["selected-date"]),
            _createElementVNode("div", _hoisted_3, [
                (_ctx.events)
                    ? (_openBlock(), _createBlock(_component_v_dropdown, {
                        key: 0,
                        itemList: _ctx.eventTypes,
                        onSelected: _ctx.updateCalendar
                    }, null, 8, ["itemList", "onSelected"]))
                    : _createCommentVNode("", true),
                _createVNode(_component_CalendarDateSelector, {
                    "selected-date": _ctx.selectedDate,
                    onDateSelected: _ctx.selectDate
                }, null, 8, ["selected-date", "onDateSelected"])
            ])
        ]),
        _createVNode(_component_CalendarWeekdays),
        _createElementVNode("ol", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day) => {
                return (_openBlock(), _createBlock(_component_CalendarMonthDayItem, {
                    key: day.date,
                    day: day,
                    events: _ctx.getLabel(day),
                    "is-today": day.date === _ctx.today,
                    selectable: _ctx.isSelectable(day),
                    onSelected: ($event) => (_ctx.setDate(day.date))
                }, null, 8, ["day", "events", "is-today", "selectable", "onSelected"]));
            }), 128))
        ])
    ]));
}
