<template>
	<svg xmlns="http://www.w3.org/2000/svg" class="icon" :class="$props.class" :viewBox="`0 0 ${width} ${height}`">
		<path fill="currentColor" :d="svgPath"/>
	</svg>
</template>

<script lang="js">
import {defineComponent, computed} from 'vue';
import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';

export default defineComponent({
	name: 'SvgIcon',

	props: {
		icon: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'fas',
			required: false
		},
		class: String
	},

	setup(props) {

		const parseIcon = (icon) => {
			if (!icon) return null;
			let tmp = icon.split(' ');
			if (tmp.length === 1) return {name: tmp[0], type: 'fas'};
			return {name: tmp[1].replace(/^fa-/, ''), type: tmp[0]};
		};

		const definition = computed(() => {
			if (!props.icon) return;
			let d = findIconDefinition({
				prefix: props.type,
				iconName: props.icon
			});
			if (!d) {
				const parsed = parseIcon(props.icon);
				if (parsed) {
					d = findIconDefinition({
						prefix: parsed.type,
						iconName: parsed.name
					});
				}
			}
			if (!d) console.error(`Icon ${props.icon} - ${props.type} not included`);
			return d;
		});

		const width = computed(() => definition.value ? definition.value.icon[0] : 0);
		const height = computed(() => definition.value ? definition.value.icon[1] : 0);
		const svgPath = computed(() => definition.value ? definition.value.icon[4] : '');

		return {width, height, svgPath};
	}
});
</script>
