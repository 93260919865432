import { reactive, toRefs } from 'vue';
import { cfw, API, Logger } from '../utils';
import { getModelSchema } from './model';
const DEFAULT_PAGE = 1;
const DEFAULT_LENGTH = 30;
const DEFAULT_SORT = '-_id';
const getData = params => {
    const cfg = params.cfg, model = params.model;
    const view = params.id ? 'form' : 'list';
    let url = null;
    if (cfg) {
        if (!cfg.url)
            return;
        url = cfg.url;
        if (view === 'form') {
            url += `/${params.id}`;
        }
        else {
            const q = cfw.query(cfw.removeProps(params, 'cfg'), true);
            Logger.trace('getData-cfg', q);
            if (q)
                url += `?${q}`;
        }
        return API.get(url);
    }
    else if (model) {
        return new Promise(resolve => {
            getModelSchema(model).then(cfg => {
                url = cfg[view].url;
                if (view === 'form') {
                    url += `/${params.id}`;
                }
                else {
                    // TODO
                    if (['log', 'tracking'].includes(params.flag)) {
                        url = `/ihub/${params.flag}/${model}`;
                    }
                    const q = cfw.query(cfw.removeProps(params, 'model'), true);
                    Logger.trace('getData-model', q);
                    if (q)
                        url += `?${q}`;
                }
                API.get(url).then(data => {
                    let out = data;
                    if (view === 'form') {
                        out = {
                            options: { cfg: cfg },
                            item: data.items ? data.items[0] : data
                        };
                    }
                    else {
                        out.options.cfg = cfg;
                    }
                    resolve(out);
                });
            });
        });
    }
};
const getMonitor = (params, download = false) => {
    if (!params.flag)
        return;
    const model = params.model;
    let url = `/ihub/${params.flag}/${model}`;
    const q = cfw.query(cfw.removeProps(params, 'model', 'flag'), true);
    if (q)
        url += `?${q}`;
    if (download) {
        url += `${q ? '&' : '?'}format=xlsx`;
        return url;
    }
    return API.get(url);
};
const getSearchUrl = (url, entry, options, params = null) => {
    let search = null;
    if (options.search) {
        const obj = {};
        const m = options.search.match(/\{.*?\}/g);
        if (m) {
            const fields = m.map(e => e.substr(1, e.length - 2));
            if (entry)
                fields.forEach(f => obj[f] = cfw.getProp(entry, f));
        }
        search = options.search.format(obj);
    }
    const p = cfw.clone(params || {});
    if (search)
        p.search = search;
    let searchUrl = url;
    const q = cfw.query(p, true);
    Logger.trace('getSearchUrl', q);
    if (q)
        searchUrl += `?${q}`;
    return searchUrl;
};
export default {
    getData,
    getMonitor,
    getSearchUrl,
    DEFAULT_PAGE,
    DEFAULT_LENGTH,
    DEFAULT_SORT
};
export function useParseQuery(queryString) {
    const href = window.location.href.split('?');
    const search = href.length === 1 ? '' : '?' + href[1];
    let query = queryString ? queryString : search;
    return {
        query: cfw.parseQuery(query)
    };
}
export function useFetchData(fetcher) {
    const state = reactive({
        loading: false,
        error: null,
        result: null
    });
    let lastPromise;
    const use = async () => {
        state.error = null;
        state.loading = true;
        const promise = (lastPromise = fetcher());
        try {
            const result = await promise;
            if (lastPromise === promise)
                state.result = result;
        }
        catch (err) {
            state.error = err;
        }
        finally {
            state.loading = false;
        }
    };
    return {
        ...toRefs(state),
        use
    };
}
