<template>
	<div class="filter-selected">
		<div class="filter-content" @click="onFilterClick">
			{{filterContent}}
		</div>
		<div class="filter-delete" v-if="props.canDelete">
			<v-button class="delete-button" layout="close" @click="deleteFilter"></v-button>
		</div>
	</div>

</template>

<script lang="js">
import {defineComponent, computed} from 'vue';
import {drawerShow} from '../use/model';

export default defineComponent({
	name: 'v-filter',
	emits:['deleteFilter'],
	props: {
		label: String,
		operator: String,
		value: String,
		id: String,
		canDelete: Boolean
	},
	setup(props, {emit}) {
		const onFilterClick = () => {
			drawerShow('settings', 1);
		};

		const deleteFilter = () => {
			emit("deleteFilter", props.id);
		};

		const filterContent = computed(() => {
			//return `${props.label} ${props.operator} ${props.value}`
			return `${props.label}: ${props.value}`;
		});

		return {
			onFilterClick,
			filterContent,
			deleteFilter,
			props
		};
	}
});
</script>

<style lang="scss">
.filter-selected {
	display: flex;
	border: 1px solid $border-color;
	padding: 5px 10px;
	border-radius: $border-radius;
	font-size: 0.75em;
	margin-left: 1em;
	background-color: $gray-100;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
}
.filter-content{
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
}
.filter-delete{
	display: inline-block;
	margin-left: $padding;

}
.delete-button{
	height: 15px;
	width: 10px;
	line-height: 0px;
}
</style>
