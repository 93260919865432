import { createRouter, createWebHistory } from 'vue-router';
const VIEW_PATH = './views';
const components = {
    model: import(/* webpackChunkName: "model" */ `${VIEW_PATH}/Model.vue`),
    obj: import(/* webpackChunkName: "obj" */ `${VIEW_PATH}/Obj.vue`),
    monitor: import(/* webpackChunkName: "monitor" */ `${VIEW_PATH}/Monitor.vue`),
    search: import(/* webpackChunkName: "search" */ `${VIEW_PATH}/Search.vue`),
    help: import(/* webpackChunkName: "search" */ `${VIEW_PATH}/Help.vue`),
    NotFound: import(/* webpackChunkName: "notfound" */ `${VIEW_PATH}/NotFound.vue`)
};
components['config-rule'] = import(/* webpackChunkName: "config-rule" */ `${VIEW_PATH}/config/Rule.vue`);
components['config-localization'] = import(/* webpackChunkName: "config-localization" */ `${VIEW_PATH}/config/Localization.vue`);
if (process.env.NODE_ENV !== 'production') {
    components['config-connector'] = import(/* webpackChunkName: "config-connector" */ `${VIEW_PATH}/config/Connector.vue`);
    components['config-model'] = import(/* webpackChunkName: "config-model" */ `${VIEW_PATH}/config/Model.vue`);
}
const routes = [
    {
        path: '/',
        name: 'home',
        meta: { nav: 'home' },
        component: () => import(/* webpackChunkName: "dashboard" */ `${VIEW_PATH}/Dashboard.vue`)
    },
    {
        path: '/reports',
        name: 'reports',
        meta: { nav: 'reports' },
        component: () => import(/* webpackChunkName: "reports" */ `${VIEW_PATH}/Reports.vue`)
    },
    {
        path: '/reports/:report',
        name: 'report',
        meta: { nav: 'reports' },
        component: () => import(/* webpackChunkName: "reports" */ `./components/Cubejs.vue`)
    },
    {
        path: '/signin',
        name: 'signin',
        meta: { layout: 'empty', nav: 'signin' },
        component: () => import(/* webpackChunkName: "login" */ `${VIEW_PATH}/Login.vue`)
    },
    // {
    // 	path: '/signup',
    // 	name: 'signup',
    // 	meta: {layout: 'empty', nav: 'signup'},
    // 	component: () => import(/* webpackChunkName: "register" */ `${VIEW_PATH}/Register.vue`)
    // },
    {
        path: '/account',
        name: 'account',
        meta: { nav: 'account' },
        component: () => import(/* webpackChunkName: "account" */ `${VIEW_PATH}/Account.vue`)
    },
    {
        path: '/map/:markerData?',
        name: 'map',
        meta: { nav: 'map' },
        component: () => import(/* webpackChunkName: "map" */ `${VIEW_PATH}/Map.vue`)
    },
    {
        path: '/frame/:source',
        name: 'frame',
        meta: { nav: 'frame' },
        component: () => import(/* webpackChunkName: "map" */ `${VIEW_PATH}/Frame.vue`)
    },
    {
        path: '/invoices',
        name: 'invoices',
        meta: { nav: 'invoices' },
        component: () => import(/* webpackChunkName: "invoices" */ `${VIEW_PATH}/Invoices.vue`)
    },
    {
        path: '/legacy',
        name: 'legacy',
        meta: { nav: 'legacy' },
        component: () => import(/* webpackChunkName: "legacy" */ `${VIEW_PATH}/config/Legacy.vue`)
    },
    {
        path: '/read/:type/:id',
        name: 'read',
        meta: { layout: 'read', nav: 'read' },
        component: () => import(/* webpackChunkName: "login" */ `${VIEW_PATH}/Read.vue`)
    },
    {
        path: '/offline',
        name: 'offline',
        meta: { layout: 'empty', nav: 'offline' },
        component: () => import(/* webpackChunkName: "login" */ `${VIEW_PATH}/NotFound.vue`)
    },
    {
        path: '/search/:model?',
        name: 'search',
        meta: { nav: 'search' },
        component: () => components.search
    },
    {
        path: '/help/:doc?',
        name: 'help',
        meta: { layout: 'large', nav: 'help' },
        component: () => components.help
    }
];
if (process.env.NODE_ENV !== 'production') {
    routes.push({
        path: '/code/sandbox',
        name: 'code-sandbox',
        meta: { nav: 'code-sandbox' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "sandbox" */ `${VIEW_PATH}/SandBox.vue`)
    });
}
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            };
        }
        else if (savedPosition) {
            return savedPosition;
        }
        else {
            return { top: 0 };
        }
    }
});
export { router, components };
