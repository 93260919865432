<template>
	<div class="p-container">
		<div v-for="(bar, i) in orderedBars()" class="bar-block" :style="{width: bar.pct}" :key="i">
			<div class="bar" :class="{'first-bar': i===0}" :style="{'background-color': bar.color, width: '100%'}">&nbsp;</div>
			<div :class="getLineClass(bar.color)" :style="getLineStyle(bar)"></div>
			<div :class="getLabelClass()">
				<b>{{ bar.label }}</b> - {{ cfw.formatNumber(bar.value) }}
			 </div>
		</div>
	</div>

</template>

<script>
import {defineComponent} from "vue";
import {cfw} from '../utils';

export default defineComponent({
	name: "v-progress-labelled",
	props: {
	 data:Object
	},
	setup(props) {

		const orderedBars = () => {
		  // let sum = 0;
		  let ordered = props.data.consumed.sort((a, b) => {
				if (a.value > b.value) return 1;
				else return -1;
			}).map((e) => {
				return {
				  ...e,
				  pct: (e.value * 100) / props.data.total + "%"
				};
			});

		  let previous = "up";
		  ordered = ordered.map((e, i) => {
				let labelHeight = "up";
				if (i !== 0) {
				  if (previous === 'up') labelHeight = 'down';
					previous = labelHeight;
				}
				return {
				  ...e,
				  labelHeight
				};
			});

		  return ordered;
		};

		const getLineClass = () => {
		  return ['p-line', {
			 'p-line-right': props.data.labelPosition === 'right',
			 'p-line-center': props.data.labelPosition === 'center',
			 'p-line-left': props.data.labelPosition === 'left'
		  }];
		};

		const getLineStyle = (bar) => {
		  return {'border-left': '1px solid ' + bar.color, height: bar.labelHeight === 'up' ? '15px' : '30px'};
		};

		const getLabelClass = () => {
		  return ['p-label', {
			 'p-label-right': props.data.labelPosition === 'right',
			 'p-label-center': props.data.labelPosition === 'center',
			 'p-label-left': props.data.labelPosition === 'left'
		  }];
		};

		return {
			orderedBars,
			getLineClass,
			getLineStyle,
			getLabelClass,
			cfw
		};
	}
});
</script>

<style lang="scss">
.p-container {
	display: flex;
	font-size: 0.7rem;
	width: 100%;
	height: 15px;
	background-color: rgb(228, 228, 228);
  border-radius: $border-radius;
  margin-bottom: $padding-xxl;
}

.l-container {
	display: flex;
	max-height: 25%;
	width: 100%;
	border-right: $border-base;
}

.bar-block {
  position:relative;
}

.bar {
	height: 15px;
}

.first-bar {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius
}

.p-label {
  position: relative;
  width:100vw
}

.p-label-right {
  text-align: right;
}

.p-label-center {
  text-align: center;
}

.p-label-left {
  text-align: left;
}

.p-line {
	position: relative;
}

.p-line-right {
  left: 100%;
}

.p-line-center {
  left: 50%;
}

.p-line-left {
  left: 0%;
}

.label-total {
  position: relative;
  right: -10%;
  align-self: center;
  margin-left:auto
}
</style>
