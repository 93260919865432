<template>
	<div class="app-header">
		<button type="button" class="btn btn-sidebar" data-toggle="sidebar" aria-label="Sidebar Toggle"
			@click="isOpen = true">
			<v-icon icon="bars"/>
		</button>
		<router-link class="app-logo" tabindex="-1" aria-label="" to="/">
			<img :src="`${APP.path}themes/${APP.theme}/logo.png`" alt="">
		</router-link>
		<div class="app-search"></div>
		<div class="app-actions">
			<v-button v-if="appAccess" icon="sitemap" layout="link"
				@click="drawerToggle()" :text="group.name"
				class="mr-3"
				label="Change group"/>
			<v-button v-if="APP.ui.speechRecognition && APP.user && recognition" :icon="recognition.isListening.value ? 'microphone' : 'microphone-slash'" @click="listeningToggle"/>
			<v-button v-if="APP.ui.serviceDesk && APP.user" icon="life-ring" @click="drawerToggle('top')" :label="i18n`Help`"/>
			<v-button v-if="appHelp" layout="circle" @click="sidebarHelp = true" icon="question-circle" :title="i18n`Help`"/>
			<v-button v-if="route.path === '/'" icon="cog" label="Configure Dashboard" @click="editDashboard()"/>
			<v-button icon="sync-alt" @click="reloadApp(true)" :label="i18n`Reload APP`"/>
			<v-overlay v-if="APP.user">
				<template v-slot:handler>
					<span class="avatar">
						<user-avatar/>
					</span>
				</template>
				<div class="app-user">
					<router-link to="/account">
						<div class="text-center p-3">
							<div class="avatar lg mb-3">
								<user-avatar/>
							</div>
							<h4>{{i18n`Hello ${APP.user.name}`}}</h4>
							<div class="text-muted">{{APP.user.role.name}}</div>
						</div>
					</router-link>
					<div class="dropdown-divider"></div>
					<form class="p-3 d-flex justify-content-center">
						<!-- <v-button layout="secondary" icon="home" :tab="false" :text="i18n`Sourcing`" @click="home"/> -->
						<v-button layout="secondary" icon="sign-out-alt" :tab="false" :text="i18n`Sign out`" @click="home"/>
					</form>
				</div>
			</v-overlay>
			<router-link v-else class="btn btn-link-circle" to="/signin">
				<v-icon icon="sign-in-alt"/>
			</router-link>
		</div>
	</div>
	<v-sidebar header="P2P online help" v-model:visible="drawerTopOpen" position="top">
		<p>{{i18n`How can we assist you`}}?</p>
	</v-sidebar>
	<v-sidebar v-model:visible="drawerOpen" position="right" class="sidebar-groups">
		<template v-slot:header>
			<div>
				<div class="mb-1"><small class="text-muted">{{i18n`Keep working in`}}</small></div>
				<h5>{{group.name}}</h5>
				<div class="mt-4"><small class="text-muted">{{i18n`or select one from the list below`}}</small></div>
				<!-- <div class="input-icon">
					<button type="button" class="input-icon-btn-left" tabindex="-1">
						<v-icon icon="search"/>
					</button>
					<input type="text" class="form-control" autocomplete="off" v-model="quickFilter"/>
				</div> -->
			</div>
		</template>
		<v-tree
			v-if="APP.ua.active === 'organization'"
			:value="nodes"
			:expandedKeys="expandedKeys"
			:filter="true" filterMode="lenient"
			selectionMode="single"
			:metaKeySelection="false"
			v-model:selectionKeys="selectedKeys"
			@node-select="onNodeSelect"
			@node-unselect="onNodeUnselect"/>
		<ul v-if="APP.ua.active === 'group'" class="list-group">
			<li
				  class="list-group-item list-group-item-action pl-3"
				  v-for="group in groups"
				  :key="group._id"
				  @click="changeGroup(group)"
			>
				{{group.name}}
			</li>
		</ul>
		<template v-slot:footer>
			<div class="divider-top">
				<v-button layout="primary" @click="drawerOpen = false" :text="i18n`Close`"/>
				<v-button v-if="APP.ua.active === 'organization' && APP.ua.plant" layout="secondary" @click="changeGroup()" :text="i18n`Clear selection`"/>
			</div>
		</template>
	</v-sidebar>
	<v-sidebar v-if="appHelp" v-model:visible="sidebarHelp" position="right" class="sidebar-help">
		<template v-slot:header>
			<div>
				<div class="mb-1"><small class="text-muted">{{appHelp.topic}}</small></div>
				<h5><v-icon icon="question-circle" class="text-theme"/> {{appHelp.title}}</h5>
			</div>
		</template>
		<hr>
		<div v-if="appHelp.url"><a :href="appHelp.url" target="_blank"><v-icon icon="external-link-alt"></v-icon> {{i18n`User Manual`}}</a></div>
		<hr v-if="appHelp.url">
		<div v-if="appHelp.secondaryUrls">
			<div v-for="su in appHelp.secondaryUrls" :key="su.title">
				<v-icon icon="circle-small"></v-icon>{{` - `}}<a class="text-muted" :href="su.url" target="_blank"> {{su.title}}</a>
			</div>
		</div>
		<hr v-if="appHelp.secondaryUrls">
		<div v-html="appHelp.content"></div>
		<template v-slot:footer>
			<div class="divider-top inline">
				<v-button layout="primary" @click="sidebarHelp = false" :text="i18n`Close`"/>
				<input-checkbox v-if="false" v-model="hideHelp" :label="i18n`Don't show this message again`"/>
			</div>
		</template>
	</v-sidebar>
</template>

<script lang="js">
import {defineComponent, ref, computed, onMounted} from 'vue'; // onUnmounted
import {useRoute, useRouter} from 'vue-router';
import {APP, setUser, setUserAccess, reloadApp, useHelp, useAccess, setEditingDashboard, isRole, hasRole} from '../app';
import {cfw, API, Logger, DomHandler} from '../utils';
import {useSidebar, useNavigation} from '../use/navigation';
import useSpeechRecognition from '../use/speechRecognition';

//TODO: Don't show this message again hidden - code line 112

// import useTextToSpeech from '../use/textToSpeech';

export default defineComponent({
	name: 'app-header',
	components: {
	},
	setup() { // _, {emit}
		const route = useRoute();
		const router = useRouter();
		const {signout, home} = useNavigation(route, router);

		const drawerOpen = ref(false);
		const drawerTopOpen = ref(false);
		const {isOpen} = useSidebar();

		const recognition = useSpeechRecognition('en-US');

		const {sidebarHelp, appHelp, hideHelp} = useHelp();
		const {appAccess} = useAccess();

		const saveConfig = async (config) => {
			try {
				const data = await API.post('/profile/user/config', config);
				await setUser((data.token ? data : false));
				reloadApp(true);
			} catch (err) {
				Logger.error(err);
			}
		};

		const getGroups = () => {
			if (!APP.user) return;
			API.fetch({api: '/profile/user/groups', cache: 'groups'}).then(result => {
				nodes.value = result.organization;
			});
		};

		// https://jsfiddle.net/arjunpaliath/cdrztt1L/63/
		onMounted(() => {
			if (APP.ui.chat.enabled) {
				let firstName = APP.user.name;
				let lastName = null;
				const sep = APP.user.name.indexOf(' ');
				if (sep !== -1) {
					firstName = APP.user.name.substr(0, sep);
					lastName = APP.user.name.substr(sep + 1);
				}
				const options = {
					token: APP.ui.chat.token,
					host: APP.ui.chat.host,
					externalId: APP.user.username,
					firstName: firstName,
					lastName: lastName,
					email: APP.user.email,
					open: false,
					config: {
						disableEvents: true,
						showFAQOnOpen: true,
						hideFAQ: false,
						// cssNames: {
						// 	widget: 'fc_frame',
						// 	open: 'fc_open',
						// 	expanded: 'fc_expanded'
						// },
						agent: {
							hideName: true,
							hidePic: true,
							hideBio: true
						},
						headerProperty: {
							appName: 'Clearis Helpdesk',
							backgroundColor: '#343a40', // #337ab7 #ea5b0c
							foregoundColor: '#ffffff',
							// Hide the chat button on load
							hideChatButton: false
						}
					}
				};
				DomHandler.initFreshChat(APP.ui.chat.widget, () => {
					window.fcWidget.init(options);
				});
			}
			getGroups();
			if (APP.user && APP.user.group) group.value = APP.user.group;
		});

		// onUnmounted(() => {
		// 	window.fcWidget = null;
		// });

		const group = computed(() => {
			const group = cfw.clone(APP.ua.active === 'group' ? APP.ua.workgroup : APP.ua.group);
			if (APP.ua.active === 'group' && !group._id) group.name = 'Created by me';
			return group;
		});

		const groups = computed(() => {
			let groups = [];
			if (isRole('admin,manager,buyer,auditor,director,manager-purchasing,manager-supplier')) {
				groups.push({_id: 'all', key: 'all', name: 'All'});
			}
			groups = [...groups, {_id: 'me', key: 'me', name: 'Created by me'}];
			if (hasRole('cst', false)) groups.push({_id: 'assigned', key: 'assigned', name: 'Assigned to me'});
			// TODO
			if (!hasRole('ltd', false)) {
				if (APP.user.groups.length > 1) groups.push({_id: 'mygroups', key: 'mygroups', name: 'All groups'});
				groups.push({_id: 'mycontracts', key: 'mycontracts', name: 'Contracts managed by me'});
				groups = [...groups, ...cfw.clone(APP.user.groups)];
			}
			return groups;
		});

		const changeGroup = async (ua = null) => {
			if (ua && ua.key === 'me') ua = null;
			drawerOpen.value = false;
			const g = APP.ua.active === 'group' ? {group: cfw.clone(ua)} : cfw.clone(ua);
			g.active = APP.ua.active;
			await setUserAccess(g);
		};

		const drawerToggle = (position = 'right') => {
			if (position === 'top') {
				drawerTopOpen.value = !drawerTopOpen.value;
			} else {
				drawerOpen.value = !drawerOpen.value;
			}
		};

		const listeningToggle = () => {
			recognition.toggleListening();
		};

		const nodes = ref([]);
		const expandedKeys = ref({});
		const selectedKeys = ref({});

		const onNodeSelect = (node) => {
			if (['company', 'plant'].includes(node.type)) {
				const group = {
					_id: node.group._id,
					name: node.label // node.group.name.join(' ')
				};
				if (node.type === 'company') {
					const children = node.children.length > 0 ? node.children : [node];
					changeGroup({
						group: group,
						location: node.data,
						plant: children[0].data
					});
				} else if (node.type === 'plant') {
					changeGroup({
						group: group,
						location: node.data.parent,
						plant: node.data
					});
				}
			}
		};

		const onNodeUnselect = () => { // node
		};

		const editDashboard = () => {
			setEditingDashboard(true);
		};

		return {
			APP,
			isOpen,
			drawerOpen,
			drawerTopOpen,
			drawerToggle,
			signout,
			home,
			recognition,
			listeningToggle,
			reloadApp,
			group,
			groups,
			changeGroup,
			saveConfig,
			nodes,
			expandedKeys,
			selectedKeys,
			onNodeSelect,
			onNodeUnselect,
			sidebarHelp,
			appHelp,
			hideHelp,
			appAccess,
			route,
			editDashboard,
			hasRole
		};
	}
});
</script>

<style lang="scss">
.c-sidebar.sidebar-groups {
	width: 30vw;
}
.c-sidebar.sidebar-help {
	width: 30vw;
}
</style>
