const minLength = min => {
	return input => input.length < min ? `Value must be at least ${min} characters` : null;
};

const isEmail = () => {
	const re = /\S+@\S+\.\S+/;
	return input => re.test(input) ? null : "Must be a valid email address";
};

const validators = {
	email: new RegExp(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	),
	url: new RegExp(
		/^(https?|ftp|rmtp|mms):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i
	),
	text: new RegExp(/^[a-zA-Z]+$/),
	digits: new RegExp(/^[\d() \.\:\-\+#]+$/),
	isodate: new RegExp(
		/^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/
	),
	passwordMedium: new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'),
	passwordStrong: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
};

export {
	minLength,
	isEmail,
	validators
};
