<template>
	<slot></slot>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'v-tab',
	props: {
		header: null,
		disabled: Boolean,
		hidden: Boolean
	}
});
</script>
