<template>
	<div :class="containerClass" ref="containerRef">
		<div class="input">
			<div class="input-selected">
				<div v-for="(item, i) in selectedNodes" :key="i" class="tag">
					<span>{{displaySelected(i)}}</span>
					<v-button @click="removeItem($event, i)" layout="close" class="remove"/>
				</div>
				<input ref="inputRef" type="text"
					:disabled="true" class="multiple"
					@focus="onFocus"
					@blur="onBlur"
					:id="inputId"/>
			</div>
			<v-button  ref="dropdownRef" layout="icon" icon="caret-down" :class="dropdownClass" @click="onDropdownClick" :tab="false"/>
		</div>
		<transition name="c-connected-overlay" @enter="onOverlayEnter" @leave="onOverlayLeave">
			<div ref="overlayRef" v-if="overlayVisible" class="input-suggestion-overlay tree-overlay" :style="{'max-height': scrollHeight}">
				<!-- <div v-for="(item, i) in filteredResults" :key="i" class="list-group-item list-group-item-action"
					@click="selectItem($event, item)" @mousedown.prevent>
					{{displayResult(item)}}
				</div>-->
				<v-tree class="mt-6"
					:value="nodes"
					v-model:expandedKeys="expandedKeys"
					:filter="true" filterMode="lenient"
					:selectionMode="field.multiple? 'multiple' : 'single'"
					:metaKeySelection="false"
					v-model:selectionKeys="selectedKeys"
					@node-select="onNodeSelect"
					@node-unselect="onNodeUnselect"/>
			</div>
		</transition>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed, watch, onBeforeUnmount, onMounted} from 'vue';
import {API, cfw, DomHandler, ObjectUtils, componentId} from '../../utils';
import {useOverlay} from '../../use/overlay';

/*
 :class="getResultsContainerClass"
:class="getResultsItemClass"
*/

export default defineComponent({
	name: 'input-tree-select',
	emits: ['update:value', 'input', 'item-select', 'item-unselect', 'dropdown-click', 'clear', 'changed'],
	inheritAttrs: false,
	props: {
		id: String,
		value: null,
		results: {
			type: Array,
			default: null
		},
		// max: {
		// 	type: Number,
		// 	default: 10
		// },
		multiple: {
			type: Boolean,
			default: false
		},
		minLength: {
			type: Number,
			default: 1
		},
		delay: {
			type: Number,
			default: 300
		},
		field: Object,
		entry: Object,
		leftButton: {
			type: String,
			default: null
		},
		rightButton: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		dropdown: {
			type: String,
			default: 'blank'
		},
		scrollHeight: {
			type: String,
			default: '200px'
		}
	},
	setup(props, {attrs, emit}) {
		const loading = ref(false);
		const focused = ref(false);

		const overlayRef = ref();
		const containerRef = ref();
		const inputRef = ref();
		const dropdownRef = ref();

		const expandedKeys = ref({});
		const selectedKeys = ref({
			//"ARP_ATO_CH01": true,
		});
		const selectedNodes = ref([]);

		const nodes = ref([]);

		const onNodeSelect = (node) => {
			const newNode = {...node, text: node.label, name: node.label};
			if (props.multiple) {
				selectedNodes.value.push(newNode);
			} else {
				selectedNodes.value[0] = newNode;
			}
			emit('item-select', {
				value: newNode,
				selected: selectedNodes.value
			});
		};

		const onNodeUnselect = (node) => {
			//toast.add({severity: 'warning', summary: 'Node unselected', detail: node.label, life: 3000});
			const newValue = selectedNodes.value.filter(e => e.key !== node.key);
			selectedNodes.value = newValue;
			emit('changed', {value: newValue});
			emit('update:value', newValue);
			emit('item-unselect', {
				value: node
			});
		};

		watch(() => props.results, () => {
			if (loading.value) {
				if (props.results && props.results.length) {
					showOverlay();
				} else {
					hideOverlay();
				}
				loading.value = false;
			}
		});

		onMounted(() => {
			if (props.value){
				// value vem assim:
				//"ARP_ATO_CH01 - CH01 - Galp Trading, SA, ARP_ATO_NL05 - NL05 - Galp Energia E&P, B.V."
				// Quando deveria vir como objecto, para ser só inserir nos selectedKeys e selectedNodes as respectivas keys e nodes
				// Vindo em String, tem que se extrair as keys 'a mao'
				const indexes = props.value.split(" - ");
				indexes.forEach(e => selectedKeys.value[e] = true);
				indexes.forEach(e => selectedNodes.value.push({
					key: e,
					label: e
				}));
			}
			API.get(props.field.tree).then(data => {
				nodes.value = data.tree;
			});
		});

		onBeforeUnmount(() => {
			removeOverlay();
		});

		const inputId = computed(() => {
			return props.id || componentId();
		});

		const inputPlaceholder = computed(() => {
			let placeholder = attrs.placeholder || '';
			if (props.value && props.value.length) placeholder = '';
			return placeholder;
		});

		const containerClass = computed(() => {
			// , 'input-icon'
			return ['input-suggestion', {
				'focus': focused.value,
				'open': overlayVisible.value,
				'disabled': props.disabled
			}];
		});

		const selectItem = (e, item, keepFocus = true) => {
			if (e) e.preventDefault();
			if (props.multiple) {
				inputRef.value.value = '';
				if (!isSelected(item)) {
					let newValue = props.value ? [...props.value, item] : [item];
					emit('update:value', newValue);
					emit('changed', {value: newValue});
				}
			} else {
				emit('update:value', item);
				emit('changed', {value: item});
			}
			emit('item-select', {
				originalEvent: e,
				value: item
			});
			hideOverlay();
			if (keepFocus) focus();
		};

		const removeItem = (e, i) => {
			const objKey = selectedNodes.value[i].key;
			delete selectedKeys.value[objKey];

			let removedValue =  selectedNodes.value[i];
			let newValue = selectedNodes.value.filter((val, ix) => (i !== ix));
			selectedNodes.value.splice(i, 1);
			emit('update:value', newValue);
			emit('changed', {value: newValue});
			emit('item-unselect', {
				originalEvent: e,
				value: removedValue
			});
		};

		const isSelected = (val) => {
			let selected = false;
			if (props.value && props.value.length) {
				for (let i = 0; i < props.value.length; i++) {
					if (ObjectUtils.equals(props.value[i], val)) {
						selected = true;
						break;
					}
				}
			}
			return selected;
		};

		const onFocus = () => {
			if (!props.disabled) focused.value = true;
		};

		const onBlur = () => {
			focused.value = false;

			// if (!props.multiple) {
			// 	// if (props.results.length === 1) {
			// 	// 	selectItem(null, props.results[0], false);
			// 	// }
			// 	const val = cfw.getProp(props.entry, props.field.src);
			// 	if (!cfw.isObject(val)) cfw.setProp(props.entry, props.field.src, null);
			// }

			// if (!overlayVisible.value && !props.multiple) {
			// 	props.value = null; // inputRef.value.value = '';
			// }
		};

		const onDropdownClick = (e) => {
			if (props.disabled) return;
			//focus();
			if (overlayVisible.value) {
				hideOverlay();
				return;
			}
			overlayVisible.value = true;
			emit('dropdown-click', {
				originalEvent: e
			});
		};

		const focus = () => {
			inputRef.value.focus();
		};

		const {
			overlayVisible,
			showOverlay,
			hideOverlay,
			onOverlayEnter,
			onOverlayLeave,
			removeOverlay
		} = useOverlay(overlayRef, containerRef, inputRef, dropdownRef);

		const displaySelected = (i) => {
			return selectedNodes.value[i].label;
		};

		const displayResult = (item) => {
			const f = 'text';
			return f ? ObjectUtils.resolveFieldData(item, f) : item;
		};

		const dropdownClass = computed(() => {
			return ['append', {'disabled': props.disabled}];
		});

		return {
			inputId,
			inputPlaceholder,
			overlayRef,
			containerRef,
			inputRef,
			dropdownRef,
			containerClass,
			loading,
			onFocus,
			onBlur,
			onDropdownClick,
			selectItem,
			removeItem,
			displaySelected,
			displayResult,
			overlayVisible,
			onOverlayEnter,
			onOverlayLeave,
			dropdownClass,
			expandedKeys,
			selectedKeys,
			onNodeSelect,
			onNodeUnselect,
			selectedNodes,
			nodes
		};
	}
});
</script>

<style lang="scss">
.tree-overlay {
	padding: $padding
}
</style>
