import cfw from './cfw';
import {Parser, Evaluator} from './parser';

// Gross price which is material price
// NET PRICE which is material price + discount( negative in nature).
// Effective price will be material price + discount ( negative in nature)+ P & F Charge + Insurance + Delievery Cost + Taxes.
const calc = [
	{"src": "grossValue", "expr": "{price} * {quantity}", "dataType": "number", "round": 2},
	{"src": "discountValue", "expr": "({price} * {discount.percent}) * {quantity} + {discount.value}", "dataType": "number", "round": 2},
	{"src": "listValue", "expr": "{grossValue} - {discountValue}", "dataType": "number", "round": 2},
	{"src": "costValue", "expr": "({price} * {cost.percent}) * {quantity} + {cost.value}", "dataType": "number", "round": 2},
	{"src": "netValue", "expr": "{grossValue} - {discountValue} + {costValue}", "dataType": "number", "round": 2},
	{"src": "taxValue", "expr": "{netValue} * {tax.value}", "dataType": "number", "round": 2},
	{"src": "value", "expr": "{netValue} + {taxValue}", "dataType": "number", "round": 2}
];

const priceInfo = [
	{"_id": "RA01", "text": "RA01 - Desc.% preço bruto", "type": "discount", "percent": true},
	// {"_id": "RA00", "text": "RA00 - Desconto % prç.líq.", "type": "discount", "percent": true},
	{"_id": "RB00", "text": "RB00 - Desconto absoluto", "type": "discount"},
	{"_id": "ZFA1", "text": "ZFA1 - Frete %", "type": "cost", "percent": true},
	{"_id": "ZFB1", "text": "NZFB1 - Frete absoluto", "type": "cost"},
	{"_id": "Z030", "text": "Z030 - Custos complementares diversos", "type": "cost"}
];

const calcExpr = (c, entry, vars = {}, returnValue = false) => {
	const expr = c.expr;
	const dataType = c.dataType || 'number';
	const fields = expr.match(/\{.*?\}/g).map(e => e.substr(1, e.length - 2));
	const obj = {};
	fields.forEach(f => {
		obj[f] = cfw.getProp(vars, f);
		if (cfw.isEmpty(obj[f])) obj[f] = cfw.getProp(entry, f) || 0;
	});
	let formula = expr;
	fields.forEach(f => {
		const find = `{${f}}`;
		formula = formula.replace(find, obj[f]);
	});
	if (dataType === 'number') {
		let result = new Evaluator(new Parser().parse(formula)).evaluate();
		if (cfw.isNumber(result)) {
			result = cfw.roundFix(result, c.round);
			const typedValue = cfw.applyType(result, dataType);
			if (returnValue) {
				return typedValue;
			} else {
				cfw.setProp(entry, c.src, typedValue);
			}
		}
		if (returnValue) return 0;
	}
};

const calcLine = (type, line, linePriceInfo = null) => {
	const vars = {
		discount: {
			percent: 0,
			value: 0
		},
		cost: {
			percent: 0,
			value: 0
		},
		tax: {
			value: 0
		}
	};
	['quantity', 'price'].forEach(prop => {
		if (!line[prop]) line[prop] = 0;
		if (!cfw.isNumber(line[prop])) line[prop] = parseFloat(line[prop]);
	});
	if (!linePriceInfo) linePriceInfo = line.priceInfo;
	if (line.upfrontPayment && line.price > 0) line.price = -1 * line.price;
	if (type === 'order') {
		if (linePriceInfo && linePriceInfo.length > 0) {
			linePriceInfo.forEach(pr => {
				const pi = priceInfo.find(e => e._id === pr.condition);
				if (pi.percent) {
					vars[pi.type].percent += cfw.roundFix(pr.value / 100, 5);
				} else {
					if (!line.lineQuantity || line.lineQuantity === line.quantity) {
						vars[pi.type].value += cfw.roundFix(pr.value, 2);
					} else {
						const absoluteDiscountPerUnit = line.lineQuantity > 0 ? pr.value / line.lineQuantity : 0;
						const discountValue = absoluteDiscountPerUnit * line.quantity > pr.value
							? pr.value
							: absoluteDiscountPerUnit * line.quantity;
						vars[pi.type].value += cfw.roundFix(discountValue, 2);
					}
				}
			});
		}
		if (line.tax) vars.tax.value = line.tax.value;
	}
	const deleted = line._state ? (line._state.current === 'deleted') : false;
	calc.forEach(c => {
		if (deleted) {
			line[c.src] = 0;
		} else {
			calcExpr(c, line, vars);
		}
		if (c.src === 'listValue' && type === 'order') {
			// const d = cfw.countDecimals(line.listValue);
			// const dp = cfw.countDecimals(line.price);
			line.listPrice = linePriceInfo && linePriceInfo.length > 0
				? cfw.roundFix(line.listValue / line.quantity, 2) // d
				: line.price; // cfw.roundFix(line.price, dp)
		}
	});
};

export {
	calc,
	calcExpr,
	calcLine
};
