<template>
	<div class="main-container">
		<div>
			<GoogleMap
				ref="mapRef"
				:api-key="GOOGLE_MAPS_API_KEY"
				class="map"
				style="width: 100%; height: 90vh"
				:center="markersLocations.length === 1 ? markersLocations[0] : DEFAULT_CENTER_LOCATION"
				:zoom="markersLocations.length === 1 ? DEFAULT_ZOOM_IF_SINGLE : DEFAULT_ZOOM_IF_MULTIPLE"
				language="pt"
			>
			</GoogleMap>
		</div>
		<div v-show="showInfoContainer" class="info-container">
			<div class="close-icon">
				<v-button @click="showInfoContainer = false" v-if="true" :aria-label="ariaCloseLabel" layout="close"/>
			</div>

			<div class="row tabs text-muted">
				<div class="col-sm-3 tab" :class="{'tab-selected': isShowingMainTab}" @click="isShowingMainTab = true">
					{{i18n`Global`}}
				</div>
				<div v-if="isShowingRoute" class="col-sm-9 tab" :class="{'tab-selected': !isShowingMainTab}" @click="isShowingMainTab = false">
					{{i18n`Rota Sugerida`}}
				</div>
			</div>

			<div class="divider-bottom"></div>

			<div class="info-container-block">
				<div v-if="isShowingMainTab">
					<div class="row">
						<div class="col-sm-3 text-muted">
							{{i18n`Name`}}
						</div>
						<div class="col-sm-9">
							{{markersData[currentMarker].name}}
						</div>
					</div>
					<div class="row">
						<div class="col-sm-3 text-muted">
							{{i18n`Address`}}
						</div>
						<div class="col-sm-9">
							{{markersData[currentMarker].address ? markersData[currentMarker].address.formatted : markersData[currentMarker].deliveryAddress.formatted}}
						</div>
					</div>
					<v-divider></v-divider>
					<v-button layout="primary" icon="route" :text="i18n`Sugerir rota`" @click="openRouteSuggestionDialog"></v-button>
					<v-divider></v-divider>
					<v-button layout="link" icon="mobile-alt" :text="i18n`Enviar para o telemóvel`" @click="sendByPhone = true"></v-button>
					<v-button layout="link" icon="envelope" :text="i18n`Enviar por email`"  @click="sendByMail = true"></v-button>
					<v-button layout="link" icon="download" :text="i18n`Download`" @click="showDownloadDialog = true"></v-button>
				</div>
				<div v-show="!isShowingMainTab" >
					<div id="directions-panel"/>
				</div>
			</div>
		</div>
	</div>
	<v-dialog
		:visible="sendByMail || sendByPhone"
		:header="sendByMail ? i18n`Confirm email` : i18n`Confirm`"
		:maximizable="false"
		:position="center"
		:closable="false"
	>
		<p>{{i18n`Escolha o que quer enviar ${sendByMail ? 'por email' : 'para o seu telemóvel'}:`}} </p>

		<p>
		<input v-model="sendOption" type="radio" id="route" name="send_option" v-bind:value="'send_route'" checked>
		<label for="route"> {{i18n`Route`}} </label><br>
		<input v-model="sendOption" type="radio" id="deliveryLoc" name="send_option" v-bind:value="'send_deliveryLoc'">
		<label for="deliveryLoc"> {{i18n`Local de entrega`}} </label><br></p>

		<p v-if="sendByPhone"> {{i18n`Se aceitar, vai ser redirecionado para o website do Google Maps para enviar`}}
			{{sendOption == 'send_deliveryLoc'
			? i18n`a localização do local de entrega selecionado`
			: i18n`as direcções da rota escolhida`}} {{i18n` para o seu telemóvel.`}}</p>

		<p v-if="sendByPhone" class="text-muted">
			{{i18n`O botão para enviar para o telemóvel pode ser encontrado na barra lateral esquerda. Tem de ter a sessão iniciada na sua conta Google.`}}
		</p>

		<p v-if="sendByMail">
			{{i18n`Introduza o endereço de email para o qual quer enviar a localização`}}
			{{sendOption == 'send_deliveryLoc' ? i18n`do local de entrega selecionado.` : i18n`da rota escolhida.`}}
			<br>
			<input v-model="sendToMail" type="text">
		</p>

		<template #footer>
			<v-button :text="sendByPhone ? i18n`Accept` : i18n`Send`" layout="primary" @click="processSendBy" />
			<v-button :text="i18n`Cancel`" layout="secondary" @click="sendByMail ? sendByMail = false : sendByPhone = false" autofocus />
		 </template>
	</v-dialog>

	<v-dialog
		:visible="showRouteSuggestionDialog"
		:header="i18n`Insira o seu ponto de partida`"
		:maximizable="false"
		:position="center"
		:closable="false"
	>

		<p>{{i18n`Escolha o seu ponto de partida:`}} </p>
			<input v-model="initialPoint" type="radio" id="route" name="initialPoint" v-bind:value="'current'" checked>
			<label for="html"> {{i18n`Geolocation`}} </label><br>
			<input v-model="initialPoint" type="radio" id="deliveryLoc" name="initialPoint" v-bind:value="'address'">
			<label for="html"> {{i18n`Address`}} </label><br>
			<input type="text" v-show="initialPoint === 'address'" id="autoCompleteField">

		<template #footer>
				<v-button :text="i18n`Submit`" :disabled="initialPoint === 'address' && specificAddress === null"  layout="primary" @click="calculateRoute"/>
				<v-button :text="i18n`Cancel`" layout="secondary" @click="showRouteSuggestionDialog = false" autofocus />
			</template>

	</v-dialog>

	<v-dialog
		:visible="showDownloadDialog"
		:header="i18n`Confirm download`"
		:maximizable="false"
		:position="center"
		:closable="false"
	>

		<p>{{i18n`Escolha qual o ficheiro KML quer fazer download:`}} </p>
			<input v-model="downloadFile" type="radio" id="route" name="downloadFile" v-bind:value="'send_route'" checked>
			<label for="html"> {{i18n`Route`}} </label><br>
			<input v-model="downloadFile" type="radio" id="deliveryLoc" name="downloadFile" v-bind:value="'send_deliveryLoc'">
			<label for="html"> {{i18n`Local de entrega`}} </label><br>
		<p></p>
			<v-button :text="downloadFile === 'send_deliveryLoc' ? i18n`Download do ficheiro KML do local de entrega` : i18n`Download do ficheiro KML da rota`"
				layout="secondary" @click="processDownloadFile" />

		<template #footer>
				<v-button :text="i18n`Cancel`" layout="primary" @click="showDownloadDialog = false" autofocus />
		</template>

	</v-dialog>

</template>

<script>
/* eslint-disable- no-undef */
import {defineComponent , computed, onMounted, ref} from 'vue';
import {GoogleMap} from 'vue3-google-map';
import {useMessages} from '../app';
import {useGeolocation} from '../use/geolocation';
// import {useRouter, useRoute} from "vue-router";
import {API} from '../utils';
import {MarkerClusterer} from "@googlemaps/markerclusterer";

export default defineComponent({
	name: 'App',
	components: {GoogleMap},
	props: {
		markersData: { //Information about the markers
			type: Array
		},
		markersLocations:{ //Markers locations already formated to LatLng
			type: Array
		}
	},
	setup(props) {
		const GOOGLE_MAPS_API_KEY = "AIzaSyCwzKoBoyEmLyxxLeYG-cElfm12BjZl6Jo";
		const DEFAULT_CENTER_LOCATION = {lat: 38.736946, lng: -9.142685};
		const DEFAULT_ZOOM_IF_SINGLE = 15;
		const DEFAULT_ZOOM_IF_MULTIPLE = 10;

		//Uses
		// const router = useRouter();
		// const route = useRoute();
		const msg = useMessages();

		//DOM Refs
		const mapRef = ref(null);
		const autoCompleteRef = ref();

		//Other refs
		const currentMarker = ref(null);

		const sendByMail = ref(false);
		const sendByPhone = ref(false);
		const sendOption = ref("send_route");
		const sendToMail = ref("");

		const showInfoContainer = ref(false);
		const showRouteSuggestionDialog = ref(false);
		const initialPoint = ref("current");
		const specificAddress = ref(null);
		const isShowingRoute = ref(false);

		const isShowingMainTab = ref(null);

		const downloadFile = ref("current");
		const showDownloadDialog = ref(false);

		// Google Maps Services, defined at onMounted
		let directionsService = null;
		let directionsRenderer = null;
		let autoComplete = null;

		const sendFields = ref([
			{"src": "send_route", "label": "Rota", "type": "checkbox"},
			{"src": "send_destination", "label": "Local de entrega", "type": "checkbox"}
		]);

		// Current Geolocation
		const {coords} = useGeolocation();
		const currPos = computed (() => ({
			lat: coords.value.latitude,
			lng: coords.value.longitude
		}));

		function onMarkerClick(i) {
			showInfoContainer.value = true;
			isShowingMainTab.value = true;

			//First initialization
			if (isShowingMainTab.value === null) isShowingMainTab.value = true;

			currentMarker.value = i;
		}

		//To wait for Google Maps API to get ready
		function sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}

		const calculateRoute = () => {
			let originValue = null;

			initialPoint.value === 'current' ? originValue = {...currPos.value} : originValue = autoComplete.getPlace().geometry.location;

			directionsService.route({
				origin: originValue,
				destination: props.markersLocations[currentMarker.value],
				travelMode: mapRef.value.api.TravelMode.DRIVING
			}).then(response => {
				showRouteSuggestionDialog.value = false;
				directionsRenderer.setDirections(response);
				isShowingRoute.value = true;
			});

			isShowingMainTab.value = false;
		};

		function openRouteSuggestionDialog() {
			showRouteSuggestionDialog.value = true;

			sleep(1).then(() => {
				autoComplete = new google.maps.places.Autocomplete(
					document.getElementById("autoCompleteField"),
					{
						//componentRestrictions: {'country': ['PT']},
						fields: ['place_id', 'geometry', 'name']
					}
				);

				autoComplete.addListener('place_changed', () => {
					var place = autoComplete.getPlace();
					if (place.geometry) specificAddress.value = place;
				});
			});
		}

		async function sendEmail(to, content){
			const data = {
				"to":to,
				"subject": "GeoLocation Link",
				"html":"<h1>Mail</h1><p>Link para o mapa:</p>" + content
			};
			await API.post('/api/notifications/sendMail', data);
		}

		async function processSendBy(){
			if(sendOption.value === "send_deliveryLoc"){
				const url='https://www.google.com/maps/place/' + props.markersLocations[currentMarker.value].lat + "," + props.markersLocations[currentMarker.value].lng;
				if(sendByPhone.value)
					window.open(url, '_blank').focus();
				else
					sendEmail(sendToMail.value, url);
			}
			else{
				if(!isShowingRoute.value)
					msg.warn("Nenhuma rota calculada. Carregue no botão 'Sugerir Rota'.");
				else{
					const url='https://www.google.com/maps/dir/'
								+ directionsRenderer.getDirections().request.origin.location.lat()
								+ "," + directionsRenderer.getDirections().request.origin.location.lng()
								+ "/" + props.markersLocations[currentMarker.value].lat
								+ "," + props.markersLocations[currentMarker.value].lng;
					if(sendByPhone.value)
						window.open(url, '_blank').focus();
					else
						sendEmail(sendToMail.value, url);
				}
			}
			sendByMail.value = sendByPhone.value = false;
		}

		// If Value length = 1 - localizacao da entrega; If length = 3, route
		const generateKml = (value) => {
			if (value.length === 1)
				return `<?xml version='1.0' encoding='UTF-8'?>
							<kml xmlns='http://www.opengis.net/kml/2.2'>
								<Document>
									<Placemark>
										<name>Local de entrega</name>
										<description>Local de entrega</description>
										<Point>
											<coordinates>${value[0].lng},${value[0].lat},0.</coordinates>
										</Point>
									</Placemark>
								</Document>
							</kml>`;
			else
				return `<?xml version='1.0' encoding='UTF-8'?>
							<kml xmlns='http://www.opengis.net/kml/2.2'>
								<Document>
									<Style id='path'>
										<LineStyle>
											<color>FF0000FF</color>
											<width>3</width>
										</LineStyle>
									</Style>
									<Placemark>
										<name>Ponto de partida</name>
										<description>Localização do utilizador</description>
										<Point>
											<coordinates>${value[0].lng()},${value[0].lat()},0.</coordinates>
										</Point>
									</Placemark>
									<Placemark>
										<name>Ponto de chegada</name>
										<description>Fim da rota</description>
										<Point>
											<coordinates>${value[1].lng()},${value[1].lat()},0.</coordinates>
										</Point>
									</Placemark>
									<Placemark>
										<name>Rota sugerida</name>
										<description>O seu percurso</description>
										<styleUrl>#path</styleUrl>
										<LineString>
											<coordinates>${value[2]}</coordinates>
										</LineString>
									</Placemark>
								</Document>
							</kml>`;
		};

		function processDownloadFile(){
			let value = null;
			let fileName = "";
			if(downloadFile.value === "send_deliveryLoc"){
				fileName = "plant.kml";
				value = [props.markersLocations[currentMarker.value]]; //String com pos da entrega
			}
			else{
				if(isShowingRoute.value){
					let pointsString =   directionsRenderer.directions.request.origin.location.lng() + ',' + directionsRenderer.directions.request.origin.location.lat()+',0. ';
					directionsRenderer.directions.routes[0].legs.forEach(leg => leg.steps.forEach(step => step.path.forEach( path => pointsString += path.lng() +','+path.lat()+',0. ')));
					fileName = "route.kml";
					value = [directionsRenderer.directions.request.origin.location,directionsRenderer.directions.request.destination.location,pointsString]; //loc init, loc fim, string com linhas

				}
				else
					msg.warn("Nenhuma rota calculada. Carregue no botão 'Sugerir Rota' primeiro, para poder fazer o download da mesma.");
			}
			showDownloadDialog.value = false;

			const data = generateKml(value);

			var element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
			element.setAttribute('download', fileName);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		}

		onMounted(async () => {
			sleep(2000).then(() => {
				// Directions
				directionsService = new mapRef.value.api.DirectionsService();
				directionsRenderer = new mapRef.value.api.DirectionsRenderer({
					draggable: false
				});

				// Markers
				const markers = props.markersLocations.map((position, i) => {
					const pos = {...position};
					const marker = new mapRef.value.api.Marker({
						position:pos,
						map: mapRef.value.map
					});

					marker.addListener("click", () => {
						onMarkerClick(i);
					});

					return marker;
				});

				// Cluster
				const mapVal = mapRef.value.map;
				const m = new MarkerClusterer({markers, map: mapVal});
				// TODO

				directionsRenderer.setMap(mapRef.value.map);
				directionsRenderer.setPanel(document.getElementById("directions-panel")); //Set the Directions Panel
			});
		});

		return {
			currPos,
			DEFAULT_CENTER_LOCATION,
			DEFAULT_ZOOM_IF_SINGLE,
			DEFAULT_ZOOM_IF_MULTIPLE,
			GOOGLE_MAPS_API_KEY,
			showInfoContainer,
			mapRef,
			sendByMail,
			sendByPhone,
			sendFields,
			sendOption,
			sendToMail,
			showRouteSuggestionDialog,
			openRouteSuggestionDialog,
			initialPoint,
			calculateRoute,
			autoCompleteRef,
			specificAddress,
			processSendBy,
			isShowingMainTab,
			isShowingRoute,
			showDownloadDialog,
			downloadFile,
			processDownloadFile,
			onMarkerClick,
			currentMarker
		};
	}
});
</script>

<style lang="scss">
.main-container{
	position:relative;
}

.info-container{
	width: 300px;
	height: 100%;
	position: absolute;
	right: 0px;
	top: 0px;
	background: white;
}

.close-icon{
	float: right;
	margin: 5px;
}

.info-container-block{
	clear:both;
	padding: $padding-xxl;
	padding-bottom:7*$padding-xxl;
	height:100%;
	overflow-y: scroll;
}

.pac-container {
	z-index: 1051 !important;
}

.tabs {
	font-weight: 600;
	padding: $padding;
	margin-top: $padding;


}

.tab {
	&:hover {
		color: $gray-900;
		cursor: pointer
	}
}

.tab-selected {
	color: $gray-900
}


</style>
