<template>
	<div class="note-add">
		<div :class="containerClass" @dragover="onDragover" @dragleave="onDragleave" @drop="onDrop">
			<input type="file" ref="fileRef" multiple @change="handleFiles"/>
			<textarea ref="textRef" v-model="data.text" :placeholder="i18n`Write your message here...`" class="resizable" autocomplete="off" rows="1"
				@input="onInput" @focus="onFocus" @blur="onBlur" @keydown="onKeyDown" @paste="onPaste"/>
			<div class="file-list" v-if="files.length > 0">
				<div v-for="(file, i) in files" :key="i" class="file">
					<!-- <img v-if="file.content" class="image" :src="file.content" :title="file.name"> -->
					<div v-if="file.content" class="btn-download">
						<img :src="file.content"/>
						<span class="text-muted">{{file.name}}</span>
					</div>
					<span v-else class="text-muted">{{file.name}}</span>
					<v-button @click="removeFile(i)" icon="close" :label="i18n`Remove`" layout="link"/>
				</div>
			</div>
			<div class="handler">
				<div v-if="isPurchasing" class="target">
					<div class="target-select">
						<label>Visibility: </label>
						<v-dropdown :itemList="toList" :defaultItemId="toSelected" @selected="(item) => fillData('to', item)"/>
					</div>
					<div class="target-select">
						<label>Concerning: </label>
						<v-dropdown :itemList="lineList" :defaultItemId="lineSelected" @selected="(item) => fillData('line', item)"/>
					</div>
				</div>
				<div class="buttons">
					<v-button v-if="btnFiles === 'icon'" class="files" layout="icon" @click="addFiles" icon="attach" :label="i18n`Add files`"/>
					<v-button v-else-if="btnFiles !== 'none'" class="files" layout="link" @click="addFiles" icon="attach" :text="i18n`Add files`"/>
					<v-button v-if="btnSubmit === 'icon'" class="submit" layout="icon" :disabled="submitDisabled" @click="submit" icon="paper-plane" :label="i18n`Submit`"/>
					<v-button v-else-if="btnSubmit !== 'none'" class="submit" layout="link" :disabled="submitDisabled" @click="submit" icon="paper-plane" :text="i18n`Submit`"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed, onMounted} from 'vue';
import {useTextarea} from '../use/input';
import {isPurchasingModel} from '../use/model';
import {cfw, API, Logger} from '../utils';

import {useMessages, isSupplier, i18n} from '../app';

export default defineComponent({
	name: 'post-message',
	emits: ['changed', 'before-post', 'posted'],
	props: {
		model: {
			type: String,
			required: true
		},
		id: {
			type: String,
			required: true
		},
		maxFileSize: {
			type: Number
		},
		extensions: {
			type: String
		},
		entry: Object,
		defaultData: Object,
		btnFiles: {
			type: String,
			default: 'link'
		},
		btnSubmit: {
			type: String,
			default: 'link'
		}
	},
	setup(props, {emit}) {
		const textRef = ref();
		const fileRef = ref();
		const files = ref([]);
		const msg = useMessages();
		const focused = ref(false);
		const over = ref(false);

		const data = ref({
			text: null,
			assignees: [],
			to: isSupplier() ? 'supplier' : 'requester'
		});

		const uploading = ref(false);
		const submitDisabled = computed(() => uploading.value || (!data.value.text && files.value.length === 0));

		const isPurchasing = computed(() => {
			let p = false;
			if (props.entry && isPurchasingModel(props.entry)) p = true;
			return p;
		});

		const defaultVisibility = computed(() => {
			if (props.model === 'rfx') return '_all';
			return isSupplier() ? 'supplier' : 'requester';
		});

		// TODO
		const toList = ref(isSupplier() ? [
			{id: 'supplier', value: 'External'}
		]: [
			{id: '_all', value: 'Everyone'},
			{id: 'requester', value: 'Internal'},
			{id: 'supplier', value: 'External'}
		]);
		const toSelected = ref(defaultVisibility);

		const lineList = ref([
			{id: '_all', value: 'All document'}
		]);
		const lineSelected = ref('_all');

		const fillData = (prop, item) => {
			if (!item) return;
			if (item.id === '_all') {
				data.value[prop] = '_all';
			} else {
				data.value[prop] = prop === 'to' ? item.id : {_id: item.id, label: item.value};
			}
		};

		onMounted(() => {
			files.value = [];
			if (props.defaultData) {
				data.value.text = props.defaultData.text;
				files.value = props.defaultData.files;
				_changed();
			}
			if (isPurchasing.value) {
				props.entry.lines.forEach(line => {
					let lineText = `Item ${line.doc.pos}`;
					if (props.entry.doc.type === 'contract') lineText += `.${line.doc.subpos}`;
					lineList.value.push({id: line._id, value: lineText});
				});
			}

			fillData('to', toList.value.find(item => item.id === toSelected.value));
		});

		const {resize} = useTextarea();

		const containerClass = computed(() => {
			return ['input-comment', {
				'focus': focused.value,
				'over': over.value
			}];
		});

		const onInput = (e) => {
			resize(e.target);
		};

		const onFocus = () => {
			focused.value = true;
		};

		const onBlur = () => {
			focused.value = false;
			_changed();
		};

		const onKeyDown = (e) => {
			switch(e.which) {
				// enter
				case 13:
					console.log('send');
					break;

				// tab
				case 9:
					break;

				default:
					break;
			}
		};

		const onPaste = (e) => {
			const items = e.clipboardData?.items;
			if (!items) return;

			const files = [];

			for (let item of items) {
				const file = item.getAsFile();
				if (file) files.push(file);
			}

			if (files.length === 0) {
				resize(e.target);
				return;
			}

			e.preventDefault();
			addValidFiles(files);
		};

		const onDrop = (e) => {
			e.preventDefault();
			const files = e.dataTransfer.files;
			addValidFiles(files);
			focused.value = false;
			over.value = false;
		};

		const onDragover = (e) => {
			e.preventDefault();
			focused.value = true;
			over.value = true;
		};

		const onDragleave = () => {
			focused.value = false;
			over.value = false;
		};

		const addFile = (file) => {
			if (!/^image/.test(file.type)) {
				files.value.push(file);
				return;
			}

			const reader = new FileReader();

			reader.onload = e => {
				file.content = e.target.result;
				files.value.push(file);
			};

			reader.readAsDataURL(file);
		};

		const addFiles = () => {
			fileRef.value.click();
		};

		const removeFile = (i) => {
			files.value.splice(i, 1);
			_changed();
		};

		const isValidExtension = (ext) => {
			if (!props.extensions) return true;
			const allowedExt = props.extensions.split(',').map(e => e.toLowerCase());
			return allowedExt.includes(ext.toLowerCase());
		};

		const handleFiles = () => {
			addValidFiles(fileRef.value.files);
			// fileRef.value.value = ''; // to allow uploading the same file twice
		};

		const addValidFiles = (files) => {
			let canUpload = true;
			const maxFileSize = props.maxFileSize || 0;
			if (files.length === 0) return;
			let errors = [];

			for (let file of files) {
				const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
				if (maxFileSize > 0 && file.size > maxFileSize) {
					errors.push(i18n`File size of ${cfw.formatBytes(file.size)} exceeds the limit of ${cfw.formatBytes(maxFileSize)} allowed.`);
					canUpload = false;
				} else if (!isValidExtension(fileExt)) {
					errors.push(i18n`The file extension '${fileExt}' is not allowed. Allowed extensions: ${props.extensions}.`);
					canUpload = false;
				}
			}

			if (!canUpload) {
				errors = [...new Set(errors)];
				msg.fail(errors.join('<br>'));
				return;
			}

			files.forEach(file => {
				addFile(file);
			});

			_changed();
		};

		const _changed = () => {
			emit('changed', {
				data: data,
				files: files
			});
		};

		const submit = async () => {
			emit('before-post');

			if (submitDisabled.value) {
				emit('posted', false);
				return;
			}

			preventVisibility();
			uploading.value = true;
			const postdata = new FormData();
			// postdata.append('text', data.value.text);
			// postdata.append('assignees', JSON.stringify(data.value.assignees));
			const linkedFiles = [];
			postdata.append('log', JSON.stringify(data.value));
			for (let i = 0; i < files.value.length; i++) {
				const file = files.value[i];
				if (file._id) {
					linkedFiles.push(file._id);
				} else {
					postdata.append(`files[${i}]`, file);
				}
			}
			postdata.append('linkedFiles', JSON.stringify(linkedFiles));
			const url = `/notifications/${props.model}/${props.id}`;
			try {
				await API.post(url, postdata, true);
				files.value = [];
				data.value.text = null;
				textRef.value.value = '';
				resize(textRef.value);
				_changed();
				emit('posted', true);
				uploading.value = false;
			} catch (err) {
				Logger.error(err);
				emit('posted', false);
				uploading.value = false;
			}
		};

		const preventVisibility = () => {
			const item = toList.value && toList.value.find(i => i && i.id === data.value.to);
			if (item) fillData('to', item);
		};

		return {
			fileRef,
			textRef,
			containerClass,
			onInput,
			onFocus,
			onBlur,
			onKeyDown,
			onPaste,
			onDragover,
			onDragleave,
			onDrop,
			addFiles,
			handleFiles,
			submit,
			files,
			removeFile,
			data,
			submitDisabled,
			isPurchasing,
			toList,
			toSelected,
			lineList,
			lineSelected,
			fillData
		};
	}
});
</script>

<style lang="scss">
input[type="file"] {
	position: absolute;
	top: -500px;
}

.drawer-body {
	.note-add {
		margin: 0 0 $padding-xl 0;
	}
	.handler {
		display: block;

		.file-list {
			width: 100%;
		}

		.buttons {
			display: flex;
			align-items: flex-end;
		}

		button.files {
			margin-left: auto;
			margin-right: 0;
		}
	}
}
</style>
