/**
 * if we need localdb storage (localforage), uncomment
 * +/- 100KB
 */
const _localDB = false;
// import localforage from 'localforage';
// import addFind from 'localforage-find';
// addFind(localforage);

import {APIError} from './error';
import {setUser, setOffline} from '../app';
import cfw from './cfw';

const CLEAN_ALL = [
	'lang',
	'group',
	'location',
	'plant'
];

const CLEAN_MODEL = [
	'app',
	'lang',
	'group',
	'location',
	'plant',
	'token',
	'navigation',
	'navigation-current',
	'routes',
	'translations',
	'user',
	'version',
	'workgroup'
];

// https://dmitripavlutin.com/timeout-fetch-request/
const fetchWithTimeout = async (resource, options = {}) => {
	const {timeout = 60000} = options;
	const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	const response = await fetch(resource, {
		...options,
		signal: controller.signal
	});
	clearTimeout(id);
	return response;
};

const _fetch = async (url, options = {}) => {
	if (options.data) {
		options.mode = 'cors';
		if (options.formData === true) {
			options.method = 'POST';
			options.body = options.data;
		} else {
			options.headers = options.headers || {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			};
			options.method = options.method || 'POST';
			options.body = JSON.stringify(options.data);
		}
		delete options.data;
		delete options.formData;
	} else {
		if (!options.hasOwnProperty('method')) options.method = 'GET';
	}

	const status = options.status || false;
	delete options.status;

	const token = _local.get('token');
	const env = _local.get('env');

	// TODO
	let ua = {}, id = null;
	if (_local.get('group')) {
		id = _local.get('group')._id;
		if (id) ua.group = id;
	}
	if (_local.get('location')) ua.location = _local.get('location')._id.toString();
	if (_local.get('plant')) ua.plant = _local.get('plant')._id.toString();
	if (_local.get('workgroup')) {
		id = _local.get('workgroup')._id;
		if (id) ua.workgroup = id;
	}
	if (Object.keys(ua).length > 0) {
		ua = cfw.base64encode(JSON.stringify(ua));
	} else {
		ua = null;
	}

	// TODO
	if (token || env) {
		options.headers = options.headers || {};
		if (token) options.headers.Authorization = 'Bearer ' + token;
		if (env) options.headers.env = env;
		if (ua) options.headers.ua = ua;
		const u = _local.get('user');
		if (u) options.headers.username = u.username;
	}

	let type = options.type || 'json';
	const cache = options.cache || false;
	delete options.cache;

	const base64 = options.base64 || false;
	delete options.base64;

	let error = false;

	setOffline(false);
	try {
		const res = await fetchWithTimeout(url, options);
		Logger.trace(`${options.method} ${url} ${res.status}`);
		const ct = res.headers.get('Content-Type');
		if (base64 && res.status === 200) {
			const buffer = await res.arrayBuffer();
			const base64Data = `data:${ct};base64,${cfw.arrayBufferToBase64(buffer)}`;
			return base64Data;
		}
		if (ct && (ct.match(/application\/json/i) || ct.match(/text\//i))) {
			type = ct.match(/text\//i) ? 'text' : (ct.match(/application\/json/i) ? 'json' : null);
		}
		if (res.status === 202) type = 'text';
		if (res.status >= 400) error = `${res.status} ${res.statusText}`;
		const data = type === 'text' ? await res.text() : await res.json();
		if (error) throw new APIError({message: error, data: data, status: res.status});
		if (cache && cache.type === 'localStorage') {
			_local.set(cache.key, data);
		} else if (cache && cache.type === 'memory') {
			_local.cache.set(cache.key, data);
		}
		return status ? {status: res.status, data: data} : data;
	} catch (err) {
		if ([401].includes(err.status)) {
			setUser(false);
		} else if ([502, 503].includes(err.status) || /NetworkError/.test(err.message)) {
			setOffline(true);
		} else {
			throw err;
		}
	}
};

const _match = (path) => {
	let p = _local.api_url;
	if (_local.overrides.length > 0) {
		_local.overrides.some(e => {
			const re = new RegExp(e.path);
			const match = re.test(path);
			if (match) p = e.url;
			return match;
		});
	}
	return p;
};

const _url = (url) => {
	if (/(http(s?)):\/\//i.test(url)) return url;
	let path = url.split('?');
	path = path[0];
	// let p = '/ihub/s4g/purchasing';
	// // p = p.replace(/\//g, '/');
	// const re = new RegExp(p);
	const api_path = _match(path); // _local.overrides[path] || _local.api_url;
	return api_path.replace(/\/$/, '') + '/' + url.replace(/^\/api/, '').replace(/^\//, '');
};

/* const setStorage = (key, value) => {
	try {
		localStorage.setItem(key, value);
		localStorage.setItem('lastStore', new Date().getTime());
		const storageSize = Math.round(JSON.stringify(localStorage).length / 1024);
		console.log(storageSize);
	} catch(err) {
		console.log(err);
		if (err.code === 22) {
			// we've hit our local storage limit! lets remove 1/3rd of the entries (hopefully chronologically)
			// and try again... If we fail to remove entries, lets silently give up
			console.log('Local storage capacity reached.');

			// let maxLength = localStorage.length, reduceBy = ~~(maxLength / 3);

			// for (let i = 0; i < reduceBy; i++) {
			// 	if (localStorage.key(0)) {
			// 		localStorage.removeItem(localStorage.key(0));
			// 	} else {
			// 		break;
			// 	}
			// }

			// if (localStorage.length < maxLength) {
			// 	console.log('Cache data reduced to fit new entries. (' + maxLength + ' => ' + localStorage.length + ')');
			// 	setStorage(key, value);
			// } else {
			// 	console.log('Could not reduce cache size. Removing session cache setting from this instance.');
			// }
		}
	}
}; */

const _local = {
	id: null,
	api_url: '/api',
	overrides: [],
	db: {},
	cache: new Map(),
	get: (key) => {
		key = _local.id + '.' + key;
		const strVal = localStorage.getItem(key);
		let val = null;
		try {
			val = JSON.parse(strVal);
		} catch (e) {
			val = strVal;
		}
		return val || null;
	},
	set: (key, value) => {
		key = _local.id + '.' + key;
		// try {
		localStorage.setItem(key, JSON.stringify(value));
		// 	localStorage.setItem('lastStore', new Date().getTime());
		// 	const size = Math.round(JSON.stringify(localStorage).length / 1024);
		// 	console.log(size);
		// } catch(err) {
		// 	console.log(err);
		// }
	},
	clear: (key) => {
		key = _local.id + '.' + key;
		localStorage.removeItem(key);
	},
	empty: () => {
		Object.keys(localStorage).forEach(key => {
			const k = key.split('.');
			if (k[0] === _local.id) localStorage.removeItem(key);
		});
	},
	clean: (modelsOnly = false) => {
		const ignore = modelsOnly ? CLEAN_MODEL : CLEAN_ALL;
		Object.keys(localStorage).forEach(key => {
			const k = key.split('.');
			if (k[0] === _local.id) {
				if (!ignore.includes(k[1])) {
					localStorage.removeItem(key);
					Logger.trace(`clean: ${key} removed`);
				}
			}
		});
	},
	cleanOne: (model) => {
		if (!model) return;
		const key = `${_local.id}.${model}`;
		localStorage.removeItem(key);
		Logger.trace(`clean: ${key} removed`);
	},
	debugStorage: () => {
		const storageSize = Math.round(JSON.stringify(localStorage).length / 1024);
		Object.keys(localStorage).forEach(key => {
			const size = Math.round(JSON.stringify(localStorage.getItem(key)).length / 1024);
			Logger.debug(`${key}: ${size}K`);
		});
		Logger.debug(`TOTAL STORAGE: ${storageSize}K`);
	}
};

if (_localDB) {
	_localDB.forEach(name => {
		_local.db[name] = localforage.createInstance({name: name});
	});
}

// const localDBset = (id, type, data) => {
// 	if (id && _localDB && _localDB.includes(type)) {
// 		_local.db[type].setItem(id, data).then(value => {
// 			Logger.trace(`new db item: ${type} ${id} ${value._id}`);
// 		}).catch(err => {
// 			Logger.error(err);
// 		});
// 	}
// };

// const getUrl = (url, id, params) => {
// 	let fetch_url = url + (id ? `/${id}` : `?q=${params.q || ''}`);
// 	console.log(fetch_url);
// 	// TODO
// 	if (!id) {
// 		if (params.sort_by) fetch_url += `&s=${params.sort_by}`;
// 		if (params.p) fetch_url += `&p=${params.p}`;
// 		if (params.l) fetch_url += `&l=${params.l}`;
// 		if (params.flag) fetch_url += `&flag=${params.flag}`;
// 		if (params.count) fetch_url += `&c=${params.count}`;
// 	}
// 	return fetch_url;
// };

// const getData = (type, id, cfg, params) => {
// 	let fetch_url = id ? cfg.form.url : cfg.list.url;
// 	fetch_url = getUrl(fetch_url.replace(/^\/api/, ''), id, params);
// 	if (!navigator.offline) {
// 		return new Promise((resolve, reject) => {
// 			_fetch(fetch_url).then(data => {
// 				if (data.err)  {
// 					reject(data.err);
// 				} else {
// 					localDBset(id, type, data);
// 					resolve(data);
// 				}
// 			});
// 		});
// 	}
// };

const fetchData = (req) => {
	if (cfw.isString(req)) req = {url: req};
	const type = req.type || 'json';
	if (req.api) req.url = _url(req.api);

	const reload = req.reload || false;

	let cache = req.cache ? {
		key: req.url,
		type: 'memory'
	} : false;
	if (cache && cfw.isString(req.cache)) {
		cache.key = req.cache;
		cache.type = 'localStorage';
	}

	if (cache && !reload) {
		if (cache.type === 'memory' && _local.cache.has(cache.key)) {
			return Promise.resolve(_local.cache.get(cache.key));
		} else if (cache.type === 'localStorage') {
			const localData = _local.get(cache.key);
			if (localData) return Promise.resolve(localData);
		}
	}

	const options = {
		type: type,
		method: 'GET',
		cache: cache
	};

	return _fetch(req.url, options);
};

const fetchParallel = (list) => {
	return Promise.all(
		list.map(req => {
			let fn = req.fetcher || fetchData;
			return fn(req).then(data => data);
		}) // .map(handleRejection)
	);
};

export default class API {

	static config(options = {}) {
		if (options.id) _local.id = options.id;
		if (options.url) _local.api_url = options.url;
		if (options.overrides) _local.overrides = options.overrides;
	}

	static clean(modelsOnly = false) {
		return _local.clean(modelsOnly);
	}

	static cleanOne(model) {
		return _local.cleanOne(model);
	}

	static debugStorage() {
		return _local.debugStorage();
	}

	static value(key, value) {
		if (value === undefined) return _local.get(key);
		return _local.set(key, value);
	}

	static clear(key) {
		return _local.clear(key);
	}

	// static getUrl(url, id, params) {
	// 	return getUrl(url, id, params);
	// }

	// static getData(type, id, cfg, params) {
	// 	Logger.trace(`API.getData ${type} ${id}`);
	// 	return getData(type, id, cfg, params);
	// }

	static fetch(req) {
		Logger.trace(`API.fetch ${JSON.stringify(req)}`);
		return fetchData(req);
	}

	static fetchParallel(list) {
		Logger.trace(`API.fetchParallel ${JSON.stringify(list)}`);
		return fetchParallel(list);
	}

	static async get(url, base64 = false, options = {}) {
		Logger.trace(`API.get ${url}`);
		options.base64 = base64;
		return await _fetch(_url(url), options);
	}

	static async post(url, data, formData = false, base64 = false, options = {}) {
		Logger.trace(`API.post ${url}`);
		options.data = data;
		options.method = 'POST';
		options.formData = formData;
		options.base64 = base64;
		return await _fetch(_url(url), options);
	}

	static async put(url, data, options = {}) {
		Logger.trace(`API.put ${url}`);
		options.data = data;
		options.method = 'PUT';
		return await _fetch(_url(url), options);
	}

	static async delete(url, options = {}) {
		Logger.trace(`API.delete ${url}`);
		options.method = 'DELETE';
		return await _fetch(_url(url), options);
	}
}
