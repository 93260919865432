import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "margin" };
const _hoisted_3 = { key: 1 };
const _hoisted_4 = ["placeholder"];
const _hoisted_5 = ["placeholder"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_input_date = _resolveComponent("input-date");
    return (_ctx.type === 'date')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_input_date, {
                    placeholder: _ctx.i18n `From`,
                    id: `f_rangeFrom`,
                    value: _ctx.toDate(_ctx.range.from),
                    onChanged: _cache[0] || (_cache[0] = ($event) => (_ctx.onChanged('from', $event)))
                }, null, 8, ["placeholder", "value"])
            ]),
            _createVNode(_component_input_date, {
                placeholder: _ctx.i18n `To`,
                id: `f_rangeTo`,
                value: _ctx.toDate(_ctx.range.to),
                onChanged: _cache[1] || (_cache[1] = ($event) => (_ctx.onChanged('to', $event)))
            }, null, 8, ["placeholder", "value"])
        ]))
        : (_ctx.type === 'numeric')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _withDirectives(_createElementVNode("input", {
                    placeholder: _ctx.i18n `From`,
                    type: "text",
                    class: "form-control margin",
                    autocomplete: "off",
                    id: `f_rangeFrom`,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.range.from) = $event)),
                    onBlur: _cache[3] || (_cache[3] =
                        //@ts-ignore
                        (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
                }, null, 40, _hoisted_4), [
                    [_vModelText, _ctx.range.from]
                ]),
                _withDirectives(_createElementVNode("input", {
                    placeholder: _ctx.i18n `To`,
                    type: "text",
                    class: "form-control",
                    autocomplete: "off",
                    id: `f_rangeTo`,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.range.to) = $event)),
                    onBlur: _cache[5] || (_cache[5] =
                        //@ts-ignore
                        (...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
                }, null, 40, _hoisted_5), [
                    [_vModelText, _ctx.range.to]
                ])
            ]))
            : _createCommentVNode("", true);
}
