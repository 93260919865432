import { ref, provide, inject } from 'vue';
// https://vuedose.tips/create-a-i18n-plugin-with-composition-api-in-vuejs-3/
/* const recursiveRetrieve = (chain, messages) => {
    if (!messages[chain[0]] && messages[chain[0]] !== '') {
        throw new Error('Not Found');
    } else if (chain.length === 1) {
        return typeof messages[chain[0]] === 'string' ? messages[chain[0]] : '';
    } else {
        return recursiveRetrieve(chain.slice(1), messages[chain[0]]);
    }
}; */
const _createI18n = config => {
    const locale = ref(config.locale), messages = config.messages;
    const t = (...args) => {
        let key = '', i = 0;
        const e = args[0], p = args.slice(1);
        for (i = 0; i < e.length; i++) {
            key += e[i];
            const l = p[i] || null;
            if (l)
                key += '${' + i + '}';
        }
        const pack = messages[locale.value];
        let translated = pack[key];
        if (!translated)
            translated = key;
        const format = (str, p) => {
            for (let i = 0; i < p.length; i++) {
                const regexp = new RegExp('\\$\\{' + i + '\\}', 'gi');
                str = str.replace(regexp, p[i]);
            }
            return str;
        };
        return format(translated, p);
    };
    return {
        locale,
        messages,
        t
    };
};
const i18nSymbol = Symbol();
export function configI18n(app, i18nConfig) {
    const i18n = _createI18n(i18nConfig);
    app.provide(i18nSymbol, i18n);
    app.config.globalProperties.i18n = i18n.t;
}
export function provideI18n(i18nConfig) {
    const i18n = _createI18n(i18nConfig);
    provide(i18nSymbol, i18n);
}
export function useI18n() {
    const i18n = inject(i18nSymbol);
    if (!i18n)
        throw new Error('No i18n provided');
    return i18n;
}
