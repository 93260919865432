import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "card" };
const _hoisted_2 = {
    key: 0,
    class: "card-header"
};
const _hoisted_3 = { class: "card-body" };
const _hoisted_4 = {
    key: 0,
    class: "card-title"
};
const _hoisted_5 = {
    key: 1,
    class: "card-subtitle"
};
const _hoisted_6 = { class: "card-content" };
const _hoisted_7 = {
    key: 2,
    class: "card-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "header")
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
            (_ctx.$slots.title)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "title")
                ]))
                : _createCommentVNode("", true),
            (_ctx.$slots.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _renderSlot(_ctx.$slots, "subtitle")
                ]))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "content")
            ]),
            (_ctx.$slots.footer)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _renderSlot(_ctx.$slots, "footer")
                ]))
                : _createCommentVNode("", true)
        ])
    ]));
}
