<template>
	<button :class="buttonClass" :aria-label="label" :title="label" :tabindex="tabindex" :disabled="isDisabled" type="button">
		<v-icon v-if="iconBefore" :icon="iconBefore.name" :type="iconBefore.type"/>
		{{text}}
		<v-icon v-if="iconAfter" :icon="iconAfter.name" :type="iconAfter.type"/>
	</button>
</template>

<script lang="js">
import {defineComponent, computed} from 'vue';
import global from '../main';
import {cfw} from '../utils';

export default defineComponent({
	name: 'v-button',
	props: {
		name: String,
		label: String,
		helpText: String,
		helpLink: String,
		isDisabled: {
			type: Boolean,
			default: false
		},
		layout: {
			type: String,
			default: 'circle'
		},
		icon: String,
		iconAppend: String,
		text: String,
		class: String,
		tab: {
			type: Boolean,
			default: true
		}
	},
	setup(props) {
		const buttonClass = computed(() => {
			const s = {
				empty: '',
				circle: 'btn btn-link btn-link-circle',
				close: 'btn btn-close',
				primary: 'btn btn-primary',
				secondary: 'btn btn-outline-secondary',
				icon: 'btn btn-icon',
				link: 'btn btn-link'
			};
			let css = s[props.layout];
			if (props.class) css += ` ${props.class}`;
			return css;
		});

		const iconBefore = computed(() => {
			if (props.class === 'no-icon') return false;
			const iconName = (props.layout === 'close') ? 'times' : props.icon;
			let iconStyle = false;
			if (iconName) {
				const def = global.ICONS[iconName] || iconName;
				iconStyle = cfw.parseIcon(def);
			}
			return iconStyle;
		});

		const iconAfter = computed(() => {
			if (props.class === 'no-icon') return false;
			const iconName = props.iconAppend;
			let iconStyle = false;
			if (iconName) {
				const def = global.ICONS[iconName] || iconName;
				iconStyle = cfw.parseIcon(def);
			}
			return iconStyle;
		});

		const tabindex = computed(() => {
			let tabindex = props.tab ? '' : '-1';
			if (['circle', 'close'].includes(props.layout)) tabindex = '-1';
			return tabindex;
		});

		return {
			buttonClass,
			iconBefore,
			iconAfter,
			tabindex
		};
	}
});
</script>

