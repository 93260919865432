import {reactive, readonly, ref, computed} from 'vue';
import {router, components} from './router';
import {configI18n} from '../plugins/i18n'; // useI18n
import {cfw, API, Logger} from './utils';

import {useToast} from './components/notifications/useToast';
import {useConfirm} from './components/notifications/useConfirm';

// import {Notifier} from '@airbrake/browser';
// import {useRoute} from 'vue-router';

const state = reactive({
	id: 'p2p',
	api_url: process.env.API_URL,
	api_key: process.env.API_KEY,
	env: process.env.APP_ENV,
	version: process.env.APP_VERSION,
	lang: process.env.APP_LANG,
	user: null,
	redirect: null,
	offline: false,
	navigation: [],
	navigationCurrent: null,
	loading: false,
	fullscreenLoading: false,
	fullscreenBlock: false,
	theme: process.env.APP_THEME,
	path: process.env.BASE_URL,
	// user interface
	ui: {
		speechRecognition: false,
		serviceDesk: false,
		register: false,
		chat: {
			enabled: false,
			token: '683cf1d6-ce98-4ce4-9563-a6fa2f10acdb',
			host: 'https://wchat.eu.freshchat.com',
			widget: 'https://wchat.eu.freshchat.com/js/widget.js'
		}
	},
	// user access
	ua: {
		active: false,
		role: null,
		group: null,
		location: null,
		plant: null,
		workgroup: null
	},
	help: null,
	// entry: null, // entry for creating form with pre-filled fields
	editingDashboard: false,
	maintenanceMode: false,
	cfg: {
		purchasing: {}
	}
});

let _i18n = null;

const APP = readonly(state);

const setUser = async (data, stop = false) => {
	let reload = false, pageReload = false;
	const vars = ['token', 'user'];

	// check version
	const cur_version = parseInt(API.value('version') || 0);
	const app_version = parseInt(state.version);
	if (app_version === 0 || !cfw.isNumber(cur_version) || app_version > cur_version) {
		API.clean();
		API.value('version', app_version);
	}

	if (data === true) {
		// page reload
		pageReload = true;
		state.lang = API.value('lang') || 'en';
	} else if (data === false) {
		// logout
		reload = true;
		// vars.map(v => API.clear(v));
		API.clean();
		state.user = null;
		if (stop) return;
	} else if (cfw.isObject(data)) {
		// login
		reload = true;
		if (data.token && data.user) {
			vars.map(v => API.value(v, data[v]));
		}
	}

	const user = API.value('user');
	if (user) {
		state.user = user;
		let lang = user.lang || 'en';
		if (lang.key) lang = lang.key;
		// state.user.photo = `${state.path}assets/img/default-avatar-3.png`;
		state.lang = lang;
		API.value('lang', state.lang);
		state.ua.group = API.value('group') || {_id: null, name: null};
		state.ua.location = API.value('location') || null;
		state.ua.plant = API.value('plant') || null;
		state.ua.workgroup = API.value('workgroup') || {_id: null, name: null};
	}

	// reload = true;
	Logger.trace(`app.setUser: ${(user ? user.username : '-')} ${state.lang} ${state.version} ${state.env}`);
	let cfg = {};

	try {
		const data = await API.fetchParallel([
			{api: `/config/app/${state.lang}`, cache: 'app', reload: reload},
			{api: `/config/routes/${state.lang}`, cache: 'routes', reload: reload},
			{api: `/config/navigation/${state.lang}`, cache: 'navigation', reload: reload},
			{api: `/config/translations/${state.lang}`, cache: 'translations', reload: reload},
			{api: `/config/purchasing`, cache: 'purchasing', reload: reload}
		]);
		if (data[0].err) {
			state.offline = true;
		} else {
			cfg = {
				// models: data[0].models,
				actions: data[0].actions,
				fileTypes: data[0].fileTypes,
				icons: data[0].icons,
				fieldTypes: data[0].fieldTypes,
				langs: data[0].langs.sort(),
				public: data[1].public,
				routes: data[1].routes,
				translations: data[3]
			};
			configRouter(cfg, pageReload);
			configNavigation(data[2], reload);
			API.config(data[0].api);
			cfw.setLocale(data[0].locale);
			state.cfg.purchasing = data[4];
		}
	} catch (err) {
		Logger.error(err);
	}
	loader.hide();

	// TODO... porque é que se usa o i18n plugin? deviamos tentar deixar de usar o outro e passar só para este...
	// de cada vez que fazemos login teriamos de chamar o configApp e não temos o app aqui
	// isto está agora aqui para resolver o problema do i18n sem ser de plugin
	_i18n = new I18n({[state.lang]: {strings: cfg.translations || {}}});

	return cfg;
};

const setUserAccess = async (ua = null, reload = true) => {
	try {
		let group = {_id: null, name: null}, location = null, plant = null;
		if (ua) {
			if (ua.group && ua.group._id) {
				group = ua.group;
				if (!['all', 'assigned', 'mygroups', 'mycontracts'].includes(ua.group._id)) {
					const data = await API.fetch({api: `/profile/group/${ua.group._id}`});
					group.filter = data.filter;
				}
			}
			if (ua.location) location = ua.location;
			if (ua.plant) plant = ua.plant;
		}
		if (ua.active === 'organization') {
			API.value('group', group);
			state.ua.group = group;
			API.value('location', location);
			state.ua.location = location;
			API.value('plant', plant);
			state.ua.plant = plant;
		} else if (ua.active === 'group') {
			API.value('workgroup', group);
			state.ua.workgroup = group;
		}
		if (reload) reloadApp();
	} catch (err) {
		Logger.error(err);
	}
};

const configNavigation = (nav, reload) => {
	if (!reload) {
		state.navigation = API.value('navigation');
		state.navigationCurrent = API.value('navigation-current');
		return;
	}
	nav.forEach(n => {
		if (n.icon) n.icon = cfw.parseIcon(n.icon);
		n.name = n.name || n.text;
		n.expanded = false;
		n.active = false;
		if (n.children) {
			Object.values(n.children).forEach(child => {
				child.active = false;
			});
		}
	});
	API.value('navigation', nav);
	state.navigation = nav;
};

const setNavigation = (route) => {
	const names = state.navigation.map(n => {
		const p = {name: n.name, names: []};
		if (n.children) p.names = [...new Set(n.children.map(c => c.name || '').filter(e => e !== ''))];
		p.expanded = false;
		return p;
	});

	// // Handle subroutes
	// const groupsWithSubroutes = state.navigation
	// 	.flatMap(group => group.children ? group.children : group)
	// 	.filter(group => group.subroutes);

	// for (const group of groupsWithSubroutes) {
	// 	const subroute = group.subroutes.find(path => route.path.includes(path));
	// 	if (subroute) {
	// 		route.meta.nav = group.name;
	// 		break;
	// 	}
	// }

	state.navigationCurrent = route.meta.nav;
	API.value('navigation-current', route.meta.nav);

	let toGroup = names.find(e => e.names.includes(route.meta.nav));
	const fromGroup = state.navigation.find(n => n.expanded);
	let toSingle = names.find(e => e.name === route.meta.nav && e.names.length === 0);

	if (!fromGroup || !toGroup || fromGroup.name !== toGroup.name || toSingle) {
		if (fromGroup) fromGroup.expanded = false;
		if (toGroup) {
			toGroup = state.navigation.find(n => n.name === toGroup.name);
			toGroup.expanded = true;
		}
		if (toSingle) {
			toSingle = state.navigation.find(n => n.name === toSingle.name);
			toSingle.expanded = true;
		}
		API.value('navigation', state.navigation);
	}
};

const expandNavigation = (item) => {
	const current = state.navigation.find(n => n.expanded === true);
	const next = state.navigation.find(n => n.name === item.name);
	next.expanded = !next.expanded;
	if (!current || current.name !== item.name) {
		if (current) current.expanded = false;
	}
};

class I18n {
	constructor(cfg) {
		this.cfg = cfg;
	}

	translate(...args) {
		let key = '', i = 0;
		const e = args[0], p = args.slice(1);
		const lang = p.pop();

		for (i = 0; i < e.length; i++) {
			key += e[i];
			const l = p[i] || null;
			if (l) key += '${' + i + '}';
		}

		const pack = this.cfg[lang].strings;
		let translated = pack[key];
		if (!translated) translated = key;

		const format = (str, p) => {
			for (let i = 0; i < p.length; i++) {
				const regexp = new RegExp('\\$\\{' + i + '\\}', 'gi');
				str = str.replace(regexp, p[i]);
			}
			return str;
		};

		return format(translated, p);
	}
}

const configApp = (app, cfg) => {
	const config = app.config.globalProperties;
	config.ACTIONS = cfg.actions;
	config.FILE_TYPES = cfg.fileTypes;
	config.ICONS = cfg.icons;
	config.FIELD_TYPES = cfg.fieldTypes;
	config.LANGS = cfg.langs;
	config.PUBLIC_ROUTES = cfg.public;

	const messages = {[state.lang]: cfg.translations || {}};
	configI18n(app, {
		locale: state.lang,
		messages: messages
	});

	// const airbrake = new Notifier({
	// 	environment: 'production',
	// 	projectId: 484086,
	// 	projectKey: '36c95a111c23961d63df83425808eac9'
	// });

	if (APP.env === 'development') return;

	app.config.errorHandler = (err, vm, info) => {
		// err: error trace
		// vm: component in which error occurred
		// info: Vue specific error information such as lifecycle hooks, events etc.
		// airbrake.notify({
		// 	error: err,
		// 	params: {info: info}
		// });
		const stack = err.stack.split('\n');
		// const route = useRoute();
		const ts = new Date().getTime();
		const post = {
			ts: ts,
			user: state.user ? state.user._id : null,
			env: state.env,
			path: window.location.href, // route ? route.path : null,
			err: `${err}`,
			stack: stack,
			info: {
				lifecycle: `${info}`,
				dt: cfw.formatDate(ts, 'yyyy-MM-dd'),
				h: cfw.formatDate(ts, 'HH'),
				username: state.user ? state.user.username : null
			}
		};
		if (/Authentication token is not valid/.test(post.err)) {
			setUser(false);
		}
		console.log(post.err);
	};
};

const configRouter = (cfg, pageReload) => {
	const routes = cfg.routes, publicRoutes = cfg.public;
	if (state.maintenanceMode) {
		router.getRoutes().forEach(r => {
			router.removeRoute(r.name);
		});
		router.addRoute({
			path: '/:pathMatch(.*)*',
			name: 'maintenance',
			meta: {layout: 'empty', nav: 'maintenance'},
			component: () => components.NotFound
		});
		return;
	}
	if (!pageReload) {
		let names = routes.map(e => e.name);
		names = ['notfound', ...names];
		router.getRoutes().forEach(r => {
			if (names.includes(r.name)) {
				router.removeRoute(r.name);
			}
		});
	}
	Object.keys(routes).forEach(key => {
		const route = routes[key];
		let routeInfo = {
			path: route.path,
			name: route.name,
			meta: {...route.meta},
			component: () => components[route.component]
		};
		if (!routeInfo.meta.nav) routeInfo.meta.nav = route.name;
		router.addRoute(routeInfo);
		if (route.meta.form) {
			routeInfo = {
				path: `${route.path}/:id/:action?`,
				name: `${route.name}-form`,
				meta: {...route.meta}, // , ...{nav: route.name}
				component: () => components[route.meta.form]
			};
			if (!routeInfo.meta.nav) routeInfo.meta.nav = route.name;
			router.addRoute(routeInfo);
		}
	});
	// push as last element because the wildcard match will catch all the unknown urls
	router.addRoute({
		path: '/:pathMatch(.*)*',
		name: 'notfound',
		meta: {layout: 'empty', nav: 'notfound'},
		component: () => components.NotFound
	});
	// const unsorted = router.getRoutes();
	// const sorted = cfw.sortBy(unsorted, 'path');
	// sorted.forEach(r => {
	// 	Logger.trace(`${r.path} ${r.name} ${r.meta.nav}`);
	// });
	if (pageReload) {
		router.beforeEach((to, from, next) => {
			const v = document.getElementById('view');
			if (v) v.scrollTo(0, 0);
			if (to.meta.nav !== from.meta.nav) setNavigation(to);
			if (publicRoutes.includes(to.name)) return next();
			if (!APP.user) return next({name: 'signin', query: {redirect: to.fullPath}});
			next();
		});
	}
};

const loader = {
	show: (fullscreen = false) => {
		// if (fullscreen) {
		// 	state.fullScreenLoading = true;
		// } else {
		state.loading = true;
		// }
		state.fullscreenBlock = fullscreen;
	},
	hide: () => {
		state.loading = false;
		state.fullscreenBlock = false;
	},
	loading: () => {
		return state.loading && !state.fullscreenBlock;
	},
	fullscreenLoading: () => {
		return state.loading && state.fullscreenBlock;
	},
	fullscreen: () => {
		return state.fullscreenBlock;
	}
};

const reloadApp = (clean = false, timeout = 100) => {
	if (!state.loading) loader.show(true);
	if (clean) {
		API.clean(true);
		window.location.reload();
	} else {
		setTimeout(() => {
			window.location.reload();
		}, timeout);
	}
};

const reloadModel = (model) => {
	API.cleanOne(model);
};

const useMessages = () => {
	const toastService = useToast();
	const confirmService = useConfirm();

	// toast.add({
	// severity: 'danger',
	// summary: 'Error',
	// detail: '...',
	// group: 'br', icon:
	// 'times-circle', life: 3000});

	const pop = (title, msg) => {
		if (state.loading) loader.hide();
		toastService.add({severity: 'primary', summary: title, detail: msg, life: 3000});
	};

	const done = (msg, reload = false, model) => {
		if (state.loading) loader.hide();
		const t = {severity: 'success', summary: i18n`Success`, detail: msg, life: 3000, icon: 'check-circle'};
		toastService.add(t);
		if (reload) {
			reloadApp(true, 500);
		} else if (model) {
			reloadModel(model);
		}
	};

	const info = (msg) => {
		if (state.loading) loader.hide();
		const t = {severity: 'info', summary: i18n`Info`, detail: msg, life: 3000, icon: 'info-circle'};
		toastService.add(t);
	};

	const warn = (msg) => {
		if (state.loading) loader.hide();
		const t = {severity: 'warning', summary: i18n`Warning`, detail: msg, life: 5000, icon: 'exclamation-circle'};
		toastService.add(t);
	};

	const fail = (msg, err = null) => {
		if (state.loading) loader.hide();
		const t = {severity: 'danger', summary: i18n`Error`, detail: msg, life: 5000, icon: 'times-circle'};
		toastService.add(t);
		if (err) Logger.error(err);
	};

	const confirm = (options) => {
		confirmService.require(options);
	};

	return {
		pop,
		done,
		info,
		warn,
		fail,
		confirm
	};
};

const useHelp = () => {

	const sidebarHelp = ref(false);

	const appHelp = computed(() => {
		if (!state.user) return;
		let h = '';
		if (APP.help) {
			let help = cfw.copy(APP.help);
			if (isSupplier()) {
				if (APP.help.supplier) {
					help = APP.help.supplier;
				} else {
					help = null;
				}
			}
			if (help) {
				h = {
					topic: help[APP.lang].topic,
					title: help[APP.lang].title,
					content: help[APP.lang].content,
					url: help[APP.lang].url,
					secondaryUrls: help[APP.lang].secondaryUrls
				};
			}
		}
		return h;
	});

	const hideHelp = computed(() => {
		return APP.help ? APP.help.hide : false;
	});

	const setHelp = (help) => {
		if (!help) {
			state.help = null;
		} else {
			const h = {...help, hide: false};
			state.help = h;
		}
	};

	return {
		sidebarHelp,
		appHelp,
		setHelp,
		hideHelp
	};
};

const useAccess = () => {

	const sidebar = ref(false);

	const appAccess = computed(() => {
		let active = false;
		if (state.user && !/^ihub/.test(state.user.role.key) && !state.user.supplier) {
			active = state.ua.active;
		}
		return active;
	});

	const setAccess = (active = false) => {
		state.ua.active = active;
	};

	return {
		sidebar,
		appAccess,
		setAccess
	};
};

const i18n = (...args) => {
	if (args.length === 2 && cfw.isArray(args[0]) && args[1]) {
		const a = args[0].filter(e => e);
		if (a.length === 0) {
			return _i18n.translate(args[1], state.lang);
		} else {
			return _i18n.translate(...args, state.lang);
		}
	}
	return _i18n.translate(...args, state.lang);
	// const provider = useI18n();
	// return provider.t(...args);
};

if (process.env.NODE_ENV === 'production') {
	setInterval(async () => {
		const ts = new Date().getTime();
		const url = `${state.path}manifest.json?ts=${ts}`;
		try {
			const res = await fetch(url);
			const data = await res.json();
			const cur_version = parseInt(API.value('version') || 0);
			if (!cfw.isNumber(cur_version) || data.version > cur_version) {
				API.clean();
				window.location.reload();
			}
		} catch (err) {
			console.error(err);
		}
	}, 600000);
}

/* SSE ---------- */
/* const es = new EventSource(`${state.api_url}/sse`);
es.addEventListener('user.reload', event => {
	const data = JSON.parse(event.data);
	if (APP.user._id.toString() === data._id.toString()) {
		API.clean();
		window.location.reload();
	}
}, false); */
/* // TODO - por listening start and stop apenas no DataForm
// https://javascript.info/server-sent-events
es.addEventListener('data.reload', event => {
	const data = JSON.parse(event.data);
	window.location.reload();
}, false);
es.addEventListener('error', event => {
	if (event.readyState === EventSource.CLOSED) {
	}
}, false); */

const setEntry = (entry) => {
	API.value('entry', entry);
};

const getEntry = () => {
	return API.value('entry');
};

const setEditingDashboard = (value) => {
	state.editingDashboard = value;
};

const isRoot = () => {
	if (!state.user) return false;
	return state.user.role.key === 'admin';
};

const isAdmin = () => {
	if (!state.user) return false;
	return ['admin', 'manager'].includes(state.user.role.key);
};

const isSupplier = () => {
	if (!state.user) return false;
	// state.user.supplier;
	return ['supplier', 'supplier-user'].includes(state.user.role.key);
};

const isInspector = (entry) => {
	if (!['supplier', 'supplier-user'].includes(state.user.role.key)) return false;
	return entry.inspectionSuppliers && entry.inspectionSuppliers.find(supplier => supplier._id === state.user._profile[0]);
};

const isRole = (role) => {
	if (!state.user) return false;
	if (['admin', 'manager'].includes(state.user.role.key)) return true;
	const r = role.split(',');
	return r.includes(state.user.role.key);
};

const hasRole = (role, min = 'manager') => {
	if (!state.user) return false;
	if (['admin', 'manager'].includes(state.user.role.key) && min === 'manager') return true;
	if (['admin'].includes(state.user.role.key) && min === 'admin') return true;
	const roles = state.user.roles.map(e => e.key);
	// TODO
	const r = role.split(',');
	let found = false;
	r.forEach(ro => {
		if (roles.includes(ro)) found = true;
	});
	return found;
};

const setOffline = (value) => {
	if (state.offline !== value) {
		state.offline = value;
	}
};

export {
	APP,
	configApp,
	setUser,
	setUserAccess,
	setNavigation,
	setEntry,
	getEntry,
	expandNavigation,
	loader,
	reloadApp,
	reloadModel,
	useMessages,
	useHelp,
	useAccess,
	// useI18n,
	i18n,
	setEditingDashboard,
	isRoot,
	isAdmin,
	isSupplier,
	isInspector,
	isRole,
	hasRole,
	setOffline
};
