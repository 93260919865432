const PURCHASING_MODELS = ['requisition', 'contract', 'order'];
const SOURCING_TYPES = ['C001', 'C002', 'C003', 'P001', 'P002', 'P003'];
const INBOUND = ['R001', 'R004', 'R005', 'R006'];
const ROUND_QUANTITY = 3;
const ROUND_PRICE = 5;
const ROUND_VALUE = 2;

export default {
	PURCHASING_MODELS,
	SOURCING_TYPES,
	INBOUND,
	ROUND_QUANTITY,
	ROUND_PRICE,
	ROUND_VALUE
};
