<template>
	<div ref="containerRef">
		<div ref="inputRef" class="dropdown-handler" @click.stop="dropdownClick">
			<slot name="handler"></slot>
		</div>
		<transition name="c-connected-overlay" @enter="onOverlayEnter" @leave="onOverlayLeave">
			<div ref="overlayRef" v-if="overlayVisible" :class="overlayClass" @click="overlayClick">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed, onBeforeUnmount} from 'vue'; // watch
import {useOverlay} from '../use/overlay';
export default defineComponent({
	name: 'v-overlay',
	// emits: ['selected'],
	props: {
		closeOnClick: {
			type: Boolean,
			default: true
		},
		class: null
		// modelValue: Boolean
	},
	setup(props) { // , {emit}

		const overlayRef = ref();
		const containerRef = ref();
		const inputRef = ref();
		const dropdownRef = ref();

		// watch(() => props.modelValue, () => { // newval
		// 	// hideOverlay();
		// });

		const overlayClass = computed(() => {
			return [
				'input-suggestion-overlay', props.class
				// {
				// 	'disabled': attrs.disabled || props.readonly
				// }
			];
		});

		onBeforeUnmount(() => {
			removeOverlay();
		});

		const dropdownClick = () => {
			if (!overlayVisible.value) {
				showOverlay();
			} else {
				hideOverlay();
			}
		};

		const overlayClick = () => { // e
			if (props.closeOnClick) hideOverlay();
		};

		// const itemClick = (item) => {
		// 	selectedItem.value = item;
		// 	emit('selected', selectedItem.value);
		// 	// items.value.forEach(i => {
		// 	// 	i.selected = i.id === item.id;
		// 	// });
		// 	hideOverlay();
		// };

		const options = {containerWidth: false};
		const {
			overlayVisible,
			showOverlay,
			hideOverlay,
			onOverlayEnter,
			onOverlayLeave,
			removeOverlay
		} = useOverlay(overlayRef, containerRef, inputRef, dropdownRef, options);

		return {
			overlayRef,
			containerRef,
			inputRef,
			dropdownRef,
			overlayVisible,
			onOverlayEnter,
			onOverlayLeave,
			dropdownClick,
			overlayClick,
			overlayClass
		};
	}
});
</script>

<style lang="scss">
</style>
