<template>
	<div v-if="cfgOptions.container" class="form-list">
		<div v-if="cfgOptions.header" class="content-header">
			<div class="actions-left">
				<div v-if="cfgOptions.select" class="select-or-back">
					<input-checkbox
						:id="`${cfg.name}-select-all`"
						:checked="items.length > 0 && selected.length === items.length"
						@input="toggleAll()"/>
				</div>
				<slot name="actions-left"></slot>
			</div>
			<div class="actions-right">
				<slot name="actions-right"></slot>
				<span v-if="cfgOptions.count" role="button" class="pagination hide-xs">{{count}}</span>
				<v-button v-if="cfgOptions.paging" icon="angle-left" class="hide-xs" :title="i18n`Previous`" :disabled="firstPage" @click="pagePrevious"/>
				<v-button v-if="cfgOptions.paging" icon="angle-right" class="hide-xs" :title="i18n`Next`" :disabled="lastPage" @click="pageNext"/>
			</div>
		</div>
		<v-block :blocked="loading">
			<ol class="table" :class="`model-${cfg.name}`">
				<li class="table-header">
					<div v-if="cfgOptions.select" class="attribute"></div>
					<div v-for="(col, i) in fields" :key="col.src" :class="colClass(col, i, true)" :title="col.label">{{col.label}}</div>
				</li>
				<li v-for="(item, n) in items" :key="n" :class="itemClass(item)" @click="handleRowClick(n)">
					<div v-if="cfgOptions.select" class="attribute cell-checkbox"
						@click.stop="handleItem(item, 'toggle')">
						<input-checkbox
							:id="`${cfg.name}-select-item-${n}`"
							v-model="item._selected"
							@input="toggle(item)"/>
					</div>
					<div v-for="(col, i) in fields" :key="col.src" :class="colClass(col, i)" :data-name="col.label">
						<input-field :field="col" :entry="item" :fields="fields" :view="item._view"/>
					</div>
				</li>
			</ol>
		</v-block>
	</div>
	<div v-if="cfgOptions.header && !cfgOptions.container" id="view-header" class="content-header">
		<div class="actions-left">
			<div v-if="cfgOptions.select" class="select-or-back">
				<input-checkbox
					:id="`${cfg.name}-select-all`"
					:checked="items.length > 0 && selected.length === items.length"
					@input="toggleAll()"/>
			</div>
			<slot name="actions-left"></slot>
		</div>
		<div class="actions-right">
			<slot name="actions-right"></slot>
			<span v-if="cfgOptions.count" role="button" class="pagination hide-xs">{{count}}</span>
			<v-button v-if="cfgOptions.paging" icon="angle-left" class="hide-xs" :title="i18n`Previous`" :disabled="firstPage" @click="pagePrevious"/>
			<v-button v-if="cfgOptions.paging" icon="angle-right" class="hide-xs" :title="i18n`Next`" :disabled="lastPage" @click="pageNext"/>
		</div>
	</div>
	<slot name="table-subheader"></slot>
	<v-block v-if="!cfgOptions.container" id="view" :blocked="loading">
		<ol class="table" :class="`model-${cfg.name}`">
			<li class="table-header">
				<div v-if="cfgOptions.select" class="attribute"></div>
				<div v-for="(col, i) in fields" :key="col.src" :class="colClass(col, i, true)" :title="col.label">{{col.label}}</div>
			</li>
			<li v-for="(item, n) in items" :key="n" :class="itemClass(item)" @click="handleRowClick(n)">
				<div v-if="cfgOptions.select" class="attribute cell-checkbox"
					@click.stop="handleItem(item, 'toggle')">
					<input-checkbox
						:id="`${cfg.name}-select-item-${n}`"
						v-model="item._selected"
						@input="toggle(item)"/>
				</div>
				<div v-for="(col, i) in fields" :key="col.src" :class="colClass(col, i)" :data-name="col.label">
					<input-field :field="col" :entry="item" :fields="fields" :view="item._view"/>
				</div>
			</li>
		</ol>
	</v-block>
</template>

<script lang="js">
import {defineComponent, ref, computed, watch} from 'vue';
import {useConditionWatcher} from 'vue-condition-watcher';
import {useList, getStyle} from '../use/model';
import api from '../use/api';
import {cfw, API} from '../utils';
import {useRouter} from "vue-router";

export default defineComponent({
	name: 'data-ref',
	components: {
	},
	emits: ['changed'],
	props: {
		cfg: Object
	},
	setup(props) { // , {emit}

		const cfgOptions = computed(() => {
			let options = {
				header: false,
				select: false,
				count: false,
				paging: false
			};
			if (props.cfg && props.cfg.options) options = props.cfg.options;
			if (!options.hasOwnProperty('container')) options.container = true;
			return options;
		});

		const fields = computed(() => {
			const f = cfw.clone(props.cfg.fields);
			if (!cfw.isArray(f)) return [];
			f.forEach(field => {
				field.id = field.src;
				field.visible = true;
				field.labelPosition = 'none';
			});
			return f;
		});

		const router = useRouter();

		const options = ref(null);
		const items = ref([]);

		// onMounted(() => {
		// 	if (!cfw.isString(props.cfg.data)) {
		// 		const data = [...props.cfg.data];
		// 		options.value = {
		// 			count: data.length
		// 			// pages: Math.ceil(Math.max(data.options.count, 1) / Math.max(data.options.pagesize, 1))
		// 		};
		// 	}
		// });

		const getData = async params => {
			let url = null;
			let data = null;
			const result = {
				options: {
					count: 0,
					page: 1
				},
				items: []
			};
			if (cfw.isString(props.cfg.data)) {
				url = api.getSearchUrl(props.cfg.data, null, props.cfg.options, params);
				data = await API.get(url);
				result.options.count = data.options.count;
				data = data.items;
			} else {
				data = [...props.cfg.data];
				result.options.count = data.length;
			}
			result.items = data;
			result.items.forEach(item => { // , i
				item['_view'] = 'list';
			});
			return result;
		};

		const config = {
			fetcher: getData,
			defaultParams: {},
			conditions: {
				q: null,
				p: api.DEFAULT_PAGE,
				l: parseInt(props.cfg.options.pageSize) || api.DEFAULT_LENGTH,
				s: props.cfg.options.sort || api.DEFAULT_SORT
			},
			beforeFetch(conditions) {
				getStyle(props.cfg, fields, true);
				if (options.value) {
					conditions.c = options.value.count;
				}
				return conditions;
			},
			afterFetch(data) {
				if (!data) return;
				if (!options.value) {
					options.value = {
						count: data.options.count,
						pages: Math.ceil(Math.max(data.options.count, 1) / Math.max(data.options.pagesize, 1))
					};
				}
				items.value = data.items;
			}
		};

		const queryOptions = {ignore: ['c', 's']};
		const {conditions, loading} = useConditionWatcher(config, queryOptions);

		const list = useList(conditions, options, items);

		// const listCount = computed(() => {
		// 	let count = list.count.value;
		// 	if (!count) count = options.value.count || null;
		// 	return count;
		// });

		const handleItem = (item, action) => {
			if (action === 'toggle') {
				list.toggle(item);
			}
		};

		const itemClass = (item) => {
			const css = ['table-row', {
				'selected': item._selected,
				'table-row-action': props.cfg.rowsLinks
			}];
			// TODO
			if (item.flag && item.flag.status) {
				if (item.flag.status === 'new') {
					css.push('info');
				} else if (item.flag.status === 'warn') {
					css.push('warn');
				} else if (item.flag.status === 'fail') {
					css.push('fail');
				} else if (item.flag.status === 'ignore') {
					css.push('ignore');
				}
			}
			return css;
		};

		const colClass = (col, index, header) => {
			const css = ['attribute', ...list.css(col, header).split(' '), {
				'first': !cfgOptions.value.select && index === 0,
				'cell-value': col.right
			}];
			return css;
		};

		const handleRowClick = (i) => {
			if (props.cfg.rowsLinks) router.push(props.cfg.rowsLinks[i]);
		};

		watch(() => props.cfg.data, (newval, oldval) => {
			if (newval !== oldval) {
				getStyle(props.cfg, fields, true);
				items.value = newval;
				if (cfw.isArray(props.cfg.data)) {
					options.value = {
						count: props.cfg.data.length
					};
					config.conditions.l = props.cfg.data.length;
				}
			}
		});

		return {
			cfgOptions,
			fields,
			conditions,
			loading,
			items,
			itemClass,
			colClass,
			handleItem,
			handleRowClick,
			// listCount,
			...list
		};
	}
});
</script>
