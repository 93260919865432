import { register } from 'register-service-worker';
if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.log('App is being served from cache by a service worker.');
        },
        registered() {
            console.log('Service worker has been registered.');
        },
        cached() {
            console.log('Content has been cached for offline use.');
        },
        updatefound() {
            caches.keys().then(names => {
                names.forEach(name => {
                    caches.delete(name);
                });
            });
            console.log('New content is downloading.');
        },
        updated() {
            console.log('New content is available; please refresh.');
            // window.location.reload(true);
            // registration.update();
            // caches.keys().then(names => {
            // 	for (let name of names) {
            // 		caches.delete(name);
            // 	}
            // });
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error(err) {
            console.error('Error during service worker registration:', err);
        }
    });
}
