import { API, Logger } from './utils';
import { APP, configApp, setUser } from './app';
API.config({ id: APP.id, url: APP.api_url });
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import { router } from './router';
/**
 * ----------------------------------------------------------------------
 * layout and icons
 * ----------------------------------------------------------------------
 */
import './scss/compile.scss';
import { SvgIcon } from '../plugins/icon';
import CollapseTransition from './components/animations/CollapseTransition.vue';
import PrismCode from './components/PrismCode';
import { Cropper } from 'vue-advanced-cropper';
import ToastService from './components/notifications/ToastService';
import ConfirmService from './components/notifications/ConfirmService';
import Button from './components/Button.vue';
import Dialog from './components/Dialog.vue';
import FilterItem from "./components/FilterItem.vue";
import TabList from './components/TabList.vue';
import Tab from './components/Tab.vue';
import Sidebar from './components/Sidebar.vue';
import IconStatus from './components/IconStatus.vue';
import Toast from './components/notifications/Toast.vue';
import ToastMessage from './components/notifications/ToastMessage.vue';
import Message from './components/notifications/Message.vue';
import Confirm from './components/notifications/Confirm.vue';
import Chart from './components/Chart.vue';
import DashboardChart from './components/DashboardChart.vue';
import Dropdown from './components/Dropdown.vue';
import Card from './components/Card.vue';
import Divider from './components/Divider.vue';
import Block from './components/Block.vue';
import Progress from './components/Progress.vue';
import ProgressLabelled from './components/ProgressLabelled.vue';
import Tree from './components/Tree.vue';
import TreeNode from './components/TreeNode.vue';
import Note from './components/Note.vue';
import File from './components/File.vue';
import Favorites from './components/Favorites.vue';
import Activity from './components/Activity.vue';
import QrReader from './components/QrReader.vue';
import Avatar from './components/Avatar.vue';
import Post from './components/PostMessage.vue';
import Table from './components/Table.vue';
import Overlay from './components/Overlay.vue';
import Cubejs from './components/Cubejs.vue';
import Map from './components/Map.vue';
import Tooltip from './components/Tooltip.js';
import Label from './components/Label.vue';
import QuillEditor from './components/QuillEditor.vue';
// common inputs
import InputField from './components/input/Field.vue';
import InputCheckbox from './components/input/Checkbox.vue';
import InputSuggestion from './components/input/Suggestion.vue';
import InputRating from './components/input/Rating.vue';
import InputRadio from './components/input/Radio.vue';
import InputDate from './components/input/Date.vue';
import InputAddress from './components/input/Address.vue';
import InputMeasure from './components/input/Measure.vue';
import InputSwitch from './components/input/Switch.vue';
import InputSlider from './components/input/Slider.vue';
import TreeSelect from './components/input/TreeSelect.vue';
import InputRange from './components/input/Range.vue';
import InputTextArea from './components/input/TextArea.vue';
import VerificationCode from './components/input/VerificationCode.vue';
let app = null;
const main = async () => {
    app = createApp(App);
    app.component('cube-renderer', Cubejs);
    app.component('v-icon', SvgIcon);
    app.component('collapse-transition', CollapseTransition);
    app.component('prism-code', PrismCode);
    app.component('quill-editor', QuillEditor);
    app.component('v-cropper', Cropper);
    app.component('v-button', Button);
    app.component('v-dialog', Dialog);
    app.component("v-filter-selected", FilterItem);
    app.component('v-tablist', TabList);
    app.component('v-tab', Tab);
    app.component('v-sidebar', Sidebar);
    app.component('v-icon-status', IconStatus);
    app.component('v-toast', Toast);
    app.component('v-toast-message', ToastMessage);
    app.component('v-message', Message);
    app.component('v-confirm', Confirm);
    app.component('v-chart', Chart);
    app.component('v-dashboard-chart', DashboardChart);
    app.component('v-dropdown', Dropdown);
    app.component('v-card', Card);
    app.component('v-divider', Divider);
    app.component('v-block', Block);
    app.component('v-progress', Progress);
    app.component('v-progress-labelled', ProgressLabelled);
    app.component('v-tree', Tree);
    app.component('v-treenode', TreeNode);
    app.component('v-note', Note);
    app.component('v-file', File);
    app.component('v-qrreader', QrReader);
    app.component('v-post', Post);
    app.component('v-table', Table);
    app.component('v-overlay', Overlay);
    app.component('v-map', Map);
    app.component('v-tooltip', Tooltip);
    app.component('v-label', Label);
    app.component('user-favorites', Favorites);
    app.component('user-activity', Activity);
    app.component('user-avatar', Avatar);
    app.component('input-field', InputField);
    app.component('input-checkbox', InputCheckbox);
    app.component('input-suggestion', InputSuggestion);
    app.component('input-rating', InputRating);
    app.component('input-radio', InputRadio);
    app.component('input-date', InputDate);
    app.component('input-address', InputAddress);
    app.component('input-measure', InputMeasure);
    app.component('input-slider', InputSlider);
    app.component('input-switch', InputSwitch);
    app.component('input-tree-select', TreeSelect);
    app.component('input-range', InputRange);
    app.component('input-textarea', InputTextArea);
    app.component('input-verification-code', VerificationCode);
    const cfg = await setUser(true);
    configApp(app, cfg);
    app.use(ToastService);
    app.use(ConfirmService);
    app.provide('router', router).use(router).mount('#app');
    app.directive('tooltip', Tooltip);
    return app;
};
main().then(app => {
    Logger.trace(`Vue ${app.version} > ${APP.lang} ${APP.version}`);
    // API.debugStorage();
    /* for (let i = 0, data = "m"; i < 40; i++) {
        try {
            localStorage.setItem("DATA", data);
            data = data + data;
        } catch(e) {
            const storageSize = Math.round(JSON.stringify(localStorage).length / 1024);
            console.log("LIMIT REACHED: (" + i + ") " + storageSize + "K");
            console.log(e);
            break;
        }
    } */
    // localStorage.removeItem("DATA");
});
const global = app.config.globalProperties;
export default global;
/*
"@cubejs-client/core": "^0.28.52",
"@fortawesome/fontawesome-svg-core": "1.x",
"@fortawesome/free-regular-svg-icons": "5.x",
"@fortawesome/free-solid-svg-icons": "5.x",
"@googlemaps/markerclusterer": "^1.0.21",
"@vueup/vue-quill": "^1.0.0-beta.8",
"chart.js": "3.x",
"chartjs-chart-geo": "3.x",
"chartjs-plugin-annotation": "1.x",
"chartjs-plugin-zoom": "1.x"
*/
