<template>
	<div :class="containerClass">
		<template v-if="loading">
			<div class="p-tree-loading-overlay component-overlay">
				<i :class="loadingIconClass"/>
			</div>
		</template>
		<div class="c-tree-filter-container" v-if="filter">
			<div class="input-icon">
				<button type="button" class="input-icon-btn-left" tabindex="-1">
					<v-icon icon="search"/>
				</button>
				<input type="text" autocomplete="off" class="form-control" :placeholder="filterPlaceholder"
					@keydown="onFilterKeydown" v-model="filterValue"/>
			</div>
		</div>
		<ul class="p-tree-container" role="tree">
			<v-treenode v-for="node of valueToRender" :key="node.key" :node="node" :templates="$slots"
				:expandedKeys="d_expandedKeys" @node-toggle="onNodeToggle" @node-click="onNodeClick"
				:selectionMode="selectionMode" :selectionKeys="selectionKeys" @checkbox-change="onCheckboxChange"/>
		</ul>
	</div>
</template>

<script lang="js">
import {defineComponent, ref, computed, watch} from 'vue'; // onMounted, onUnmounted
import TreeNode from './TreeNode.vue';
import {ObjectUtils} from '../utils'; // cfw

export default defineComponent({
	name: 'v-tree',
	components: {
		'v-treenode': TreeNode
	},
	emits: ['node-expand', 'node-collapse', 'update:expandedKeys', 'update:selectionKeys', 'node-select', 'node-unselect'],
	props: {
		value: {
			type: null,
			default: null
		},
		expandedKeys: {
			type: null,
			default: null
		},
		selectionKeys: {
			type: null,
			default: null
		},
		selectionMode: {
			type: String,
			default: null
		},
		metaKeySelection: {
			type: Boolean,
			default: true
		},
		loading: {
			type: Boolean,
			default: false
		},
		loadingIcon: {
			type: String,
			default: 'pi pi-spinner'
		},
		filter: {
			type: Boolean,
			default: false
		},
		filterBy: {
			type: String,
			default: 'label'
		},
		filterMode: {
			type: String,
			default: 'lenient'
		},
		filterPlaceholder: {
			type: String,
			default: null
		},
		filterLocale: {
			type: String,
			default: undefined
		},
		filterText: {
			type: String,
			default: null
		},
		expandOnFilter: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const d_expandedKeys = ref(props.expandedKeys || {});
		const filterValue = ref(props.filterText || null);
		const filteredValue = ref([]);
		// const filteredExpanded = ref({});
		// let initial_expandedKeys = {};

		const containerClass = computed(() => {
			return ['p-tree', {
				'p-tree-selectable': props.selectionMode != null,
				'p-tree-loading': props.loading
			}];
		});

		const loadingIconClass = computed(() => {
			return ['p-tree-loading-icon pi-spin', props.loadingIcon];
		});

		// onMounted(() => {
		// 	initial_expandedKeys = cfw.clone(props.expandedKeys || {});
		// });

		// onUnmounted(() => {
		// 	initial_expandedKeys = {};
		// });

		const filterNodes = (val) => {
			if (!val || val.trim().length === 0) return;
			let filteredNodes = [];
			const searchFields = props.filterBy.split(',');
			const filterText = val.trim().toLocaleLowerCase(props.filterLocale);
			const strict = props.filterMode === 'strict';

			for (let node of props.value) {
				let _node = {...node};
				let paramsWithoutNode = {searchFields, filterText, strict};

				if ((strict && (findFilteredNodes(_node, paramsWithoutNode) || isFilterMatched(_node, paramsWithoutNode))) ||
					(!strict && (isFilterMatched(_node, paramsWithoutNode) || findFilteredNodes(_node, paramsWithoutNode)))) {
					filteredNodes.push(_node);
				}
			}

			filteredValue.value = filteredNodes;
		};

		// const expandNodes = () => {
		// 	// d_expandedKeys.value = initial_expandedKeys;
		// };

		const valueToRender = computed(() => {
			if (filterValue.value && filterValue.value.trim().length > 0) {
				return filteredValue.value;
			} else {
				return props.value;
			}
		});

		const onNodeToggle = (node) => {
			const key = node.key;
			if (d_expandedKeys.value[key]) {
				delete d_expandedKeys.value[key];
				emit('node-collapse', node);
			} else {
				d_expandedKeys.value[key] = true;
				emit('node-expand', node);
			}
			d_expandedKeys.value = {...d_expandedKeys.value};
			emit('update:expandedKeys', d_expandedKeys.value);
		};

		const onNodeClick = (event) => {
			if (props.selectionMode !== null && event.node.selectable !== false) {
				const metaSelection = event.nodeTouched ? false : props.metaKeySelection;
				const _selectionKeys = metaSelection ? handleSelectionWithMetaKey(event) : handleSelectionWithoutMetaKey(event);
				emit('update:selectionKeys', _selectionKeys);
			}
		};

		const onCheckboxChange = (event) => {
			emit('update:selectionKeys', event.selectionKeys);
			if (event.check) {
				emit('node-select', event.node);
			} else {
				emit('node-unselect', event.node);
			}
		};

		const handleSelectionWithMetaKey = (event) => {
			const originalEvent = event.originalEvent;
			const node = event.node;
			const metaKey = (originalEvent.metaKey || originalEvent.ctrlKey);
			const selected = isNodeSelected(node);
			let _selectionKeys;

			if (selected && metaKey) {
				if (isSingleSelectionMode()) {
					_selectionKeys = {};
				} else {
					_selectionKeys = {...props.selectionKeys};
					delete _selectionKeys[node.key];
				}
				emit('node-unselect', node);
			} else {
				if (isSingleSelectionMode()) {
					_selectionKeys = {};
				} else if (isMultipleSelectionMode()) {
					_selectionKeys = !metaKey ? {} : (props.selectionKeys ? {...props.selectionKeys} : {});
				}
				_selectionKeys[node.key] = true;
				emit('node-select', node);
			}

			return _selectionKeys;
		};

		const handleSelectionWithoutMetaKey = (event) => {
			const node = event.node;
			const selected = isNodeSelected(node);
			let _selectionKeys;

			if (isSingleSelectionMode()) {
				if (selected) {
					_selectionKeys = {};
					emit('node-unselect', node);
				} else {
					_selectionKeys = {};
					_selectionKeys[node.key] = true;
					emit('node-select', node);
				}
			} else {
				if (selected) {
					_selectionKeys = {...props.selectionKeys};
					delete _selectionKeys[node.key];
					emit('node-unselect', node);
				} else {
					_selectionKeys = props.selectionKeys ? {...props.selectionKeys} : {};
					_selectionKeys[node.key] = true;
					emit('node-select', node);
				}
			}

			return _selectionKeys;
		};

		const isSingleSelectionMode = () => {
			return props.selectionMode === 'single';
		};

		const isMultipleSelectionMode = () => {
			return props.selectionMode === 'multiple';
		};

		const isNodeSelected = (node) => {
			return (props.selectionMode && props.selectionKeys) ? props.selectionKeys[node.key] === true : false;
		};

		// const isChecked = (node) => {
		// 	return props.selectionKeys ? props.selectionKeys[node.key] && props.selectionKeys[node.key].checked: false;
		// };

		const isNodeLeaf = (node) => {
			return node.leaf === false ? false : !(node.children && node.children.length);
		};

		const onFilterKeydown = (event) => {
			if (event.which === 13) event.preventDefault();
		};

		const findFilteredNodes = (node, paramsWithoutNode) => {
			if (node) {
				let matched = false;
				if (node.children) {
					let childNodes = [...node.children];
					node.children = [];
					for (let childNode of childNodes) {
						let copyChildNode = {...childNode};
						if (isFilterMatched(copyChildNode, paramsWithoutNode)) {
							matched = true;
							if (props.expandOnFilter) d_expandedKeys.value[node.key] = true;
							node.children.push(copyChildNode);
						}
					}
				}
				if (matched) return true;
			}
		};

		const isFilterMatched = (node, {searchFields, filterText, strict}) => {
			let matched = false;
			for (let field of searchFields) {
				let fieldValue = String(ObjectUtils.resolveFieldData(node, field)).toLocaleLowerCase(props.filterLocale);
				if (fieldValue.indexOf(filterText) > -1) matched = true;
			}
			if (!matched || (strict && !isNodeLeaf(node))) {
				matched = findFilteredNodes(node, {searchFields, filterText, strict}) || matched;
			}
			return matched;
		};

		watch(() => props.expandedKeys, (newval) => {
			d_expandedKeys.value = newval;
		});

		watch(() => props.filterText, (newval) => {
			filterValue.value = newval || null;
		});

		watch(() => filterValue.value, (newval) => {
			filterNodes(newval);
		});

		return {
			onNodeToggle,
			onCheckboxChange,
			onNodeClick,
			onFilterKeydown,
			valueToRender,
			containerClass,
			loadingIconClass,
			d_expandedKeys,
			filterValue
		};
	}
});
</script>

<style lang="scss">
.p-tree-container {
	margin: 0;
	padding: 0;
	list-style-type: none;
	overflow: auto;
}

.p-treenode-children {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.p-treenode-selectable {
	cursor: pointer;
	user-select: none;
}

.p-tree-toggler {
	cursor: pointer;
	user-select: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
}

.p-treenode-leaf > .p-treenode-content .p-tree-toggler {
	visibility: hidden;
}

.p-treenode-content {
	display: flex;
	align-items: center;
}

.p-tree-loading {
	position: relative;
	min-height: 4rem;
}

.p-tree .p-tree-loading-overlay {
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
