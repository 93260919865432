import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    ref: "nav",
    class: "nav nav-tabs"
};
const _hoisted_2 = ["onClick", "onKeydown", "tabindex", "aria-selected"];
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { class: "tab-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                return (_openBlock(), _createElementBlock("li", {
                    key: _ctx.getKey(tab, i),
                    class: "nav-item"
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(["nav-link", [{ 'active': (_ctx.activeIndex === i), 'disabled': _ctx.isTabDisabled(tab) }]]),
                        onClick: ($event) => (_ctx.onTabClick($event, i)),
                        onKeydown: ($event) => (_ctx.onTabKeydown($event, i)),
                        tabindex: _ctx.isTabDisabled(tab) ? null : '0',
                        "aria-selected": _ctx.activeIndex === i
                    }, [
                        (tab.props.header)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(tab.props.header), 1))
                            : _createCommentVNode("", true),
                        (tab.children.header)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(tab.children.header), { key: 1 }))
                            : _createCommentVNode("", true)
                    ], 42, _hoisted_2)
                ]));
            }), 128))
        ], 512),
        _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: _ctx.getKey(tab, i),
                    class: "tab-pane"
                }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(tab)))
                ])), [
                    [_vShow, _ctx.activeIndex === i]
                ]);
            }), 128))
        ])
    ], 64));
}
